import { useContext, useState, useRef } from 'react';
import {
    Box,
    Grid,
    FormControl,
    Paper,
    Typography,
    MenuItem,
    OutlinedInput,
    Button,
    FormHelperText,
    TextField,
    Select,
    Alert,
    CircularProgress,
    InputLabel,
    Switch
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as Yup from "yup";
import { DateTimePicker, TimePicker } from '@mui/x-date-pickers';
import { ProfileContext } from '../../context/ProfileContext';
import { editWebsite } from './InvitationsApiFunctions';
import CustomizedSnackbars from '../../ui-common-components/ui-components/SnackBar';
const HomePage = () => {
    const profile = useContext(ProfileContext);
    const [loading, setLoading] = useState(false);
    const openSnackBar = useRef(null);
    const [checked, setChecked] = useState(profile.wedding.weddingWebsite.RSVPsIsVisible);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const handleCheckedChange = (event) => {
        setChecked(event.target.checked);
    };
    return (
        <>

            <CustomizedSnackbars
                openSnackBar={openSnackBar}
                message={message}
                severity={severity}
            />
            <Box sx={{
                width: { xs: "100%", md: '65%' },
                paddingX: { md: "20px" },
                marginBottom: "20px"
            }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Formik
                        initialValues={
                            {
                                brideFirstName: profile.wedding.brideFirstName,
                                brideLastName: profile.wedding.brideLastName,
                                groomFirstName: profile.wedding.groomFirstName,
                                groomLastName: profile.wedding.groomLastName,
                                weddingDate: profile.wedding.weddingDate,
                                city: profile.wedding.city,
                                area: profile.wedding.area,
                                rsvpby: profile.wedding.RSVPBy,
                                submit: null,
                            }
                        }
                        validationSchema={
                            Yup.object().shape({
                                brideFirstName: Yup.string().max(255).required("Bride's First name is required"),
                                brideLastName: Yup.string().max(255).required("Bride's Last name is required"),
                                groomFirstName: Yup.string().max(255).required("Groom's First name is required"),
                                groomLastName: Yup.string().max(255).required("Groom's Last name is required"),
                                weddingDate: Yup.string().required("Wedding Date is required").typeError("Please enter valid date"),
                                city: Yup.string().max(255).required("City is required"),
                                area: Yup.string().max(255).required("Area is required"),
                                rsvpby: Yup.string().required("RSVP due date is required").typeError("Please enter valid date"),
                            })}
                        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                            setLoading(true);
                            try {
                                const response = await editWebsite({
                                    groomFirstName: values.groomFirstName,
                                    groomLastName: values.groomLastName,
                                    brideFirstName: values.brideFirstName,
                                    brideLastName: values.brideLastName,
                                    weddingDate: values.weddingDate.toString(),
                                    RSVPBy: values.rsvpby.toString(),
                                    RSVPsIsVisible: checked,
                                    area: values.area,
                                    city: values.city
                                });
                                if (response.status == 200) {
                                    setSeverity("success");
                                    setMessage("Home page updated successfully");
                                    openSnackBar.current();
                                    window.location.reload();
                                }
                            } catch (error) {
                                setSeverity("error");
                                setMessage(error.response.data.message);
                                openSnackBar.current();
                            } finally {
                                setLoading(false);
                            }

                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            setFieldValue,
                            touched,
                            values,
                        }) => (
                            <form style={{ width: '100%' }} noValidate onSubmit={handleSubmit}>
                                <Grid container direction={"row"}>
                                    {/* Bride First Name */}
                                    <Grid item xs={12} md={6} paddingX={1}>
                                        <FormControl
                                            fullWidth
                                            error={Boolean(touched.brideLastName && errors.brideLastName)}
                                            variant="outlined"
                                            sx={{
                                                marginBottom: "10px",
                                            }}
                                        >
                                            <OutlinedInput
                                                sx={{
                                                    background: "#F8FAFD",
                                                    border: "1px solid #E7ECF3",
                                                    borderRadius: "10px",
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#E7ECF3',
                                                    },
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#E7ECF3',
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#E7ECF3',

                                                    },
                                                    fieldSet: {
                                                        legend: {
                                                            width: "unset",
                                                        }
                                                    }
                                                }}
                                                id={"outlined-adornment-" + "brideFirstName"}
                                                type={"text"}
                                                value={values["brideFirstName"]}
                                                name={"brideFirstName"}
                                                placeholder={"Bride's First Name"}
                                                onBlur={handleBlur}
                                                onChange={handleChange}


                                            />
                                            {touched.brideFirstName && errors.brideFirstName && (
                                                <FormHelperText
                                                    error
                                                    id="standard-weight-helper-text-email-login"
                                                >
                                                    {errors.brideFirstName}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    {/* Bride Last Name */}
                                    <Grid item xs={12} md={6} paddingX={1}>
                                        <FormControl
                                            fullWidth
                                            error={Boolean(touched.brideLastName && errors.brideLastName)}
                                            variant="outlined"
                                            sx={{
                                                marginBottom: "10px",
                                            }}
                                        >
                                            <OutlinedInput
                                                sx={{
                                                    background: "#F8FAFD",
                                                    border: "1px solid #E7ECF3",
                                                    borderRadius: "10px",
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#E7ECF3',
                                                    },
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#E7ECF3',
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#E7ECF3',

                                                    },
                                                    fieldSet: {
                                                        legend: {
                                                            width: "unset",
                                                        }
                                                    }
                                                }}
                                                id={"outlined-adornment-" + "brideLastName"}
                                                type={"text"}
                                                value={values["brideLastName"]}
                                                name={"brideLastName"}
                                                placeholder={"Bride's Last Name"}
                                                onBlur={handleBlur}
                                                onChange={handleChange}


                                            />
                                            {touched.brideLastName && errors.brideLastName && (
                                                <FormHelperText
                                                    error
                                                    id="standard-weight-helper-text-email-login"
                                                >
                                                    {errors.brideLastName}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>

                                    {/* Groom First Name */}
                                    <Grid item xs={12} md={6} paddingX={1}>
                                        <FormControl
                                            fullWidth
                                            error={Boolean(touched.groomFirstName && errors.groomFirstName)}
                                            variant="outlined"
                                            sx={{
                                                marginBottom: "10px",
                                            }}
                                        >
                                            <OutlinedInput
                                                sx={{
                                                    background: "#F8FAFD",
                                                    border: "1px solid #E7ECF3",
                                                    borderRadius: "10px",
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#E7ECF3',
                                                    },
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#E7ECF3',
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#E7ECF3',

                                                    },
                                                    fieldSet: {
                                                        legend: {
                                                            width: "unset",
                                                        }
                                                    }
                                                }}
                                                id={"outlined-adornment-" + "groomFirstName"}
                                                type={"text"}
                                                value={values["groomFirstName"]}
                                                name={"groomFirstName"}
                                                placeholder={"Groom's First Name"}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                            {touched.groomFirstName && errors.groomFirstName && (
                                                <FormHelperText
                                                    error
                                                    id="standard-weight-helper-text-email-login"
                                                >
                                                    {errors.groomFirstName}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>

                                    {/* Groom Last Name */}
                                    <Grid item xs={12} md={6} paddingX={1}>
                                        <FormControl
                                            fullWidth
                                            error={Boolean(touched.groomLastName && errors.groomLastName)}
                                            variant="outlined"
                                            sx={{
                                                marginBottom: "10px",
                                            }}
                                        >
                                            <OutlinedInput
                                                sx={{
                                                    background: "#F8FAFD",
                                                    border: "1px solid #E7ECF3",
                                                    borderRadius: "10px",
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#E7ECF3',
                                                    },
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#E7ECF3',
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#E7ECF3',

                                                    },
                                                    fieldSet: {
                                                        legend: {
                                                            width: "unset",
                                                        }
                                                    }
                                                }}
                                                id={"outlined-adornment-" + "groomLastName"}
                                                type={"text"}
                                                value={values["groomLastName"]}
                                                name={"groomLastName"}
                                                placeholder={"Groom's Last Name"}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                            {touched.groomLastName && errors.groomLastName && (
                                                <FormHelperText
                                                    error
                                                    id="standard-weight-helper-text-email-login"
                                                >
                                                    {errors.groomLastName}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>

                                    {/* Wedding Date */}
                                    <Grid item xs={12} md={6} paddingX={1}>
                                        <InputLabel
                                            sx={{ color: "#000", width: "100%", marginY: "0.5rem", marginLeft: "0.5rem" }}
                                        >
                                            Wedding Date
                                        </InputLabel>
                                        <FormControl fullWidth variant="outlined">
                                            <DateTimePicker

                                                value={values["weddingDate"]}
                                                onChange={(value) => { setFieldValue("weddingDate", value); }}
                                                minDate={new Date()}

                                                renderInput={(params) =>
                                                    <TextField

                                                        id="outlined-adornment-weddingDate"
                                                        variant="outlined"
                                                        name="weddingDate"
                                                        sx={{
                                                            marginBottom: "10px",
                                                            background: "#F8FAFD",
                                                            borderRadius: "10px",
                                                            border: "1px solid #E7ECF3",
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset.MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: 'transparent',
                                                                },
                                                            },
                                                            fieldSet: {
                                                                legend: {
                                                                    width: "unset",
                                                                }
                                                            }
                                                        }

                                                        }

                                                        {...params} />
                                                }
                                            />
                                            {touched.weddingDate && errors.weddingDate && (
                                                <FormHelperText
                                                    error
                                                    id="standard-weight-helper-weddingDate"
                                                >
                                                    {errors.weddingDate}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>

                                    {/* rsvpby */}
                                    <Grid item xs={12} md={6} paddingX={1}>
                                        <InputLabel
                                            sx={{ color: "#000", width: "100%", marginY: "0.5rem", marginLeft: "0.5rem" }}
                                        >
                                            RSVP By
                                        </InputLabel>
                                        <FormControl fullWidth variant="outlined">
                                            <DatePicker
                                                value={values["rsvpby"]}
                                                onChange={(value) => { setFieldValue("rsvpby", value); }}
                                                minDate={new Date()}
                                                renderInput={(params) =>
                                                    <TextField
                                                        id="outlined-adornment-rsvpby"
                                                        variant="outlined"
                                                        name="rsvpby"
                                                        sx={{
                                                            marginBottom: "10px",
                                                            background: "#F8FAFD",
                                                            borderRadius: "10px",
                                                            border: "1px solid #E7ECF3",
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset.MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: 'transparent',
                                                                },
                                                            },
                                                            fieldSet: {
                                                                legend: {
                                                                    width: "unset",
                                                                }
                                                            }
                                                        }
                                                        } {...params} />
                                                }
                                            />
                                            {touched.rsvpby && errors.rsvpby && (
                                                <FormHelperText
                                                    error
                                                    id="standard-weight-helper-rsvpby"
                                                >
                                                    {errors.rsvpby}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>

                                    {/* City */}
                                    <Grid item xs={12} md={6} paddingX={1}>
                                        <FormControl
                                            fullWidth
                                            error={Boolean(touched.city && errors.city)}
                                            variant="outlined"
                                            sx={{
                                                marginBottom: "10px",
                                            }}
                                        >
                                            <OutlinedInput
                                                sx={{
                                                    background: "#F8FAFD",
                                                    border: "1px solid #E7ECF3",
                                                    borderRadius: "10px",
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#E7ECF3',
                                                    },
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#E7ECF3',
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#E7ECF3',

                                                    },
                                                    fieldSet: {
                                                        legend: {
                                                            width: "unset",
                                                        }
                                                    }
                                                }}
                                                id={"outlined-adornment-" + "city"}
                                                type={"text"}
                                                value={values["city"]}
                                                name={"city"}
                                                placeholder={"City"}

                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                            {touched.city && errors.city && (
                                                <FormHelperText
                                                    error
                                                    id="standard-weight-helper-city"
                                                >
                                                    {errors.city}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>

                                    {/* Area */}
                                    <Grid item xs={12} md={6} paddingX={1}>
                                        <FormControl
                                            fullWidth
                                            error={Boolean(touched.area && errors.area)}
                                            variant="outlined"
                                            sx={{
                                                marginBottom: "10px",
                                            }}
                                        >
                                            <OutlinedInput
                                                sx={{
                                                    background: "#F8FAFD",
                                                    border: "1px solid #E7ECF3",
                                                    borderRadius: "10px",
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#E7ECF3',
                                                    },
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#E7ECF3',
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#E7ECF3',

                                                    },
                                                    fieldSet: {
                                                        legend: {
                                                            width: "unset",
                                                        }
                                                    }
                                                }}
                                                id={"outlined-adornment-" + "area"}
                                                type={"text"}
                                                value={values["area"]}
                                                name={"area"}
                                                placeholder={"Area"}

                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                            {touched.area && errors.area && (
                                                <FormHelperText
                                                    error
                                                    id="standard-weight-helper-area"
                                                >
                                                    {errors.area}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} paddingX={1}>
                                        <Paper
                                            elevation={0}
                                            sx={
                                                {
                                                    height: "64px",
                                                    backgroundColor: "#FFFFFF",
                                                    boxShadow: "0px 0px 10px rgba(153, 153, 153, 0.15)",
                                                    borderRadius: "8px",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    padding: 1
                                                }
                                            }>
                                            <Typography
                                                sx={{
                                                    width: "117px",
                                                    height: "18px",

                                                    fontFamily: 'Montserrat',
                                                    fontStyle: "normal",
                                                    fontWeight: 600,
                                                    fontSize: "16px",
                                                    lineHeight: "18px",
                                                    color: "#090A0A"
                                                }}
                                            >
                                                RSVP
                                            </Typography>
                                            {/* TODO:: chage color */}
                                            <Switch
                                                checked={checked}
                                                onChange={handleCheckedChange}
                                                size='medium'
                                                // sx={{
                                                //     "&.Mui-checked": {
                                                //         color: "red",
                                                //     }
                                                // }}
                                            />
                                        </Paper>
                                    </Grid>

                                    <Grid
                                    item
                                        xs={12}
                                        paddingX={1}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <Button
                                            size="medium"
                                            type="submit"
                                            variant="contained"
                                            disabled={loading}
                                            sx={{
                                                width: { xs: "100%", md: "307px" },
                                                height: "48px",
                                                marginTop: "10px",
                                                background: "#F1A3A5",
                                                borderRadius: "48px",
                                                fontWeight: "600",
                                                fontSize: "18px",
                                                lineHeight: "16px",
                                                color: "#FFF",
                                                '&:hover': {
                                                    backgroundColor: '#F1A3A5',
                                                    color: '#FFF',
                                                },
                                            }}
                                        >
                                            {loading ? (
                                                <CircularProgress sx={{ color: "#FFF" }} size={"1.8rem"} />
                                            ) : (
                                                "    Save changes"
                                            )}

                                        </Button>
                                    </Grid>

                                </Grid>
                            </form>
                        )}
                    </Formik>
                </LocalizationProvider >
            </Box >
        </>
    );
};
export default HomePage;