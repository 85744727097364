import React, {
  useEffect,
  useContext,
  useState,
  useRef,
  useCallback,
} from "react";
import Box from "@mui/material/Box";
import { WebAppTitle } from "../WebAppTitle";
import { ProfileContext } from "../../context/ProfileContext";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import { Grid, Typography, Chip, Stack } from "@mui/material";
import GuestsTopbar from "./GuestsTopbar";
import { useLocation } from "react-router-dom";
import {
  COOKIES_SETTINGS,
  GET_GUESTS_IN_GROUP,
} from "../../endpoints/Constants";
import axios from "axios";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CustomizedSnackbars from "../../ui-common-components/ui-components/SnackBar";
import SendMessageForm from "./SendMessageForm";
import GuestsCount from "./GuestsCount";
import GuestForm from "./GuestForm";
import { GuestsContext } from "./GuestsList";

const GuestsFromGroup = () => {
  const statusColors = {
    Attending: {
      txtColor: "#198155",
      bgColor: "#ECFCE5",
    },
    Declined: {
      txtColor: "#D3180C",
      bgColor: "#FFE5E5",
    },
    "No Response": {
      txtColor: "#A05E03",
      bgColor: "#FFEFD7",
    },
  };

  const profile = useContext(ProfileContext);
  const url = useLocation();
  const [loading, setLoading] = useState(true);
  const [loadMore, setLoadMore] = useState(false);

  const { mainList, currentGroupName } = useContext(GuestsContext);
  const [guestsList, setGuestsList] = mainList;
  const [pageTitle, setPageTitle] = currentGroupName;

  const filters = ["All", "Attending", "Declined", "No Response"];
  const [appliedFilter, setAppliedFilter] = useState(0);
  const [currentMode, setCurrentMode] = useState("all");

  const getGuests = (id, pageToLoad) => {
    let groupId = "";
    if (id !== "all") {
      groupId = "guestGroup=" + id;
      setCurrentMode("not-all");
    }

    let rsvpStatus = "";

    if (appliedFilter !== 0) {
      rsvpStatus = "RSVPStatus=" + filters[appliedFilter];
    }

    let paramsToUse = groupId ? groupId : "";

    if (paramsToUse && rsvpStatus) {
      paramsToUse = paramsToUse + "&" + rsvpStatus;
    } else if (rsvpStatus) {
      paramsToUse = rsvpStatus;
    }

    if (paramsToUse && searchContentAfterDelay) {
      paramsToUse =
        paramsToUse + "&name=" + encodeURIComponent(searchContentAfterDelay);
    } else if (searchContentAfterDelay) {
      paramsToUse = "name=" + encodeURIComponent(searchContentAfterDelay);
    }

    if (paramsToUse && pageToLoad) {
      paramsToUse = paramsToUse + "&page=" + pageToLoad;
    } else if (pageToLoad) {
      paramsToUse = "page=" + pageToLoad;
    }

    if (paramsToUse) {
      paramsToUse = "?" + paramsToUse;
    }
    //console.log("Request:", GET_GUESTS_IN_GROUP + paramsToUse);

    if (pageToLoad === 1) {
      setLoading(true);
    } else {
      setLoadMore(true);
    }

    axios
      .get(GET_GUESTS_IN_GROUP + paramsToUse, COOKIES_SETTINGS)
      .then((res) => {
        //console.log(res);
        hasMore.current = res.data.totalPages > pageToLoad;
        if (pageToLoad != 1) {// eslint-disable-line
          setGuestsList((prev) => [...prev, ...res.data.docs]);
        } else {
          setGuestsList(res.data.docs);
          setPageTitle(res.data.guestGroup.name);
        }
      })
      .finally(() => {
        setLoading(false);
        setLoadMore(false);
      });
  };

  const [searchContent, setSearchContent] = useState("");

  const [searchContentAfterDelay, setSearchContentAfterDelay] = useState("");

  const typingTimeoutRef = useRef(null);

  const searchGuests = () => {
    setSearchContentAfterDelay(searchContent);
  };

  useEffect(() => {
    localStorage.setItem("apdfltr", filters[0])
  },[])

  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(searchGuests, 500);
  }, [searchContent]);// eslint-disable-line

  const observer = useRef();
  const pageToLoad = useRef(1);
  const hasMore = useRef(true);

  useEffect(() => {
    setCurrentMode("all");
    try {
      const id = url.pathname.split("/")[2];
      if (id) {
        pageToLoad.current = 1;
        getGuests(id, pageToLoad.current);
      } else {
        window.location.pathname = "/guests";
      }
    } catch (error) {
      window.location.pathname = "/guests";
    }
  }, [appliedFilter, searchContentAfterDelay]);// eslint-disable-line

  const applyFilter = (index) => {
    setAppliedFilter(index);
    localStorage.setItem("apdfltr", filters[index])
  };

  // Pagination

  let lastGuestElementRef = useCallback((node) => {
    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (hasMore.current) {
          pageToLoad.current = pageToLoad.current + 1;
          const id = url.pathname.split("/")[2];
          getGuests(id, pageToLoad.current);
        }
      }
    });

    if (node) {
      observer.current.observe(node);
    }
  }, []);// eslint-disable-line

  const openSnackBar = useRef(null);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSevirity, setSnackBarSevirity] = useState("");
  const openSendMessageForm = useRef(null);

  const selectedGuest = useRef(null);

  const checkAndSendMessage = () => {
    if (profile.wedding.weddingWebsite) {
      openSendMessageForm.current();
    } else {
      setSnackBarMessage("You haven't setup your invitation yet!");
      setSnackBarSevirity("warning");
      openSnackBar.current();
    }
  };

  const [selectedGuestObject, setSelectedGuestObject] = useState(null);
  const openGuestForm = useRef(null);

  return (
    <>
      <WebAppTitle text={pageTitle} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        <GuestsCount />
      </Box>

      <Box
        sx={{
          marginTop: "0.75rem",
          marginBottom: "4rem",
          minHeight: "200px",
        }}
      >
        <CustomizedSnackbars
          openSnackBar={openSnackBar}
          message={snackBarMessage}
          severity={snackBarSevirity}
        />

        <Box>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <GuestsTopbar
                searchText={searchContent}
                controlFunction={setSearchContent}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <Stack
                direction={{ xs: "row", md: "column" }}
                spacing={3}
                marginBottom={"10px"}
                sx={{
                  overflowX: "scroll",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  marginTop: { xs: 0, md: "1rem" },
                }}
              >
                {filters.map((item, index) => {
                  return (
                    <Chip
                      key={index}
                      label={item}
                      sx={{
                        color: appliedFilter === index ? "#FFF" : "#475467",
                        backgroundColor:
                          appliedFilter === index
                            ? "#F1A3A5"
                            : "rgba(255,255,255,0.6)",
                        borderRadius: "7px",
                        width: "124px",
                        minWidth: "124px",
                        height: "44px",
                        pointerEvents: appliedFilter === index ? "none" : null,
                        fontWeight: "bold",
                        "&:hover": {
                          backgroundColor: "#F1A3A5",
                          color: "#FFF",
                        },
                      }}
                      onClick={() => {
                        applyFilter(index);
                      }}
                    />
                  );
                })}
              </Stack>
            </Grid>

            <Grid
              item
              container
              spacing={1}
              xs={12}
              md={10}
              justifyContent="center"
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: { xs: "none", md: "block" },
                }}
              >
                <GuestsTopbar
                  searchText={searchContent}
                  controlFunction={setSearchContent}
                />
              </Grid>
              {loading ? (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "200px",
                  }}
                >
                  <Container
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <CircularProgress></CircularProgress>
                  </Container>
                </Box>
              ) : (
                <>
                  {guestsList.length === 0 ? (
                    <Typography
                      sx={{
                        textAlign: "center",
                        marginTop: "1rem",
                      }}
                    >
                      No guests added yet
                    </Typography>
                  ) : (
                    <>
                      {guestsList.map((guest, index) => {
                        return (
                          <Grid
                            ref={
                              index + 1 === guestsList.length
                                ? lastGuestElementRef
                                : null
                            }
                            key={"guest-" + index}
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            lg={4}
                            sx={{
                              transition: "all .2s ease-in-out",
                              "&:hover": {
                                transform: "scale(1.01)",
                                cursor: "pointer",
                              },
                            }}
                            onClick={(e) => {
                              if (e.target.tagName !== "svg") {// eslint-disable-line
                                setSelectedGuestObject(guest);
                                openGuestForm.current();
                              }
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "1.5rem 1rem",
                                boxShadow:
                                  "0px 0px 10px rgba(153, 153, 153, 0.15)",
                                borderRadius: "8px",
                                background: "#FFF",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: { xs: "55%", md: "55%", lg: "60%" },
                                }}
                              >
                                {currentMode === "all" ? (
                                  <Typography
                                    sx={{
                                      fontWeight: 400,
                                      color: "#878787",
                                      fontSize: "0.75rem",
                                    }}
                                  >
                                    {guest.guestGroup.name}
                                  </Typography>
                                ) : null}
                                <Box sx={{
                                  display:"flex",
                                  justifyContent: "space-between"
                                }}>
                                  <Typography
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: { xs: "1rem", sm: "1.1rem" },

                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                    >
                                    {guest.firstName + " " + guest.lastName}
                                  </Typography>

                                  <Typography
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: { xs: "1rem", sm: "1.1rem" },
                                      color:"#AB9E72"
                                    }}
                                    >
                                    {guest.accompanyingGuests.length ? ("+"+guest.accompanyingGuests.length) : null}
                                  </Typography>
                                </Box>

                                <Typography
                                  sx={{
                                    fontWeight: 400,
                                    color: "#878787",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    //flexDirection:"column",
                                    //alignItems:"flex-start",
                                    fontSize: {
                                      xs: "2.9vw",
                                      sm: "1.4vw",
                                      md: "1.2vw",
                                      lg: "1vw",
                                    },
                                  }}
                                >
                                  {guest.phone}

                                  <WhatsAppIcon
                                    sx={{
                                      marginLeft: "5px",
                                      color: "#5BD066",
                                      cursor: "pointer",
                                    }}
                                    fontSize="small"
                                    onClick={(e) => {
                                      if(e.target.tagName === "svg"){
                                        selectedGuest.current = guest;
                                        checkAndSendMessage();
                                      }
                                    }}
                                  ></WhatsAppIcon>
                                </Typography>
                              </Box>
                              <Typography
                                sx={{
                                  fontWeight: 400,
                                  color:
                                    statusColors[guest.RSVPStatus].txtColor,
                                  fontSize: "0.70rem",
                                  backgroundColor:
                                    statusColors[guest.RSVPStatus].bgColor,
                                  padding: "0.5rem 1rem",
                                  borderRadius: "48px",
                                }}
                              >
                                {guest.RSVPStatus}
                              </Typography>
                            </Box>
                          </Grid>
                        );
                      })}

                      {loadMore ? (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            height: "200px",
                          }}
                        >
                          <Container
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <CircularProgress></CircularProgress>
                          </Container>
                        </Box>
                      ) : null}
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Box>
        <SendMessageForm
          openDialog={openSendMessageForm}
          whatsapp={true}
          selectedGuest={selectedGuest}
        />
      </Box>

      <GuestForm
        opendialog={openGuestForm}
        data={selectedGuestObject}
        toedit={"true"}
      />
    </>
  );
};
export default GuestsFromGroup;
