import { useEffect, useState, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  FormControl,
  OutlinedInput,
  Button,
  CircularProgress,
} from "@mui/material";
import Rating from "@mui/material/Rating";
import { REVIEW_VENDOR, COOKIES_SETTINGS } from '../../../endpoints/Constants'
import axios from "axios";
import CustomizedSnackbars from "../../../ui-common-components/ui-components/SnackBar";

export default function RequestQuoteDialog({ vendorId, openDialog, setSuccessMessage }) {
  const formStyle = {
    marginBottom: "10px",
    background: "#F8FAFD",
    border: "1px solid #E7ECF3",
    borderRadius: "10px",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E7ECF3",
    },
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "#E7ECF3",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E7ECF3",
    },
    fieldSet: {
      legend: {
        width: "unset",
      },
    },
  };
  const initialValues = {
    firstName: "",
    lastName: "",
    stars: 5,
    text: "",
    vendorId,
  }
  const [item, setItem] = useState(initialValues);
  const [reviewing, setReviewing] = useState(false);
  const errorSnackBar = useRef(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const [open, setOpen] = useState(false);

  const handleChange = (event) => {
    setItem({ ...item, [event.target.name]: event.target.value });
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    openDialog.current = handleClickOpen;
  });
  const handleSubmit = async (event) => {
    event.preventDefault();
    setReviewing(true);
    try {
    await axios.post(REVIEW_VENDOR, item, COOKIES_SETTINGS);
    }
    catch (e) {
      setErrorMessage(e.response.data.message);
      errorSnackBar.current();
      return;
    }
    setReviewing(false);
    setSuccessMessage("Review submitted successfully");
    setItem(initialValues);
    handleClose();
  };
  return (
    <div>
      <CustomizedSnackbars
        openSnackBar={errorSnackBar}
        message={errorMessage}
        severity="error"
      ></CustomizedSnackbars>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: 15,
            "& .MuiPickersDay-root": {
              "&.Mui-selected": {
                backgroundColor: "black",
              },
            },
          },
        }}
        sx={{}}
      >
        <Box width={{ xs: 300, md: 500 }} sx={{ overflowX: "hidden" }}>
          <DialogContent sx={{ padding: "0", width: "100%" }}>
            <DialogTitle color="#101828">
              <Typography
                fontStyle={"normal"}
                fontWeight={"700"}
                fontSize={"30px"}
                lineHeight={"38px"}
                textAlign={"center"}
                color={"#101828"}
              >
                {"Tell us about your experience"}
              </Typography>
            </DialogTitle>
            <Rating
              name="hover-feedback"
              // defaultValue={5}
              value={item.stars}
              // precision={0.5}
              size="large"
              onChange={(event, newValue) => {
                setItem({ ...item, stars: newValue });
              }}
              sx={{ paddingLeft: "20px" }}
            />
            <form onSubmit={handleSubmit}>
              <Box paddingX={2} paddingY={3}>
                <FormControl fullWidth variant="outlined">
                  <OutlinedInput
                    required
                    sx={formStyle}
                    id={"outlined-adornment-" + "firstName"}
                    type={"text"}
                    value={item["firstName"]}
                    name={"firstName"}
                    placeholder={"First Name"}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl fullWidth variant="outlined">
                  <OutlinedInput
                    required
                    sx={formStyle}
                    id={"outlined-adornment-" + "lastName"}
                    type={"text"}
                    value={item["lastName"]}
                    name={"lastName"}
                    placeholder={"Last Name"}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl fullWidth variant="outlined">
                  <OutlinedInput
                    required
                    sx={formStyle}
                    id={"outlined-adornment-" + "text"}
                    type={"text"}
                    value={item["text"]}
                    name={"text"}
                    multiline
                    rows={4}
                    placeholder={"Please write a review"}
                    onChange={handleChange}
                  />
                </FormControl>

                <Button
                  fullWidth
                  size="medium"
                  type="submit"
                  variant="contained"
                  disabled={reviewing}
                  sx={{
                    height: "48px",
                    marginTop: "10px",

                    background: "#F1A3A5",
                    borderRadius: "48px",
                    fontWeight: "600",
                    fontSize: "18px",
                    lineHeight: "16px",
                    color: "#FFF",
                    "&:hover": {
                      backgroundColor: "#F1A3A5",
                      color: "#FFF",
                    },
                  }}
                >
                  {reviewing?
                  <CircularProgress sx={{
                    color: "#FFF",
                    width: "20px",
                  }}></CircularProgress>:`Submit a Review`}
                </Button>
              </Box>
            </form>
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
}
