import { Box, Grid, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, useContext } from "react";
// third party
import * as Yup from "yup";
import { Formik } from "formik";

import {
  COOKIES_SETTINGS,
  CREATE_USER_WEDDING,
} from "../../../endpoints/Constants";
import axios from "axios";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const WeddingInfoForm = (props) => {
  const [loading, setLoading] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Formik
        initialValues={{
          brideFirstName: "",
          brideLastName: "",
          groomFirstName: "",
          groomLastName: "",
          weddingDate: "",
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          brideFirstName: Yup.string()
            .max(255)
            .required("Bride's First name is required"),
          brideLastName: Yup.string()
            .max(255)
            .required("Bride's Last name is required"),

          groomFirstName: Yup.string()
            .max(255)
            .required("Groom's First name is required"),
          groomLastName: Yup.string()
            .max(255)
            .required("Groom's Last name is required"),
          weddingDate: Yup.date().required("Wedding Date is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setLoading(true);
            let formData = {
              groomFirstName: values.groomFirstName,
              groomLastName: values.groomLastName,
              brideFirstName: values.brideFirstName,
              brideLastName: values.brideLastName,
              weddingDate: values.weddingDate,
            };

            axios
              .post(CREATE_USER_WEDDING, formData, COOKIES_SETTINGS)
              .then((res) => {
                setLoading(false);

                if (res.status === 200) {
                  setStatus({ success: true });
                  window.location.reload();
                } else {
                  setStatus({ success: false });
                  setErrors({ submit: res.data.message });
                }
              })
              .catch((err) => {
                setLoading(false);
                setStatus({ success: false });
                setErrors({ submit: err.response.data.message });
              });
          } catch (err) {
            //setLoading(false);
            setStatus({ success: false });
            setErrors({ submit: "Error" });
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container justifyContent="center" alignItems="center">
              {errors.submit && (
                <Alert severity="error" sx={{ width: "100%", margin: "1rem" }}>
                  {errors.submit}
                </Alert>
              )}
              <FormControl
                fullWidth
                error={Boolean(touched.brideFirstName && errors.brideFirstName)}
                sx={{ margin: "0.5rem 1rem" }}
              >
                <InputLabel
                  htmlFor="outlined-adornment-brideFirstName"
                  sx={{ color: "text.lightGray" }}
                >
                  Bride's First Name
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-brideFirstName"
                  type="text"
                  value={values.brideFirstName}
                  name="brideFirstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Bride's First Name"
                  inputProps={{}}
                  style={{
                    color: "#000",
                    borderRadius: "10px",
                  }}
                />
                {touched.brideFirstName && errors.brideFirstName && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-brideFirstName"
                  >
                    {errors.brideFirstName}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl
                fullWidth
                error={Boolean(touched.brideLastName && errors.brideLastName)}
                sx={{ margin: "0.5rem 1rem" }}
              >
                <InputLabel
                  htmlFor="outlined-adornment-brideLastName"
                  sx={{ color: "text.lightGray" }}
                >
                  Bride's Last Name
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-brideLastName"
                  type="text"
                  value={values.brideLastName}
                  name="brideLastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Bride's Last Name"
                  inputProps={{}}
                  style={{
                    color: "#000",
                    borderRadius: "10px",
                  }}
                />
                {touched.brideLastName && errors.brideLastName && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-brideLastName"
                  >
                    {errors.brideLastName}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl
                fullWidth
                error={Boolean(touched.groomFirstName && errors.groomFirstName)}
                sx={{ margin: "0.5rem 1rem" }}
              >
                <InputLabel
                  htmlFor="outlined-adornment-groomFirstName"
                  sx={{ color: "text.lightGray" }}
                >
                  Groom's First Name
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-groomFirstName"
                  type="text"
                  value={values.groomFirstName}
                  name="groomFirstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Groom's First Name"
                  inputProps={{}}
                  style={{
                    color: "#000",
                    borderRadius: "10px",
                  }}
                />
                {touched.groomFirstName && errors.groomFirstName && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-groomFirstName"
                  >
                    {errors.groomFirstName}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl
                fullWidth
                error={Boolean(touched.groomLastName && errors.groomLastName)}
                sx={{ margin: "0.5rem 1rem" }}
              >
                <InputLabel
                  htmlFor="outlined-adornment-groomLastName"
                  sx={{ color: "text.lightGray" }}
                >
                  Groom's Last Name
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-groomLastName"
                  type="text"
                  value={values.groomLastName}
                  name="groomLastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Groom's Last Name"
                  inputProps={{}}
                  style={{
                    color: "#000",
                    borderRadius: "10px",
                  }}
                />
                {touched.groomLastName && errors.groomLastName && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-groomLastName"
                  >
                    {errors.groomLastName}
                  </FormHelperText>
                )}
              </FormControl>
              <Box
              sx={{
                textAlign: "left",
                color: "#475467",
                width:"100%",
                margin: "0.5rem 0.5rem 0rem 1.2rem"
              }}
              >

              <InputLabel
                sx={{
                  textAlign: "left",
                  color: "#475467",
                }}
                >
                Wedding Date
              </InputLabel>
                </Box>
              <FormControl
                fullWidth
                error={Boolean(touched.weddingDate && errors.weddingDate)}
                variant="outlined"
                sx={{ margin: "0.5rem 1rem" }}
              >
                <DatePicker
                  id="wedding-date"
                  value={values["weddingDate"]}
                  onChange={(value) => {
                    setFieldValue("weddingDate", value);
                  }}
                  minDate={new Date()}
                  renderInput={(params) => (
                    <TextField
                      id="outlined-adornment-weddingDate"
                      variant="outlined"
                      name="weddingDate"
                      sx={{
                        marginBottom: "10px",
                        background: "#F8FAFD",
                        borderRadius: "10px",
                        border: "1px solid #E7ECF3",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset.MuiOutlinedInput-notchedOutline": {
                            borderColor: "transparent",
                          },
                        },
                        fieldSet: {
                          legend: {
                            width: "unset",
                          },
                        },
                      }}
                      {...params}
                    />
                  )}
                />
                {touched.weddingDate && errors.weddingDate && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-weddingDate"
                  >
                    {errors.weddingDate}
                  </FormHelperText>
                )}
              </FormControl>

              <Grid item xs={11}>
                <Button
                  fullWidth
                  disabled={loading}
                  size="medium"
                  type="submit"
                  variant="contained"
                  sx={{
                    padding: "0.7rem 0rem",
                    color: "text.white",
                    backgroundColor: "text.tertiary",
                    ":hover": {
                      backgroundColor: "text.tertiaryHover",
                    },
                    borderRadius: "48px",
                    textTransform: "none",
                    fontSize: "1.2rem",
                    marginBottom: "1.2rem",
                  }}
                >
                  {loading ? (
                    <CircularProgress sx={{ color: "#FFF" }} size={"1.8rem"} />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </LocalizationProvider>
  );
};

export default WeddingInfoForm;
