import React, { useContext, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import StarIcon from "@mui/icons-material/Star";
import Button from "@mui/material/Button";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { WebAppTitle } from "../../WebAppTitle";
import axios from "axios";
import { GET_WEBSITE_VENDOR_BY_ID } from "../../../endpoints/Constants";
import VendorReview from "./vendor-review";
import VendorMediaCarousel from "./vendor-photo-carousel";
import { AuthComponentsContext } from "../../../context/AuthComponentsContext";
import { ProfileContext } from "../../../context/ProfileContext";
import RequestQuoteDialog from "./request-quote-dialog";
import ReviewDialog from "./review-dialog";
import CustomizedSnackbars from "../../../ui-common-components/ui-components/SnackBar";

const Vendor = () => {
  const params = useParams();
  const authComponents = useContext(AuthComponentsContext);
  const profile = useContext(ProfileContext);
  const successSnackbar = useRef(null);
  const errorSnackbar = useRef(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [vendor, setVendor] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const requestQuoteDialog = useRef(null);
  const reviewDialog = useRef(null);

  const successMessageHandler = (message) => {
    setSuccessMessage(message);
    successSnackbar.current();
  };

  useEffect(() => {
    const id = params.vendorId;
    axios
      .get(GET_WEBSITE_VENDOR_BY_ID + `/${id}`)
      .then((response) => {
        //console.log(response.data);
        setVendor(response.data.vendor);
        setReviews(response.data.reviews);
      })
      .finally(() => setIsLoading(false));
  }, []);
  return (
    <>
      <CustomizedSnackbars
        openSnackBar={successSnackbar}
        message={successMessage}
        severity="success"
      ></CustomizedSnackbars>
      <CustomizedSnackbars
        openSnackBar={errorSnackbar}
        message={errorMessage}
        severity="error"
      ></CustomizedSnackbars>
      <RequestQuoteDialog
        openDialog={requestQuoteDialog}
        vendorId={params.vendorId}
        setSuccessMessage={successMessageHandler}
      />
      <ReviewDialog
        openDialog={reviewDialog}
        vendorId={params.vendorId}
        setSuccessMessage={successMessageHandler}
      />
      <Box minHeight="200px">
        {isLoading ? (
          <Container
            sx={{
              textAlign: "center",
            }}
          >
            <CircularProgress></CircularProgress>
          </Container>
        ) : vendor === null ? (
          <Typography textAlign="center">No Vendor Found</Typography>
        ) : (
          <div>
            <WebAppTitle text={vendor.name} />
            <Box
              minHeight="500px"
              sx={{
                margin: "20px 58px 58px 58px",
              }}
            >
              <VendorMediaCarousel
                vendor={vendor}
                setVendor={setVendor}
                setSuccessfulSnackBarText={setSuccessMessage}
                successfulSnackBar={successSnackbar}
                errorSnackBar={errorSnackbar}
                setErrorSnackBarText={setErrorMessage}
              />
              <Grid
                container
                spacing={2}
                sx={{
                  marginTop: "20px",
                }}
              >
                <Grid item xs={12} sm={12} md={8}>
                  <Paper
                    sx={{
                      padding: "20px",
                      height: "100%",
                    }}
                    elevation={0}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "0rem 0",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#090A0A",
                          fontWeight: "700",
                          fontSize: { xs: "0.9rem", md: "1.2rem" },
                          marginBottom: "0.2rem",
                        }}
                      >
                        {vendor.name}
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <StarIcon
                          fontSize="small"
                          sx={{
                            color: "#ffb400",
                            marginRight: "0.2rem",
                          }}
                        />
                        <span>
                          <Typography
                            sx={{
                              fontWeight: "700",
                              color: "#090A0A",
                              fontSize: "12px",
                            }}
                          >
                            {vendor.rating.toFixed(2)}
                          </Typography>
                        </span>
                      </Box>
                    </Box>
                    <Typography
                      sx={{
                        color: "#090A0A",
                        fontWeight: "400",
                        fontSize: { xs: "0.8rem", md: "0.9rem" },
                        marginBottom: "0.5rem",
                      }}
                    >
                      {vendor.area || "No area"}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#090A0A",
                        fontWeight: "400",
                        fontSize: { xs: "0.6rem", md: "0.8rem" },
                      }}
                    >
                      {vendor.about}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Paper
                    sx={{
                      padding: "20px",
                      height: "100%",
                    }}
                    elevation={0}
                  >
                    {!vendor.phone || vendor.phone === "" ? null : (
                      <Grid container justifyContent="space-between">
                        <Grid item>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{ width: 10, paddingLeft: 2 }}
                              class="fa fa-phone"
                              aria-hidden="true"
                            ></span>
                            <Typography
                              sx={{
                                marginLeft: "13.4px",
                                fontSize: { xs: "13px", md: "14px" },
                              }}
                            >
                              {vendor.phone}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Typography
                            sx={{
                              color: "#F1A3A5",
                              fontSize: { xs: "13px", md: "14px" },
                            }}
                          >
                            Call
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    {!vendor.websiteUrl || vendor.websiteUrl === "" ? null : (
                      <a
                        target="_blank"
                        href={vendor.websiteUrl}
                        // color="inherit"
                        style={{
                          ":hover": {
                            textDecoration: "none",
                            color: "#F1A3A5 !important",
                          },
                        }}
                      >
                        <Grid container justifyContent="space-between">
                          <Grid item width="80%">
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <LanguageOutlinedIcon
                                width="10px"
                                height="10px"
                                fontSize="10px"
                              ></LanguageOutlinedIcon>
                              {/* <span
                            style={{ width: 10 }}
                            class="fa fa-globe"
                            aria-hidden="true"
                          ></span> */}
                              <Typography
                                noWrap
                                sx={{
                                  marginLeft: "8.5px",
                                  fontSize: { xs: "13px", md: "14px" },
                                }}
                              >
                                {vendor.websiteUrl}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Typography
                              sx={{
                                color: "#F1A3A5",
                                fontSize: { xs: "13px", md: "14px" },
                              }}
                            >
                              Visit
                            </Typography>
                          </Grid>
                        </Grid>
                      </a>
                    )}
                    {!vendor.facebookLink ||
                    vendor.facebookLink === "" ? null : (
                      <a
                        href={vendor.facebookLink}
                        underline="none"
                        color="inherit"
                        sx={{
                          "&:hover": {
                            color: "inherit",
                          },
                        }}
                      >
                        <Grid container justifyContent="space-between">
                          <Grid item width="80%">
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{ width: 10, paddingLeft: 3 }}
                                class="fa fa-facebook"
                                aria-hidden="true"
                              ></span>
                              <Typography
                                noWrap
                                sx={{
                                  marginLeft: "13.4px",
                                  fontSize: { xs: "13px", md: "14px" },
                                }}
                              >
                                {vendor.facebookLink || "Not provided"}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Typography
                              sx={{
                                color: "#F1A3A5",
                                fontSize: { xs: "13px", md: "14px" },
                              }}
                            >
                              Visit
                            </Typography>
                          </Grid>
                        </Grid>
                      </a>
                    )}
                    {!vendor.instagramLink ||
                    vendor.instagramLink === "" ? null : (
                      <a
                        href={vendor.instagramLink}
                        underline="none"
                        color="inherit"
                        sx={{
                          "&:hover": {
                            color: "inherit",
                          },
                        }}
                      >
                        <Grid container justifyContent="space-between">
                          <Grid item width="80%">
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{ width: 10, paddingLeft: 1 }}
                                class="fa fa-instagram"
                                aria-hidden="true"
                              ></span>
                              <Typography
                                noWrap
                                sx={{
                                  marginLeft: "13.4px",
                                  fontSize: { xs: "13px", md: "14px" },
                                }}
                              >
                                {vendor.instagramLink || "Not provided"}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Typography
                              sx={{
                                color: "#F1A3A5",
                                fontSize: "14px",
                              }}
                            >
                              Visit
                            </Typography>
                          </Grid>
                        </Grid>
                      </a>
                    )}
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      padding: "20px",
                    }}
                    elevation={0}
                  >
                    <Typography
                      sx={{
                        color: "#090A0A",
                        fontWeight: "700",
                        fontSize: "14px",
                        marginBottom: "12px",
                      }}
                    >
                      Reviews
                    </Typography>
                    {reviews.length === 0 ? (
                      <Container minHeight="100px">
                        <Typography
                          textAlign="center"
                          sx={{
                            fontSize: "14px",
                            paddingBottom: "10px",
                          }}
                        >
                          No reviews yet. Be the first to add a review
                        </Typography>
                      </Container>
                    ) : (
                      reviews.map((review) => (
                        <VendorReview review={review} key={review._id} />
                      ))
                    )}
                    <Typography
                      sx={{
                        color: "#F1A3A5",
                        fontSize: "16px",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (!profile.isUser)
                          return authComponents.openRegisterFirst(
                            "write a review"
                          );
                        reviewDialog.current();
                      }}
                    >
                      Write a Review
                    </Typography>
                  </Paper>
                  <Box
                    sx={{
                      display: { xs: "flex", md: "block" },
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="text"
                      fontWeight="bold"
                      sx={{
                        /*marginRight: "35px",*/
                        fontSize: { xs: "0.8rem", sm: "1rem", md: "1rem" },
                        color: "text.white",
                        backgroundColor: "text.tertiary",
                        padding: { xs: "0rem 3rem", md: "0.3rem 3rem" },
                        height: { xs: "2.5rem" },
                        borderRadius: "2rem",
                        ":hover": {
                          backgroundColor: "text.tertiaryHover",
                        },
                        textAlign: "center",
                        textTransform: "none",
                        marginTop: "1rem",
                      }}
                      onClick={() => {
                        if (!profile.isUser)
                          return authComponents.openRegisterFirst(
                            "request an appointment"
                          );

                        //console.log("here");
                        requestQuoteDialog.current();
                      }}
                    >
                      {" "}
                      Request an Appointment
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </div>
        )}
      </Box>
    </>
  );
};

export default Vendor;
