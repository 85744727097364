import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import Typography from "@mui/material/Typography";
import ImageListItem from "@mui/material/ImageListItem";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  Avatar,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Stack,
} from "@mui/material";
import Rating from "@mui/material/Rating";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import axios from "axios";
import PublicIcon from "@mui/icons-material/Public";
import DeleteIcon from "@mui/icons-material/Delete";
import PaginationCustom from "../../ui-common-components/ui-components/PaginationCustom";
import IconButton from "@mui/material/IconButton";
import {
  COOKIES_SETTINGS,
  GET_VENDORS,
  GET_VENDOR_REVIEWS,
  DELETE_VENDOR_REVIEW,
} from "../../endpoints/Constants";
import { Facebook, Instagram, Person } from "@mui/icons-material";

import VendorForm from "./VendorForm";
import AlertDialogSlide from "../../ui-common-components/ui-components/AlertDialogSlide";
import CustomizedSnackbars from "../../ui-common-components/ui-components/SnackBar";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const VendorDetails = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars
  const [pageLoading, setPageLoading] = useState(false);
  const [tabLoading, setTabLoading] = useState(true);

  const [profile, setProfile] = useState({});

  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    setPageLoading(false);

    const id = searchParams.get("id");
    if (id) {
      // console.log(id);
      getVendorDetails(id);
    } else {
      navigate("../vendors");
    }
  }, [searchParams, navigate]);

  const getVendorDetails = async (id) => {
    setTabLoading(true);

    try {
      axios.get(GET_VENDORS + `/${id}`, COOKIES_SETTINGS).then((res) => {

        setProfile(res.data);
        setTabLoading(false);
        // console.log("Vendor", res.data);
      });
    } catch (error) {
     // console.log(error);
      setTabLoading(false);
    }
  };

  const [tab, setTab] = useState(0);

  const handleTabChange = (event, newTab) => {
    setTab(newTab);
    //localStorage.setItem("LBCurrentTab", newTab);
  };

  const [totalPages, setTotalPages] = useState(-1);

  const handleReviews = async (page) => {
    if (profile._id) {
      setTabLoading(true);
      try {
        const response = await axios.get(
          GET_VENDOR_REVIEWS + "/" + profile._id + "/reviews?page=" + page,
          COOKIES_SETTINGS
        );
        // console.log("reviews:", response.data);
        setReviews(response.data.docs);

        //if (totalPages === -1) setTotalPages(response.data.totalPages);
        setTotalPages(response.data.totalPages);

        setTabLoading(false);
      } catch (error) {
     //   console.log(error);
        setTabLoading(false);
      }
      setTabLoading(false);
    }
  };
  const openAlertDialog = useRef(null);
  const openSnackBar = useRef(null);
  const [deleteId, setDeleteId] = useState(null);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSevirity, setSnackBarSevirity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [actionText, setActionText] = useState("");

  const deleteReview = async () => {
    // delete review with the given id
    try {
      await axios.delete(
        DELETE_VENDOR_REVIEW + "/" + deleteId,
        COOKIES_SETTINGS
      );
      // console.log("delete review:", response);
      handleReviews(localStorage.getItem("LBCurrentPage"));
      setSnackBarMessage("Review is deleted successfully");
      setSnackBarSevirity("success");
      openSnackBar.current();
    } catch (error) {
     // console.log(error);
      setSnackBarMessage("An error occcured");
      setSnackBarSevirity("error");
      openSnackBar.current();
    }

    setDeleteId(null);
  };
  const [changePage, setChangePage] = useState(1);

  useEffect(() => {
    if (changePage === 1) {
      localStorage.setItem("LBCurrentPage", 1);
      setChangePage(changePage + 1);
    }
  }, [changePage]);

  useEffect(() => {
    handleReviews(localStorage.getItem("LBCurrentPage"));
  }, [changePage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid container spacing={0} alignItems="start" justifyContent="center">
      <AlertDialogSlide
        confirmAlertRef={deleteReview}
        openAlertDialog={openAlertDialog}
        title={"Alert"}
        description={alertMessage}
        actionText={actionText}
        severity={snackBarSevirity}
      />
      <CustomizedSnackbars
        openSnackBar={openSnackBar}
        message={snackBarMessage}
        severity={snackBarSevirity}
      />

      <Grid item xs={12} marginBottom={3}>
        <Stack
          direction={{ xs: "column", md: "row", sm: "row", lg: "row" }}
          height={{ xs: 180, sm: 60, md: 60, lg: 60 }}
          borderRadius={2}
          sx={{ backgroundColor: "#FFF" }}
          display={"flex"}
          alignContent={"center"}
          justifyContent={{
            md: "space-between",
            sm: "space-evenly",
            xs: "center",
          }}
          alignItems={"center"}
        >
          <Typography
            margin={1}
            marginLeft={{ sm: 1, md: 3, lg: 3 }}
            color={"primary.main"}
            fontSize={20}
            fontWeight={"bold"}
          >
            Vendor Details
          </Typography>

          <Box margin={3}>
            <Breadcrumbs
              aria-label="breadcrumb"
              sx={{ fontSize: { xs: "0.75rem", sm: "0.75rem", md: "1rem" } }}
            >
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="../"
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Home
              </Link>
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="../vendors"
              >
                <ShoppingBagIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Vendors
              </Link>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: { xs: "0.75rem", sm: "0.75rem", md: "1rem" },
                }}
                color={"primary.main"}
              >
                <Person sx={{ mr: 0.5 }} fontSize="inherit" />
                Profile
              </Typography>
            </Breadcrumbs>
          </Box>
        </Stack>
      </Grid>

      {pageLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid
          item
          //spacing={2}
          xs={12}
          borderRadius={2}
          sx={{ backgroundColor: "#FFF" }}
        >
          <Box sx={{ width: "100%", minHeight: "85vh" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }} margin={1}>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                aria-label="Tab navigation"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
              >
                <Tab label="Profile" {...a11yProps(0)} />
                <Tab label="Photos" {...a11yProps(1)} />
                <Tab
                  label="Reviews"
                  {...a11yProps(2)}
                  onClick={handleReviews}
                />
              </Tabs>
            </Box>
            <TabPanel value={tab} index={0}>
              {tabLoading ? (
                <Box
                  sx={{
                    width: "100%",
                    height: "70vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Grid container spacing={5} justifyContent={"space-around"}>
                  <Grid item xs={12} lg={4}>
                    <Box
                      sx={{
                        width: "100%",
                        //height: "70vh",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Stack width={"100%"}>
                        <Box
                          width={"100%"}
                          display="flex"
                          justifyContent="center"
                        >
                          <Card
                            variant="elevation"
                            sx={{
                              borderRadius: "50%",
                              marginBottom: 5,
                            }}
                            elevation={3}
                          >
                            <Avatar
                              alt="Remy Sharp"
                              //src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzHQv_th9wq3ivQ1CVk7UZRxhbPq64oQrg5Q&usqp=CAU"
                              src={
                                "https://lucky-mode-6c5e.testproxyimage.workers.dev/" +
                                profile.icon
                              }
                              sx={{ width: 200, height: 200 }}
                            />
                          </Card>
                        </Box>
                        <Box
                          width={"100%"}
                          display="flex"
                          justifyContent="center"
                        >
                          <Card
                            variant="outlined"
                            sx={{
                              padding: 2,
                              width: "100%",
                              ":hover": {
                                boxShadow: 1,
                              },
                              borderRadius: 2,
                            }}
                          >
                            <CardContent
                              sx={{
                                padding: 0,
                                paddingBottom: 1,
                                fontSize: "1rem",
                                fontWeight: "600",
                              }}
                            >
                              About
                            </CardContent>
                            <Typography marginBottom={2} fontSize={"0.9rem"}>
                              {profile.about}
                            </Typography>
                            <Divider sx={{ color: "rgb(238, 238, 238)" }} />
                            <Grid
                              container
                              spacing={2}
                              sx={{ paddingTop: "0.5rem" }}
                            >
                              {profile.websiteUrl === null ? null : (
                                <Grid
                                  item
                                  xs={12}
                                  flexWrap={"nowrap"}
                                  width={"100%"}
                                >
                                  {/*underline="hover"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            color="link.main"*/}
                                  <a
                                    className="link-custom"
                                    href={
                                      profile.websiteUrl.startsWith(
                                        "http://"
                                      ) ||
                                      profile.websiteUrl.startsWith("https://")
                                        ? profile.websiteUrl
                                        : "//" + profile.websiteUrl
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <PublicIcon
                                      sx={{
                                        mr: 0.5,
                                        color: "primary.main",
                                        fontSize: "1.5rem",
                                      }}
                                    />
                                    <Typography noWrap>
                                      {profile.websiteUrl}
                                    </Typography>
                                  </a>
                                </Grid>
                              )}
                              <Grid
                                item
                                xs={12}
                                flexWrap={"nowrap"}
                                width={"100%"}
                              >
                                <a
                                  className="link-custom"
                                  href={
                                    profile.instagramLink.startsWith(
                                      "http://"
                                    ) ||
                                    profile.instagramLink.startsWith("https://")
                                      ? profile.instagramLink
                                      : "//" + profile.instagramLink
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Instagram
                                    sx={{
                                      mr: 0.5,
                                      color: "rgb(216, 67, 21)",
                                      fontSize: "1.5rem",
                                    }}
                                  />
                                  <Typography noWrap>
                                    {profile.instagramLink}
                                  </Typography>
                                </a>
                              </Grid>
                              {profile.facebookLink === null ? null : (
                                <Grid item flexWrap={"nowrap"} width={"100%"}>
                                  <a
                                    className="link-custom"
                                    href={
                                      profile.facebookLink.startsWith(
                                        "http://"
                                      ) ||
                                      profile.facebookLink.startsWith(
                                        "https://"
                                      )
                                        ? profile.facebookLink
                                        : "//" + profile.facebookLink
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <Facebook
                                      sx={{
                                        mr: 0.5,
                                        color: "rgb(33, 150, 243)",
                                        fontSize: "1.5rem",
                                      }}
                                    />
                                    <Typography noWrap>
                                      {profile.facebookLink}
                                    </Typography>
                                  </a>
                                </Grid>
                              )}
                            </Grid>
                          </Card>
                        </Box>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Box
                      sx={{
                        width: "100%",
                        //height: "70vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "start",
                      }}
                    >
                      <Card
                        variant="outlined"
                        sx={{
                          padding: 2,
                          width: "100%",
                          ":hover": {
                            boxShadow: 1,
                          },
                          borderRadius: 2,
                        }}
                      >
                        <VendorForm
                          data={profile}
                          add={0}
                          canceladdvendordialog={() => {}}
                          successaddvendordialog={() => {}}
                        />
                      </Card>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </TabPanel>

            <TabPanel value={tab} index={1}>
              {tabLoading ? (
                <Box
                  sx={{
                    width: "100%",
                    height: "70vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Grid container spacing={0} alignItems="start" justifyContent="center">
                 
                    {profile.images && profile.images.length !== 0 ? null : (
                      <Typography textAlign={"center"}>
                        No images found!
                      </Typography>
                    )}

                    {profile.images &&
                      profile.images.map((item, index) => (
                        <Grid
                        textAlign={"right"}
                        key={"image-" + index}
                        item
                        xs={12}
                        sm={5}
                        md={3}
                        lg={2}
                        margin={1}
                                  
                      >
                        <ImageListItem
                          key={index}
                          sx={{
                            height: "200px!important",
                            width: "100%"
                          }}
                        >
                          <img
                            src={ "https://lucky-mode-6c5e.testproxyimage.workers.dev/" + item}
                            alt="..."
                            height="200px"
                            width="300"
                            loading="lazy"
                          />
                        </ImageListItem>
                      </Grid>




                       
                      ))}    
                    
 

                </Grid>
              )}
            </TabPanel>

            <TabPanel value={tab} index={2}>
              {tabLoading ? (
                <Box
                  sx={{
                    width: "100%",
                    height: "70vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Box
                  display="flex"
                  flexDirection={"column"}
                  justifyContent="center"
                  alignItems={"center"}
                >
                  {reviews.map((item, index) => (
                    <Box
                      width={{ xs: "100%", md: "100%", lg: "50%" }}
                      margin={1}
                      key={"review-" + index}
                    >
                      <Card
                        variant="outlined"
                        sx={{
                          padding: 2,
                          width: "100%",
                          ":hover": {
                            boxShadow: 1,
                          },
                          borderRadius: 2,
                        }}
                      >
                        <Stack
                          direction="row"
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Stack direction="column" alignItems={"center"}>
                            <CardContent
                              sx={{
                                padding: 0,
                                paddingBottom: 1,
                                fontSize: "1rem",
                                fontWeight: "600",
                              }}
                            >
                              {item.firstName} {item.lastName}
                            </CardContent>

                            <CardContent
                              sx={{
                                padding: 0,
                                paddingBottom: "0 !important",
                                fontSize: "1rem",
                                fontWeight: "600",
                              }}
                            >
                              <Rating
                                name="half-rating-read"
                                value={item.stars}
                                defaultValue={2.5}
                                precision={0.1}
                                readOnly
                              />
                            </CardContent>
                          </Stack>

                          <IconButton
                            sx={{
                              padding: 0,
                              paddingBottom: 0,
                              fontSize: "1rem",
                              fontWeight: "600",
                            }}
                            //onClick={() => deleteReview(item._id)}
                            onClick={() => {
                              setDeleteId(item._id);

                              setActionText("Delete");
                              setAlertMessage(
                                "Are you sure you want to delete this review?"
                              );

                              openAlertDialog.current();
                            }}
                          >
                            <DeleteIcon
                              sx={{ margin: "0.5rem", color: "#F00" }}
                            />
                          </IconButton>
                        </Stack>

                        <Divider sx={{ color: "rgb(238, 238, 238)" }} />
                        <Typography marginTop={2} fontSize={"1rem"}>
                          {item.text}
                        </Typography>

                        <Typography
                          marginTop={2}
                          textAlign={"right"}
                          color="rgba(50, 50, 50, 0.6)"
                          fontSize={"0.9rem"}
                        >
                          {moment(item.createdAt).format("DD-MMM-YYYY")}
                        </Typography>
                      </Card>
                    </Box>
                  ))}
                </Box>
              )}
              {totalPages === 0 ||
              totalPages === -1 ||
              (totalPages === 1 && reviews.length === 0) ? (
                <Typography textAlign={"center"}>No reviews found!</Typography>
              ) : (
                <div
                  onClick={(e) => {
                    if (totalPages !== 1) {
                      e.preventDefault();
                      setChangePage(changePage + 1);
                    }
                  }}
                >
                  <PaginationCustom totalPages={totalPages} />
                </div>
              )}
            </TabPanel>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default VendorDetails;
