import React, { createContext, Component } from "react";
import axios from "axios";
import { GET_PROFILE, GET_USER_PROFILE, COOKIES_SETTINGS } from "../endpoints/Constants";
//import {responseHandler} from "../common/ResponseHandler"

export const ProfileContext = createContext();

class ProfileContextProvider extends Component {
  
  state = {
    isAdmin: false,
    isOwner: false,
    isUser: false,
    isLoading: true,
    openSnackbar: false,
  };

  componentDidMount = () => {
    if(window.location.pathname.startsWith("/dashboard")){ // trying to reach dashboard
      this.setState({ isLoading:true });
      axios
      .get(GET_PROFILE, COOKIES_SETTINGS)
      .then((res) => {

        if (res.status === 200) {
          let isAdmin = false
          if(res.data.role==="admin"){ isAdmin = true }
          
          this.setState({ ...res.data, isAdmin: isAdmin , isLoading:false});
        }
      })
      .catch((err) => {
        this.setState({ isLoading:false });
        if(window.location.pathname.startsWith("/dashboard/")
        && window.location.pathname !== "/dashboard-login"){   
            window.location.href = "/dashboard-login";
          }
        });
      }
      else{ // trying to reach website or templates
        this.setState({ isLoading:true });
        axios
        .get(GET_USER_PROFILE, COOKIES_SETTINGS)
        .then((res) => {

          if (res.status === 200) {
              this.setState({ ...res.data, isUser: true,  isAdmin: false, isOwner: false , isLoading:false});
            }
          })
          .catch((err) => {
            this.setState({ isLoading:false});
            //window.location.href = "/";
          });

      }
  };

  render() {
    return (
      <ProfileContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </ProfileContext.Provider>
    );
  }
}

export default ProfileContextProvider;
