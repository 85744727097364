import React, { useEffect, useState, useContext } from "react";
import { ProfileContext } from "../../context/ProfileContext";
import { AuthComponentsContext } from "../../context/AuthComponentsContext";
import { useLocation } from "react-router-dom";

import Link from "@mui/material/Link";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

import appLogo from "../../assets/Lovebirds.png";

const navItems = [
  {
    name: "ABOUT US",
    goTo: "./about-us",
  },
  {
    name: "VENDORS",
    goTo: "./vendor-category",
  },
  {
    name: "CHECKLIST",
    goTo: "./checklist",
  },
  {
    name: "GUESTS",
    goTo: "./guests",
  },
  {
    name: "INVITATIONS",
    goTo: "./invitations",
  },
];

const Navbar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const profile = useContext(ProfileContext);
  const authComponents = useContext(AuthComponentsContext);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const url = useLocation();
  
  useEffect(() => {
    const path = window.location.pathname.split("/")[1];
    if (path === undefined || path === "") {
      setSelectedIndex(-1);
    } else {
      const index = navItems.findIndex((item) => item.goTo === "./" + path);
      setSelectedIndex(index);
    }
  }, [url]);

  /*const handleListItemClick = (event, index) => {
    //setSelectedIndex(index);
  };*/

  /*const myProfileClick = () => {
    //setSelectedIndex(-1);
  };*/


  return (
    <div id="navbar-container">

      <AppBar
        position="static"
        sx={{
          backgroundColor: "transparent",
          color: "text.primary",
          height: {xs:70, md:85},
          //marginBottom:{xs:"0", md:"1rem"},
          justifyContent: "flex-end",
        }}
        elevation={0}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
            <Box sx={{
              display:"flex",
              width:{xs:"50%", md:"15%"},
              alignItems:"center"
            }}>

              
              <Box
                sx={{ /*flexGrow: 1,*/ display: { xs: "flex", md: "none" } }}
              >
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                  sx={{ justifyContent: "end" }}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {/*<MenuItem
                    key={"mobile-link-container-signup"}
                    onClick={handleCloseNavMenu}
                  >
                    {profile.isUser ? (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box
                          sx={{
                            flexGrow: 0,
                            borderRadius: "100%",
                            marginRight: 2,
                          }}
                          backgroundColor={
                            window.location.pathname === "/my-account"
                              ? "#AB9E72"
                              : "#F9FAFB"
                          }
                        >
                         
                          <Typography
                            variant="h6"
                            color={
                              window.location.pathname === "/my-account"
                                ? "text.white"
                                : "text.primary"
                            }
                            fontSize={{
                              xs: "1rem",
                              sm: "1rem",
                              md: "0.8rem",
                              lg: 18,
                            }}
                            fontWeight="bold"
                            sx={{ p: 1 }}
                          >
                            {profile.user.firstName[0] +
                              profile.user.lastName[0]}
                          </Typography>
                        </Box>
                        <Link
                          href="./my-account"
                          underline="none"
                          color="text.primary"
                          sx={{
                            height: "fit-content",
                            ":hover": {
                              backgroundColor: "transparent",
                              color: "text.secondary",
                            },
                          }}
                          //onClick={myProfileClick}
                        >
                          <Typography
                            variant="h6"
                            color="text.primary"
                            fontSize={{ xs: "0.9rem", md: "0.7rem", lg: 18 }}
                            fontWeight="bold"
                          >
                            My account
                          </Typography>
                        </Link>
                      </Box>
                    ) : (
                      <Button
                        variant="text"
                        fontWeight="bold"
                        sx={{
                          fontSize: { xs: "0.8rem", sm: "1rem", md: "1rem" },
                          color: "text.white",
                          backgroundColor: "text.tertiary",
                          padding: { xs: "0rem 3rem", md: "0.3rem 2.3rem" },
                          height: { xs: "2.5rem" },
                          borderRadius: "2rem",
                          ":hover": {
                            backgroundColor: "text.tertiaryHover",
                          },
                          textAlign: "center",
                          width: "100%",
                        }}
                        onClick={() => {
                          authComponents.openLogin();
                        }}
                      >
                        {" "}
                        Sign In
                      </Button>
                    )}
                  </MenuItem>*/}
                  {
                   /* profile.isUser ?
                    <Box sx={{borderBottom:"1px solid rgb(177, 176, 176)"}}></Box>
                  
                    :null*/
                  }
                  {navItems.map((navItem, index) => (
                    <MenuItem
                      key={"mobile-link-container-" + index}
                      onClick={handleCloseNavMenu}
                    >
                      <Link
                        key={"mobile-link-" + index}
                        href={navItem["goTo"]}
                        underline="none"
                        //onClick={(event) => handleListItemClick(event, index)}
                        sx={{
                          height: "fit-content",
                          ":hover": {
                            backgroundColor: "transparent",
                            color: "text.secondary",
                          },
                        }}
                        color={
                          selectedIndex === index
                            ? "text.selected"
                            : "text.primary"
                        }
                      >
                        <Button
                          key={"mobile-btn-" + navItem["name"]}
                          onClick={handleCloseNavMenu}
                          className="navBtn"
                          sx={{
                            my: 2,
                            color: "inherit",
                            display: "block",
                            margin:0,
                            marginRight: 5,
                            fontSize: 18,
                            fontWeight: "bold",
                            ":hover": {
                              backgroundColor: "transparent",
                              color: "text.secondary",
                            },
                          }}
                        >
                          {navItem["name"]}
                        </Button>
                      </Link>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              
              <Box
                width={{ xs: "80px", sm: "80px", md: "80px", lg:"100px" }}
                marginLeft="5px"
                //marginRight={{ xs: 3, sm: 3, md: 0 }}
                textAlign="left"
              >
                <Link
                  href="./"
                  //onClick={(event) => handleListItemClick(event, -1)}
                >
                  <img src={appLogo} alt="" width={"100%"} />
                </Link>
              </Box>
            </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                  justifyContent: "center",
                }}
              >
                {navItems.map((navItem, index) => (
                  <Link
                    key={"desktop-link-" + index}
                    href={navItem["goTo"]}
                    underline="none"
                    //onClick={(event) => handleListItemClick(event, index)}
                    sx={{
                      height: "fit-content",
                      ":hover": {
                        backgroundColor: "transparent",
                        color: "text.secondary",
                      },
                    }}
                    color={
                      selectedIndex === index ? "text.selected" : "text.primary"
                    }
                  >
                    <Button
                      key={"desktop-btn-" + navItem["name"]}
                      onClick={handleCloseNavMenu}
                      className="navBtn"
                      sx={{
                        my: 2,
                        color: "inherit",
                        display: "block",
                        marginRight: { md: 4, lg: 5 },
                        fontSize: { md: "1.4vw", lg: 18 },
                        fontWeight: "bold",
                        ":hover": {
                          backgroundColor: "transparent",
                          color: "text.secondary",
                        },
                      }}
                    >
                      {navItem["name"]}
                    </Button>
                  </Link>
                ))}
              </Box>

              {profile.isUser ? (
                <Box
                  sx={{
                    //display: { xs: "none", sm: "none", md: "flex" },
                    display: "flex" ,
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      flexGrow: 0,
                      borderRadius: "100%",
                      marginRight: 2,
                    }}
                    backgroundColor={
                      window.location.pathname.includes("/my-account")
                        ? "#AB9E72"
                        : "#efefef"
                    }
                  >
                    {/*<Tooltip title="Open settings">*/}
                    {/*<IconButton onClick={handleOpenUserMenu} sx={{ p: 1 }}>*/}
                    <Typography
                      variant="h6"
                      color={
                        window.location.pathname.includes("/my-account")
                          ? "text.white"
                          : "text.primary"
                      }
                      fontSize={{
                        xs: "1rem",
                        sm: "1rem",
                        md: "0.8rem",
                        lg: 18,
                      }}
                      fontWeight="bold"
                      sx={{ padding: "0.25rem 0.5rem" }}
                    >
                      {
                        profile.user.firstName ?   
                        profile.user.firstName[0] + profile.user.lastName[0]
                        :
                        profile.user.name[0]
                      }
                    </Typography>
                    {/*</IconButton>*/}
                  </Box>
                  <Link
                    href="./my-account"
                    underline="none"
                    color="text.primary"
                    sx={{
                      height: "fit-content",
                      ":hover": {
                        backgroundColor: "transparent",
                        color: "text.secondary",
                      },
                    }}
                    //onClick={myProfileClick}
                  >
                    <Typography
                      variant="h6"
                      color="text.primary"
                      fontSize={{ xs: "0.9rem", md: "0.8rem", lg: 18 }}
                      fontWeight="bold"
                    >
                      My account
                    </Typography>
                  </Link>
                </Box>
              ) : (
                <Button
                  variant="text"
                  fontWeight="bold"
                  sx={{
                    /*marginRight: "35px",*/
                    fontSize: { xs: "0.8rem", sm: "1rem", md: "1rem" },
                    color: "text.white",
                    backgroundColor: "text.tertiary",
                    padding: { xs: "0rem 3rem", md: "0.3rem 2.3rem" },
                    height: { xs: "2.5rem" },
                    borderRadius: "2rem",
                    ":hover": {
                      backgroundColor: "text.tertiaryHover",
                    },
                    textAlign: "center",
                   // display: { xs: "none", sm: "none", md: "block" },
                   display:"block" ,
                  }}
                  onClick={() => {
                    authComponents.openLogin();
                  }}
                >
                  {" "}
                  Sign In
                </Button>
              )}

              
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};
export default Navbar;
