import { useEffect, useState, useRef, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Grid,
  FormControl,
  MenuItem,
  OutlinedInput,
  Button,
  FormHelperText,
  TextField,
  Select,
  Alert,
  CircularProgress,
  Container,
  InputLabel,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

import { Formik, Form, Field } from "formik";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import axios from "axios";
import {
  ADD_GUEST,
  COOKIES_SETTINGS,
  DELETE_GUEST,
  EDIT_GUEST,
  GET_GUESTS_GROUPS,
} from "../../endpoints/Constants";

import { GuestsContext } from "./GuestsList";

//import { editCheckListItem, addCheckListItem, deleteCheckListItem } from "./check-list-api-functions";
export default function GuestForm(props) {
  const [loading, setLoading] = useState(false);

  const { mainList, currentGroupName, groupsListState, guestsCountState } =
    useContext(GuestsContext);
  const [guestsList, setGuestsList] = mainList;
  const [pageTitle, setPageTitle] = currentGroupName;
  const [groupsList, setGroupsList] = groupsListState;
  const [guestsInfo, getGuestsInfo] = guestsCountState;

  const [guest, setGuest] = useState({
    _id: "",
    firstName: "",
    lastName: "",
    phone: "",
    guestGroup: "Bride's friends",
    RSVPStatus: "No Response",
    accompanyingGuests: [],
  });

  const [accompanyingGuestsErrors, setAccompanyingGuestsErrors] = useState([]);

  useEffect(() => {
    if (props.data !== null) {
      props.data.guestGroup = props.data.guestGroup.name;
      setGuest(props.data);
      props.data.accompanyingGuests.forEach(() => {
        accompanyingGuestsErrors.push({
          firstName: null,
          lastName: null,
          RSVPStatus: null,
        });
      });
      setAccompanyingGuestsErrors([...accompanyingGuestsErrors]);
    }
  }, [props.data]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [guestGroups, setGuestGroups] = useState([]);
  const RSVPStatus = ["No Response", "Attending", "Declined"];

  useEffect(() => {
    props.opendialog.current = handleClickOpen;

    axios
      .get(GET_GUESTS_GROUPS, COOKIES_SETTINGS)
      .then((res) => {
        setGuestGroups(res.data);
      })
      .catch(() => {});
  }, []);

  const add = async (values, setErrors) => {
    setLoading(true);
    await axios
      .post(
        ADD_GUEST,
        {
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
          guestGroup: values.guestGroup,
          RSVPStatus: values.RSVPStatus,
          accompanyingGuests: values.accompanyingGuests,
        },
        COOKIES_SETTINGS
      )
      .then((res) => {
        setLoading(false);
        handleClose();
        setErrors({ submit: null });
        //console.log("ADD:", res);

        getGuestsInfo(res.data.guestsCount);

        if (window.location.pathname === "/guests") {
          // update number of guests in a group

          groupsList.forEach((group, index) => {
            if (group.name === values.guestGroup) {
              groupsList[index].numberOfGuests = group.numberOfGuests + 1;
            }
          });

          setGroupsList([...groupsList]);
        } else {
          // add guest to list
          if (values.guestGroup === pageTitle) {
            //res.data.guest.guestGroup = {name:res.data.guest.guestGroup}
            setGuestsList((prev) => [...prev, res.data.guest]);
          }
        }
      })
      .catch((error) => {
        setErrors({ submit: error.response.data.message });
        setLoading(false);
      });
  };

  const edit = async (values, setErrors) => {
    setLoading(true);
    await axios
      .put(
        EDIT_GUEST + "/" + values._id,
        {
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
          guestGroup: values.guestGroup,
          RSVPStatus: values.RSVPStatus,
          accompanyingGuests: values.accompanyingGuests,
        },
        COOKIES_SETTINGS
      )
      .then((res) => {
        setLoading(false);
        handleClose();
        setErrors({ submit: null });
        //console.log(res);

        getGuestsInfo(res.data.guestsCount);

        let temp = guestsList;
        for (var i = 0; i < temp.length; i++) {
          var obj = temp[i];

          if (obj._id === values._id) {
            //console.log("here");
            if (
              values.RSVPStatus === localStorage.getItem("apdfltr") ||
              localStorage.getItem("apdfltr") === "All"
            ) {
              temp[i] = res.data.guest;
            } else {
              // remove guest from the list
              temp.splice(i, 1);
            }
            //console.log(temp);
          }
        }
        setGuestsList([...temp]);
      })
      .catch((error) => {
        setErrors({ submit: error.response.data.message });
        setLoading(false);
      });
  };

  const deleteItem = async (values, setErrors) => {
    setLoading(true);
    await axios
      .delete(DELETE_GUEST + "/" + values._id, COOKIES_SETTINGS)
      .then((res) => {
        setLoading(false);
        handleClose();
        //setErrors({ submit: null });
        getGuestsInfo(res.data.guestsCount);

        let temp = guestsList;
        for (var i = 0; i < temp.length; i++) {
          var obj = temp[i];

          if (obj._id === values._id) {
           // console.log("here");
            temp.splice(i, 1);
           // console.log(temp);
          }
        }
        setGuestsList([...temp]);
      })
      .catch((error) => {
        setErrors({ submit: error.response.data.message });
        setLoading(false);
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { borderRadius: 15 },
        }}
        sx={{ background: "rgba(0,0,0,0.5)", backdropFilter: "blur(16px)" }}
      >
        <Box width={{ xs: "80vw", sm: 500 }} sx={{ overflowX: "hidden" }}>
          <DialogContent sx={{ padding: "0", width: "100%" }}>
            <DialogTitle color="#101828">
              <Typography
                fontStyle={"normal"}
                fontWeight={"700"}
                fontSize={"30px"}
                lineHeight={"38px"}
                textAlign={"center"}
                color={"#101828"}
              >
                {props.toedit === "true" ? "Edit Guest" : "Add Guest"}
              </Typography>
            </DialogTitle>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Formik
                enableReinitialize
                initialValues={{
                  _id: guest._id,
                  firstName: guest.firstName,
                  lastName: guest.lastName,
                  phone: guest.phone,
                  guestGroup: guest.guestGroup,
                  RSVPStatus: guest.RSVPStatus,
                  accompanyingGuests: guest.accompanyingGuests,
                  submit: null,
                }}
                validationSchema={Yup.object().shape({
                  firstName: Yup.string().required("First name is required"),
                  lastName: Yup.string().required("Last name is required"),
                  phone: Yup.string().required("Phone is required"),
                })}
                onSubmit={async (values, { setErrors, setSubmitting }) => {
                  let errorFlag = false;

                  values.accompanyingGuests.forEach(
                    (accompanyingGuest, index) => {
                      accompanyingGuestsErrors[index].firstName = null;
                      accompanyingGuestsErrors[index].lastName = null;

                      if (values.accompanyingGuests[index].firstName === "") {
                        accompanyingGuestsErrors[index].firstName =
                          "First name is required";
                        errorFlag = true;
                      }

                      if (values.accompanyingGuests[index].lastName === "") {
                        accompanyingGuestsErrors[index].lastName =
                          "Last name is required";
                        errorFlag = true;
                      }
                    }
                  );
                  if (errorFlag) {
                    setAccompanyingGuestsErrors([...accompanyingGuestsErrors]);
                  } else {
                    //console.log("Will submit:",values)
                    if (props.toedit === "false") {
                      add(values, setErrors, setSubmitting);
                    } else {
                      edit(values, setErrors, setSubmitting);
                    }
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit} {...props}>
                    <Grid container justifyContent="center" alignItems="center">
                      <Box paddingX={2} paddingY={3}>
                        {errors.submit && (
                          <Alert
                            sx={{
                              marginBottom: "10px",
                            }}
                            severity="error"
                          >
                            {errors.submit}
                          </Alert>
                        )}
                        <FormControl
                          fullWidth
                          error={Boolean(touched.firstName && errors.firstName)}
                          //variant="outlined"
                          sx={{
                            marginBottom: "10px",
                          }}
                        >
                          <InputLabel
                            htmlFor={"outlined-adornment-" + "firstName"}
                            sx={{
                              color: "#475467",
                              "&.Mui-focused": {
                                color: "#475467",
                              },
                            }}
                          >
                            First name
                          </InputLabel>
                          <OutlinedInput
                            sx={{
                              background: "#F8FAFD",
                              borderRadius: "10px",
                              /*border: "1px solid #E7ECF3",*/
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#E7ECF3",
                                },
                              ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "#E7ECF3",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#E7ECF3",
                              },
                              fieldSet: {
                                legend: {
                                  width: "unset",
                                },
                              },
                            }}
                            id={"outlined-adornment-" + "firstName"}
                            type={"text"}
                            value={values["firstName"]}
                            name={"firstName"}
                            placeholder={"First name"}
                            label="First name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {touched.firstName && errors.firstName && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-email-login"
                            >
                              {errors.firstName}
                            </FormHelperText>
                          )}
                        </FormControl>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.lastName && errors.lastName)}
                          variant="outlined"
                          sx={{
                            marginBottom: "10px",
                          }}
                        >
                          <InputLabel
                            htmlFor={"outlined-adornment-" + "lastName"}
                            sx={{
                              color: "#475467",
                              "&.Mui-focused": {
                                color: "#475467",
                              },
                            }}
                          >
                            Last name
                          </InputLabel>
                          <OutlinedInput
                            sx={{
                              background: "#F8FAFD",
                              // border: "1px solid #E7ECF3",
                              borderRadius: "10px",
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#E7ECF3",
                                },
                              ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "#E7ECF3",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#E7ECF3",
                              },
                              fieldSet: {
                                legend: {
                                  width: "unset",
                                },
                              },
                            }}
                            id={"outlined-adornment-" + "lastName"}
                            type={"text"}
                            value={values["lastName"]}
                            name={"lastName"}
                            placeholder={"Last name"}
                            label="Last name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {touched.lastName && errors.lastName && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-email-login"
                            >
                              {errors.lastName}
                            </FormHelperText>
                          )}
                        </FormControl>

                        <FormControl
                          fullWidth
                          error={Boolean(touched.phone && errors.phone)}
                          variant="outlined"
                          sx={{
                            marginBottom: "10px",
                          }}
                        >
                          <PhoneInput
                            inputStyle={{
                              width: "100%",
                              background: "#F8FAFD",
                              border: "1px solid #E7ECF3",
                              borderRadius: "10px",
                              borderColor: "#E7ECF3",
                            }}
                            inputProps={{ name: "phone" }}
                            value={values.phone}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              values.phone = "+" + e;
                            }}
                            country={"eg"}
                            placeholder="Phone Number"
                            // onlyCountries={['eg']}
                            countryCodeEditable={false}
                            isValid={touched["phone"] && !errors["phone"]}
                          />

                          {touched["phone"] && errors["phone"] && (
                            <FormHelperText
                              error
                              id={"standard-weight-helper-text-" + "phone"}
                            >
                              {errors["phone"]}
                            </FormHelperText>
                          )}
                        </FormControl>

                        <FormControl fullWidth variant="outlined">
                          <Select
                            id="outlined-adornment-guestGroup"
                            name="guestGroup"
                            sx={{
                              marginBottom: "10px",
                              background: "#F8FAFD",
                              border: "1px solid #E7ECF3",
                              borderRadius: "10px",
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#E7ECF3",
                                },
                              ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "#E7ECF3",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#E7ECF3",
                              },
                              fieldSet: {
                                legend: {
                                  width: "unset",
                                },
                              },
                            }}
                            value={values.guestGroup}
                            onChange={handleChange}
                            input={<OutlinedInput />}
                          >
                            {guestGroups.map((group, index) => {
                              return (
                                <MenuItem
                                  key={"Group-" + index}
                                  value={group.name}
                                >
                                  {group.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>

                        <FormControl fullWidth variant="outlined">
                          <Select
                            id="outlined-adornment-RSVPStatus"
                            name="RSVPStatus"
                            sx={{
                              marginBottom: "10px",
                              background: "#F8FAFD",
                              border: "1px solid #E7ECF3",
                              borderRadius: "10px",
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#E7ECF3",
                                },
                              ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "#E7ECF3",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#E7ECF3",
                              },
                              fieldSet: {
                                legend: {
                                  width: "unset",
                                },
                              },
                            }}
                            value={values.RSVPStatus}
                            onChange={handleChange}
                            input={<OutlinedInput />}
                          >
                            {RSVPStatus.map((statusName, index) => {
                              return (
                                <MenuItem
                                  key={"RSVPStatus-" + index}
                                  value={statusName}
                                >
                                  {statusName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>

                        <FormControl
                          variant="outlined"
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            id="outlined-adornment-accompanyingGuests"
                            name="accompanyingGuests"
                            sx={{
                              //marginBottom: "10px",
                              color: "#000",
                              background: "#F8FAFD",
                              border: "1px solid #E7ECF3",
                              borderRadius: "10px",
                              maxWidth: { xs: "10%", md: "25%" },
                              minWidth: "15%",
                            }}
                            onClick={() => {
                              let temp = values;
                              temp.accompanyingGuests.pop();
                              setFieldValue({ ...temp });

                              accompanyingGuestsErrors.pop();
                              setAccompanyingGuestsErrors([
                                ...accompanyingGuestsErrors,
                              ]);
                            }}
                            input={<OutlinedInput />}
                          >
                            <RemoveIcon />
                          </Button>

                          <Typography
                            sx={{
                              margin: { xs: "0 0.5rem", md: "0 1rem" },
                              //marginBottom: "10px",
                              color: "#A7B0C0",
                              background: "#F8FAFD",
                              border: "1px solid #E7ECF3",
                              borderRadius: "10px",
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              textAlign: "center",
                              //fontSize:{"1rem"},
                              padding: { xs: "0rem 0.1rem", md: "0rem 0.5rem" },
                            }}
                          >
                            {values.accompanyingGuests.length === 0
                              ? "Accompanying Guest(s)"
                              : values.accompanyingGuests.length}
                          </Typography>

                          <Button
                            id="outlined-adornment-accompanyingGuests"
                            name="accompanyingGuests"
                            sx={{
                              //marginBottom: "10px",
                              color: "#000",
                              background: "#F8FAFD",
                              border: "1px solid #E7ECF3",
                              borderRadius: "10px",
                              maxWidth: { xs: "10%", md: "25%" },
                              minWidth: "15%",
                            }}
                            onClick={() => {
                              let temp = values;
                              temp.accompanyingGuests.push({
                                firstName: "",
                                lastName: "",
                                RSVPStatus: "No Response",
                              });
                              setFieldValue({ ...temp });

                              accompanyingGuestsErrors.push({
                                firstName: null,
                                lastName: null,
                                RSVPStatus: null,
                              });
                              setAccompanyingGuestsErrors([
                                ...accompanyingGuestsErrors,
                              ]);
                            }}
                            //input={<OutlinedInput />}
                          >
                            <AddIcon />
                          </Button>
                        </FormControl>

                        {values.accompanyingGuests.length === 0 ? null : (
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#000",
                              fontWeight: "bold",
                              fontSize: "1rem",
                              margin: "0.75rem 0",
                            }}
                          >
                            {"Accompanying Guest(s)"}
                          </Typography>
                        )}
                       
                        {values.accompanyingGuests.map(
                          (accompanyingGuest, index) => {
                            return (
                              <Box
                                key={"relative-" + index}
                                variant="contained"
                                sx={{
                                  boxShadow:
                                    "0px 0px 10px rgba(153, 153, 153, 0.15)",
                                  padding: "1rem",
                                  borderRadius: "8px",
                                  margin: "1rem 0",
                                }}
                              >
                                <FormControl
                                  fullWidth
                                  error={Boolean(
                                    accompanyingGuestsErrors[index].firstName
                                  )}
                                 // variant="outlined"
                                  sx={{
                                    marginBottom: "10px",
                                  }}
                                >
                                  <InputLabel
                                    htmlFor={
                                      "outlined-adornment-" +
                                      "firstName-" +
                                      index
                                    }
                                    sx={{
                                      color: "#475467",
                                      "&.Mui-focused": {
                                        color: "#475467",
                                      },
                                    }}
                                  >
                                    First name
                                  </InputLabel>
                                  <OutlinedInput
                                    sx={{
                                      background: "#F8FAFD",
                                      border: "1px solid #E7ECF3",
                                      borderRadius: "10px",
                                      "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor: "#E7ECF3",
                                        },
                                      ".MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#E7ECF3",
                                      },
                                      "&:hover .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor: "#E7ECF3",
                                        },
                                      fieldSet: {
                                        legend: {
                                          width: "unset",
                                        },
                                      },
                                    }}
                                    id={
                                      "outlined-adornment-" +
                                      "firstName-" +
                                      index
                                    }
                                    type={"text"}
                                    value={
                                      values.accompanyingGuests[index].firstName
                                    }
                                    name={"firstName"}
                                    placeholder={"First name"}
                                    label={"First name"}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      values.accompanyingGuests[
                                        index
                                      ].firstName = e.target.value;
                                      setFieldValue({ ...values });
                                    }}
                                  />
                                  {accompanyingGuestsErrors[index]
                                    .firstName && (
                                    <FormHelperText
                                      error
                                      id="standard-weight-helper-text-email-login"
                                    >
                                      {
                                        accompanyingGuestsErrors[index]
                                          .firstName
                                      }
                                    </FormHelperText>
                                  )}
                                </FormControl>

                                <FormControl
                                  fullWidth
                                  error={Boolean(
                                    accompanyingGuestsErrors[index].lastName
                                  )}
                                  //variant="outlined"
                                  sx={{
                                    marginBottom: "10px",
                                  }}
                                >
                                  <InputLabel
                                    htmlFor={
                                      "outlined-adornment-" +
                                      "lasttName-" +
                                      index
                                    }
                                    sx={{
                                      color: "#475467",
                                      "&.Mui-focused": {
                                        color: "#475467",
                                      },
                                    }}
                                  >
                                    Last name
                                  </InputLabel>
                                  <OutlinedInput
                                    sx={{
                                      background: "#F8FAFD",
                                      border: "1px solid #E7ECF3",
                                      borderRadius: "10px",
                                      "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor: "#E7ECF3",
                                        },
                                      ".MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#E7ECF3",
                                      },
                                      "&:hover .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor: "#E7ECF3",
                                        },
                                      fieldSet: {
                                        legend: {
                                          width: "unset",
                                        },
                                      },
                                    }}
                                    id={
                                      "outlined-adornment-" +
                                      "lastName-" +
                                      index
                                    }
                                    type={"text"}
                                    value={
                                      values.accompanyingGuests[index].lastName
                                    }
                                    name={"lastName"}
                                    placeholder={"Last name"}
                                    label={"Last name"}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                      values.accompanyingGuests[
                                        index
                                      ].lastName = e.target.value;
                                      setFieldValue({ ...values });
                                    }}
                                  />
                                  {accompanyingGuestsErrors[index].lastName && (
                                    <FormHelperText
                                      error
                                      id="standard-weight-helper-text-email-login"
                                    >
                                      {accompanyingGuestsErrors[index].lastName}
                                    </FormHelperText>
                                  )}
                                </FormControl>

                                <FormControl fullWidth variant="outlined">
                                  <Select
                                    id="outlined-adornment-RSVPStatus"
                                    name="RSVPStatus"
                                    sx={{
                                      marginBottom: "10px",
                                      background: "#F8FAFD",
                                      border: "1px solid #E7ECF3",
                                      borderRadius: "10px",
                                      "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor: "#E7ECF3",
                                        },
                                      ".MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#E7ECF3",
                                      },
                                      "&:hover .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor: "#E7ECF3",
                                        },
                                      fieldSet: {
                                        legend: {
                                          width: "unset",
                                        },
                                      },
                                    }}
                                    value={
                                      values.accompanyingGuests[index]
                                        .RSVPStatus
                                    }
                                    onChange={(e) => {
                                      values.accompanyingGuests[
                                        index
                                      ].RSVPStatus = e.target.value;
                                      setFieldValue({ ...values });
                                    }}
                                    input={<OutlinedInput />}
                                  >
                                    {RSVPStatus.map((statusName, subIndex) => {
                                      return (
                                        <MenuItem
                                          key={
                                            "RSVPStatus-" +
                                            index +
                                            "-" +
                                            subIndex
                                          }
                                          value={statusName}
                                        >
                                          {statusName}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Box>
                            );
                          }
                        )}

                        <Button
                          fullWidth
                          size="medium"
                          type="submit"
                          variant="contained"
                          disabled={loading}
                          sx={{
                            height: "48px",
                            marginTop: "10px",

                            background: "#F1A3A5",
                            borderRadius: "48px",
                            fontWeight: "600",
                            fontSize: "18px",
                            lineHeight: "16px",
                            color: "#FFF",
                            "&:hover": {
                              backgroundColor: "#F1A3A5",
                              color: "#FFF",
                            },
                          }}
                        >
                          {loading === true ? (
                            <CircularProgress
                              sx={{ color: "#FFF" }}
                              size={"1.8rem"}
                            />
                          ) : props.toedit === "true" ? (
                            "Save changes"
                          ) : (
                            "Add"
                          )}
                        </Button>

                        {props.toedit === "true" ? (
                          <Button
                            fullWidth
                            size="medium"
                            variant="outlined"
                            disabled={loading}
                            onClick={() => {
                              deleteItem(values);
                            }}
                            sx={{
                              height: "48px",
                              marginTop: "10px",
                              background: "#FFFFFF",
                              border: "1px solid #D3180C",
                              borderRadius: "48px",
                              fontWeight: "600",
                              fontSize: "16px",
                              lineHeight: "16px",
                              color: "#D3180C",
                              "&:hover": {
                                backgroundColor: "text.tertiaryHover",
                                color: "#FFFFFF",
                              },
                            }}
                          >
                            Delete item
                          </Button>
                        ) : null}
                      </Box>
                    </Grid>
                  </form>
                )}
              </Formik>
            </LocalizationProvider>
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
}
