import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";

import { WebAppTitle } from "../../WebAppTitle";
import axios from "axios";
import {
  GET_WEBSITE_VENDOR_CATEGORY,
  GET_WEBSITE_VENDORS,
  COOKIES_SETTINGS,
} from "../../../endpoints/Constants";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import VendorCard from "../vendor/vendor-card";
import { VendorsContext } from "../../../context/VendorsContext";

import { useLocation } from "react-router-dom";

import { OutlinedInput } from "@mui/material";
const VendorCategory = ({ isFromFavorites = false }) => {
  const vendorsContext = useContext(VendorsContext);
  const params = useParams();

  // Request
  const getVendorsList = (page) => {
    //console.log("getVendorsList Here", currentVendorCategory._id);
    return axios
      .get(
        GET_WEBSITE_VENDORS +
          `/${currentVendorCategory._id}?page=${page}&name=${searchText}`,
        COOKIES_SETTINGS
      )
      .then((response) => {
        //console.log(response.data);
        hasMore.current = response.data.totalPages > pageToLoad.current;
        if (page != 1)
          setVendorList((prev) => [...prev, ...response.data.docs]);
        else setVendorList(response.data.docs);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  // Search attributes
  const typingTimeoutRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const searchVendors = () => {
    if (isFromFavorites) {
      const list = vendorsContext.currentVendorList.filter((vendor) => {
        return vendor.name.toLowerCase().includes(searchText.toLowerCase());
      });
      setVendorList(list);
    } else {
      pageToLoad.current = 1;
      setIsLoadingVendors(true);
    }
  };
  const onSearchTextChange = (event) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    const value = event.target.value;
    setSearchText(value);
    typingTimeoutRef.current = setTimeout(searchVendors, 500);
  };
  // States
  const [currentVendorCategory, setCurrentVendorCategory] = useState(
    isFromFavorites ? vendorsContext.currentVendorCategory : null
  );
  const [isLoadingVendorCategory, setIsLoadingVendorCategory] = useState(
    true && !isFromFavorites
  );
  const [isLoadingVendors, setIsLoadingVendors] = useState(
    true && !isFromFavorites
  );
  const [vendorList, setVendorList] = useState(
    isFromFavorites ? vendorsContext.currentVendorList : []
  );

  const url = useLocation();

  // Pagination
  const observer = useRef();
  const pageToLoad = useRef(1);
  const hasMore = useRef(true);
  let lastVendorElementRef = useCallback(
    (node) => {
      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (hasMore.current) {
            pageToLoad.current = pageToLoad.current + 1;
            //console.log("here");
            getVendorsList(pageToLoad.current);
          }
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [currentVendorCategory]
  );

  //Use Effect to get categories
  useEffect(() => {
    if (!isFromFavorites) {
      const vendorCategoryId = params.vendorCategoryId;
      axios
        .get(GET_WEBSITE_VENDOR_CATEGORY, COOKIES_SETTINGS)
        .then((response) => {
          //console.log(response.data);
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i]._id === vendorCategoryId) {
              //console.log("Found ", response.data[i]);
              setCurrentVendorCategory(response.data[i]);
              break;
            }
          }
        })
        .catch((error) => {
         // console.log(error);
        })
        .finally(() => setIsLoadingVendorCategory(false));
    } else {
      if (!vendorsContext.currentVendorCategory)
        window.location.href = "/vendors";
    }
  }, [url]);

  // Use Effect to get vendors
  useEffect(() => {
    if (
      isLoadingVendorCategory ||
      !currentVendorCategory ||
      isFromFavorites ||
      !isLoadingVendors
    )
      return;
    // console.log(vendorCategory[currentVendorCategory]);
    setIsLoadingVendors(true);

    getVendorsList(1).finally(() => setIsLoadingVendors(false));
  }, [currentVendorCategory, isLoadingVendors]);

  return (
    <>
      {isLoadingVendorCategory ? (
        <Container
          sx={{
            textAlign: "center",
            marginTop: "50px",
            marginBottom: "50px",
          }}
        >
          <CircularProgress></CircularProgress>
        </Container>
      ) : !currentVendorCategory ? (
        <Container
          sx={{
            textAlign: "center",
            marginTop: "50px",
            marginBottom: "50px",
          }}
          minHeight={500}
        >
          <Typography>No Vendor Category Found</Typography>
        </Container>
      ) : (
        <>
          <WebAppTitle text={currentVendorCategory.name} />
          <Box
            minHeight="500px"
            sx={{ margin: { md: "0 58px 58px 58px", xs: "0 30px 30px 30px" } }}
          >
            <Grid item xs={12} marginTop="20px">
              <Container
                sx={{
                  width: { xs: "100%", sm: "100%", md: "40%", lg: "40%" },
                  marginLeft: "0px",
                  paddingLeft: "0px !important",
                }}
              >
                <OutlinedInput
                  fullWidth
                  placeholder="Search for a vendor"
                  value={searchText}
                  onChange={onSearchTextChange}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  sx={{
                    marginBottom: "10px",
                    background: "#F8FAFD",
                    border: "1px solid #E7ECF3",
                    borderRadius: "10px",
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E7ECF3",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E7ECF3",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E7ECF3",
                    },
                    fieldSet: {
                      legend: {
                        width: "unset",
                      },
                    },
                  }}
                ></OutlinedInput>
              </Container>
            </Grid>
            {isLoadingVendors ? (
              <Container
                sx={{
                  textAlign: "center",
                  marginTop: "50px",
                  marginBottom: "50px",
                }}
              >
                <CircularProgress></CircularProgress>
              </Container>
            ) : (
              <Grid container marginTop={2} spacing={1}>
                {vendorList.length === 0 ? (
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      No vendors found
                    </Typography>
                  </Grid>
                ) : (
                  <>
                    {vendorList.map((vendor, index) => {
                      return (
                        <VendorCard
                          vendor={vendor}
                          key={index}
                          index={index}
                          setVendorList={setVendorList}
                        />
                      );
                    })}
                    <Box
                      ref={!isFromFavorites ? lastVendorElementRef : null}
                    ></Box>
                  </>
                )}
              </Grid>
            )}
          </Box>
        </>
      )}
    </>
  );
};
export default VendorCategory;
