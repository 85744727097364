import React, { useEffect, useState, useContext, useRef } from "react";
import { ProfileContext } from "../../context/ProfileContext";
import { AuthComponentsContext } from "../../context/AuthComponentsContext";

import Box from "@mui/material/Box";
import { Link, Typography } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./Home.css";
import moment from "moment";
import CustomizedSnackbars from "../../ui-common-components/ui-components/SnackBar";
import { CHECKLIST_STATUS, COOKIES_SETTINGS } from "../../endpoints/Constants";
import axios from "axios";

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: "4px",
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E49EA1",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#fff",
  },
}));

const WeddingSection = () => {
  const profile = useContext(ProfileContext);
  const authComponents = useContext(AuthComponentsContext);

  const [weddingTimeLeft, setWeddingTimeLeft] = useState({
    weeks: "00",
    days: "00",
    hours: "00",
    minutes: "00",
  });

  const [checklistInfo, setChecklistInfo] = useState({
    completedNumber: 0,
    totalNumber: 0,
  });

  const [guestsInfo, setGuestsInfo] = useState({
    acceptedGuests: "00",
    declinedGuests: "00",
    noResponseGuests: "00",
    totalGuests: "00",
  });

  const formatObject = (obj) => {
    let cpyObj = obj;
    for (const [key, value] of Object.entries(cpyObj)) {
      cpyObj[key] = value.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    }
    return cpyObj;
  };

  const getWeddingTimeLeft = (date) => {
    //console.log(date);
    const weddingDate = moment(date);
    const currentDate = moment();
    const diff = weddingDate.diff(currentDate);
    const diffDuration = moment.duration(diff);

    const weddingTimeObj = {
      weeks: "00",
      days: "00",
      hours: "00",
      minutes: "00",
    };

    const intWeeks = parseInt(diffDuration.asWeeks());
    const remWeeks = diffDuration.asWeeks() - intWeeks;
    const remDays = parseInt(remWeeks * 7, 10);

    weddingTimeObj.weeks = intWeeks;
    weddingTimeObj.days = remDays;
    weddingTimeObj.hours = diffDuration.hours();
    weddingTimeObj.minutes = diffDuration.minutes();

    const weddingTimeObjFormated = formatObject(weddingTimeObj);

    setWeddingTimeLeft(weddingTimeObjFormated);
  };

  const getGuestsInfo = (guestsCount) => {
    const guestsCountFormated = formatObject(guestsCount);
    setGuestsInfo(guestsCountFormated);
  };

  const getCheckListInfo =()=>{
    
    axios
    .get(CHECKLIST_STATUS, COOKIES_SETTINGS)
    .then((res) => {
      //console.log("checklist:",res)
      setChecklistInfo(res.data)
    })
    .catch((err) => {
      
    });
  }

  useEffect(() => {
    if(profile.wedding){
      getWeddingTimeLeft(profile.wedding.weddingDate);
      getGuestsInfo(profile.wedding.guestsCount);
    }

    getCheckListInfo()
  }, [profile]);// eslint-disable-line

  const openSnackBar = useRef(null);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const copyInvitationLink = () => {
    try {
      const template = profile.wedding.weddingWebsite.weddingTemplate;

      let link =
        window.location.host + "/"+
        template.uniqueName +
        "/" +
        profile.wedding.weddingWebsite.uniqueName;
      navigator.clipboard.writeText(link);
      setSeverity("success");
      setMessage("Link Copied Successfully");
    } catch (error) {
      setSeverity("error");
      setMessage("Something went wrong with your invitation");
    }
    openSnackBar.current();
  };

  const openWeddingInfoModal = () => {
    if (!profile.wedding) {
      authComponents.openWeddingInfo()
    }
  };

  return (
    <Box
      id="wedding-container"
      sx={{
        height: "100%",
        PaddingTop: "5rem",
        margin: { xs: "0 0rem", sm: "0 2rem", md: "0 3rem" },
      }}
    >
      <CustomizedSnackbars
        openSnackBar={openSnackBar}
        message={message}
        severity={severity}
      />

      {profile.wedding ? (
        <Typography
          fontFamily={"Des-Morgan"}
          fontSize={{ xs: "60px", sm: "74px", md: "74px", lg: "74px" }}
          color={"primary.main"}
          textAlign="center"
        >
          {profile.wedding.brideFirstName}
          <Box
            component="span"
            sx={{
              marginLeft: "5px",
              fontFamily: "Snell Roundhand",
              fontSize: { xs: "46px", sm: "60px", md: "60px", lg: "60px" },
            }}
          >
            {"  &"}
          </Box>
          {profile.wedding.groomFirstName}
          
        </Typography>
      ) : <Box sx={{height:{xs:"0", md:"100px"}}}></Box>}

      {/************************Cards container*****************************/}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: { xs: "350px", md: "400px" },
            height: "auto",
            padding: "1rem 1rem",
            backgroundColor: "#BDE1F1",
            margin: { xs: "0.5rem", md: "1rem 1rem" },
            borderRadius: "14px",
            transition: "all .2s ease-in-out",
            "&:hover": {
              transform: "scale(1.01)",
              cursor: "pointer",
            },
          }}
          onClick={() => {
            openWeddingInfoModal();
           
          }}
        >
          {profile.wedding ? (
            <Typography
              fontSize={{ xs: "1rem", md: "1.3rem" }}
              color={"#589BD5"}
              textAlign="center"
              fontWeight={600}
              width="100%"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              HAPPILY MARRIED IN
            </Typography>
          ) : (
            <Typography
              fontSize={{ xs: "1rem", md: "1.3rem" }}
              color={"#589BD5"}
              textAlign="center"
              fontWeight={600}
              width="100%"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              UPDATE YOUR WEDDING INFO{" "}
              <ArrowForwardIosIcon fontSize="small" sx={{}} />
            </Typography>
          )}

          {profile.wedding ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1rem",
              }}
            >
              <Typography
                fontSize={"0.8rem"}
                color={"#589BD5"}
                textAlign="center"
                fontWeight={600}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: { xs: "0.1rem 0.8rem", md: "0.1rem 1.2rem" },
                  border: "1px solid #589BD5",
                  borderRadius: "10px",
                }}
              >
                {weddingTimeLeft.weeks}
                <br />
                WEEKS
              </Typography>
              <Typography
                fontSize={"0.8rem"}
                color={"#589BD5"}
                textAlign="center"
                fontWeight={600}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: { xs: "0.1rem 0.8rem", md: "0.1rem 1.2rem" },
                  border: "1px solid #589BD5",
                  borderRadius: "10px",
                }}
              >
                {weddingTimeLeft.days}
                <br />
                DAYS
              </Typography>
              <Typography
                fontSize={"0.8rem"}
                color={"#589BD5"}
                textAlign="center"
                fontWeight={600}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: { xs: "0.1rem 0.8rem", md: "0.1rem 1.2rem" },
                  border: "1px solid #589BD5",
                  borderRadius: "10px",
                }}
              >
                {weddingTimeLeft.hours}
                <br />
                HOURS
              </Typography>
              <Typography
                fontSize={"0.8rem"}
                color={"#589BD5"}
                textAlign="center"
                fontWeight={600}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: { xs: "0.1rem 0.8rem", md: "0.1rem 1.2rem" },
                  border: "1px solid #589BD5",
                  borderRadius: "10px",
                }}
              >
                {weddingTimeLeft.minutes}
                <br />
                MINUTES
              </Typography>
            </Box>
          ) : null}
        </Box>

        <Link
          href="./checklist"
          underline="none"
          sx={{
            width: { xs: "350px", md: "400px" },
            height: "auto",
            padding: "1rem 1rem",
            backgroundColor: "#F1A3A5",
            margin: { xs: "0.5rem", md: "1rem 1rem" },
            borderRadius: "14px",
            transition: "all .2s ease-in-out",
            "&:hover": {
              transform: "scale(1.01)",
            },
          }}
        >
          <Typography
            fontSize={{ xs: "1rem", md: "1.3rem" }}
            color={"text.white"}
            textAlign="center"
            fontWeight={600}
            width="100%"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            TO DO’S BEFORE I DO <ArrowForwardIosIcon fontSize="small" sx={{}} />
          </Typography>
          {profile.wedding ? (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  margin: " 1rem 1.5rem",
                }}
              >
                {
                  parseInt(checklistInfo.totalNumber) === 0 ?
                    <Typography variant="body2" color="text.white" sx={{fontSize:"1rem"}}>
                      No checklist yet!
                    </Typography>
                  :
                  <>

                    <Box sx={{ minWidth: "20%" }}>
                      <Typography variant="body2" color="text.white">
                        {checklistInfo.completedNumber + "/" + checklistInfo.totalNumber}
                      </Typography>
                    </Box>
                    <Box sx={{ width: "80%", mr: 1, color: "text.white" }}>
                      <BorderLinearProgress
                        variant="determinate"
                        value={(parseInt(checklistInfo.completedNumber) / parseInt(checklistInfo.totalNumber)) * 100}
                        />
                    </Box>
                  </>
                }
              </Box>
            </Box>
          ) : null}
        </Link>

        <Link
          href="./guests"
          underline="none"
          sx={{
            width: { xs: "350px", md: "400px" },
            height: "auto",
            padding: "1rem 1rem",
            backgroundColor: "#FAF7F4",
            margin: { xs: "0.5rem", md: "1rem 1rem" },
            borderRadius: "14px",
            border: "1px solid #AB9E72",
            transition: "all .2s ease-in-out",
            "&:hover": {
              transform: "scale(1.01)",
            },
          }}
        >
          <Typography
            fontSize={{ xs: "1rem", md: "1.3rem" }}
            color={"primary.main"}
            textAlign="center"
            fontWeight={600}
            width="100%"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            GUESTS <ArrowForwardIosIcon fontSize="small" sx={{}} />
          </Typography>
          {profile.wedding ? (
             <Box
             sx={{
               display: "flex",
               justifyContent: "space-between",
               marginTop: "1rem",
             }}
           >
             <Typography
               color={"primary.main"}
               textAlign="center"
               fontWeight={600}
               sx={{
                   fontSize:{xs:"0.6rem",sm:"0.65rem", md:"0.65rem"},
                 display: "flex",
                 justifyContent: "center",
                 alignItems: "center",
                 padding: { xs: "0.1rem 0.6rem", md: "0.1rem 0.9rem" },
                 border: "1px solid #AB9E72",
                 borderRadius: "10px",
               }}
             >
               {guestsInfo.totalGuests}
               <br />
               TOTAL
             </Typography>
             
             <Typography
               color={"primary.main"}
               textAlign="center"
               fontWeight={600}
               sx={{
                   fontSize:{xs:"0.6rem",sm:"0.65rem", md:"0.65rem"},
                 display: "flex",
                 justifyContent: "center",
                 alignItems: "center",
                 padding: { xs: "0.1rem 0.6rem", md: "0.1rem 0.9rem" },
                 border: "1px solid #AB9E72",
                 borderRadius: "10px",
               }}
             >
               {guestsInfo.acceptedGuests}
               <br />
               ACCEPTED
             </Typography>
             <Typography
               color={"primary.main"}
               textAlign="center"
               fontWeight={600}
               sx={{
                   fontSize:{xs:"0.6rem",sm:"0.65rem", md:"0.65rem"},
                 display: "flex",
                 justifyContent: "center",
                 alignItems: "center",
                 padding: { xs: "0.1rem 0.6rem", md: "0.1rem 0.9rem" },
                 border: "1px solid #AB9E72",
                 borderRadius: "10px",
               }}
             >
               {guestsInfo.declinedGuests}
               <br />
               DECLINED
             </Typography>
             <Typography
               color={"primary.main"}
               textAlign="center"
               fontWeight={600}
               sx={{
                   fontSize:{xs:"0.6rem",sm:"0.65rem", md:"0.65rem"},
                 display: "flex",
                 justifyContent: "center",
                 alignItems: "center",
                 padding: { xs: "0.1rem 0.6rem", md: "0.1rem 0.9rem" },
                 border: "1px solid #AB9E72",
                 borderRadius: "10px",
               }}
             >
               {guestsInfo.noResponseGuests}
               <br />
               NO RESPONSE
             </Typography>
           </Box>
          ) : null}
        </Link>




        <Link
          href="./vendor-category"
          underline="none"
          sx={{
            width: { xs: "350px", md: "400px" },
            height: "auto",
            //minHeight: "100px",
            padding: "1rem 1rem",
            backgroundColor: "#589BD5",
            margin: { xs: "0.5rem", md: "1rem 1rem" },
            borderRadius: "14px",
            transition: "all .2s ease-in-out",
            "&:hover": {
              transform: "scale(1.01)",
            },
          }}
        >
          <Typography
            fontSize={{ xs: "1rem", md: "1.3rem" }}
            color={"text.white"}
            textAlign="center"
            fontWeight={600}
            width="100%"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            VENDORS <ArrowForwardIosIcon fontSize="small" sx={{}} />
          </Typography>
          
        </Link>

      {/************************Copy inv link*****************************/}
      {profile.wedding && profile.wedding.weddingWebsite ? (
        <Box textAlign={"center"} 
          marginTop="2rem"
          sx={{
            width: { xs: "350px", md: "400px" },
            height: "auto",
            minHeight: "100px",
            borderRadius: "10px",
            backgroundColor:"#AB9E72",
            padding: "1rem 1rem",
            margin: { xs: "0.5rem", md: "1rem 1rem" },
            display:"flex",
            flexDirection:"column",
            justifyContent:"space-around",
            transition: "all .2s ease-in-out",
            "&:hover": {
              transform: "scale(1.01)",
              cursor:"pointer"
            },
          }}
          onClick={() => {
            copyInvitationLink();
          }}
          >
            <Typography
            fontSize={{ xs: "1rem", md: "1.3rem" }}
            color={"text.white"}
            textAlign="center"
            fontWeight={600}
            width="100%"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            INVITATION <ArrowForwardIosIcon fontSize="small" sx={{}} />
          </Typography>

          <Box
            
            sx={{
              color: "#FAF7F4",
              
              
              fontSize: "18px",
              textTransform: "none",
              width:"100%",
              height:"100%",
             
            }}
            
          >
            

            Copy Invitation link <ContentCopyIcon />
          </Box>
        </Box>
      ) : null}
      </Box>

    </Box>
  );
};

export default WeddingSection;
