import React, { useContext, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Grid, CircularProgress, Card, Stack, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";

import { WebAppTitle } from "../../WebAppTitle";

import ImagesCaroussel from "./ImagesCaroussel";
import { getGuestBoardDetailByIdApi } from "../InvitationsApiFunctions";

const GuestWishDetails = () => {
  const [isLoading, setLoading] = useState(true);
  const [guestWishDetails, setGuestWishDetails] = useState(null);

  const params = useParams();
  useEffect(() => {
    getGuestBoardDetailById();
  }, []);

  const getGuestBoardDetailById = async () => {

    try {
      const response = await getGuestBoardDetailByIdApi(params.id);
      setGuestWishDetails(response);
    } catch (error) {
      // TODO::Handle error

    } finally {
      setLoading(false);
    }
  }
  return (
    <>
      <Box minHeight="200px">
        {isLoading ? (
          <Container
            sx={{
              textAlign: "center",
            }}
          >
            <CircularProgress></CircularProgress>
          </Container>
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <WebAppTitle text={"Guest wish details"} />
            </Grid>

            <Grid
              xs={12}
              item

              sx={{
                margin: "20px 58px 58px 58px",
              }}>
              <ImagesCaroussel images={guestWishDetails.images}></ImagesCaroussel>
            </Grid>
            <Grid
              xs={12}
              item
              minHeight="500px"
              sx={{
                margin: "20px 58px 58px 58px",
              }}>

              <Card sx={{
                padding: "10px",

              }}>
                <Stack
                  spacing={1}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  <Typography
                    sx={{
                      marginY: "10px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "22px",
                      lineHeight: "18px",
                      display: "flex",
                      alignItems: "center",
                      color: "#090A0A",
                    }}
                  >
                    {guestWishDetails.firstName} {guestWishDetails.lastName}
                  </Typography>
                  <Divider
                    sx={{ marginY: "10px", }}
                  ></Divider>
                  <Typography
                    sx={{
                      marginY: "10px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "23px",
                      display: "flex",
                      alignItems: "center",
                      color: " #090A0A",
                    }}
                  >
                    {guestWishDetails.wish}
                  </Typography>
                </Stack>
              </Card>
            </Grid>

          </Grid>
        )}
      </Box>
    </>
  );
};

export default GuestWishDetails;
