import { useEffect, useState, useRef } from "react";
import CustomizedSnackbars from "../../../ui-common-components/ui-components/SnackBar";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
} from "@mui/material";
import WebsiteInfoForm from "./WebsiteInfoForm";

export default function WebsiteInfo(props) {
    /*********************Form variables********************/
    const [open, setOpen] = useState(false);


    /*********************Snackbar variables********************/
    const openSnackBar = useRef(null);


    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        props.openDialog.current = handleClickOpen;
    });

    return (
        <div>
            <CustomizedSnackbars
                openSnackBar={openSnackBar}
                message="Registered successfully"
                severity="success"
            />

            <Dialog
                id="website-info-dialog-main"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: { borderRadius: 15 },
                }}
                sx={{ background: "rgba(0,0,0,0.5)", backdropFilter: "blur(16px)" }}
            >
                <Box
                    width={{ xs: "80vw", sm: 500 }}
                    sx={{ overflowX: "hidden" }}
                >
                    <DialogContent sx={{ padding: "0", width: "100%" }}>
                        <DialogTitle
                            color="text.dark"
                            sx={{
                                fontSize: { xs: "5.5vw", md: "2rem" },
                                fontWeight: "bold",
                                textAlign: "center"
                            }}
                        >
                            Tell us about your wedding
                        </DialogTitle>

                        <WebsiteInfoForm
                            setopen={setOpen}
                        />
                    </DialogContent>
                </Box>

            </Dialog>
        </div>);
}