
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import './Questions.css';

import axios from "axios";
import { PhoneContext } from "../../context/PhoneContext";
import {
    COOKIES_SETTINGS,
    GET_TEMPLATE_QUESTIONS
} from "../../endpoints/Constants";
import { CircularProgress, Box } from '@mui/material';

const Questions = ({ templateID }) => {

    const [questionsData, setQuestionsData] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const phone = useContext(PhoneContext);

    const getTemplateQuestions = async (id) => {

        setLoading(true);
        try {
            axios
                .get(GET_TEMPLATE_QUESTIONS + `/${id}/question`, COOKIES_SETTINGS)
                .then((res) => {
                    setQuestionsData(res.data);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    //console.log("h", err);
                    navigate("../not-found");
                });
        } catch (error) {
            //console.log("hena", error);
            setLoading(false);
            navigate("../not-found");
        }
    };

    useEffect(() => {
        getTemplateQuestions(templateID);
    }, []);
    return (
        <div>
            {loading ? <Box
                sx={{
                    width: "100%",
                    height: "50vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <CircularProgress
                    style={{ 'color': phone.state.templateMainColor }}
                />
            </Box> : <div className="accordion" id="questions-col">
                {
                    questionsData.length > 0 ?
                        questionsData.map((question) =>
                            <div className="accordion-item" id="questions-item" >
                                <h2 className="accordion-header" id={"heading" + question._id}>
                                    <button className="accordion-button  collapsed  shadow-none" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + question._id} aria-expanded="false" aria-controls={"collapse" + question._id}>
                                        {question.question}
                                    </button>
                                </h2>
                                <div id={"collapse" + question._id} className="accordion-collapse collapse   shadow-none" aria-labelledby={"heading" + question._id} data-bs-parent="#questions-col">
                                    <div className="accordion-body   shadow-none">
                                        {question.answer}
                                    </div>
                                </div>
                            </div>
                        )
                        :
                        <h4 className='text-center' style={{ color: phone.state.templateMainColor }}>No Questions Found</h4>
                }
            </div>
            }

        </div>
    )
}

export default Questions;