import "./WebAppCommonStyle.css";
import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import bgImage from "../assets/webapp/title.png"

export const WebAppTitle = ({ text }) => {
  return (
    <Box sx={{ margin: {xs:"0.5rem 0.25rem 0 0.25rem", md:"1.25rem 58px 0 58px"} }}>
      <Grid
        container
        id="webapp-title-container"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{
          backgroundImage: {xs:"none", md:`url(${bgImage})`},
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          minHeight: {xs:"none", md:"20vw"},
          borderRadius: "16px",
          textTransform: {xs:"none", md:"uppercase"},
        }}
      >
        <Grid item>
          <Typography align="center" className="webapp-title"
            sx={{
              fontSize:{xs:"1.5rem", md:"2.8rem"},
              width:{md:"45vw"},
              fontWeight:{xs:"500", md:"400"}
            }}
          >
            {text}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

