import React, { useState , useEffect } from "react";
import "./TemplateEight.css";
import { Link } from "react-router-dom";
import {
  getMonth,
  getDayNumber,
  RSVPPressed_common,
  getDayName,
  getYear
} from "../TemplatesCommonFunctions";
import CircleIcon from '@mui/icons-material/Circle';

// const getDayName = (date) => {
//   const d2 = new Date(date);
//   var weekdays = new Array(7);
//   weekdays[0] = "Sun.";
//   weekdays[1] = "Mon.";
//   weekdays[2] = "Tues.";
//   weekdays[3] = "Wed.";
//   weekdays[4] = "Thurs.";
//   weekdays[5] = "Fri.";
//   weekdays[6] = "Sat.";
//   return weekdays[d2.getDay()];
// };
const getTime = (date) => {
  const d2 = new Date(date);
  var hour = d2.getHours();

  let timeSTR = "";
  if (hour === 0) {
    timeSTR = "12AM";
  } else if (hour > 12) {
    timeSTR = hour - 12 + "PM";
  } else {
    timeSTR = hour + "AM";
  }

  return timeSTR;
};

const getMonth_notcommon = (date) => {
  const d2 = new Date(date);
  var Months = new Array(12);
  Months[0] = " January";
  Months[1] = " February";
  Months[2] = " March";
  Months[3] = " April";
  Months[4] = " May";
  Months[5] = " June";
  Months[6] = " July";
  Months[7] = " August";
  Months[8] = " September";
  Months[9] = " October";
  Months[10] = " November";
  Months[11] = " December";
  return Months[d2.getMonth()];
};

const TemplateEightInvitation = ({ templateData, weddingId }) => {
  const [dummy, setDummy] = useState(0);

  const RSVPPressed = () => {
    setDummy(!dummy)
    RSVPPressed_common("eight")
  }

  return (
    <>
      <div id="template-eight-body" className="row">
        <div className="col-1">
        </div>
        <div id="body-col" className="col-10 ">

          <div id="body-main-container" className="container-fluid" >
            <div id="body-row-inv-message" className='row'>
              <div className='col'>
                <p>TOGETHER WITH THEIR FAMILIES</p>
              </div>
            </div>

            <div id="body-row-couple">
              <div id="bride-col" className='row container-fluid'>
                <div id="bride-first-name" className='row'>
                  <p >
                    {templateData.brideFirstName} {templateData.brideLastName}
                  </p>
                </div>
              </div>
              <div id="couple-and" className='row'>
                <p >
                  &
                </p>
              </div>
              <div id="groom-col" className='row container-fluid'>
                <div id="groom-first-name" className='row'>
                  <p >
                    {templateData.groomFirstName} {templateData.groomLastName}
                  </p>
                </div>
              </div>
            </div>

            <div id="body-row-couple-message" className='row'>
              <p>
                INVITE YOU TO CELEBRATE
              </p>
              <p>
                THEIR WEDDING DAY
              </p>
            </div>

            <div id="body-row-time" className='row'>
              <p id="body-row-time-area">
                {templateData.area} 
                {/* {templateData.city} */}
              </p>
            </div>


            <div id="body-row-date" className='row'>
              <div id="body-date-full">
                <p>
                  {/* {getDayNumber(templateData.weddingDate)} <strong style={{fontSize:"6vw",lineHeight:"2.5vw"}}>.</strong> {getMonth_notcommon(templateData.weddingDate)} <strong style={{fontSize:"6vw",lineHeight:"2.5vw"}}>.</strong> {getTime(templateData.weddingDate)} */}
                
                  {getDayNumber(templateData.weddingDate)} <CircleIcon className="circle-icon" style={{ color: "#88611A" ,marginLeft: "0.78vw",marginRight: "0.78vw"}} /> {getMonth_notcommon(templateData.weddingDate)} <CircleIcon  className="circle-icon"  style={{ color: "#88611A" ,marginLeft: "0.78vw",marginRight: "0.78vw"}} /> {getTime(templateData.weddingDate)}
                
                

                </p>
              </div>
            </div>



            {templateData.weddingWebsite.RSVPsIsVisible ?

              <div>


                <div id="body-row-rsvp-by" className='row'>
                  <p>
                    KINDLY RSVP  BY {getMonth(templateData.RSVPBy)} {getDayNumber(templateData.RSVPBy)}
                  </p>
                </div>

                <div id="body-row-rsvp-button" className='row'>
                  <div id="rsvp-col" className='col-3'></div>
                  <div id="rsvp-col" className='col-6'>
                    <Link to={"/islamic-simplicity/"+weddingId+"/rsvp"}>


                      <button onClick={RSVPPressed} className=" shadow-none" >
                        RSVP HERE
                      </button>
                    </Link>
                  </div>
                  <div id="rsvp-col" className='col-3'></div>
                </div>
              </div>
              : <div id="rsvp-placeholder"></div>
            }

          </div>

        </div>
        <div className="col-1">
        </div>
      </div>

      <div id="created-on" className="w-100" >
            <div className="w-50">

              <p>
                Created on Lovebirds
              </p>
              <p>
                Getting married? <Link id="created-on-link" style={{ textDecoration: 'underline' }} 
                to={"/"}
                >Create your wedding website for free.</Link>
              </p>
            </div>
          </div>
    </>
  );
};

export default TemplateEightInvitation;
