import { useEffect, useState, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import { AuthComponentsContext } from "../../../context/AuthComponentsContext";

export default function RegisterFirst(props) {
  const authComponents = useContext(AuthComponentsContext);
  /*********************Form variables********************/
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    props.openDialog.current = handleClickOpen;
  });

  return (
    <div>
      <Dialog
        id="register-first-dialog-main"
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { borderRadius: 15 },
        }}
        sx={{ background: "rgba(0,0,0,0.5)", backdropFilter: "blur(16px)" }}
      >
        <Box
          width={{ xs: "80vw", sm: 500 }}
          sx={{ overflowX: "hidden" }}
        >
          <DialogContent sx={{ padding: "24px", width: "100%" }}>
            <Typography
              color="text.dark"
              sx={{
                fontSize: { xs: "5.5vw", md: "2rem" },
                fontWeight: "bold",
                marginBottom: "8px",
              }}
            >
              You have to register first
            </Typography>
            <Typography
              variant="body2"
              color="#667085"
              sx={{
                marginBottom: "32px",
              }}
            >
              {`You have to register an account to be able to ${props.text}.`}
            </Typography>
            <Grid
              container
              spacing={1}
              sx={{
                marginTop: { xs: "1rem", md: "2rem" },
                justifyContent: "center",
              }}
            >
              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  variant="text"
                  fontWeight="bold"
                  sx={{
                    /*marginRight: "35px",*/
                    fontSize: { xs: "0.8rem", sm: "1rem", md: "1rem" },
                    color: "black",
                    backgroundColor: "white",
                    padding: { xs: "0rem 3rem", md: "0.3rem 2.3rem" },
                    height: { xs: "2.5rem" },
                    borderRadius: "2rem",
                    border: "1px solid #D0D5DD",
                    ":hover": {
                      backgroundColor: "#e7e7e7",
                    },
                    textAlign: "center",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    authComponents.openLogin();
                  }}
                >
                  {" "}
                  Login
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  variant="text"
                  fontWeight="bold"
                  sx={{
                    /*marginRight: "35px",*/
                    fontSize: { xs: "0.8rem", sm: "1rem", md: "1rem" },
                    color: "text.white",
                    backgroundColor: "text.tertiary",
                    padding: { xs: "0rem 3rem", md: "0.3rem 2.3rem" },
                    height: { xs: "2.5rem" },
                    borderRadius: "2rem",
                    ":hover": {
                      backgroundColor: "text.tertiaryHover",
                    },
                    textAlign: "center",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    authComponents.openSignup();
                  }}
                >
                  {" "}
                  Register Now
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
}
