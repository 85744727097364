import React, { useEffect, useContext } from "react";
import Box from '@mui/material/Box';
import { ProfileContext } from "../../context/ProfileContext";
import { Typography } from "@mui/material";
import { GuestsContext } from "./GuestsList";

const GuestsCount = () => {
  
  const profile = useContext(ProfileContext);

  const {guestsCountState } = useContext(GuestsContext);
  const [guestsInfo, getGuestsInfo] = guestsCountState;

  useEffect(() => {
    if(profile.wedding){
      getGuestsInfo(profile.wedding.guestsCount);
    }

  }, [profile]);// eslint-disable-line

  //console.log(profile)
  return (
    <>
    {profile.wedding ? (

   
    <Box
    
    underline="none"
    sx={{
      width: { xs: "100%", sm: "480px" },
      height: "auto",
      padding: {xs:"0rem 0.5rem 1rem 0.5rem",md:"1rem 1rem 2rem 1rem"},
      backgroundColor: "#FAF7F4",
      margin: { xs: "0rem", md: "1rem 1rem" },
      borderRadius: "14px",
      border: "1px solid #AB9E72",
      
    }}
  >
    
    
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "1rem",
        }}
      >
        <Typography
          color={"primary.main"}
          textAlign="center"
          fontWeight={600}
          sx={{
              fontSize:{xs:"0.65rem",sm:"0.8rem"},
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: { xs: "0.1rem 0.6rem", md: "0.1rem 1rem" },
            border: "1px solid #AB9E72",
            borderRadius: "10px",
          }}
        >
          {guestsInfo.totalGuests}
          <br />
          TOTAL
        </Typography>
        
        <Typography
          color={"primary.main"}
          textAlign="center"
          fontWeight={600}
          sx={{
              fontSize:{xs:"0.65rem",sm:"0.8rem"},
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: { xs: "0.1rem 0.6rem", md: "0.1rem 1rem" },
            border: "1px solid #AB9E72",
            borderRadius: "10px",
          }}
        >
          {guestsInfo.acceptedGuests}
          <br />
          ACCEPTED
        </Typography>
        <Typography
          color={"primary.main"}
          textAlign="center"
          fontWeight={600}
          sx={{
              fontSize:{xs:"0.65rem",sm:"0.8rem"},
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: { xs: "0.1rem 0.6rem", md: "0.1rem 1rem" },
            border: "1px solid #AB9E72",
            borderRadius: "10px",
          }}
        >
          {guestsInfo.declinedGuests}
          <br />
          DECLINED
        </Typography>
        <Typography
          color={"primary.main"}
          textAlign="center"
          fontWeight={600}
          sx={{
              fontSize:{xs:"0.65rem",sm:"0.8rem"},
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: { xs: "0.1rem 0.6rem", md: "0.1rem 1rem" },
            border: "1px solid #AB9E72",
            borderRadius: "10px",
          }}
        >
          {guestsInfo.noResponseGuests}
          <br />
          NO RESPONSE
        </Typography>
      </Box>
  </Box>
    ) : null}
     </>
  );
};
export default GuestsCount;










      