import React, { useState , useEffect } from "react";
import "./TemplateTen.css";
import { Link } from "react-router-dom";
import {
  getMonth,
  getDayNumber,
  RSVPPressed_common,
  getDayName
} from "../TemplatesCommonFunctions";
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

const getTime = (date) => {
  const d2 = new Date(date);
  var hour = d2.getHours();

  let timeSTR = "AT ";
  if (hour === 0) {
    timeSTR = "AT 12 AM";
  } else if (hour > 12) {
    timeSTR = "AT " + hour - 12 + " PM";
  } else {
    timeSTR = "AT " + hour + " AM";
  }

  return timeSTR;
};

const getMonth_notcommon = (date) => {
  const d2 = new Date(date);
  var Months = new Array(12);
  Months[0] = " January";
  Months[1] = " February";
  Months[2] = " March";
  Months[3] = " April";
  Months[4] = " May";
  Months[5] = " June";
  Months[6] = " July";
  Months[7] = " August";
  Months[8] = " September";
  Months[9] = " October";
  Months[10] = " November";
  Months[11] = " December";
  return Months[d2.getMonth()];
};

const TemplateTenInvitation = ({ templateData, weddingId }) => {
  const [dummy, setDummy] = useState(0);

  const RSVPPressed = () => {
    setDummy(!dummy)
    RSVPPressed_common("one")
  }

  return (
    <>
      <div id="template-ten-body" className="row">
        <div className="col-1">
        </div>
        <div id="body-col" className="col-10 ">

          <div id="body-main-container" className="container-fluid" >
            <div id="body-row-inv-message" className='row'>
              <div className='col'>
                <p>YOU ARE CORDIALLY INVITED</p> 
                <p>TO CELEBRATE THE WEDDING OF</p>
              </div>
            </div>

            <div id="body-row-couple">
              
              <div id="bride-col" className='row container-fluid'>
                <div id="bride-first-name" className='row'>
                  <p >
                    {templateData.brideFirstName} {templateData.brideLastName}
                  </p>
                </div>
              </div>
              <div id="couple-and" className='row'>
                <p >
                  &
                </p>
              </div>
              <div id="groom-col" className='row container-fluid'>
                <div id="groom-first-name" className='row'>
                  <p >
                    {templateData.groomFirstName} {templateData.groomLastName}
                  </p>
                </div>
              </div>

            </div>

            {/* <div id="body-row-couple-message" className='row'>
              <p>
                INVITE YOU TO
              </p>
              <p>
                CELEBRATE THEIR WEDDING
              </p>
            </div> */}


            <div id="body-row-date" className='row '>
              <div id="body-date-day-name"  className='col-4'>
                <p>
                  {getDayName(templateData.weddingDate)} 
                {/* <HorizontalRuleIcon style={{ color: "#936370" ,fontSize: "2vw"}} />  */}
                </p>
              </div>
              <div className="col-1 date-divider"></div>
              <div id="body-date-day-number"  className='col-2'>
                <p>
                  {getDayNumber(templateData.weddingDate)}
                </p>
              </div>
              <div className="col-1 date-divider"> </div>
              <div id="body-date-month"  className='col-3'>
                <p>
                  {getMonth_notcommon(templateData.weddingDate)} 
                </p>
              </div>
            </div>
            
              <div id="body-row-time-city">
            <div id="body-row-time" className='row'>
                <p >
                  {templateData.city}
                </p>
                <p>
                  {getTime(templateData.weddingDate)}
                </p>
              </div>
            </div>


            {templateData.weddingWebsite.RSVPsIsVisible ?

              <div>


                <div id="body-row-rsvp-by" className='row'>
                  <p>
                    KINDLY RSVP BY 
                  </p>
                  <p>
                    {getMonth(templateData.RSVPBy)} {getDayNumber(templateData.RSVPBy)}
                  </p>
                </div>

                <div id="body-row-rsvp-button" className='row'>
                  <div id="rsvp-col" className='col-3'></div>
                  <div id="rsvp-col" className='col-6'>
                    <Link to={"/oriental-royalty/"+weddingId+"/rsvp"}>


                      <button onClick={RSVPPressed} className=" shadow-none" >
                        RSVP HERE
                      </button>
                    </Link>
                  </div>
                  <div id="rsvp-col" className='col-3'></div>
                </div>
              </div>
              : <div id="rsvp-placeholder"></div>
            }

          </div>

        </div>
        <div className="col-1">
        </div>
      </div>

      <div id="created-on" className="w-100">
            <div className="w-60">

              <p>
                Created on Lovebirds
              </p>
              <p>
                Getting married? <Link id="created-on-link" style={{ textDecoration: 'underline' }} 
                to={"/"}
                >Create your wedding website for free.</Link>
              </p>
            </div>
          </div>
    </>
  );
};

export default TemplateTenInvitation;
