import React, { createContext, useRef, } from "react";

export const VendorsContext = createContext();

const VendorsContextProvider = (props) => {
  
  const currentVendorCategory = useRef(null);
  const currentVendorList = useRef([]);
  const navigationFromFavorites = useRef(false);

  const setCurrentVendorCategory = (category) => {
    currentVendorCategory.current = category;
    currentVendorList.current = category.vendors?category.vendors:[];
    console.log("currentVendorCategory",currentVendorCategory.current);
    console.log("currentVendorList",currentVendorList.current);
    console.log("navigationFromFavorites",navigationFromFavorites.current);
  }; 
  const setNavigationFromFavorites = (value) => {
    navigationFromFavorites.current = value;
  };

  return (
    <VendorsContext.Provider
      value={{
        currentVendorCategory: currentVendorCategory.current,
        currentVendorList: currentVendorList.current,
        navigationFromFavorites: navigationFromFavorites.current,
        setCurrentVendorCategory: setCurrentVendorCategory,
        setNavigationFromFavorites: setNavigationFromFavorites,
      }}
    >
      {props.children}
    </VendorsContext.Provider>
  );
};

export default VendorsContextProvider;
