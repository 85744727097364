import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

import {
  Box,
  Grid,
  Typography,
  Divider,
  Stack,
  useMediaQuery,
  Card,
} from "@mui/material";
import image from "../../assets/Lovebirds.png";
import Alert from "@mui/material/Alert";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
// third party
import * as Yup from "yup";
import { Formik } from "formik";

// assets
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { ADMIN_LOGIN, COOKIES_SETTINGS } from "../../endpoints/Constants";
import axios from "axios";

const DashboardLogin = ({ ...others }) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Box sx={{ backgroundColor: "#ebf5ff", minHeight: 1, height: "auto" }}>
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: "calc(100vh - 68px)" }}
        >
          <Card
            sx={{
              maxWidth: 500,
              borderRadius: 5,
              borderColor: "#97cafc",
              margin: "2rem",
              paddingLeft: "1rem ",
              justifyContent: "center",
            }}
            elevation={2}
          >
            <Grid
              item
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
              sx={{ p: 3 }}
            >
              <Grid item sx={{ m: 0, mb: 5, paddingRight: 2 }}>
                <img src={image} alt="" width={80} height={40} />
              </Grid>
              <Grid
                container
                direction={matchDownSM ? "column-reverse" : "row"}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}
                  >
                    <Typography
                      color={"#0761b0"}
                      fontSize={"24px"}
                      fontWeight={"bold"}
                    >
                      Hi, Welcome Back
                    </Typography>
                    <Typography
                      color={"#9E9E9E"}
                      fontSize="16px"
                      textAlign={matchDownSM ? "center" : "inherit"}
                    >
                      Enter your credentials to continue
                    </Typography>
                    <Divider />
                  </Stack>
                </Grid>
              </Grid>

              <Formik
                initialValues={{
                  email: "email@gmail.com",
                  password: "123456",
                  submit: null,
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email("Must be a valid email")
                    .max(255)
                    .required("Email is required"),
                  password: Yup.string()
                    .max(255)
                    .required("Password is required"),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  try {
                    setLoading(true);
                    // console.log(values);
                    let formData = {
                      email: values.email,
                      password: values.password,
                    };
                    axios
                      .post(ADMIN_LOGIN, formData, COOKIES_SETTINGS)
                      .then((res) => {
                        setLoading(false);

                        if (res.status === 200) {
                          setStatus({ success: true });
                          window.location.href = "/dashboard";
                        } else {
                          setStatus({ success: false });
                          setErrors({ submit: res.data.message });
                        }
                      })
                      .catch((err) => {
                        setLoading(false);
                        setStatus({ success: false });
                        setErrors({ submit: err.response.data.message });
                      });
                  } catch (err) {
                    setLoading(false);
                    setStatus({ success: false });
                    setErrors({ submit: "Error" });
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit} {...others}>
                    <Grid container justifyContent="center" alignItems="center">
                      {errors.submit && (
                        <Alert
                          severity="error"
                          sx={{ width: "100%", margin: "1rem" }}
                        >
                          {errors.submit}
                        </Alert>
                      )}
                      <FormControl
                        fullWidth
                        error={Boolean(touched.email && errors.email)}
                        sx={{ margin: "1rem" }}
                      >
                        <InputLabel htmlFor="outlined-adornment-email-login">
                          Email Address
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-email-login"
                          type="email"
                          value={values.email}
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label="Email Address"
                          inputProps={{}}
                        />
                        {touched.email && errors.email && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-email-login"
                          >
                            {errors.email}
                          </FormHelperText>
                        )}
                      </FormControl>

                      <FormControl
                        fullWidth
                        error={Boolean(touched.password && errors.password)}
                        sx={{ margin: "1rem" }}
                      >
                        <InputLabel htmlFor="outlined-adornment-password-login">
                          Password
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password-login"
                          type={showPassword ? "text" : "password"}
                          value={values.password}
                          name="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                size="large"
                              >
                                {showPassword ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                          inputProps={{}}
                        />
                        {touched.password && errors.password && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-password-login"
                          >
                            {errors.password}
                          </FormHelperText>
                        )}
                      </FormControl>
                      <Grid item xs={11} sx={{ m: "1rem" }}>
                        <Link to="/dashboard-forget-password">
                          <Typography
                            color="#0761b0"
                            sx={{
                              textDecoration: "right",
                              cursor: "pointer",
                              textAlign: "right",
                            }}
                          >
                            Forgot Password?
                          </Typography>
                        </Link>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          disabled={loading}
                          size="medium"
                          type="submit"
                          variant="contained"
                        >
                          {loading ? (
                            <CircularProgress sx={{ color: "#FFF" }} size={'1.54rem'}/>
                          ) : (
                            "Sign in"
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
              <Divider />
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardLogin;
