import React from "react";

import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

// program to convert first letter of a string to uppercase
function capitalizeFirstLetter(str) {
  // converting first letter to uppercase
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
  return capitalized;
}

const VendorReview = ({ review }) => {
  return (
    <div
      style={{
        marginBottom: "0.5rem",
        minHeight: '62px'
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0rem 0",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: "#090A0A",
            fontWeight: "700",
            fontSize: "14px",
            marginBottom: "0.2rem",
          }}
        >
          {`${capitalizeFirstLetter(review.firstName)} ${capitalizeFirstLetter(
            review.lastName
          )}`}
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <StarIcon
            fontSize="small"
            sx={{
              color: "#ffb400",
              marginRight: "0.2rem",
            }}
          />
          <span>
            <Typography
              sx={{
                fontWeight: "700",
                color: "#090A0A",
                fontSize: "12px",
              }}
            >
              {review.stars.toFixed(2)}
            </Typography>
          </span>
        </Box>
      </Box>
      <Typography
        sx={{
          color: "#090A0A",
          fontWeight: "400",
          fontSize: "0.8rem",
          marginBottom: "12px",
        }}
      >
        {review.text}
      </Typography>
      <Divider />
    </div>
  );
};

export default VendorReview;
