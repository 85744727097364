import { useEffect, useState, useRef } from "react";
import AdminForm from "./AdminForm";
import CustomizedSnackbars from "../../ui-common-components/ui-components/SnackBar";
import { Divider } from '@mui/material';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
} from "@mui/material";
export default function AdminDialog(props) {
    /*********************Form variables********************/
    const [open, setOpen] = useState(false);

    const [message, setMessage] = useState("");
    /*********************Snackbar variables********************/
    const openSnackBar = useRef(null);


    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const onSuccessAdd = () => {
        if(props.toEditAdmin === null){
            // Add
            setMessage("Admin is added successfully");
        }
        else{
            // Edit
            setMessage("Admin is updated successfully");
        }
        props.refreshVendors();
        openSnackBar.current();
        handleClose();
    }
    useEffect(() => {
        props.openDialog.current = handleClickOpen;
    });

    return (
        <div>
            <CustomizedSnackbars
                openSnackBar={openSnackBar}
                message={message}
                severity="success"
            />
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: { borderRadius: 15 },
                }}
            >
                <Box
                    width={{ xs: 290, sm: 500, md: 600, lg: 600 }}
                    sx={{ overflowX: "hidden" }}
                >
                    <DialogContent sx={{ padding: "0", width: "100%" }}>
                        <DialogTitle color="primary">
                            {
                                props.toEditAdmin === null ? "Add Admin" : "Edit Admin"
                            }
                        </DialogTitle>
                        <Divider></Divider>
                        <AdminForm
                            add={props.toEditAdmin === null ? 1 : 0}
                            canceladdvendordialog={handleClose}
                            successadmindialog={onSuccessAdd}
                            data={props.toEditAdmin}
                        >
                        </AdminForm>
                    </DialogContent>
                </Box>

            </Dialog>
        </div>);
}