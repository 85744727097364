import React from "react";
import { WebAppTitle } from "../WebAppTitle";
import Container from "@mui/material/Container";
import Question from "./Question";

const FAQs = () => {
  const questions = [
    {
      _id: "1",
      question: "What are the steps to creating a wedding website?",
      answerComponent: <p>
        In the “Invitations” tab:<br></br>
        <ol>
          <li>
          Under “Website Theme,” choose a free website theme or contact us to create your unique template.
          </li>
          <li>
          Under “Hompage,” fill out all your wedding details.
          </li>
          <li>
          Under “Q&A,” add all questions you’d like to answer for you guests. If you don’t want to add this feature, just turn off its visibility. An example of a question could be:
          <ul>
            <li>
      	    Q: “Can kids attend the wedding?” 
            </li>
            <li>
            A: “Please kiss your kids goodnight”
            </li>
          </ul>
          </li>
        </ol>
    
      <strong>Make sure to add all your guests in the “Guests” tab so that they can access all features on your website.</strong>
      </p>,
    },
    {
      _id: "2",
      question: "How do I create a unique wedding website?",
      answer: "Please whastapp us at +201024132505 and we will put you in contact with one of our designers.",
    },
    {
      _id: "3",
      question: "How long does it take to create a wedding invitation through the app?",
      answer: "If you are using a pre-exisiting template, you can create it within minutes. If you’d like to create a unique template, please contact us at +201024132505 and we will have it ready for you in 1 to 2 weeks.",
    },
    {
      _id: "4",
      question: "What is “accompanying guests”?",
      answer: "An accomapying guest is someone you’d like to register under another person’s name. For example, you can add a friend’s mother under the friend’s number.",
    },
    {
      _id: "5",
      question: "How to I add an accompaying guest or a +1?",
      answer: "Under the “Guests” tab, search for the guest’s name at the top. Once found, click on the guest’s name. You will find a section labeled as “Add Accompanying Guests” at the bottom. Add the number of accompayning guests then fill out their information. If you’d like to add a +1, simply put “+” in the first name section and “1” in the last name section.",
    },
    {
      _id: "6",
      question: "What is a guest board?",
      answer: "The guest board is a section on your website where guests are able to send their congratulations to the couple before the wedding. Then after the wedding, they can use the same link to upload their photos from the wedding. The bride can then access all these message through the app and download the photos she would like to keep.",
    },
    {
      _id: "7",
      question: "What is a guest board?",
      answer: "The guest board is a section on your website where guests are able to send their congratulations to the couple before the wedding. Then after the wedding, they can use the same link to upload their photos from the wedding. The bride can then access all these message through the app and download the photos she would like to keep.",
    },
    {
      _id: "8",
      question: "Why aren’t my guests able to RSVP?",
      answerComponent: <p>
        It might be due to 2 situations:<br></br>
        <ul>
          <li>
          You have not registered their number under your “Guests” tap.
          </li>
          <li>
          It is passed your “RSVP by” date so you need to extend. To do so, go to the “invitations” tab, “Homepage,” the RSVP date will be the last section.
          </li>
        </ul>    
      </p>,
    },
    {
      _id: "9",
      question: "How do I check which guests have RSVPed?",
      answer: "○	Under the “Guests” section, you can choose “All Guests” then use the filter under the search bar to check all guests’ RSVP status. This filter is also available for each guest category you choose. For example, you can just check the RSVP status of the bride’s family by going to “Bride’s Family” in guests instead of “All Guests”.",
    },
    {
      _id: "10",
      question: "How do I send my website to all my guests?",
      answerComponent: <p>
        <ul>
          <li>
          If you’d like to send the website to just one guests:
          <ul>
            <li>
            Under the “Guests” tab, go to “All Guests” then search for the guest’s name. Then click on the whatsapp icon and choose what message you’d like to send to the guest. It will redirect you to your Whatsapp chat with this guest and have an automated message for you. Please feel free to change this message as you see fit.
            </li>
          </ul>
          </li>
          <li>
          If you’d like to send the website to several guests:
          <ol>
            <li>
            Under the “Guests” tab, click on “Send a Message” at the bottom.
            </li>
            <li>
            Choose your desired message template thorugh the filters at the top.
            </li>
            <li>
            Once it populates on your scree, please feel free to edit the message as you see fit.
            </li>
            <li>
            Copy your message.
            </li>
            <li>
            Within Whatsapp (or your preffered method of communication), paste and send your message to your guests.
            </li>
          </ol>
          <strong>Please be aware that in order to enable the RSVP and Guest Board Features, you must first regurstier your guests’ numbers within the “Guests” tab.</strong>
          </li>
        </ul>
      </p>,
    },
    {
      _id: "11",
      question: "Having additional questions?",
      answer: "Please WhatsApp us at +201024132505 and we will get back to you within a couple of hours."
    }
  ];
  return (
    <>
      <WebAppTitle text="FAQS" />
      <Container sx={{
        padding: {xs:"20px 58px"},
      }}>
        <div className="accordion" id="questions-col">
          {questions.map((question, index) => (
            <Question
            question={question['question']}
            answer={question['answer']}
            answerComponent={question['answerComponent']}
            key={index}
            ></Question>
          ))}
        </div>
      </Container>
    </>
  );
};
export default FAQs;
