import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Box, Link, Typography } from "@mui/material";
import ImageListItem from "@mui/material/ImageListItem";

import { COOKIES_SETTINGS, GET_TEMPLATES } from "../../endpoints/Constants";
import axios from "axios";

const InvitationSectionGrid = () => {
  const [templates, setTemplates] = useState([]);

  const [selectedItem, setSelectedItem] = useState(0); // eslint-disable-line
  const [numberOfItems, setNumberOfItems] = useState(3);

  // optional: save the width of the window using state
  const handleWindowSizeChange = () => {
    if (window.innerWidth < 600) {
      setNumberOfItems(3);
    } else if (window.innerWidth < 1210) {
      setNumberOfItems(3);
    } else {
      setNumberOfItems(4);
    }
  };

  // call your useEffect
  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    getTemplates();
  }, []);

  const getTemplates = async () => {
    //setPageLoading(true);

    try {
      const response = await axios.get(GET_TEMPLATES, COOKIES_SETTINGS);
      setTemplates(response.data);
      //console.log(response);
    } catch (error) {
     // console.log(error);
    }
    // setPageLoading(false);
  };

  return (
    <Grid container spacing={0} justifyContent="center" marginTop={"1.5rem"}>
      {templates.map((item, j) => {
        return (
          <Grid
            item
            //xs={12/numberOfItems}
            xs={5}
            sm={3}
            md={3}
            lg={3}
            key={"template-" + j}
            container
            justifyContent="start"
            marginTop={"1rem"}
            sx={{
              backgroundColor: "#FFF",
              padding: "0",
              margin: {
                xs: "1rem 0.75rem",
                sm: "1rem 1rem",
                md: "1rem 1rem",
                lg: "1rem 1rem",
              },
              borderRadius: "8px",
              transition: "all .2s ease-in-out",
              "&:hover": {
                transform: "scale(1.01)",
              },
            }}
          >
            <Link
              href={`/${item.uniqueName}/${item.uniqueName}`}
              underline="none"
              target="_blank"
              className="w-100"
            >
              <ImageListItem
                key={j}
                sx={{
                  //height: {xs:"120px!important", sm:"180px!important", md:"200px!important"},
                  borderRadius: "8px",
                  cursor: "pointer",
                  height: "200px!important",
                  width: "100%!important",
                }}
              >
                <img
                  src={item.icon}
                  alt={item.name}
                  loading="lazy"

                  //style={{ borderRadius: "8px" }}
                  //height="200px!important"
                  //width="300px!important"
                />
              </ImageListItem>
              <Typography
                color={"text.dark"}
                fontSize={{ xs: "3vw", sm: "1.1rem", md: "1.1rem" }}
                textAlign="left"
                marginLeft={"1rem"}
                marginTop={"1rem"}
                marginBottom={"1rem"}
                fontWeight={"bold"}
                //textTransform="uppercase"
              >
                {item.name}
              </Typography>
            </Link>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default InvitationSectionGrid;
