
import { Box} from "@mui/material";


// assets
import FacebookIcon from "@mui/icons-material/Facebook";

import { COOKIES_SETTINGS, FACEBOOK_USER } from "../../../endpoints/Constants";
import axios from "axios";
import { useRef } from "react";
import CustomizedSnackbars from "../../../ui-common-components/ui-components/SnackBar";


const FacebookBtn = () => {
const openSnackBar = useRef(null);

const fbLogin = (e) => {
    e.preventDefault();
    window.FB.login(
      function (response) {
        console.log(response)
       if (response.status === "connected") {
          let fbtoken = response.authResponse.accessToken;
          axios
            .post(
              FACEBOOK_USER,
              {
                token: fbtoken,
              },
              COOKIES_SETTINGS
            )
            .then((res) => {
              if (res.status === 200) {
                window.location.reload();
              }
            })
            .catch((err) => {
                openSnackBar.current()
            });
        }
      },
      {
        scope:"",
      }
    );
  };
  
  return (
    <>
    <CustomizedSnackbars
        openSnackBar={openSnackBar}
        message="Error in Sign in"
        severity="error"
    />

{/*<FacebookLogin
    appId={appId}
    autoLoad={true}
    fields="name,email,picture"
    callback={onSuccess}
    cssClass="my-facebook-button-class"
    icon="fa-facebook"
  />*/}

<Box
        sx={{
          border: "1px solid",
          borderColor: "text.darkGray",
          borderRadius: "0.5rem",
          padding: { xs: "0.4rem 1.5rem", md: "0.4rem 3.2rem" },
          width: "fit-content",
          ":hover": {
            cursor: "pointer",
          },
        }}
        onClick={fbLogin}
      >
        <FacebookIcon
          sx={{ color: "text.darkGray", fontSize: "2rem" }}
        />
      </Box>
      </>
  );
};

export default FacebookBtn;
