// export const domainName = "https://lovebirdsweddingguide.herokuapp.com"
export const domainName = "https://lovebirdsweddingapp.com";

export const baseURL = domainName + "/api/v1";

export const COOKIES_SETTINGS = {
    withCredentials: true,
    credentials: "include",
};

/**************************Users********************************/
export const GET_KPIS = baseURL + "/admin/kpis";

/**************************Dashboard********************************/
export const GET_PROFILE = baseURL + "/admin/profile";
export const ADMIN_LOGIN = baseURL + "/admin/auth/login";
export const ADMIN_LOGOUT = baseURL + "/admin/auth/logout";
export const ADMIN_FORGETPW = baseURL + "/admin/auth/forgetPassword";
export const ADMIN_RESETPW = baseURL + "/admin/auth/resetPassword";

/**************************Admins********************************/
export const ADD_ADMIN = baseURL + "/admin/addAdmin";
export const GET_ADMINS = baseURL + "/admin";
export const EDIT_ADMIN = baseURL + "/admin";
export const TOGGLE_DELETE_ADMIN = baseURL + "/admin/toggleDelete";

/**************************Users********************************/
export const GET_USERS = baseURL + "/admin/user";
export const TOGGLE_DEACTIVATE_USER = baseURL + "/admin/user/toggleDeactivate";

/**************************Vendor Categories********************************/
export const VENDOR_CATEGORY = baseURL + "/admin/vendorCategory";
export const UPLOAD_VENDOR_CATEGORY_IMAGE =
    baseURL + "/admin/vendorCategory/image";
export const TOGGLE_HIDE_VENDOR_CATEGORY =
    baseURL + "/admin/vendorCategory/toggleHidden";
export const TOGGLE_DELETE_VENDOR_CATEGORY =
    baseURL + "/admin/vendorCategory/toggleDelete";

/**************************Vendors********************************/
export const GET_VENDORS = baseURL + "/admin/vendor";
export const EDIT_VENDOR = baseURL + "/admin/vendor";
export const GET_VENDOR_REVIEWS = baseURL + "/admin/vendor";
export const DELETE_VENDOR_REVIEW = baseURL + "/admin/vendor/review";
export const TOGGLE_DELETE_VENDOR = baseURL + "/admin/vendor/toggleDelete";

/**************************Quote Requests********************************/
export const GET_QUOTE_REQUESTS = baseURL + "/admin/vendor/quotes";

/**************************Templates Dashboard **************************/
export const GET_TEMPLATES = baseURL + "/wedding/template";

/**************************Template Requests********************************/
export const GET_TEMPLATE_DETAILS = baseURL + "/wedding";
export const GET_TEMPLATE_QUESTIONS = baseURL + "/wedding";
export const POST_WEDDING_RSVP_PHONE = baseURL + "/wedding";
export const POST_WEDDING_GUEST_BOARD_URL =
    baseURL + "/wedding/guestBoard/getAWSUrls";
export const POST_WEDDING_GUEST_BOARD = baseURL + "/wedding/guestBoard";
export const PUT_WEDDING_RSVP_STATUS = baseURL + "/wedding";

/********************************************Website Application**********************************************************************/
export const USER_LOGIN = baseURL + "/auth/login";
export const USER_LOGOUT = baseURL + "/auth/logout";
export const USER_SIGNUP = baseURL + "/auth/signUp";
export const USER_FORGETPW = baseURL + "/auth/forgetPassword";
export const USER_RESETPW = baseURL + "/auth/resetPassword";
export const USER_CHANGEPW = baseURL + "/auth/changePassword";
export const USER_UPDATE_WEDDING_DETAILS = baseURL + "/wedding";
export const GOOGLE_USER = baseURL + "/auth/continueWithGoogle";
export const FACEBOOK_USER = baseURL + "/auth/continueWithFacebook";
export const APPLE_USER = baseURL + "/auth/continueWithApple";

export const GET_USER_PROFILE = baseURL + "/profile";

export const CREATE_USER_WEDDING = baseURL + "/wedding";
/***************************Vendors****************************** */
export const GET_WEBSITE_VENDOR_CATEGORY = baseURL + "/vendorCategory";
export const GET_WEBSITE_VENDORS = baseURL + "/vendorCategory";
export const GET_WEBSITE_VENDOR_BY_ID = baseURL + "/vendor";
export const FAVORITE_VENDOR = baseURL + "/vendor/favorite";
export const UNFAVORITE_VENDOR = baseURL + "/vendor/unfavorite";
export const GET_FAVORITE_VENDORS = baseURL + "/vendor/favorites";
export const REVIEW_VENDOR = baseURL + "/vendor/review";
export const REQUEST_QUOTE_VENDOR = baseURL + "/vendor/quotes";

/*****************************CheckList******************************** */
export const CHECKLIST = baseURL + "/checklist";
export const CHECKLIST_STATUS = baseURL + "/checklist/status";

/*****************************Invitations******************************** */
export const WEDDING_WEBSITE = baseURL + "/wedding/website";
export const QUESTIONS_WEBSITE = baseURL + "/wedding/question";
export const GUEST_BOARD_WEBSITE = baseURL + "/wedding/guestBoard";

/*****************************Guests******************************** */
export const GET_GUESTS_GROUPS = baseURL + "/wedding/guestGroups";
export const GET_GUESTS_IN_GROUP = baseURL + "/wedding/guest";
export const ADD_GUEST = baseURL + "/wedding/guest";
export const EDIT_GUEST = baseURL + "/wedding/guest";
export const DELETE_GUEST = baseURL + "/wedding/guest";
