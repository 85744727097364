import { useTheme } from "@mui/material/styles";
import {
  Box,
  Grid,
  Typography,
  Divider,
  Stack,
  useMediaQuery,
  Card,
} from "@mui/material";
import image from "../../assets/Lovebirds.png";
import Alert from "@mui/material/Alert";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
// third party
import * as Yup from "yup";
import { Formik } from "formik";

// assets
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import {
  ADMIN_FORGETPW,
  ADMIN_RESETPW,
  COOKIES_SETTINGS,
} from "../../endpoints/Constants";
import axios from "axios";

import SwipeableViews from "react-swipeable-views";

const ForgetPassword = ({ ...others }) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const [emailState, setEmailState] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const [activeStep, setActiveStep] = useState(0);
  const handleStepChange = (step) => {
    // console.log(step);
    setActiveStep(step);
  };

  return (
    <Box sx={{ backgroundColor: "#ebf5ff", minHeight: 1, height: "auto" }}>
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: "calc(100vh - 68px)" }}
        >
          <Card
            sx={{
              maxWidth: 500,
              borderRadius: 5,
              borderColor: "#97cafc",
              margin: "2rem",
              paddingLeft: "1rem ",
              justifyContent: "center",
            }}
            elevation={2}
          >
            <Grid
              item
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
              sx={{ p: 3 }}
            >
              <Grid item sx={{ m: 0, mb: 5, paddingRight: 2 }}>
                <img src={image} alt="" width={80} height={40} />
              </Grid>
              <Grid
                container
                direction={matchDownSM ? "column-reverse" : "row"}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}
                  >
                    <Typography
                      color={"#0761b0"}
                      fontSize={"24px"}
                      fontWeight={"bold"}
                    >
                      Forgot Your Password?
                    </Typography>
                    <Typography
                      color={"#9E9E9E"}
                      fontSize="16px"
                      textAlign={matchDownSM ? "center" : "inherit"}
                    >
                      No problem enter your email to get a new one
                    </Typography>
                    <Divider />
                  </Stack>
                </Grid>
              </Grid>

              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                disableMouseEvents
              >
                <Formik
                  initialValues={{
                    email: "",
                    submit: null,
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email("Must be a valid email")
                      .max(255)
                      .required("Email is required"),
                  })}
                  onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting }
                  ) => {
                    try {
                      setLoading(true);
                      // console.log(values);
                      let formData = {
                        email: values.email,
                      };
                      axios
                        .post(ADMIN_FORGETPW, formData, COOKIES_SETTINGS)
                        .then((res) => {
                          setLoading(false);

                          // console.log(res);

                          if (res.status === 200) {
                            setEmailState(values.email);
                            setStatus({ success: true });
                            handleStepChange(1);
                          } else {
                            setStatus({ success: false });
                            setErrors({ submit: res.data.message });
                          }
                        })
                        .catch((err) => {
                          setLoading(false);
                          setStatus({ success: false });
                          setErrors({ submit: err.response.data.message });
                        });
                    } catch (err) {
                      setLoading(false);
                      setStatus({ success: false });
                      setErrors({ submit: "Error" });
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                  }) => (
                    <form noValidate onSubmit={handleSubmit} {...others} style={{height:'90%'}}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="space-between"
                        alignContent="space-between"
                        height="90%"
                      >
                        {errors.submit && (
                          <Alert
                            severity="error"
                            sx={{ width: "100%", margin: "1rem" }}
                          >
                            {errors.submit}
                          </Alert>
                        )}
                        <FormControl
                          fullWidth
                          error={Boolean(touched.email && errors.email)}
                          sx={{ margin: "1rem", marginTop: "3rem" }}
                        >
                          <InputLabel htmlFor="outlined-adornment-email-login">
                            Email Address
                          </InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-email-login"
                            type="email"
                            value={values.email}
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Email Address"
                            inputProps={{}}
                          />
                          {touched.email && errors.email && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-email-login"
                            >
                              {errors.email}
                            </FormHelperText>
                          )}
                        </FormControl>
                        <Grid item xs={12}>
                          <Button
                            fullWidth
                            disabled={isSubmitting}
                            size="medium"
                            type="submit"
                            variant="contained"
                          >
                            {loading ? (
                              <CircularProgress sx={{ color: "#FFF" }} size={'1.54rem'}/>
                            ) : (
                              "Send Email"
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>

                <Formik
                  initialValues={{
                    code: "",
                    password: "",
                    confirmPassword: "",
                    submit: null,
                  }}
                  validationSchema={Yup.object().shape({
                    code: Yup.string()
                      .max(255)
                      .required("Code is required"),
                    password: Yup.string()
                      .max(255)
                      .required("Password is required"),
                    confirmPassword: Yup.string()
                      .max(255)
                      .required("Confirm Password is required"),
                  })}
                  onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting }
                  ) => {
                    if (values.password !== values.confirmPassword) {
                      setErrors({ submit: "Passwords do not match" });
                      return;
                    }
                    try {
                      setLoading(true);
                      // console.log(values);
                      let formData = {
                        email: emailState,
                        resetCode: values.code,
                        password: values.password,
                      };
                      axios
                        .post(ADMIN_RESETPW, formData, COOKIES_SETTINGS)
                        .then((res) => {
                          setLoading(false);

                         // console.log(res);

                          if (res.status === 200) {
                            setStatus({ success: true });
                            window.location.href = "/dashboard-login";
                          }
                        })
                        .catch((err) => {
                          setLoading(false);
                          setStatus({ success: false });
                          setErrors({ submit: err.response.data.message });
                        });
                    } catch (err) {
                      setLoading(false);
                      setStatus({ success: false });
                      setErrors({ submit: "Error" });
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                  }) => (
                    <form noValidate onSubmit={handleSubmit} {...others} style={{height:"100%", overflow:'hidden'}}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                      >
                        {errors.submit && (
                          <Alert
                            severity="error"
                            sx={{ width: "100%", margin: "1rem" }}
                          >
                            {errors.submit}
                          </Alert>
                        )}

                        <FormControl
                          fullWidth
                          error={Boolean(touched.code && errors.code)}
                          sx={{ margin: "1rem" }}
                        >
                          <InputLabel htmlFor="outlined-adornment-code-login">
                            Code
                          </InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-code-login"
                            type="text"
                            value={values.code}
                            name="code"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Code"
                            inputProps={{}}
                          />
                          {touched.code && errors.code && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-code-login"
                            >
                              {errors.code}
                            </FormHelperText>
                          )}
                        </FormControl>

                        <FormControl
                          fullWidth
                          error={Boolean(touched.password && errors.password)}
                          sx={{ margin: "1rem" }}
                        >
                          <InputLabel htmlFor="outlined-adornment-password-login">
                            Password
                          </InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password-login"
                            type={showPassword ? "text" : "password"}
                            value={values.password}
                            name="password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                  size="large"
                                >
                                  {showPassword ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Password"
                            inputProps={{}}
                          />
                          {touched.password && errors.password && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-password-login"
                            >
                              {errors.password}
                            </FormHelperText>
                          )}
                        </FormControl>

                        <FormControl
                          fullWidth
                          error={Boolean(touched.password && errors.password)}
                          sx={{ margin: "1rem" }}
                        >
                          <InputLabel htmlFor="outlined-adornment-confirm-password-login">
                            Confirm Password
                          </InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-confirm-password-login"
                            type={showConfirmPassword ? "text" : "password"}
                            value={values.confirmPassword}
                            name="confirmPassword"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowConfirmPassword}
                                  onMouseDown={handleMouseDownConfirmPassword}
                                  edge="end"
                                  size="large"
                                >
                                  {showConfirmPassword ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Confirm Password"
                            inputProps={{}}
                          />
                          {touched.confirmPassword && errors.confirmPassword && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-password-login"
                            >
                              {errors.confirmPassword}
                            </FormHelperText>
                          )}
                        </FormControl>

                        <Grid item xs={12}>
                          <Button
                            fullWidth
                            disabled={isSubmitting}
                            size="medium"
                            type="submit"
                            variant="contained"
                          >
                            {loading ? (
                              <CircularProgress sx={{ color: "#FFF" }} size={'1.54rem'}/>
                            ) : (
                              "Change Password"
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              </SwipeableViews>

              <Divider />
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ForgetPassword;

/**
 
 <FormControl
                                                fullWidth
                                                error={Boolean(touched.password && errors.password)}
                                                sx={{ margin: "1rem" }}
                                            >
                                                <InputLabel htmlFor="outlined-adornment-password-login">
                                                    Password
                                                </InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-password-login"
                                                    type={showPassword ? "text" : "password"}
                                                    value={values.password}
                                                    name="password"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                                size="large"
                                                            >
                                                                {showPassword ? <VisibilityIcon /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="Password"
                                                    inputProps={{}}
                                                />
                                                {touched.password && errors.password && (
                                                    <FormHelperText
                                                        error
                                                        id="standard-weight-helper-text-password-login"
                                                    >
                                                        {errors.password}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                           

 */
