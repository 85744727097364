import React, { useEffect, useState, useRef } from "react";

import { useNavigate } from "react-router-dom";
import { Typography, Stack, Button, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AlertDialogSlide from "../../ui-common-components/ui-components/AlertDialogSlide";
import CustomizedSnackbars from "../../ui-common-components/ui-components/SnackBar";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import {
  TOGGLE_DELETE_ADMIN,
  COOKIES_SETTINGS,
  GET_ADMINS,
} from "../../endpoints/Constants";
//import AddVendorDialog from "./AddVendorDialog";
import PaginationCustom from "../../ui-common-components/ui-components/PaginationCustom";


const AdminsRecycleBin = () => {
    const navigate = useNavigate();
    /**************************Page variables********************************/
    const [admins, setAdmins] = useState([]);
    const [pageLoading, setPageLoading] = useState(true);
  
    /**************************Form Dialog variables********************************/
    const openDialog = useRef(null);
  
    /**************************Snackbar variables********************************/
    const openSnackBar = useRef(null);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [snackBarSevirity, setSnackBarSevirity] = useState("");
  
    /**************************Alert Dialog variables********************************/
    const openAlertDialog = useRef(null);
    const [restoreId, setRestoreId] = useState(null);
  
    /********************************************************************************/
    const [searchText, setSearchText] = useState("");
    const [totalPages, setTotalPages] = useState(-1);
  
    const [changePage, setChangePage] = useState(1);
    const [paginationLoading, setPaginationLoading] = useState(true);
  
    useEffect(() => {
        localStorage.setItem("LBCurrentPage", 1);
    }, []);
  
    useEffect(() => {
      //console.log("changePage")
  
      getAdminsDeleted();
    }, [changePage]); // eslint-disable-line react-hooks/exhaustive-deps
  

  const confirmRestore = async () => {
    // console.log(restoreId);
    try {
      await axios.put(
        TOGGLE_DELETE_ADMIN,
        {
          adminId: restoreId,
        },
        COOKIES_SETTINGS
      );
      getAdminsDeleted();
      setSnackBarMessage("Admin is restored successfully");
      setSnackBarSevirity("success");
      openSnackBar.current();
    } catch (error) {
      //console.log(error);
      setSnackBarMessage("An error occcured");
      setSnackBarSevirity("error");
      openSnackBar.current();
    }
    setRestoreId(null);
  };

  const getAdminsDeleted = async () => {
    var url = GET_ADMINS + `?page=` + localStorage.getItem("LBCurrentPage");

    if (searchText === "") {
      url += `&isDeleted=true`;
    } else {
      if(isNaN(searchText)){  
        url += `&name=${searchText}&isDeleted=true`;
    }
    else{
        url += `&phone=${searchText}&isDeleted=true`;
      }
    }

    setPageLoading(true);
    try {
      const response = await axios.get(
        url,
        COOKIES_SETTINGS
      );
      setAdmins(response.data.docs);
      setPaginationLoading(true);
      setTotalPages(response.data.totalPages);
      setPaginationLoading(false);
    } catch (error) {
      //console.log(error);
    }
    setPageLoading(false);
  };

  const handleSearch = async (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    getAdminsDeleted();
  }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div id="dashboard-admins-recyclebin" className="dashboard-admins w-100">
      <AlertDialogSlide
        confirmAlertRef={confirmRestore}
        openAlertDialog={openAlertDialog}
        title={"Alert"}
        description={"Are you sure you want to restore this admin?"}
        actionText={"Restore"}
        snackBarSevirity={"error"}
      />
      <CustomizedSnackbars
        openSnackBar={openSnackBar}
        message={snackBarMessage}
        severity={snackBarSevirity}
      />
      <Stack
        direction={{ xs: "column", sm: "row" }}
        height={{ xs: 180, sm: 60, md: 60, lg: 60 }}
        borderRadius={2}
        sx={{ backgroundColor: "#FFF" }}
        display={"flex"}
        alignContent={"center"}
        justifyContent={{
          sm: "space-between",
          xs: "center",
        }}
        alignItems={"center"}
      >
        <Typography
          margin={1}
          marginLeft={{ sm: 1, md: 3, lg: 3 }}
          color={"primary.main"}
          fontSize={20}
          fontWeight={"bold"}
        >
          Admins Recycle Bin
        </Typography>
        <Box margin={3}>
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{ fontSize: { xs: "0.75rem", sm: "0.75rem", md: "1rem" } }}
          >
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              href="../"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Home
            </Link>
            <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/dashboard/admins"
              >
                <AdminPanelSettingsIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Admins
              </Link>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: { xs: "0.75rem", sm: "0.75rem", md: "1rem" },
              }}
              color={"primary.main"}
            >
              <DeleteIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Recycle Bin
            </Typography>
          </Breadcrumbs>
        </Box>
      </Stack>
      <Box>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            borderRadius: 2,
            border: "solid 1px",
            borderColor: "primary.border",
            marginTop: "1rem",
          }}
          elevation={0}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={12}>
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      display={"flex"}
                      justifyContent={{ xs: "center", sm: "space-between" }}
                      alignContent={"center"}
                    >
                      <TextField
                        id="standard-search"
                        label="Search"
                        type="search"
                        variant="outlined"
                        onChange={handleSearch}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderRadius: `55px`,
                            },
                          },
                        }}
                      />
                    
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pageLoading ? (
                  <TableRow>
                    <TableCell
                      sx={{
                        width: "100%",
                        height: "70vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  admins.map((admin, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          display: "flex",
                          flexDirection: {
                            xs: "column",
                            lg: "row",
                            borderBottom: "0.5px solid #97cafc",
                          },
                        }}
                      >
                        <TableCell
                          sx={{
                            border: 0,
                            width: { xs: "100%", lg: "30%" },
                          }}
                        >
                          <Stack
                            direction={{ xs: "column", lg: "row" }}
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <Stack
                              direction={"column"}
                              display={"flex"}
                              alignItems={{ xs: "center", lg: "start" }}
                            >
                              <Typography
                                marginLeft={{ xs: "0rem", md: "1rem" }}
                                fontSize={20}
                                color={"primary.main"}
                                fontWeight={"bold"}
                              >
                                {admin.name}
                              </Typography>
                              <Typography
                                marginLeft={{ xs: "0rem", md: "1rem" }}
                                fontSize={12}
                                color={"#808080"}
                              >
                                {admin.role}
                              </Typography>
                            </Stack>
                          </Stack>
                        </TableCell>

                        <TableCell
                          sx={{
                            border: 0,
                            width: { xs: "100%", lg: "45%" },
                          }}
                        >
                          <Stack direction={{ xs: "column", sm: "row" }}>
                            <Stack
                              width={{ xs: "100%", md: "65%" }}
                              direction={"column"}
                              paddingRight={{ xs: "0rem", md: "0rem" }}
                              display={"flex"}
                              alignItems={{ xs: "center", lg: "start" }}
                            >
                              <Typography fontSize={13} color={"#9E9E9E"}>
                                Email
                              </Typography>
                              <Typography
                                fontSize={15}
                                color={"#212121"}
                                fontWeight={"bold"}
                                marginBottom={"1rem"}
                              >
                                {admin.email}
                              </Typography>
                            </Stack>

                            <Stack
                              width={{ xs: "100%", md: "35%" }}
                              direction={"column"}
                              paddingLeft={{ xs: "0rem", md: "0rem" }}
                              display={"flex"}
                              alignItems={{ xs: "center", md: "start" }}
                            >
                              <Typography fontSize={13} color={"#9E9E9E"}>
                                Phone
                              </Typography>
                              <Typography
                                fontSize={15}
                                color={"#212121"}
                                fontWeight={"bold"}
                              >
                                {admin.phone}
                              </Typography>
                            </Stack>
                          </Stack>
                        </TableCell>

                        {/* Actions */}

                        <TableCell
                          sx={{
                            border: 0,
                            width: { xs: "100%", lg: "30%" },
                          }}
                        >
                          <Stack direction={"row"} justifyContent={{xs:"center", lg:"flex-end"}}>
                            <Button
                              color="error"
                              sx={{ width: "50%", margin: "1rem" }}
                              variant="outlined"
                              onClick={() => {
                                openAlertDialog.current();
                                setRestoreId(admin._id);
                              }}
                            >
                              Restore
                            </Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {!paginationLoading &&
            (totalPages === 0 ||
            totalPages === -1 ||
            (totalPages === 1 && admins.length === 0) ? (
              <Typography textAlign={"center"}>No Admins deleted!</Typography>
            ) : (
              <Box
                onClick={(e) => {
                  if (totalPages !== 1) {
                    e.preventDefault();
                    setChangePage(changePage + 1);
                  }
                }}
              >
                <PaginationCustom totalPages={totalPages} />
              </Box>
            ))}
        </Paper>
      </Box>
    </div>
  );
};

export default AdminsRecycleBin;
