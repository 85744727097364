import React, { useContext }  from "react";
import { Navigate,   Outlet} from "react-router-dom";
import { ProfileContext } from "../context/ProfileContext";

const DashboardProtectedRoute = () => {
    const profile = useContext(ProfileContext);
    if(!profile.isLoading){
        if (!profile.isAdmin && !profile.isOwner) {
            return <Navigate to="/dashboard-login" replace />;
        }
    }

  
    return <Outlet />;
  };


export default DashboardProtectedRoute;