import { useEffect, useState, useRef } from "react";
import VendorForm from "./VendorForm";
import CustomizedSnackbars from "../../ui-common-components/ui-components/SnackBar";
import { Divider } from '@mui/material';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
} from "@mui/material";
export default function AddVendorDialog(props) {
    /*********************Form variables********************/
    const [open, setOpen] = useState(false);


    /*********************Snackbar variables********************/
    const openSnackBar = useRef(null);


    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const onSuccessAdd = () => {
        props.refreshVendors();
        openSnackBar.current();
        handleClose();
    }
    useEffect(() => {
        props.openDialog.current = handleClickOpen;
    });

    return (
        <div>
            <CustomizedSnackbars
                openSnackBar={openSnackBar}
                message="Vendor is added successfully"
                severity="success"
            />
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: { borderRadius: 15 },
                }}
            >
                <Box
                    width={{ xs: 290, sm: 500, md: 600, lg: 600 }}
                    sx={{ overflowX: "hidden" }}
                >
                    <DialogContent sx={{ padding: "0", width: "100%" }}>
                        <DialogTitle color="primary">Add Vendor</DialogTitle>
                        <Divider></Divider>
                        <VendorForm
                            add={1}
                            canceladdvendordialog={handleClose}
                            successaddvendordialog={onSuccessAdd}
                            data={null}
                        >
                        </VendorForm>
                    </DialogContent>
                </Box>

            </Dialog>
        </div>);
}