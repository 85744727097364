import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import axios from "axios";

import {
  COOKIES_SETTINGS,
  GET_TEMPLATES,
} from "../../endpoints/Constants";

import { CircularProgress, Link, Stack } from "@mui/material";


const Templates = () => {
  const [templates, setTemplates] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    getTemplates();
  }, []);

  
  const getTemplates = async () => {
    setPageLoading(true);
    try {
      const response = await axios.get(
        GET_TEMPLATES,
        COOKIES_SETTINGS
      );   
      setTemplates(response.data);

    } catch (error) {
     // console.log(error);
    }
    setPageLoading(false);
  };
 
  return (
    <Grid container spacing={0} alignItems="start" justifyContent="center">
      <Grid item xs={12}>
        <Stack
          direction={{ xs: "column", md: "row", sm: "row", lg: "row" }}
          height={{ xs: 180, sm: 60, md: 60, lg: 60 }}
          borderRadius={2}
          sx={{ backgroundColor: "#FFF" }}
          display={"flex"}
          alignContent={"center"}
          justifyContent={{
            md: "space-between",
            sm: "space-evenly",
            xs: "center",
          }}
          alignItems={"center"}
        >
          <Typography
            margin={1}
            marginLeft={{ sm: 1, md: 3, lg: 3 }}
            color={"primary.main"}
            fontSize={20}
            fontWeight={"bold"}
          >
            Templates
          </Typography>
          
        </Stack>
      </Grid>

      {pageLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
       
          templates.length===0?<Typography>No Templates Found</Typography>:
        templates.map((template, index) => {
          return (
              <Grid
              textAlign={"right"}
              key={index}
              item
              xs={12}
              sm={5}
              md={3}
              lg={2}
              margin={1}
              marginTop={5}
            >
              <Link href={`/${template.uniqueName}/${template.uniqueName}`} target="_blank" >
              <ImageListItem
                key={index}
                sx={{
                  height: "200px!important",
                  width: "100%"
                }}
              >
                <img
                  src={template.icon}
                  alt="..."
                  height="200px"
                  width="300"
                  loading="lazy"
                  style={{ borderRadius: "10px" }}
                />
                <ImageListItemBar
                  sx={{ textAlign: "left", borderRadius: "0px 0px 10px 10px", borderTop: 0 }}
                  title={
                    <Typography
                      color={"#FFF"}
                      fontSize={"0.9rem"}
                      fontWeight={"bold"}
                      noWrap={true}
                    >
                      {template.name}
                    </Typography>
                  }    
                />
              </ImageListItem>
            </Link>
            </Grid>
          );
        })

      )}
    </Grid>
  );
};

export default Templates;
