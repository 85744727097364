import { useEffect, useState } from 'react';
import axios from "axios";
import { Grid, Card, Typography, Stack, CircularProgress } from "@mui/material";
import { GET_KPIS, COOKIES_SETTINGS } from '../../endpoints/Constants';

const Home = () => {
  const [kpis, setKpis] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getKpis = async () => {

    try {
      setIsLoading(true);
      const response = await axios.get(GET_KPIS, COOKIES_SETTINGS);
      setKpis(response.data);
      setIsLoading(false);
    } catch (error) {
    }

  };
  useEffect(() => {
    getKpis();
  }, []);
  return (
    (isLoading ? <Grid item  display={'flex'} justifyContent={'center'}  xs={12}>
      <CircularProgress />
    </Grid> :
      <Grid container direction={{ xs: 'column', md: 'row' }} >

        <KpisCardGrid
          title="Total number of users"
          count={kpis.totalUsers}>
        </KpisCardGrid>
        <KpisCardGrid
          title="Total number of requests"
          count={kpis.totalQuotes}>
        </KpisCardGrid>
        <KpisCardGrid
          title="Total number of reviews"
          count={kpis.totalReviews}>
        </KpisCardGrid>
        <KpisCardGrid
          title="Total number of websites"
          count={kpis.totalWebsites}>
        </KpisCardGrid>
        <Grid item xs={12}>
          <Typography
            color={"primary.main"}
            fontSize={30}
            fontWeight={"bold"}
            margin={'1rem'}
          >
            Vendors by category
          </Typography>
        </Grid>

        {
          (kpis.vendorsPerCategory.length == 0) ? (
            <Grid item xs={12}>
              <Typography
                fontSize={30}
                fontWeight={"bold"}
                margin={'1rem'}
                textAlign={'center'}
              >
                No vendors found
              </Typography>
            </Grid>
          ) : (
            kpis.vendorsPerCategory.map((vendor, index) => {
              return <KpisCardGrid key={index}
                title={vendor.name}
                count={vendor.count}>
              </KpisCardGrid>
            }))}
      </Grid>
    )
  );
}

export default Home;



const KpisCardGrid = (props) => {
  return (
    <Grid item xs={3}>
      <Card
        sx={{
          borderRadius: 5,
          border: '1px solid',
          borderColor: "#97cafc",
          margin: "1rem",
          justifyContent: "start",
          alignItems: "start",
          backgroundColor: "#FFF",
          height: "150px",
        }}
        elevation={0}

      >
        <Stack
          direction={'column'}
          display="flex"
          alignItems={'start'}
        >
          <Typography
            color={"primary.main"}
            fontSize={{ xs: 15, md: 20 }}
            fontWeight={"bold"}
            margin={'1rem 1rem 0rem 1rem'}
          >
            {props.title}
          </Typography>
          <Typography
            width={'100%'}
            fontSize={50}
            fontWeight={"bold"}
            textAlign={'center'}
            marginBottom={'1rem'}
          >
            {props.count}
          </Typography>
        </Stack>
      </Card>
    </Grid>
  );
}