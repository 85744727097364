import {
    Box,
    Grid,
    Paper,
    Typography,
    Switch,
    Button

} from '@mui/material';
import { ProfileContext } from '../../context/ProfileContext';
import { useEffect, useState, createContext, useRef, useContext } from 'react';
import CustomizedSnackbars from '../../ui-common-components/ui-components/SnackBar';
import { editWebsite } from './InvitationsApiFunctions';
const Rsvps = () => {
    const profile = useContext(ProfileContext);
    const openSnackBar = useRef(null);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [rsvpVisibility, setRsvpVisibility] = useState(profile.wedding.weddingWebsite.RSVPsIsVisible);
    // console.log(profile.wedding.weddingWebsite.RSVPsIsVisible);
    const saveChanges = async () => {
        //console.log(rsvpVisibility);
        try {
            const response = await editWebsite({
                RSVPsIsVisible: rsvpVisibility,
            });
            if (response.status == 200) {
                setSeverity("success");
                setMessage("RSVP visibility changed successfully");
                openSnackBar.current();
            }
        } catch (error) {
            setSeverity("error");
            setMessage(error.response.data.message);
            setRsvpVisibility(!rsvpVisibility);
            openSnackBar.current();
        }

    }
    return (
        <Box sx={{
            width: { xs: "100%", md: '65%' },
            paddingX: { md: "20px" },
            marginBottom: "20px"
        }}>
            <CustomizedSnackbars
                openSnackBar={openSnackBar}
                message={message}
                severity={severity}
            />
            <Grid container direction={"row"}>
                {/* Bride First Name */}
                <Grid item minHeight={"200"} xs={12}>
                    <Grid item xs={12} marginY={1}>
                        <Paper
                            elevation={0}
                            sx={
                                {
                                    height: "64px",
                                    backgroundColor: "#FFFFFF",
                                    boxShadow: "0px 0px 10px rgba(153, 153, 153, 0.15)",
                                    borderRadius: "8px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: 1
                                }
                            }>
                            <Typography
                                sx={{
                                    width: "117px",
                                    height: "18px",

                                    fontFamily: 'Montserrat',
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    fontSize: "16px",
                                    lineHeight: "18px",
                                    color: "#090A0A"
                                }}
                            >
                                Page Visibility
                            </Typography>
                            {/* TODO:: chage color */}
                            <Switch size='medium' checked={rsvpVisibility} onChange={() => { setRsvpVisibility(!rsvpVisibility); }} />
                        </Paper>
                    </Grid>


                    <Grid
                    item
                        xs={12}
                        paddingX={1}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Button

                            size="medium"
                            type="submit"
                            variant="contained"
                            disabled={profile.wedding.weddingWebsite.RSVPsIsVisible === rsvpVisibility}
                            onClick={saveChanges}
                            sx={{
                                width: { xs: "100%", md: "307px" },
                                height: "48px",
                                marginTop: "10px",
                                background: "#F1A3A5",
                                borderRadius: "48px",
                                fontWeight: "600",
                                fontSize: "18px",
                                lineHeight: "16px",
                                color: "#FFF",
                                '&:hover': {
                                    backgroundColor: '#F1A3A5',
                                    color: '#FFF',
                                },
                            }}
                        >
                            Save changes
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Box>

    );
};
export default Rsvps;