import React from "react";
import Box from '@mui/material/Box';
import { Grid, Link, Typography } from "@mui/material";

import appleStore from "../../assets/app_store.png"
import googleStore from "../../assets/google_play.png"

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { redirectToWhatsapp } from "../UtilFucntions";

const DownloadAppSection = () => {
   const goToGoogleStore =()=>{
    window.location.href = "https://play.google.com/store/apps/details?id=com.apessmartsolutions.loveBirds"
  }

  const goToAppleStore =()=>{
    window.location.href = "https://apps.apple.com/eg/app/lovebirds-the-wedding-guide/id1622993002"
  }

  return (
    <Grid item sm={12} md={4}>
        
            <Typography
              color={"text.white"}
              fontSize={{ xs: "1rem", sm: "1.1rem", md: "1.7vw", lg: "1.25vw" }}
              textAlign="left"
              marginBottom={"1rem"}
              marginTop="1rem"
              textTransform="uppercase"
              fontWeight="600"
            >
              Download the LOVEBIRDS App
            </Typography>

            <Typography
              color={"text.darkBlue"}
              textAlign="left"
              marginBottom={"1rem"}
              marginTop="1rem"
            >
              Plan your wedding wherever and whenever you want on the LOVEBIRDS App.
            </Typography>

            <Box sx={{display:"flex", flexDirection:"row", marginTop:"2rem", justifyContent:"flex-start", alignItems:"center"}}>
                <Link 
                    href="https://apps.apple.com/eg/app/lovebirds-the-wedding-guide/id1622993002" 
                    onClick={(e)=>{e.preventDefault()}}
                    sx={{
                        marginRight:"1rem"
                    }}
                >
                    <img src={appleStore} alt="" width={"130px"} onClick={goToAppleStore}/>
                </Link>
                <Link href="https://play.google.com/store/apps/details?id=com.apessmartsolutions.loveBirds" onClick={(e)=>{e.preventDefault()}}>
                    <img src={googleStore} alt="" width={"130px"} onClick={goToGoogleStore} />
                </Link>
            </Box>

            <Box marginTop="3rem">

            
                <Typography
                color={"text.white"}
                fontSize={{ xs: "1rem", sm: "1.1rem", md: "1.7vw", lg: "1.25vw" }}
                textAlign="left"
                marginBottom={"1rem"}
                fontWeight="600"
                >
                Follow us on
                </Typography>

                <Box>
                    <Link href="#" underline="none" sx={{
                        color:"text.white",
                        margin:"2rem",
                        marginLeft: "0",
                        "&:hover":{
                            color:"text.darkBlue"
                        }
                    }}>
                      
                         <FacebookIcon fontSize="large"/>
                    </Link>

                    <Link href="#" underline="none" sx={{
                        color:"text.white",
                        margin:"2rem",
                        marginLeft: "0",
                        "&:hover":{
                            color:"text.darkBlue"
                        }
                    }}>
                        <InstagramIcon fontSize="large"/>
                    </Link>

                    <Link href="#" underline="none" sx={{
                        color:"text.white",
                        margin:"2rem",
                        marginLeft: "0",
                        "&:hover":{
                            color:"text.darkBlue"
                        }
                    }}
                        onClick={(e)=>{
                            e.preventDefault()
                            redirectToWhatsapp()
                        }}
                    >
                        <WhatsAppIcon fontSize="large"/> 
                    </Link>
                </Box>
            </Box>
          </Grid>

  );
};
export default DownloadAppSection;
