import React, { useEffect, useState } from "react";

import { Typography, Stack, Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import HomeIcon from "@mui/icons-material/Home";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import axios from "axios";
import { CircularProgress, TextField, Select, MenuItem, InputLabel, FormControl, Button } from "@mui/material";
import {
  COOKIES_SETTINGS,
  GET_QUOTE_REQUESTS,
  VENDOR_CATEGORY,
} from "../../endpoints/Constants";
import PaginationCustom from "../../ui-common-components/ui-components/PaginationCustom";

const QuoteRequests = () => {
  /**************************Page variables********************************/
  const [quoteRequests, setQuoteRequests] = useState([]);
  const [vendorCategories, setVendorCategories] = useState([]);
  const [categoryValue, setCategoryValue] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [pageLoading, setPageLoading] = useState(true);

  const [totalPages, setTotalPages] = useState(-1);

  const [changePage, setChangePage] = useState(1);
  useEffect(() => { getVendorCategories(); }, [])
  useEffect(() => {
    if (changePage === 1) {
      localStorage.setItem("LBCurrentPage", 1);
      setChangePage(changePage + 1);
    }
  }, [changePage]);

  useEffect(() => {
    getQuoteRequests(localStorage.getItem("LBCurrentPage"));
  }, [changePage, vendorName, categoryValue]);

  /**************************API CALL FUNCTIONS********************************/
  const getVendorCategories = async () => {

    try {
      const response = await axios.get(
        VENDOR_CATEGORY + "?isDeleted=false",
        COOKIES_SETTINGS
      );
      // console.log("Vendor categories", response);
      setVendorCategories(response.data);
    } catch (error) {
      // console.log(error);
    }

  };
  const getQuoteRequests = async (page) => {
    setPageLoading(true);
    try {
      var url = GET_QUOTE_REQUESTS + `?page=${page}`;
      if (categoryValue !== "") {
        url += `&vendorCategory=${categoryValue}`;
      } if (vendorName !== "") {
        url += `&vendorName=${vendorName}`;
      }
      // console.log("Sent url " + url);
      const response = await axios.get(
        url,
        COOKIES_SETTINGS
      );
      // console.log(response.data);
      // console.log("Vendor quote requests", response.data.docs);
      setQuoteRequests(response.data.docs);
      //if (totalPages === -1) setTotalPages(response.data.totalPages);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      //  console.log(error);
    }
    setPageLoading(false);
  };



  /***************************************************************************/

  return (
    <div
      id="dashboard-quote-requests"
      className="dashboard-quote-requests w-100"
    >
      <Stack
        direction={{ xs: "column", sm: "row" }}
        height={{ xs: 180, sm: 60, md: 60, lg: 60 }}
        borderRadius={2}
        sx={{ backgroundColor: "#FFF" }}
        display={"flex"}
        alignContent={"center"}
        justifyContent={{
          md: "space-between",
          sm: "space-evenly",
          xs: "center",
        }}
        alignItems={"center"}
      >
        <Typography
          margin={1}
          marginLeft={{ sm: 1, md: 3, lg: 3 }}
          color={"primary.main"}
          fontSize={20}
          fontWeight={"bold"}
        >
          Quote Requests
        </Typography>
        <Box margin={3}>
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{ fontSize: { xs: "0.75rem", sm: "0.75rem", md: "1rem" } }}
          >
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              href="../"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Home
            </Link>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: { xs: "0.75rem", sm: "0.75rem", md: "1rem" },
              }}
              color={"primary.main"}
            >
              <RequestQuoteIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Quote Requests
            </Typography>
          </Breadcrumbs>
        </Box>
      </Stack>

      <Box>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            borderRadius: 2,
            border: "solid 1px",
            borderColor: "primary.border",
            marginTop: "1rem",
            minHeight: "80vh",
          }}
          elevation={0}
        >
          <TableContainer>
            <Table>


              <TableHead>
                <TableRow>
                  <TableCell colSpan={12} padding='none'>
                    <Stack

                      padding={1.5}
                      direction={{ xs: "column", sm: "row" }}
                      display={"flex"}
                      justifyContent={{ xs: "center", sm: "start" }}
                      alignContent={"center"}
                      width={{ xs: "100%", sm: "40%" }}
                    >
                      <TextField
                        size="small"
                        id="standard-search"
                        label="Vendor name"
                        type="search"
                        variant="outlined"
                        value={vendorName}
                        onChange={(e) => { setVendorName(e.target.value); }}
                        sx={{
                          minWidth: 150,

                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderRadius: `10px`,
                            },
                          },
                        }}
                      />


                      <Box
                        marginLeft={{ xs: 0, sm: 2 }}
                        marginTop={{ xs: 2, sm: 0 }}
                        sx={{ minWidth: 150 }}
                      >
                        <FormControl fullWidth>  
                        <InputLabel >Category</InputLabel>               
                          <Select
                          size="small"
                            value={categoryValue}
                            label="outlined"
                            placeholder="Category"
                            onChange={(e) => { setCategoryValue(e.target.value); }}
                            sx={{
                              minWidth: 150,
                              borderRadius : '10px',
                            }}
                          >

                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {vendorCategories.map((category, index) => {
                              return <MenuItem key={"category-" + index} value={category._id}>{category.name}</MenuItem>
                            })}

                          </Select>
                        </FormControl>
                      </Box>

                      <Button
                      
                        variant="text"
                        size="small"
                        
                        sx={{ 
                          minWidth: 140,
                          marginLeft:{ xs: 0, sm: 2 },
                          marginTop:{ xs: 2, sm: 0 },
                          padding: "0.5rem 1rem",
   
                        }}

                        color="error"

                        onClick={() => { setVendorName(''); setCategoryValue(''); }}
                      >
                        Reset filter
                      </Button>

                    </Stack>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pageLoading ? (
                  <TableRow>
                    <TableCell
                      sx={{
                        width: "100%",
                        height: "70vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  quoteRequests.map((request, index) => {

                    let date2 = new Date(request.estimatedWeddingDateStart);

                    var mm2 = date2.getMonth() + 1; // getMonth() is zero-based
                    var dd2 = date2.getDate();
                    var startHours = date2.getHours();
                    var startMinutes = date2.getMinutes();

                    let estimatedWeddingDateStart = [
                      (dd2 > 9 ? "" : "0") + dd2,
                      (mm2 > 9 ? "" : "0") + mm2,
                      date2.getFullYear(),
                    ].join("/");

                    // WeddingDateEnd
                    let date3 = new Date(request.estimatedWeddingDateEnd);

                    var mm3 = date3.getMonth() + 1; // getMonth() is zero-based
                    var dd3 = date3.getDate();
                    var endHours = date3.getHours();
                    var endMinutes = date3.getMinutes();

                    let estimatedWeddingDateEnd = [
                      (dd3 > 9 ? "" : "0") + dd3,
                      (mm3 > 9 ? "" : "0") + mm3,
                      date3.getFullYear(),
                    ].join("/");

                    return (
                      <TableRow
                        key={"row-" + index}
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", md: "row" },
                          borderBottom: "0.5px solid #97cafc",
                        }}
                      >
                        <TableCell
                          sx={{
                            border: 0,
                            width: { xs: "100%", md: "20%" },
                          }}
                        >
                          <Stack direction={{ xs: "column", md: "row" }}>
                            <Stack direction={"column"}>
                              <Stack direction={"column"} marginBottom={1}>
                                <Typography fontSize={13} color={"#9E9E9E"}>
                                  User
                                </Typography>

                                <Typography
                                  marginLeft={"0rem"}
                                  fontSize={15}
                                  color={"primary.main"}
                                  fontWeight={"bold"}
                                >
                                  {request.firstName} {request.lastName}
                                </Typography>

                              </Stack>
                              <Stack direction={"column"}>
                                <Typography fontSize={13} color={"#9E9E9E"}>
                                  Vendor
                                </Typography>
                                {request.vendor ? (
                                  <Link
                                    underline="hover"
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    color="inherit"
                                    href={
                                      "../vendor-details?id=" +
                                      request.vendor._id
                                    }
                                  >
                                    <Typography
                                      fontSize={15}
                                      color={"primary.main"}
                                      fontWeight={"bold"}
                                    >
                                      {request.vendor.name}
                                    </Typography>
                                  </Link>
                                ) : (
                                  <Typography
                                    fontSize={15}
                                    color={"primary.main"}
                                    fontWeight={"bold"}
                                  >
                                    {"-"}
                                  </Typography>
                                )}
                              </Stack>
                            </Stack>
                          </Stack>
                        </TableCell>

                        <TableCell
                          sx={{ border: 0, width: { xs: "100%", md: "20%" } }}
                        >
                          <Stack
                            direction={{ xs: "column", md: "column" }}
                            justifyContent={{ xs: "center", md: "start" }}
                          >
                            <Stack direction={"column"} marginBottom={1}>
                              <Typography fontSize={13} color={"#9E9E9E"}>
                                Phone
                              </Typography>
                              <Typography
                                fontSize={15}
                                color={"#212121"}
                                fontWeight={"bold"}
                              >
                                {request.phone ? request.phone : "-"}
                              </Typography>
                            </Stack>


                            <Stack direction={"column"}>
                              <Typography fontSize={13} color={"#9E9E9E"}>
                                Budget
                              </Typography>
                              <Typography
                                fontSize={15}
                                color={"#212121"}
                                fontWeight={"bold"}
                                marginBottom={"1rem"}
                              >
                                {request.budgetFrom} - {request.budgetTo}
                              </Typography>
                            </Stack>
                          </Stack>
                        </TableCell>

                        <TableCell
                          sx={{ border: 0, width: { xs: "100%", md: "20%" } }}
                        >
                          <Stack direction={"column"} justifyContent={"start"}>
                            <Stack direction={"column"} marginBottom={1}>
                              <Typography fontSize={13} color={"#9E9E9E"}>
                                Wedding Day
                              </Typography>
                              <Typography
                                fontSize={15}
                                color={"#212121"}
                                fontWeight={"bold"}
                              >
                                {estimatedWeddingDateStart}
                              </Typography>
                            </Stack>
                            <Stack direction={"column"} marginBottom={1}>
                              <Typography fontSize={13} color={"#9E9E9E"}>
                                Wedding duration
                              </Typography>
                              <Typography
                                fontSize={15}
                                color={"#212121"}
                                fontWeight={"bold"}
                              >
                                {startHours}{":"}{startMinutes}{" "}
                                <Box component="span" color={"primary.main"}>
                                  {" "}
                                  <ArrowRightAltIcon
                                    sx={{ mr: 0.5 }}
                                    fontSize="inherit"
                                  />{" "}
                                </Box>{" "}
                                {endHours}{":"}{endMinutes}
                              </Typography>
                            </Stack>


                          </Stack>
                        </TableCell>

                        <TableCell
                          sx={{ border: 0, width: { xs: "100%", md: "20%" } }}
                        >
                          <Stack
                            direction={{ xs: "row", md: "column" }}
                            justifyContent={"start"}
                          >
                            <Stack direction={"column"} marginBottom={1}>
                              <Typography fontSize={13} color={"#9E9E9E"}>
                                Request Details
                              </Typography>
                              <Typography
                                fontSize={15}
                                color={"#212121"}
                                fontWeight={"bold"}
                                marginBottom={"1rem"}
                              >
                                {request.requestDetails}
                              </Typography>
                            </Stack>
                          </Stack>
                        </TableCell>
                        <TableCell
                          sx={{ border: 0, width: { xs: "100%", md: "20%" } }}
                        >
                          <Stack
                            direction={{ xs: "row", md: "column" }}
                            justifyContent={"start"}
                          >
                            <Stack direction={"column"} marginBottom={1}>
                              <Typography fontSize={13} color={"#9E9E9E"}>
                                Wedding Details
                              </Typography>
                              <Typography
                                fontSize={15}
                                color={"#212121"}
                                fontWeight={"bold"}
                                marginBottom={"1rem"}
                              >
                                {request.weddingDetails}
                              </Typography>
                              {/**************************************************************************** */}
                            </Stack>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {totalPages === 0 ||
            totalPages === -1 ||
            (totalPages === 1 && quoteRequests.length === 0) ? (
            <Typography textAlign={"center"}>No Requests found!</Typography>
          ) : (
            <div
              onClick={(e) => {
                if (totalPages !== 1) {
                  e.preventDefault();
                  setChangePage(changePage + 1);
                }
              }}
            >
              <PaginationCustom totalPages={totalPages} />
            </div>
          )}
        </Paper>
      </Box>
    </div>
  );
};

export default QuoteRequests;
