import { AuthComponentsContext } from "../../../context/AuthComponentsContext";

import { Box, Grid, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, useContext } from "react";
// third party
import * as Yup from "yup";
import { Formik } from "formik";

// assets
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { USER_CHANGEPW, COOKIES_SETTINGS } from "../../../endpoints/Constants";
import axios from "axios";

const ChangePasswordForm = (props) => {
  //console.log(props);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };
  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleClickShowConfirmNewPassword = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Formik
      initialValues={{
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        oldPassword: Yup.string().required("Old Password is required"),
        newPassword: Yup.string()
          .min(8, "Must be at least 8 characters")
          .max(255)
          .required("New password is required"),
        confirmNewPassword: Yup.string()
          .min(8, "Must be at least 8 characters")
          .max(255)
          .required("Confirm new password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        if (values.newPassword !== values.confirmNewPassword) {
          setErrors({
            confirmNewPassword:
              "New password and confirm new password must be the same",
          });
          return;
        }
        try {
          setLoading(true);
          // console.log(values);
          let formData = {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
          };
          axios
            .put(USER_CHANGEPW, formData, COOKIES_SETTINGS)
            .then((res) => {
              setLoading(false);

              if (res.status === 200) {
                setStatus({ success: true });
                window.location.reload();
              } else {
                setStatus({ success: false });
                setErrors({ submit: res.data.message });
              }
            })
            .catch((err) => {
              setLoading(false);
              setStatus({ success: false });
              setErrors({ submit: err.response.data.message });
            });
        } catch (err) {
          //setLoading(false);
          setStatus({ success: false });
          setErrors({ submit: "Error" });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container justifyContent="center" alignItems="center">
            {errors.submit && (
              <Alert severity="error" sx={{ width: "100%", margin: "1rem" }}>
                {errors.submit}
              </Alert>
            )}

            <FormControl
              fullWidth
              error={Boolean(touched.oldPassword && errors.oldPassword)}
              sx={{ margin: "0.5rem 1rem" }}
            >
              <InputLabel
                htmlFor="outlined-adornment-oldPassword-login"
                sx={{ color: "text.lightGray" }}
              >
                Old Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-oldPassword-login"
                type={showOldPassword ? "text" : "password"}
                value={values.oldPassword}
                name="oldPassword"
                autoComplete="on"
                onBlur={handleBlur}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowOldPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showOldPassword ? <VisibilityIcon /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Old Password"
                inputProps={{}}
                style={{
                  color: "#000",
                  borderRadius: "10px",
                }}
              />
              {touched.oldPassword && errors.oldPassword && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-oldPassword-login"
                >
                  {errors.oldPassword}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              fullWidth
              error={Boolean(touched.newPassword && errors.newPassword)}
              sx={{ margin: "0.5rem 1rem" }}
            >
              <InputLabel
                htmlFor="outlined-adornment-newPassword-login"
                sx={{ color: "text.lightGray" }}
              >
                New Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-newPassword-login"
                type={showNewPassword ? "text" : "password"}
                value={values.newPassword}
                name="newPassword"
                autoComplete="on"
                onBlur={handleBlur}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showNewPassword ? <VisibilityIcon /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="New Password"
                inputProps={{}}
                style={{
                  color: "#000",
                  borderRadius: "10px",
                }}
              />
              {touched.newPassword && errors.newPassword && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-newPassword-login"
                >
                  {errors.newPassword}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              fullWidth
              error={Boolean(
                touched.confirmNewPassword && errors.confirmNewPassword
              )}
              sx={{ margin: "0.5rem 1rem" }}
            >
              <InputLabel
                htmlFor="outlined-adornment-confirmNewPassword-login"
                sx={{ color: "text.lightGray" }}
              >
                Confirm New Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-confirmNewPassword-login"
                type={showConfirmNewPassword ? "text" : "password"}
                value={values.confirmNewPassword}
                name="confirmNewPassword"
                autoComplete="on"
                onBlur={handleBlur}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showConfirmNewPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm New Password"
                inputProps={{}}
                style={{
                  color: "#000",
                  borderRadius: "10px",
                }}
              />
              {touched.confirmNewPassword && errors.confirmNewPassword && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-confirmNewPassword-login"
                >
                  {errors.confirmNewPassword}
                </FormHelperText>
              )}
            </FormControl>

            <Grid item xs={11}>
              <Button
                fullWidth
                disabled={loading}
                size="medium"
                type="submit"
                variant="contained"
                sx={{
                  padding: "0.7rem 0rem",
                  color: "text.white",
                  backgroundColor: "text.tertiary",
                  ":hover": {
                    backgroundColor: "text.tertiaryHover",
                  },
                  borderRadius: "48px",
                  textTransform: "none",
                  fontSize: "1.2rem",
                  marginBottom: "1.2rem",
                  marginTop: "1.2rem",
                }}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "#FFF" }} size={"1.8rem"} />
                ) : (
                  "Change Password"
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
