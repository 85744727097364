
import { COOKIES_SETTINGS, WEDDING_WEBSITE, QUESTIONS_WEBSITE, GUEST_BOARD_WEBSITE } from "../../endpoints/Constants";
import axios from "axios";

export const createWebsite = async (data) => {

    var response;
    try {
        response = await axios.post(
            WEDDING_WEBSITE,
            data,
            COOKIES_SETTINGS
        );
    } catch (error) {
        throw error;
    }

    return response;
}
export const editWebsite = async (data) => {

    var response;
    try {
        response = await axios.put(
            WEDDING_WEBSITE,
            data,
            COOKIES_SETTINGS
        );
    } catch (error) {
        throw error;
    }

    return response;
}
export const getQuestions = async () => {
    var response;
    try {
        response = await axios.get(QUESTIONS_WEBSITE, COOKIES_SETTINGS);
    } catch (error) {
        throw error;
    }
    return response.data;
}
export const addQuestion = async (data) => {
    var response;
    try {
        response = await axios.post(QUESTIONS_WEBSITE, {
            question: data.question,
            answer: data.answer,
        }, COOKIES_SETTINGS);

    } catch (error) {
        throw error;
    }
    return response.data;
}
export const editQuestion = async (data) => {
    var response;
    try {
        response = await axios.put(QUESTIONS_WEBSITE, data, COOKIES_SETTINGS);

    } catch (error) {
        throw error;
    }
    return response.data;
}
export const deleteQuestion = async (data) => {


    await axios.delete(
        QUESTIONS_WEBSITE + "/" + data._id,


        COOKIES_SETTINGS


    );

}
export const getGuestListApi = async (page) => {
    var response;
    try {
        response = await axios.get(GUEST_BOARD_WEBSITE + "?page=" + page, COOKIES_SETTINGS);
    } catch (error) {
        throw error;
    }
    return response.data;
}
export const getGuestBoardDetailByIdApi = async (id) => {
    var response;
    try {
        response = await axios.get(GUEST_BOARD_WEBSITE + "/" + id, COOKIES_SETTINGS);
    } catch (error) {
        throw error;
    }
    return response.data;
}