import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";

import "./GuestBoard.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Box from "@mui/material/Box";
import { Alert, CircularProgress } from "@mui/material";
import axios from "axios";
import {
  COOKIES_SETTINGS,
  POST_WEDDING_RSVP_PHONE,
  POST_WEDDING_GUEST_BOARD,
  POST_WEDDING_GUEST_BOARD_URL,
  GET_TEMPLATE_DETAILS,
} from "../../endpoints/Constants";
import { PhoneContext } from "../../context/PhoneContext";
import Typography from "@mui/material/Typography";
import { MobileCodeNumbers } from "../MobileCodeNumbers";


const GuestBoard = ({ templateID }) => {
  const phone = useContext(PhoneContext);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryNumber, setCountryNumber] = useState("+20");
  const [errorMessage, setErrorMessage] = useState(
    "Please provide a valid phone number."
  );
  const [RSVPData, setRSVPData] = useState("");
  const [imagesUrls, setImagesUrls] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [tabLoading, setTabLoading] = useState(false);
  const [errPhoneNumber, setErrPhoneNumber] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [guestWishes, setGuestWishes] = useState("");
  const [subTabLoading, setSubTabLoading] = useState(true);
  const [submitStatusSuccess, setSubmitStatusSuccess] = useState(null);
  const [wishesErrorMessage, setWishesErrorMessage] = useState("Error");
  const [wishesLoading, setWishesLoading] = useState(false);

  useEffect(() => {
    setRSVPData(phone.state.RSVPData);
    setSubTabLoading(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errPhoneNumber === true) {
      setTimeout(() => setErrPhoneNumber(null), 7000);
    }
  }, [errPhoneNumber]);

  useEffect(() => {
    if (submitStatusSuccess !== null) {
      setTimeout(() => setSubmitStatusSuccess(null), 7000);
    }
  }, [submitStatusSuccess]);

  const observer = useRef();

  const pageToLoad = useRef(1);
  const hasMore = useRef(true);

  const lastElementRef = useCallback((node) => {
    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (hasMore.current) {
          pageToLoad.current = pageToLoad.current + 1;
          getGuestWishesData(pageToLoad.current);
        }
      }
    });

    if (node) {
      observer.current.observe(node);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [guestWishesList, setGuestWishesList] = useState([]);

  const getGuestWishesData = async (currentPage) => {
    setWishesLoading(true);
    axios
      .get(
        GET_TEMPLATE_DETAILS + `/${templateID}/guestBoard?page=` + currentPage,
        COOKIES_SETTINGS
      )
      .then((res) => {
        setWishesLoading(false);
        hasMore.current = res.data.totalPages > pageToLoad.current;
        setGuestWishesList((guestWishesList) => [
          ...guestWishesList,
          ...res.data.docs,
        ]);
      });
  };
  const initialPageLoaded = useRef(false);
  useEffect(() => {
    if (initialPageLoaded.current) {
      return;
    }
    if (phone.state.isPhoneLogged) {
      getGuestWishesData(pageToLoad.current);
      initialPageLoaded.current = true;
    }
  }, [pageToLoad, phone.state.isPhoneLogged]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmitHandler = (event) => {
    event.preventDefault();

    // if (countryNumber === "+20") {
    //   var pattern = /^1[0125][0-9]{8}$/;
    //   if (!pattern.test(phoneNumber)) {
    //     setErrorMessage("Please enter a valid phone number.");
    //     setErrPhoneNumber(true);
    //     return;
    //   }
    // } else if (countryNumber === "+1") {
    //   var pattern = /^[0-9]{10}$/;
    //   if (!pattern.test(phoneNumber)) {
    //     setErrorMessage("Please enter a valid phone number.");
    //     setErrPhoneNumber(true);
    //     return;
    //   }
    // }
    if (phoneNumber == "") {
      setErrorMessage("Please enter a phone number.");
      setErrPhoneNumber(true);
      return;
    }
    let formData = {};

    formData = {
      phone: countryNumber + phoneNumber,
    };
    handlePhoneRequest(formData);
  };

  const handlePhoneRequest = async (formData) => {
    setTabLoading(true);
    try {
      window.scrollTo(200, 400);
      axios
        .post(
          POST_WEDDING_RSVP_PHONE + `/${templateID}/guest`,
          formData,
          COOKIES_SETTINGS
        )
        .then((res) => {
          if (res.status === 200) {
            //phone.state.RSVPData = res.data
            //phone.state.isPhoneLogged = true
            phone.logPhone(formData.phone, res.data);
            //console.log("guest board data: ",res.data)
            //console.log("guest board data phone: ",phone)
            setRSVPData(res.data);
            setTabLoading(false);
            setSubTabLoading(false);
            setErrPhoneNumber(false);
          } else {
            setTabLoading(false);
            setSubTabLoading(false);
            setErrorMessage("Kindly check your phone number.");
            setErrPhoneNumber(true);
          }
        })
        .catch((err) => {
          setTabLoading(false);
          setSubTabLoading(false);
          setErrorMessage("This number is not in the guest list.");
          setErrPhoneNumber(true);
        });
    } catch (err) {
      setTabLoading(false);
      setSubTabLoading(false);
      setErrorMessage("This number is not in the guest list.");
      setErrPhoneNumber(true);
    }
  };

  const uploadSubmitHandler = (event) => {
    event.preventDefault();
    if (
      selectedImages.length === 0 &&
      guestWishes === "" &&
      firstName === "" &&
      lastName === ""
    ) {
      setSubmitStatusSuccess(false);
      setWishesErrorMessage("Fields are empty !");
      return;
    }

    let imagesNames = [];
    let imageUrls = [];

    for (let i = 0; i < selectedImages.length; i++) {
      imagesNames.push(selectedImages[i].name);
    }
    let formData = {
      weddingId: templateID,
      guestId: RSVPData._id,
      images: imagesNames,
    };

    setSubTabLoading(true);
    window.scrollTo(200, 400);
    try {
      axios
        .post(POST_WEDDING_GUEST_BOARD_URL, formData, COOKIES_SETTINGS)
        .then(async (res) => {
          if (res.status === 200) {
            // console.log("images names response: ",res.data)
            for (let i = 0; i < res.data.length; i++) {
              const uploadPhoto = new FormData();
              const uploadUrl = res.data[i].data.url;
              const image = res.data[i].data;
              Object.keys(image.fields).forEach((field) => {
                uploadPhoto.append(field, image.fields[field]);
              });
              uploadPhoto.append("file", selectedImages[i]);

              await axios({
                method: "post",
                url: uploadUrl,
                data: uploadPhoto,
                headers: { "Content-Type": "multipart/form-data" },
              });
              imageUrls.push(res.data[i].imgUrl);
            }
            // console.log("image urls: ",imageUrls)
            let guestData = {
              wish: guestWishes,
              firstName: firstName,
              lastName: lastName,
              weddingId: templateID,
              guestId: RSVPData._id,
              images: imageUrls,
            };
            axios
              .post(POST_WEDDING_GUEST_BOARD, guestData, COOKIES_SETTINGS)
              .then(async (res) => {
                if (res.status === 200) {
                  // console.log("post urls: ",res.data)
                  setSubTabLoading(false);
                  setSubmitStatusSuccess(true);
                  setGuestWishes("");
                  setSelectedImages([]);
                  pageToLoad.current = 1
                  setGuestWishesList([])
                  getGuestWishesData(1)
                } else {
                  setSubTabLoading(true);
                  setSubmitStatusSuccess(false);
                }
              });
          } else {
            setSubTabLoading(true);
            setSubmitStatusSuccess(false);
          }
        })
        .catch((err) => {
          setSubTabLoading(true);
          setSubmitStatusSuccess(false);
          setWishesErrorMessage(err.response.data.message);
        });
    } catch (err) {
      setSubTabLoading(true);
      setSubmitStatusSuccess(false);
      setWishesErrorMessage(err.response.data.message);
    }
  };

  return (
    <>
      {phone.state.isPhoneLogged ? (
        <>
          {subTabLoading ? (
            <Box
              sx={{
                width: "100%",
                height: "50vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress
                style={{ color: phone.state.templateMainColor }}
              />
            </Box>
          ) : (

            <div className="guest-list d-flex flex-column align-items-center">
              <div id="guest-board-col" className="card mb-5 w-100">
                <form
                  id="guest-board-form"
                  className="card-body"
                  onSubmit={uploadSubmitHandler}
                >

                  <div className="mb-3" id="guest-board-wishes-div">
                    <label
                      for="guest-board-wishes"
                      className="form-label"
                      id="guest-board-wishes-header"
                    >
                      <strong>
                        Write your wishes for the bride and groom!
                      </strong>
                    </label>
                    <div className="row pb-3 justify-content-between">
                      <div className="col-5">
                        <input
                          type="text"
                          className="form-control shadow-none"
                          id="guest-board-wishes"
                          placeholder="First Name"
                          onChange={(e) => {
                            setFirstName(e.target.value);
                          }}
                        ></input>
                      </div>
                      <div className="col-5">
                        <input
                          type="text"
                          className="form-control  shadow-none"
                          id="guest-board-wishes"
                          placeholder="Last Name"
                          onChange={(e) => {
                            setLastName(e.target.value);
                          }}
                        ></input>
                      </div>
                    </div>

                    <textarea
                      placeholder="Please write your wishes..."
                      className="form-control  shadow-none"
                      id="guest-board-wishes"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        setGuestWishes(e.target.value);
                      }}
                    ></textarea>
                  </div>
                  <div className="mb-1">
                    <label
                      for="guest-board-images"
                      className="form-label"
                      id="guest-board-images-header"
                    >
                      <strong>
                        Took photos? Send them to bride and groom!
                      </strong>
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      className="form-control  shadow-none"
                      id="guest-board-images"
                      onChange={(e) => {
                        setSelectedImages(e.target.files);
                        setImagesUrls("");
                      }}
                    ></input>
                  </div>
                  <button
                    type="submit"
                    onClick={uploadSubmitHandler}
                    placeholder="Upload Photos"
                    className="btn btn-primary shadow-none"
                    id="guest-board-submit"
                  >
                    Post
                  </button>



                </form>
                {submitStatusSuccess !== null ? (
                  submitStatusSuccess === true ? (
                    <Alert
                      sx={{
                        fontSize: {
                          xs: "50%",
                          sm: "60%",
                          md: "70%",
                          lg: "80%",
                        },
                        alignItems: "center",
                        padding: {
                          xs: "0% 4% 0% 4%",
                          sm: "0% 4% 0% 4%",
                          md: "0% 4% 0% 4%",
                          lg: "2% 4% 2% 4%",
                        },
                        margin: "0",
                        "& .MuiAlert-icon": {
                          fontSize: { xs: 12, sm: 15, md: 20, lg: 25 },
                        },
                        borderRadius: "inherit",
                      }}
                      severity="success"
                    >
                      Successful
                    </Alert>
                  ) : (
                    <Alert
                      sx={{
                        fontSize: {
                          xs: "50%",
                          sm: "60%",
                          md: "70%",
                          lg: "80%",
                        },
                        alignItems: "center",
                        padding: {
                          xs: "0% 4% 0% 4%",
                          sm: "0% 4% 0% 4%",
                          md: "0% 4% 0% 4%",
                          lg: "2% 4% 2% 4%",
                        },
                        margin: "0",
                        "& .MuiAlert-icon": {
                          fontSize: { xs: 12, sm: 15, md: 20, lg: 25 },
                        },
                        borderRadius: "inherit",
                      }}
                      severity="error"
                    >
                      {wishesErrorMessage}
                    </Alert>
                  )
                ) : (
                  ""
                )}
              </div>
              {
                guestWishesList.map((item, index) => {
                  const imageHeight = "100%";
                  const imageWidth = "100%";
                  return (
                    <div className="w-100">
                      <div
                        id={"carouselExampleControls" + index}
                        className="carousel slide w-100"
                        data-bs-interval="false"
                        data-ride="carousel"
                      >

                        <div className="carousel-inner carousel-container">
                          {item.images.map((img, index) => {
                            return (
                              <div
                                key={"img-" + item._id + "-" + index}
                                className={
                                  index === 0
                                    ? "carousel-item d-none active"
                                    : "carousel-item d-none"
                                }
                              >
                                <img
                                  className="d-block guest-board-image"
                                  //height={imageHeight}
                                  width={imageWidth}
                                  src={img}
                                  alt="First slide"
                                />
                              </div>
                            );
                          })}
                        </div>
                        {
                          item.images.length === 0 ? null :
                            <button
                              className="carousel-control-prev"
                              type="button"
                              data-bs-target={"#carouselExampleControls" + index}
                              data-bs-slide="prev"
                            >
                              <NavigateBeforeIcon
                                className="previous"
                                style={{ color: phone.state.templateMainColor }}
                                sx={{ width: "1.2rem", height: "1.5rem" }}

                              ></NavigateBeforeIcon>
                            </button>
                        }
                        {
                          item.images.length === 0 ? null :
                            <button
                              className="carousel-control-next"
                              type="button"
                              data-bs-target={"#carouselExampleControls" + index}
                              data-bs-slide="next"
                            >
                              <NavigateNextIcon
                                className="next"
                                style={{ color: phone.state.templateMainColor }}
                                sx={{ width: "1.2rem", height: "1.5rem" }}

                              ></NavigateNextIcon>
                            </button>
                        }
                      </div>


                      <div className="guest-wish card w-100">
                        <div class="card-body">
                          <Typography
                            variant="h5"
                            fontSize={{
                              xs: "2.1vw",
                              sm: "2.1vw",
                              md: "1.5vw",
                              lg: "1.5vw",
                            }}
                            fontWeight="bold"
                            marginBottom={"1vw"}
                            className="card-title"
                          >
                            {item.firstName + " " + item.lastName}
                          </Typography>
                          <Typography
                            fontSize={{
                              xs: "2.1vw",
                              sm: "1.8vw",
                              md: "1.2vw",
                              lg: "1.2vw",
                            }}
                            ref={
                              guestWishesList.length === index + 1
                                ? lastElementRef
                                : null
                            }
                          >
                            {item.wish}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  );
                })

              }

              {wishesLoading ? (
                <Box
                  sx={{
                    width: "100%",
                    height: "10vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress
                    style={{ color: phone.state.templateMainColor }}
                  />
                </Box>
              ) : null}
            </div>
          )}
        </>
      ) : (
        <>
          {tabLoading ? (
            <Box
              sx={{
                width: "100%",
                height: "50vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress
                style={{ color: phone.state.templateMainColor }}
              />
            </Box>
          ) : (
            <div id="RSVP-phone-col" className="card">
              <form
                id="RSVP-phone-form"
                className="card-body"
                onSubmit={onSubmitHandler}
              >
                {errPhoneNumber !== null ? (
                  errPhoneNumber === true ? (
                    <Alert
                      sx={{
                        fontSize: {
                          xs: "50%",
                          sm: "60%",
                          md: "70%",
                          lg: "80%",
                        },
                        alignItems: "center",
                        padding: {
                          xs: "0% 4% 0% 4%",
                          sm: "0% 4% 0% 4%",
                          md: "0% 4% 0% 4%",
                          lg: "2% 4% 2% 4%",
                        },
                        margin: "0",
                        "& .MuiAlert-icon": {
                          fontSize: { xs: 12, sm: 15, md: 20, lg: 25 },
                        },
                        borderRadius: "inherit",
                      }}
                      severity="error"
                    >
                      {errorMessage}
                    </Alert>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                <div className="mb-3">
                  <label
                    for="inlineFormCustomSelectPref"
                    className="form-label"
                    id="RSVP-phone-header"
                  >
                    <strong>Please enter your phone number</strong>
                  </label>
                  <div className="input-group mb-2" id="RSVP-phone-div">
                    <div id="country-start-num">
                      <select
                        className="form-select  shadow-none"
                        id="form-select"
                        aria-label="Default select"
                        onChange={(e) => setCountryNumber(e.target.value)}
                      >
                          {
                            MobileCodeNumbers.map((obj)=>{
                              return <option value={obj.code} selected={obj.code === countryNumber ? true : false}><p>{obj.code}</p></option>
                            })
                          }
                      </select>
                    </div>
                    <>
                      <input
                        type="text"
                        className="form-control form-phone-number  shadow-none"
                        id="form-phone-number-guest-board"
                        placeholder="Phone"
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      ></input>
                    </>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={onSubmitHandler}
                  placeholder="Upload Photos"
                  className="btn btn-primary shadow-none"
                  id="RSVP-phone-submit-guest-board"
                >
                  Submit
                </button>
              </form>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default GuestBoard;
