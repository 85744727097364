import React from "react";
import Box from "@mui/material/Box";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

import getStarted1Image from "../../assets/webapp/home/get-started/1.png";
import getStarted2Image from "../../assets/webapp/home/get-started/2.png";
import getStarted3Image from "../../assets/webapp/home/get-started/3.png";

import Button from "@mui/material/Button";

import "./Home.css";

const GetStartedCarousel = () => {
  const imgs = [
    {
      src: getStarted1Image,
      sx: {
        position: "absolute",
        top: { xs: "23vw", sm: "22vw", md: "19.5vw", lg: "20vw" },
        right: { xs: "7vw", sm: "9.5vw", md: "13vw", lg: "12vw" },
        fontSize: { xs: "2vw", sm: "1.5vw", md: "1rem", lg: "1.1rem" },
        padding: {
          xs: "0.5rem 0.9rem",
          sm: "0.9rem 0.7rem",
          md: "1.2rem 1rem",
          lg: "1.2rem 2rem",
        },
        height: { xs: "6vw", sm: "4vw", md: "2rem", lg: "3rem" },

        color: "text.dark",
        backgroundColor: "#647e7e",
        fontWeight: "600",
        borderRadius: "2rem",
        ":hover": {
          backgroundColor: "#557070",
        },
        textAlign: "center",
      }
    },
    {
      src: getStarted2Image,
      sx: {
        position: "absolute",
        top: { xs: "27vw", sm: "25.5vw", md: "22.1vw", lg: "22.9vw" },
        right: { xs: "11vw", sm: "13vw", md: "15.5vw", lg: "15.2vw" },
        fontSize: { xs: "2vw", sm: "1.5vw", md: "1rem", lg: "1.1rem" },
        padding: {
          xs: "0.5rem 0.9rem",
          sm: "0.9rem 0.7rem",
          md: "1.2rem 1rem",
          lg: "1.2rem 2rem",
        },
        height: { xs: "5vw", sm: "4vw", md: "2rem", lg: "3rem" },

        color: "text.dark",
        backgroundColor: "#6094cf",
        fontWeight: "600",
        borderRadius: "2rem",
        ":hover": {
          backgroundColor: "#517bac",
        },
        textAlign: "center",
      }
    },
    {
      src: getStarted3Image,
      sx: {
        position: "absolute",
        top: { xs: "27vw", sm: "26vw", md: "22.5vw", lg: "23.4vw" },
        right: { xs: "11vw", sm: "11.5vw", md: "15vw", lg: "14vw" },
        fontSize: { xs: "2vw", sm: "1.5vw", md: "1rem", lg: "1.1rem" },
        padding: {
          xs: "0.5rem 0.9rem",
          sm: "0.9rem 0.7rem",
          md: "1.2rem 1rem",
          lg: "1.2rem 2rem",
        },
        height: { xs: "5vw", sm: "4vw", md: "2rem", lg: "3rem" },

        color: "text.dark",
        backgroundColor: "#f7aa9f",
        fontWeight: "600",
        borderRadius: "2rem",
        ":hover": {
          backgroundColor: "#d49186",
        },
        textAlign: "center",
      }
    },
  ]

  const links = [
    '/checklist',
    '/vendor-category',
    '/invitations'
  ]
  const getCarouselItems = () => {
    const items = [];
    for (let i = 0; i < imgs.length; i += 1) {
      const item = imgs[i]
      items.push(
      <div className={i===0 ? "carousel-item active" : "carousel-item"} key={"img-"+i}>
        <Box
          id="get-started-container-image"
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingTop: { xs: "1rem", sm: "2rem", md: "3rem", lg: "3rem" },
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "100%", md: "85%", lg: "90%" } }}>
            <img src={item.src} alt="..." width="100%" />
           
            <Button
              variant="text"
              fontWeight="bold"
              sx={item.sx}
              href={links[i]}
            >
              {" "}
              LEARN MORE
            </Button>
          </Box>
        </Box>
      </div>
      )
    }

    return items
  }

 
  return (
    <Box
      id="get-started-container"
      sx={{
        height: "100%",
        PaddingTop: "1rem",
        margin: { xs: "0 0rem", sm: "0 2rem", md: "0 3rem" },
      }}
    >
      <div
        id="getStartedCarouselControl"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          {getCarouselItems()}
        </div>

        <Button
          className="carousel-control-prev"
          data-bs-target={"#getStartedCarouselControl"}
          data-bs-slide="prev"
          sx={{
            position: "absolute",
            top: "30%",
            left: { xs: "6vw", sm: "6vw", md: "10.5vw", lg: "8vw" },
            height: "50%",
            ':hover':{
              backgroundColor:"transparent"
            }
          }}
        >
          <Box
            component="span"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              backgroundColor: "#FFF",
              borderRadius: { xs: "2px", md: "4px" },
              padding: { xs: "0px", md: "5px" },
              ":hover": {
                backgroundColor: "#FFF",
              },
            }}
          >
            <NavigateBeforeIcon
              sx={{
                color: "primary.main",
                width: { xs: "4vw", sm: "3vw", md: "2vw", lg: "2vw" },
                height: { xs: "4vw", sm: "3vw", md: "2vw", lg: "2vw" },
              }}
            ></NavigateBeforeIcon>
          </Box>
        </Button>

        <Button
          className="carousel-control-next"
          data-bs-target={"#getStartedCarouselControl"}
          data-bs-slide="next"
          sx={{
            position: "absolute",
            top: "30%",
            right: { xs: "6vw", sm: "6vw", md: "10.5vw", lg: "8vw" },
            height: "50%",
            ':hover':{
              backgroundColor:"transparent"
            }
          }}
        >
          <Box
            component="span"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              backgroundColor: "#FFF",
              borderRadius: { xs: "2px", md: "4px" },
              padding: { xs: "0px", md: "5px" },
              ":hover": {
                backgroundColor: "#FFF",
              },
            }}
          >
            <NavigateNextIcon
              sx={{
                color: "primary.main",
                width: { xs: "4vw", sm: "3vw", md: "2vw", lg: "2vw" },
                height: { xs: "4vw", sm: "3vw", md: "2vw", lg: "2vw" },
              }}
            ></NavigateNextIcon>
          </Box>
        </Button>
      </div>
    </Box>
  );
};

export default GetStartedCarousel;
