import {
  Box,
  Grid,
  OutlinedInput,
  Typography,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useContext } from "react";
import { ProfileContext } from "../../context/ProfileContext";
import { AuthComponentsContext } from "../../context/AuthComponentsContext";
const LoginDetails = () => {
  const profile = useContext(ProfileContext);
  const authComponents = useContext(AuthComponentsContext);
  //console.log(profile);
  return (
    <Box
      sx={{
        width: { xs: "100%", md: "65%" },
        paddingX: { md: "20px" },
        marginBottom: "20px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item sm={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel
              htmlFor="outlined-adornment-email"
              sx={{ color: "text.lightGray" }}
            >
              Email
            </InputLabel>
            <OutlinedInput
              fullWidth
              sx={{
                background: "#F8FAFD",
                border: "1px solid #E7ECF3",
                borderRadius: "10px",
                color: "#000000",
                ".MuiOutlinedInput-input.Mui-disabled": {
                  "-webkit-text-fill-color": "#000000 !important",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#E7ECF3",
                },
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#E7ECF3",
                },
                fieldSet: {
                  legend: {
                    width: "unset",
                  },
                },
              }}
              id={"outlined-adornment-" + "email"}
              type={"text"}
              value={profile.user.email}
              name={"email"}
              label="Email"
              placeholder="Email"
              disabled={true}
            />
          </FormControl>
        </Grid>
        <Grid item sm={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel
              htmlFor="outlined-adornment-password"
              sx={{ color: "text.lightGray" }}
            >
              Password
            </InputLabel>
            <OutlinedInput
              fullWidth
              sx={{
                background: "#F8FAFD",
                border: "1px solid #E7ECF3",
                borderRadius: "10px",
                ".MuiOutlinedInput-input.Mui-disabled": {
                  "-webkit-text-fill-color": "#000000 !important",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#E7ECF3",
                },
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#E7ECF3",
                },
                fieldSet: {
                  legend: {
                    width: "unset",
                  },
                },
              }}
              id={"outlined-adornment-" + "password"}
              type={"text"}
              value={"......................"}
              name={"password"}
              placeholder="Password"
              label="Password"
              disabled={true}
            />
          </FormControl>
        </Grid>
        <Grid item sm={12} md={6}>
          <Typography
            sx={{
              color: "#F1A3A5",
              fontSize: "16px",
              fontWeight: "600",
              cursor: "pointer",
            }}
            onClick={authComponents.openChangePassword}
          >
            Change password
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
export default LoginDetails;
