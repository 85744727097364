import { Route, Routes } from "react-router-dom";
import "./DashboardCommonStyle.css";
import { Link } from "react-router-dom";
import React, { useEffect, useContext } from "react";
import { styled, ThemeProvider, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import GroupIcon from "@mui/icons-material/Group";
import CategoryIcon from "@mui/icons-material/Category";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import WebIcon from "@mui/icons-material/Web";
import LogoutIcon from "@mui/icons-material/Logout";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import image from "../assets/Lovebirds.png";
import axios from "axios";
import { ADMIN_LOGOUT, COOKIES_SETTINGS } from "../endpoints/Constants";

import { ProfileContext } from "../context/ProfileContext";

/**************************************************************/
import Home from "./home/DashboardHome";

/********************Admins********************************/
import Admins from "./admins/Admins";
import AdminsRecycleBin from "./admins/AdminsRecycleBin";

/********************Users********************************/
import Users from "./users/Users";

/********************Vendors*******************************/
import Vendors from "./vendors/Vendors";
import VendorDetails from "./vendors/VendorDetails";
import VendorsRecycleBin from "./vendors/VendorsRecycleBin";

/****************Vendor Categories**************************/
import VendorCategories from "./vendor-categories/VendorCategories";
import VendorCategoriesRecycleBin from "./vendor-categories/VendorCategoriesRecycleBin";

/********************Quote Requests*************************/
import QuoteRequests from "./quote-requests/QuoteRequests";

/********************Templates*************************/
import Templates from "./templates/Templates";

/**************************************************************/

import PropTypes from "prop-types";
import { Link as RouterLink, MemoryRouter } from "react-router-dom";
import { StaticRouter } from "react-router-dom/server";

const LinkBehavior = React.forwardRef((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return (
    <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />
  );
});

LinkBehavior.propTypes = {
  href: PropTypes.oneOfType([
    PropTypes.shape({
      hash: PropTypes.string,
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    PropTypes.string,
  ]).isRequired,
};

function Router(props) {
  const { children } = props;
  if (typeof window === "undefined") {
    return <StaticRouter location="/">{children}</StaticRouter>;
  }
  // console.log("Router");
  return <MemoryRouter>{children}</MemoryRouter>;
}

Router.propTypes = {
  children: PropTypes.node,
};

const theme = createTheme({
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
  palette: {
    primary: {
      main: "#0761b0",
      light: "#ebf5ff",
      border: "#97cafc",
    },
    secondary: {
      main: "#AB9E72",
      light: "#fff5d4",
    },
    link: {
      main: "rgb(97, 97, 97)",
    },
    bgsecondary: {
      main: "#FFF",
    },
    text: {
      primary: "#000",
      secondary: "#CCC",
    },
  },
});
const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  "&::-webkit-scrollbar": {
    width: "5px",
    marginTop: "500px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#9e9e9e",
    borderRadius: "5px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent",
  },
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(0)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(0)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const listItems = [
  {
    name: "KPI's",
    goTo: "./",
    icon: AnalyticsIcon,
  },
  {
    name: "Admins",
    goTo: "./admins",
    icon: AdminPanelSettingsIcon,
  },
  {
    name: "Users",
    goTo: "./users",
    icon: GroupIcon,
  },
  {
    name: "Vendor categories",
    goTo: "./vendor-categories",
    icon: CategoryIcon,
  },
  {
    name: "Vendors",
    goTo: "./vendors",
    icon: ShoppingBagIcon,
  },
  {
    name: "Quote requests",
    goTo: "./quote-requests",
    icon: RequestQuoteIcon,
  },
  {
    name: "Templates",
    goTo: "./templates",
    icon: WebIcon,
  },
];

const DashboardLayout = (props) => {
  //const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  const url = window.location;

  // console.log("AAAAAAAAAA:", url)
  useEffect(() => {
    // console.log("BBBBBBBBBB:", url)
    const path = window.location.pathname.split("/")[2];
    if (path === undefined || path === "") {
      setSelectedIndex(0);
    } else {
      const index = listItems.findIndex((item) => item.goTo === "./" + path);
      setSelectedIndex(index);
    }
    // console.log(path);
  }, [url]);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const handleLogout = () => {
    // console.log("Out");
    axios
      .post(ADMIN_LOGOUT, {}, COOKIES_SETTINGS)
      .then((res) => {
        // console.log(res);

        if (res.status === 200) {
          window.location.href = "/dashboard";
        }
      })
      .catch((err) => {});
  };

  const profile = useContext(ProfileContext);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{ display: { xs: "block", md: "block", lg: "flex", xl: "flex" } }}
      >
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            paddingBottom: 1,
          }}
          color="bgsecondary"
          elevation={0}
        >
          <Toolbar>
            <Typography variant="h6" noWrap component="div">
              <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
                sx={{
                  backgroundColor: "primary.light",

                  borderRadius: 2,
                  marginTop: 2,
                  marginRight: 5,
                  marginLeft: 3,
                  ...open,
                }}
                open={open}
              >
                <MenuIcon />
              </IconButton>
            </Typography>
            <Box
              width={{ xs: "45%", sm: "75%", md: "85%", lg: "85%" }}
              marginTop={2}
              alignItems="center"
              textAlign={"center"}
            >
              <Link to="./" onClick={(event) => handleListItemClick(event, 0)}>
                <img src={image} alt="" width={80} height={40} />
              </Link>
            </Box>
          </Toolbar>
        </AppBar>

        <Drawer
          variant="permanent"
          open={open}
          sx={{ border: "none", "& .MuiDrawer-paper": { borderWidth: 0 } }}
        >
          <List sx={{ paddingTop: 9 }}>
            {listItems.map((item, index) => {
              return (
                <div key={"container-" + index}>
                  {item.name === "Admins" && !profile.isOwner ? null : (
                    <Link
                      key={index}
                      to={item["goTo"]}
                      onClick={handleDrawerToggle}
                    >
                      <ListItemButton
                        selected={selectedIndex === index}
                        onClick={(event) => handleListItemClick(event, index)}
                        key={item.name}
                        sx={{
                          color: "primary.main",

                          "&.Mui-selected": {
                            backgroundColor:
                              selectedIndex === index
                                ? "primary.light"
                                : "rgb(255,255,255)",
                          },
                          borderRadius: "8px",
                          margin: "1rem 0.8rem",
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            color: "primary.main",
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <item.icon />
                        </ListItemIcon>

                        <ListItemText
                          primary={item.name}
                          sx={{ opacity: open ? 1 : 0, color: "primary.main" }}
                        />
                      </ListItemButton>
                    </Link>
                  )}
                </div>
              );
            })}

            <ListItemButton
              onClick={handleLogout}
              sx={{
                color: "primary.main",

                borderRadius: "8px",
                margin: "1rem 0.8rem",
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  color: "primary.main",
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <LogoutIcon />
              </ListItemIcon>

              <ListItemText
                primary="Logout"
                sx={{ opacity: open ? 1 : 0, color: "primary.main" }}
              />
            </ListItemButton>
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          <Box
            sx={{
              width: 1,
              minHeight: "100vh",
              backgroundColor: "primary.light",
              borderRadius: 2,
              padding: 2,
            }}
          >
            <Routes>
              <Route index element={<Home />} />
              {/* Admins */}
              {!profile.isOwner ? null : (
                <>
                  <Route path="/admins" element={<Admins />} />
                  <Route
                    path="/admins/recycle-bin"
                    element={<AdminsRecycleBin />}
                  />
                </>
              )}

              {/* Users */}
              <Route path="/users" element={<Users />} />

              {/* Vendors */}
              <Route path="/vendors" element={<Vendors />} />
              <Route
                path="/vendors/recycle-bin"
                element={<VendorsRecycleBin />}
              />
              <Route path="/vendor-details" element={<VendorDetails />} />

              {/* Vendor Categories */}
              <Route path="/vendor-categories" element={<VendorCategories />} />
              <Route
                path="/vendor-categories/recycle-bin"
                element={<VendorCategoriesRecycleBin />}
              />

              {/* Quote Requests */}
              <Route path="/quote-requests" element={<QuoteRequests />} />

              {/* Templates */}
              <Route path="/templates" element={<Templates />} />
            </Routes>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default DashboardLayout;
