import React, { useState , useEffect } from "react";
import "./TemplateNine.css";
import { Link } from "react-router-dom";
import {
  getMonth,
  getDayNumber,
  RSVPPressed_common,
  //getDayName,
  //getYear
} from "../TemplatesCommonFunctions";

const getDayName = (date) => {
  const d2 = new Date(date)
  var weekdays = new Array(7);
  weekdays[0] = "Sun";
  weekdays[1] = "Mon";
  weekdays[2] = "Tues";
  weekdays[3] = "Wed";
  weekdays[4] = "Thurs";
  weekdays[5] = "Fri";
  weekdays[6] = "Sat";
  return weekdays[d2.getDay()];
}
const getTime = (date) => {
  const d2 = new Date(date);
  var hour = d2.getHours();

  let timeSTR = "";
  if (hour === 0) {
    timeSTR = "12AM";
  } else if (hour > 12) {
    timeSTR = hour - 12 + "PM";
  } else {
    timeSTR = hour + "AM";
  }

  return timeSTR;
};

const getMonth_notcommon = (date) => {
  const d2 = new Date(date);
  var Months = new Array(12);
  Months[0] = " Jan";
  Months[1] = " Feb";
  Months[2] = " March";
  Months[3] = " April";
  Months[4] = " May";
  Months[5] = " June";
  Months[6] = " July";
  Months[7] = " Aug";
  Months[8] = " Sept";
  Months[9] = " Oct";
  Months[10] = " Nov";
  Months[11] = " Dec";
  return Months[d2.getMonth()];
};

const TemplateNineInvitation = ({ templateData, weddingId }) => {
  const [dummy, setDummy] = useState(0);

  const RSVPPressed = () => {
    setDummy(!dummy)
    RSVPPressed_common("nine")
  }

  return (
    <>
      <div id="template-nine-body" className="row">
        <div className="col-2 col-md-2 col-lg-3">
        </div>
        <div id="body-col" className="col-8 col-md-8 col-lg-6">

          <div id="body-main-container" className="container-fluid" >

            <div id="body-row-couple">
              <div id="bride-col" className='row container-fluid'>
                <div id="bride-first-name" className='row'>
                  <p >
                    {templateData.brideFirstName}
                  </p>
                </div>
                <div id="bride-last-name" className='row'>
                  <p >
                    {templateData.brideLastName}
                  </p>
                </div>
              </div>
              <div id="couple-and" className='row'>
                <p >
                  &
                </p>
              </div>
              <div id="groom-col" className='row container-fluid'>
                <div id="groom-first-name" className='row'>
                  <p >
                    {templateData.groomFirstName}
                  </p>
                </div>
                <div id="groom-last-name" className='row w-100'>
                  <p >
                    {templateData.groomLastName}
                  </p>
                </div>
              </div>
            </div>


            <div id="body-row-inv-message" className='row'>
              <div className='col'>
                <p>INVITE YOU TO CELEBRATE</p>
                <p>THEIR WEDDING DAY</p>
              </div>
            </div>

            
            <div id="body-row-date" className='d-flex justify-content-center'>
              <div id="body-date-month" className='col-lg-4 col-md-3 col-sm-3 col-3'>
                <p>
                  {getDayName(templateData.weddingDate)}
                </p>
              </div>
              <div id="body-date-day-num" className='ps-2 pe-2 col-lg-4 col-md-6 col-sm-6 col-6'>
                <p id="upper-num">
                  {getDayNumber(templateData.weddingDate)}
                </p>
                <p id="lower-num">
                  {getTime(templateData.weddingDate)}
                </p>
              </div>
              <div id="body-date-year" className=' col-lg-4 col-md-3 col-sm-3 col-3'>
                <p>
                  {getMonth_notcommon(templateData.weddingDate)}
                </p>
              </div>
            </div>

            <div id="body-row-time" className='row'>
              <div id="body-row-time-area">
                <p>
                  {templateData.area} 
                </p>
                {/* <p >
                  {templateData.city}
                </p> */}
              </div>
            </div>


            {templateData.weddingWebsite.RSVPsIsVisible ?

              <div>


                <div id="body-row-rsvp-by" className='row'>
                  <p>
                    KINDLY RSVP
                  </p>
                  <p>
                    BY {getMonth(templateData.RSVPBy)} {getDayNumber(templateData.RSVPBy)}
                  </p>
                </div>

                <div id="body-row-rsvp-button" className='row'>
                  <div id="rsvp-col" className='col-3'></div>
                  <div id="rsvp-col" className='col-6'>
                    <Link to={"/frosty-elegance/"+weddingId+"/rsvp"}>


                      <button onClick={RSVPPressed} className=" shadow-none" >
                        RSVP HERE
                      </button>
                    </Link>
                  </div>
                  <div id="rsvp-col" className='col-3'></div>
                </div>
              </div>
              : <div id="rsvp-placeholder"></div>
            }

          </div>

        </div>
        <div className="col-2 col-md-2 col-lg-3">
        </div>
      </div>

      <div id="created-on" className="w-100">
            <div className="w-50">

              <p>
                Created on Lovebirds
              </p>
              <p>
                Getting married? <Link id="created-on-link" style={{ textDecoration: 'underline' }} 
                to={"/"}
                >Create your wedding website for free.</Link>
              </p>
            </div>
          </div>
    </>
  );
};

export default TemplateNineInvitation;
