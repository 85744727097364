import React from "react";
import "./TemplateThree.css";

import Questions from "../Questions/Questions";

const TemplateThreeQuestions = ({ id, data }) => {

  return (
    <>

      <div id="template-three-questions" className='container-fluid'>
        <div className='row'>
          <div className="col-3">
          </div>

          <div className="col-6">

            <div id="questions-header">
              <h2>
                Questions
              </h2>
            </div>
            <Questions
              templateID={id}
              questionsDataInput={data}
            />
          </div>

          <div className="col-3">
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateThreeQuestions;
