import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import { Link } from "@mui/material";
import "./ContactUs.css";
import { redirectToWhatsapp } from "../UtilFucntions";

const ContactUs = () => {
  return (
    <Box
      sx={{
        margin: "10px 58px 10px 58px",
      }}
    >
      <Grid container spacing={1} alignItems="center" justifyContent="center">
        <Grid
          item
          xs={12}
          md={6}
          id="webapp-contact-us-container"
          sx={{
            height: { xs: "200px", md: "700px" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{}}>
            <Typography
              textAlign={"center"}
              sx={{
                fontSize: { xs: "1rem", md: "1.5rem" },
                fontWeight: "600",
                // width: "100%",
                height: "100%",
              }}
            >
              Contact us
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              margin: "20px 0px",
              padding: "25px",
              // textAlign: "center",
            }}
            elevation={0}
          >
            <Typography
              sx={{
                color: "#AB9E72",
                fontWeight: "600",
                fontSize: { xs: "1.2rem", md: "2rem" },
                textAlign: "center",
                // lineHeight: "51px",
                fontFamily: "Montserrat",
              }}
            >
              GET IN TOUCH WITH US
            </Typography>
            <Container sx={{ paddingTop: "15px" }}>
              <Box sx={{ textAlign: "center" }}>
                <a href="mailto:lovebirds@gmail.com" underline="none">
                  <Box
                    
                    sx={{
                      margin: { xs: "0.5rem", md: "2rem" },
                      marginLeft: "0",
                      color: "#F1A3A5",
                      display: "inline",
                      "&:hover": {
                        color: "text.darkBlue",
                      },
                    }}
                  >
                    <EmailIcon fontSize="large" />
                  </Box>
                </a>
                <Link
                  href="#"
                  underline="none"
                  sx={{
                    margin: { xs: "0.5rem", md: "2rem" },
                    marginLeft: "0",
                    color: "#F1A3A5",
                    "&:hover": {
                      color: "text.darkBlue",
                    },
                  }}
                >
                  <FacebookIcon fontSize="large" />
                </Link>

                <Link
                  href="#"
                  underline="none"
                  sx={{
                    margin: { xs: "0.5rem", md: "2rem" },
                    marginLeft: "0",
                    color: "#F1A3A5",
                    "&:hover": {
                      color: "text.darkBlue",
                    },
                  }}
                >
                  <InstagramIcon fontSize="large" />
                </Link>

                <Link
                  href="#"
                  sx={{
                    margin: { xs: "0.5rem", md: "2rem" },
                    marginLeft: "0",
                    color: "#F1A3A5",
                    "&:hover": {
                      color: "text.darkBlue",
                    },
                  }}
                  onClick={(e)=>{
                    e.preventDefault()
                    redirectToWhatsapp()
                }}
                >
                  <WhatsAppIcon fontSize="large" />
                </Link>
              </Box>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ContactUs;
