import { COOKIES_SETTINGS, CHECKLIST } from "../../endpoints/Constants";
import axios from "axios";
export const editCheckListItem = async (data) => {
    var response;
    try {
        response = await axios.put(
            CHECKLIST,
            data,
            COOKIES_SETTINGS
        );
    } catch (error) {
        throw error;
    }

    return response.data;
}
export const addCheckListItem = async (data) => {

    //console.log(data);
    const response = await axios.post(
        CHECKLIST,
        {
            title: data.title,
            description: data.description,
            deadline: data.deadline,
            status: data.status,
        },
        COOKIES_SETTINGS
    );
    return response.data;

}
export const deleteCheckListItem = async (data) => {


    await axios.delete(
        CHECKLIST,
        {
            data:
            {
                id: data._id,
            },
            ...COOKIES_SETTINGS
        },

    );

}