import React, { useState, useRef, useEffect, useContext } from "react";
import CheckListItem from "./check-list-item";
import {
    Box, Grid, Stack, Chip, Card, Typography, OutlinedInput, CircularProgress, Container, Paper
} from '@mui/material';
import axios from "axios";

import { COOKIES_SETTINGS, CHECKLIST } from "../../endpoints/Constants";
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CheckListDialog from "./check-list-dialog";
import { CheckListContext } from "./CheckList";
export const CheckListComponent = () => {
    const { mainList } = useContext(CheckListContext);
    const [loading, setLoading] = useState(false);
    const [checkList, setCheckList] = mainList;
    const [filteredCheckList, setFilteredCheckList] = useState([]);

    const filters = ["All", "This Month", "3 Months", "6 Months"];
    const [appliedFilter, setAppliedFilter] = useState(0);

    const opendialog = useRef(null);

    const [searchTerm, setSearchTerm] = useState("");

    function getMonthDifference(startDate, endDate) {
        return (
            endDate.getMonth() -
            startDate.getMonth() +
            12 * (endDate.getFullYear() - startDate.getFullYear())
        );
    }
    useEffect(() => {
        getCheckList();
    }, []);
    useEffect(() => {
        applyFilter(appliedFilter);
    }, [checkList]);
    useEffect(() => {
        applyFilter(appliedFilter);
    }, [searchTerm]);
    /**************************API CALL FUNCTIONS********************************/
    const getCheckList = async () => {
        try {
            setLoading(true);
            const response = await axios.get(CHECKLIST, COOKIES_SETTINGS);
            // console.log(response);
            setCheckList(response.data);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const applyFilter = (index) => {
        setAppliedFilter(index);
        if (index === 0) {
            var tempList = [];
            for (index = 0; index < checkList.length; index++) {
                if (checkList[index].title.toLowerCase().includes(searchTerm.toLowerCase())) {
                    tempList.push(checkList[index]);
                }
            }
            setFilteredCheckList(tempList);
        } else {
            //console.log("here");
            var tempList = [];
            var numOfMonthsToCompare = -1;
            if (index === 1) {
                numOfMonthsToCompare = 1;
            } else if (index === 2) {
                numOfMonthsToCompare = 3;
            } else if (index === 3) {
                numOfMonthsToCompare = 6;
            }
            for (index = 0; index < checkList.length; index++) {
                if ((getMonthDifference(new Date(), new Date(checkList[index].deadline)) <= numOfMonthsToCompare) && checkList[index].title.toLowerCase().includes(searchTerm.toLowerCase())) {
                    tempList.push(checkList[index]);
                }
            }
            setFilteredCheckList(tempList);
        }


    }
    return (
        <div>
            <CheckListDialog
                opendialog={opendialog}
                data={null}
                toedit={false}
            />
            <Box minHeight={500} style={{ margin: "0 58px 0 58px" }}>
                <Grid container marginTop={5} direction={"row"}>
                    <Grid item xs={12} md={2} >

                        <Stack
                            direction={{ xs: "row", md: "column" }}
                            spacing={3} marginBottom={"10px"}
                            sx={{

                                overflowX: "scroll",
                                '&::-webkit-scrollbar': {
                                    display: "none"
                                }
                            }}
                        >
                            {filters.map((item, index) => {
                                return (
                                    <Chip
                                        key={index}
                                        label={item}
                                        sx={{
                                            color: appliedFilter === index ? "#FFF" : "#475467",
                                            backgroundColor: appliedFilter === index ? "#F1A3A5" : "rgba(255,255,255,0.6)",
                                            borderRadius: "7px",
                                            width: "124px",
                                            minWidth: "124px",
                                            height: "44px",
                                            pointerEvents: appliedFilter === index ? "none" : null,
                                            fontWeight: "bold",
                                            "&:hover": {
                                                backgroundColor: "#F1A3A5",
                                                color: "#FFF",
                                            },
                                        }}
                                        onClick={() => { applyFilter(index); }}
                                    />
                                )
                            })
                            }
                        </Stack>

                    </Grid>

                    <Grid item xs={12} md={10}>



                        <Grid item xs={12} md={3} >
                            <OutlinedInput
                                fullWidth
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                                sx={{
                                    marginBottom: 3,
                                    height: "42px",
                                    background: "#F2F4F5",
                                    border: "1px solid #E7ECF3",
                                    borderRadius: "8px",
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'transparent',
                                    },
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'transparent',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'transparent',

                                    },
                                    fieldSet: {
                                        legend: {
                                            width: "unset",
                                        }
                                    }
                                }}
                                placeholder={"Search for an item"}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </Grid>

                        {
                            loading ?
                                <Box
                                    sx={{
                                        height: "100%",
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        height: "200px",
                                    }}
                                >
                                    <Container
                                        sx={{
                                            textAlign: "center",
                                        }}
                                    >
                                        <CircularProgress></CircularProgress>
                                    </Container>
                                </Box>
                                :
                                <Grid container spacing={1} height={400}
                                    display="flex" alignContent={"flex-start"}

                                    sx={{
                                        overflowY: "scroll",
                                        '&::-webkit-scrollbar': {
                                            display: "none"
                                        }
                                    }}>
                                    {
                                        filteredCheckList.length === 0 ?
                                            <Box
                                                sx={{
                                                    height: "100%",
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    height: "200px",
                                                }}
                                            >
                                                <Container
                                                    sx={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    No checklist items
                                                </Container>

                                            </Box> :
                                            filteredCheckList.map((item, index) => {
                                                return (
                                                    <Grid key={index} item xs={12} md={6}>
                                                        <CheckListItem
                                                            data={item}
                                                        >
                                                        </CheckListItem>
                                                    </Grid>
                                                );
                                            })
                                    }


                                </Grid>
                        }


                        <Grid item xs={12} >
                            <Card
                                onClick={() => {
                                    opendialog.current();
                                }}
                                sx={{
                                    marginTop: 3,
                                    marginBottom: 3,
                                    height: "50px",
                                    padding: "16px 0px 16px 12px",
                                    boxShadow: "0px 0px 10px rgba(153, 153, 153, 0.15)",
                                    borderRadius: "8px",
                                    transition: "all .2s ease-in-out",  
                                    ':hover':{
                                        transform: "scale(1.01)",
                                        cursor:"pointer"
                                    }
                                }}>
                                <Typography
                                    fontWeight={500}
                                    fontSize={"16px"}
                                    lineHeight={"18px"}
                                    color="#B9B9B9"
                                >
                                    Add new Item
                                </Typography>

                            </Card>
                        </Grid>

                    </Grid>
                </Grid>
            </Box >

        </div >

    );
}
export default CheckListComponent;
