import { useRef } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Box, Typography, AccordionDetails, AccordionSummary, Accordion } from "@mui/material";
import QuestionDialog from './Question-dialog';
export default function Question(props) {
    const opendialog = useRef(null);
    return (
        <div>
            <QuestionDialog
                opendialog={opendialog}
                data={props.data}
                toedit={true}
            />
            <Box paddingBottom={2}>
                <Accordion
                    elevation={0}
                    sx={{
                        background: "#FFFFFF",
                        boxShadow: "0px 0px 10px rgba(153, 153, 153, 0.15)",
                        borderRadius: "8px"
                    }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        key={props.index}
                    >
                        <Typography sx={{

                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#090A0A"
                        }}>
                            {props.data.question}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ marginY: "10px" }}>
                        <Typography sx={{

                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "22px",
                            color: "#000000",
                        }}>
                            {props.data.answer}
                        </Typography>

                        <Button
                            sx={{
                                marginY: "10px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                fontSize: "18px",
                                lineHeight: "16px",
                                color: "#F1A3A5",
                                cursor: 'pointer'
                            }}
                            onClick={() => { opendialog.current(); }}
                        >
                            Edit
                        </Button>
                    </AccordionDetails>
                </Accordion>
            </Box>


        </div >
    );
}
