import { useContext } from "react";
import { Paper, Box, Grid, Button, Typography } from "@mui/material";

import { AuthComponentsContext } from "../../../context/AuthComponentsContext";

export default function SetupWeddingFirst() {
  const authComponents = useContext(AuthComponentsContext);


  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "3rem 0rem",
      }}
    >
      <Paper
        elevation={4}
        sx={{
          padding: {xs:"1rem 1rem", md:"1rem 2rem"},
          borderRadius: "8px",
          textAlign: "center",
          width:{xs:"80%", md:"auto"}
        }}
      >
        <Typography
          sx={{
            fontSize: {xs:"1rem", md:"1.3rem"},
            fontWeight: 600,
            color: "#090A0A",
          }}
        >
          Wedding Information
        </Typography>

        <Typography
          sx={{
            fontSize: {xs:"0.8rem", md:"0.9rem"},
            fontWeight: 400,
            color: "#090A0A",
            marginTop: "0.9rem",
          }}
        >
         You have to complete your wedding information first
        </Typography>
        <Grid container spacing={1} 
        sx={{
            marginTop:{xs:"1rem", md:"2rem"},
             justifyContent:"center",
             
            }}
        >
          
          <Grid item xs={12} sm={7}>
            <Button
              fullWidth
              variant="text"
              fontWeight="bold"
              sx={{
                /*marginRight: "35px",*/
                fontSize: { xs: "0.8rem", sm: "1rem", md: "1rem" },
                color: "text.white",
                backgroundColor: "text.tertiary",
                padding: { xs: "0rem 3rem", md: "0.3rem 2rem" },
                height: { xs: "2.5rem" },
                borderRadius: "2rem",
                ":hover": {
                  backgroundColor: "text.tertiaryHover",
                },
                textAlign: "center",
                textTransform:"none"
              }}
              onClick={() => {
                authComponents.openWeddingInfo();
              }}
            >
              Create Wedding
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
