import { useEffect, useState, useRef, useContext } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
    Typography,
    Grid,
    FormControl,
    MenuItem,
    OutlinedInput,
    Button,
    FormHelperText,
    TextField,
    Select,
    Alert,
    CircularProgress,
    InputLabel

} from "@mui/material";
import { Formik, Form, Field } from 'formik';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as Yup from "yup";
import { CheckListContext } from "./CheckList";
import { editCheckListItem, addCheckListItem, deleteCheckListItem } from "./check-list-api-functions";
export default function CheckListDialog(props) {
    const [loading, setLoading] = useState(false);
    const { mainList } = useContext(CheckListContext);
    const [checkList, setCheckList] = mainList;
    const [item, setItem] = useState({
        _id: "",
        title: "",
        status: "incompleted",
        description: "",
        deadline: ""
    });
    useEffect(() => {
        if (props.data !== null) {
            setItem(props.data);
        }
    }, [props.data]);
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        props.opendialog.current = handleClickOpen;
    });
    const add = async (values, setErrors) => {
        try {
            setLoading(true);
            const response = await addCheckListItem(values);
            setCheckList(checkList => [...checkList, response]);
            handleClose();
            setErrors({ submit: null });
        } catch (error) {
            setErrors({ submit: "An error occured" });
        } finally {
            setLoading(false);
        }
    };

    const edit = async (values, setErrors) => {
        try {
            setLoading(true);
            const response = await editCheckListItem({
                id: values._id,
                description: values.description,
                status: values.status,
                deadline: values.deadline,
                title: values.title,
            });
            let newCheckList = [...checkList];
            const index = newCheckList.findIndex(item => item._id === props.data._id);
            newCheckList[index] = response;
            setCheckList(newCheckList);
            handleClose();
            setErrors({ submit: null });
        } catch (error) {
            setErrors({ submit: "An error occured" });
        } finally {
            setLoading(false);
        }

    };

    const deleteItem = async (values, setErrors) => {
        try {
            setLoading(true);
            await deleteCheckListItem(values);
            let newCheckList = checkList.filter(function (value) {
                return value._id !== props.data._id;
            });
            setCheckList(newCheckList);
            handleClose();
            setErrors({ submit: null });
        } catch (error) {
            setErrors({ submit: "An error occured" });
        } finally {
            setLoading(false);
        }
    };
    return (
        <div>

            <Dialog

                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: { borderRadius: 15 },
                }}

                sx={{ background: "rgba(0,0,0,0.5)", backdropFilter: "blur(16px)" }}
            >
                <Box
                    width={{ xs: "80vw", sm: 500 }}
                    sx={{ overflowX: "hidden" }}
                >
                    <DialogContent sx={{ padding: "0", width: "100%" }}>
                        <DialogTitle color="#101828">
                            <Typography
                                fontStyle={"normal"}
                                fontWeight={"700"}
                                fontSize={"30px"}
                                lineHeight={"38px"}
                                textAlign={"center"}
                                color={"#101828"}
                            >
                                {props.toedit === true ? "Edit Item" : "Add Item"}
                            </Typography>
                        </DialogTitle>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Formik
                                initialValues={
                                    {
                                        _id: item._id,
                                        title: item.title,
                                        description: item.description,
                                        deadline: item.deadline,
                                        status: item.status,
                                        submit: null,
                                    }
                                }
                                validationSchema={Yup.object().shape({
                                    title: Yup.string()
                                        .required("Title is required"),
                                })}
                                // validationSchema={Yup.object().shape({
                                //     title: Yup.string().
                                //         required("Title is required").test('title', 'Please enter a valid title', function (value) { if (value === " ") return false; else return true; }),
                                // })}
                                onSubmit={async (values, { setErrors, setSubmitting }) => {
                                    if (!props.toedit) {
                                        add(values, setErrors, setSubmitting);
                                    } else {
                                        //console.log(values);
                                        edit(values, setErrors, setSubmitting);
                                    }
                                }}
                            >
                                {({
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    setFieldValue,
                                    touched,
                                    values,
                                }) => (

                                    <form noValidate onSubmit={handleSubmit} {...props}>
                                        <Grid container justifyContent="center" alignItems="center">
                                            <Box paddingX={2} paddingY={3}>
                                                {errors.submit && (
                                                    <Alert
                                                        sx={{
                                                            marginBottom: "10px",
                                                        }}
                                                        severity="error"
                                                    >
                                                        {errors.submit}
                                                    </Alert>
                                                )}
                                                <FormControl
                                                    fullWidth
                                                    error={Boolean(touched.title && errors.title)}
                                                    variant="outlined"
                                                    sx={{
                                                        marginBottom: "10px",
                                                    }}
                                                >
                                                    <InputLabel
                                                        htmlFor="outlined-adornment-title"
                                                        sx={{ color: "text.lightGray" }}
                                                    >
                                                        Title
                                                    </InputLabel>
                                                    <OutlinedInput
                                                        sx={{
                                                            background: "#F8FAFD",
                                                            border: "1px solid #E7ECF3",
                                                            borderRadius: "10px",
                                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#E7ECF3',
                                                            },
                                                            '.MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#E7ECF3',
                                                            },
                                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#E7ECF3',

                                                            },
                                                            fieldSet: {
                                                                legend: {
                                                                    width: "unset",
                                                                }
                                                            }
                                                        }}
                                                        id={"outlined-adornment-" + "title"}
                                                        type={"text"}
                                                        value={values["title"]}
                                                        name={"title"}
                                                        placeholder={"Title"}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}


                                                    />
                                                    {touched.title && errors.title && (
                                                        <FormHelperText
                                                            error
                                                            id="standard-weight-helper-text-email-login"
                                                        >
                                                            {errors.title}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>

                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel
                                                        htmlFor="outlined-adornment-description"
                                                        sx={{ color: "text.lightGray" }}
                                                    >
                                                        Description
                                                    </InputLabel>
                                                    <OutlinedInput
                                                        sx={{
                                                            marginBottom: "10px",
                                                            background: "#F8FAFD",
                                                            border: "1px solid #E7ECF3",
                                                            borderRadius: "10px",

                                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#E7ECF3',
                                                            },
                                                            '.MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#E7ECF3',
                                                            },
                                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#E7ECF3',

                                                            },
                                                            fieldSet: {
                                                                legend: {
                                                                    width: "unset",
                                                                }
                                                            }
                                                        }}
                                                        multiline={true}
                                                        id={"outlined-adornment-" + "description"}
                                                        type={"text"}
                                                        value={values["description"]}
                                                        name={"description"}
                                                        placeholder={"Description"}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}

                                                    />
                                                </FormControl>

                                                <FormControl fullWidth variant="outlined">
                                             
                                                    <Select
                                                        id="outlined-adornment-status"
                                                        name="status"
                                                        sx={{
                                                            marginBottom: "10px",
                                                            background: "#F8FAFD",
                                                            border: "1px solid #E7ECF3",
                                                            borderRadius: "10px",
                                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#E7ECF3',
                                                            },
                                                            '.MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#E7ECF3',
                                                            },
                                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#E7ECF3',

                                                            },
                                                            fieldSet: {
                                                                legend: {
                                                                    width: "unset",
                                                                }
                                                            }
                                                        }}
                                                        value={values["status"]}
                                                        onChange={handleChange}
                                                        input={<OutlinedInput />}
                                                    >
                                                        <MenuItem key={"Status-" + 0} value={"incompleted"}>{"Incompleted"}</MenuItem>
                                                        <MenuItem key={"Status-" + 1} value={"completed"}>{"Completed"}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <FormControl fullWidth variant="outlined">
                                                    <DatePicker
                                                        value={values["deadline"]}
                                                        onChange={(value) => { setFieldValue("deadline", value); }}
                                                        minDate={new Date()}
                                                        renderInput={(params) =>
                                                            <TextField
                                                                id="outlined-adornment-deadline"
                                                                variant="outlined"
                                                                name="deadline"
                                                                sx={{
                                                                    marginBottom: "10px",
                                                                    background: "#F8FAFD",
                                                                    borderRadius: "10px",
                                                                    border: "1px solid #E7ECF3",
                                                                    '& .MuiOutlinedInput-root': {
                                                                        '& fieldset.MuiOutlinedInput-notchedOutline': {
                                                                            borderColor: 'transparent',
                                                                        },
                                                                    },
                                                                    fieldSet: {
                                                                        legend: {
                                                                            width: "unset",
                                                                        }
                                                                    }
                                                                }
                                                                } {...params} />
                                                        }
                                                    />
                                                </FormControl>

                                                <Button
                                                    fullWidth
                                                    size="medium"
                                                    type="submit"
                                                    variant="contained"
                                                    disabled={loading}
                                                    sx={{
                                                        height: "48px",
                                                        marginTop: "10px",

                                                        background: "#F1A3A5",
                                                        borderRadius: "48px",
                                                        fontWeight: "600",
                                                        fontSize: "18px",
                                                        lineHeight: "16px",
                                                        color: "#FFF",
                                                        '&:hover': {
                                                            backgroundColor: '#F1A3A5',
                                                            color: '#FFF',
                                                        },
                                                    }}
                                                >

                                                    {loading === true ? <CircularProgress sx={{ color: "#FFF" }} size={"1.8rem"} /> : props.toedit === true ? "Save changes" : "Add"}
                                                </Button>

                                                {props.toedit === true ? <Button
                                                    fullWidth
                                                    size="medium"
                                                    variant="outlined"
                                                    disabled={loading}
                                                    onClick={() => { deleteItem(values); }}
                                                    sx={{
                                                        height: "48px",
                                                        marginTop: "10px",
                                                        background: "#FFFFFF",
                                                        border: "1px solid #D3180C",
                                                        borderRadius: "48px",
                                                        fontWeight: "600",
                                                        fontSize: "16px",
                                                        lineHeight: "16px",
                                                        color: "#D3180C",
                                                        '&:hover': {
                                                            backgroundColor: 'text.tertiaryHover',
                                                            color: '#FFFFFF',
                                                        },
                                                    }}
                                                >
                                                    Delete item
                                                </Button> : null}
                                            </Box>
                                        </Grid>
                                    </form>


                                )}
                            </Formik>
                        </LocalizationProvider>
                    </DialogContent>
                </Box>

            </Dialog>

        </div >
    );
}