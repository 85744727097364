import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./TemplateEight.css";

import { Route, Routes } from "react-router-dom";
import { Link } from "react-router-dom";

import { PhoneContext } from "../../context/PhoneContext";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import {
  COOKIES_SETTINGS,
  GET_TEMPLATE_DETAILS,

} from "../../endpoints/Constants";

import axios from "axios";

import {
  checkUrl,
  checkSelectedBtnInNavbar,
  InvitationPressed_common,
  QuestionsPressed_common,
  RSVPPressed_common,
  GuestBoardPressed_common,
  InvitationPressedMob_common,
  QuestionsPressedMob_common,
  RSVPPressedMob_common,
  GuestBoardPressedMob_common,

} from "../TemplatesCommonFunctions";

import TemplateEightQuestions from "./TemplateEightQuestions";
import TemplateEightRSVP from "./TemplateEightRSVP";
import TemplateEightGuestBoard from "./TemplateEightGuestBoard";
import TemplateEightInvitation from "./TemplateEightInvitation";

const TemplateEight = () => {
  const phone = useContext(PhoneContext);
  phone.state.templateMainColor = "#314728";
  const [templateData, setTemplateData] = useState(null);
  const [tabLoading, setTabLoading] = useState(true);
  const navigate = useNavigate();
  const { weddingId } = useParams();
  const [templateID, setTemplateID] = useState(-1);
  var divImage;

  useEffect(() => {
    if (weddingId) {
      getTemplateDetails(weddingId);
    } else {
      navigate("../");
    }
  }, []);



  useEffect(() => {
    if (!tabLoading) {
      checkSelectedBtnInNavbar()
    }
  }, [tabLoading])


  const getTemplateDetails = async (id) => {
    setTabLoading(true);
    try {
      axios
        .get(GET_TEMPLATE_DETAILS + `/${id}`, COOKIES_SETTINGS)
        .then(async (res) => {
           await checkUrl(
             "islamic-simplicity",
             res.data.weddingWebsite.weddingTemplate.uniqueName,
             res.data.uniqueName
           );
          setTemplateData(res.data);
          setTemplateID(res.data._id);
          setTabLoading(false);

        })
        .catch((err) => {
          navigate("../not-found");
        });
    } catch (error) {

      setTabLoading(false);
      navigate("../not-found");
    }
  };

  

  return (
    <>
      {tabLoading && !templateData ? (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          
          <CircularProgress style={{ color: phone.state.templateMainColor }} />
        </Box>
      ) : (
        <div
          id="template-eight-dev"
          style={divImage}
          className="template-eight-home-dev"
        >
          <nav id="navbar-temp-eight" className="navbar navbar-dark bg-white">
            <div className="container-fluid">
              <button className="navbar-toggler shadow-none" style={{ borderColor: "rgb(0,0,0,0)", position:'absolute' ,left:0  }} type="button" data-bs-toggle="dropdown" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon white"></span>
              </button>
              <div className='d-flex justify-content-center w-100'>
                <h2 className='navbar-intials mb-0'>
                  {templateData.brideFirstName[0]}&{templateData.groomFirstName[0]}
                </h2>

              </div>
              <ul
                className="dropdown-menu"
                aria-labelledby="navbarScrollingDropdown"
              >
                <Link to={"/islamic-simplicity/" + weddingId}>
                  <li onClick={InvitationPressedMob_common} className="dropdown-item ">
                    <p
                      className=""
                      id="mob-nav-first-element"
                    >
                      INVITATION
                    </p>
                  </li>
                </Link>

                {templateData.weddingWebsite.QAIsVisible &&
                  <Link to={"/islamic-simplicity/"+weddingId+"/questions"}>
                    <li onClick={QuestionsPressedMob_common} className="dropdown-item">
                      <p id="mob-nav-second-element">QUESTIONS</p>
                    </li>
                  </Link>
                }
                {templateData.weddingWebsite.RSVPsIsVisible &&
                  <Link to={"/islamic-simplicity/"+weddingId+"/rsvp" }>
                    <li onClick={RSVPPressedMob_common} className="dropdown-item">
                      <p id="mob-nav-third-element">RSVP</p>
                    </li>
                  </Link>
                }
                {templateData.weddingWebsite.GuestBoardIsVisible &&
                  <Link to={"/islamic-simplicity/"+weddingId+"/guest-board"}>
                    <li onClick={GuestBoardPressedMob_common} className="dropdown-item">
                      <p id="mob-nav-fourth-element">GUEST BOARD</p>
                    </li>
                  </Link>
                }
              </ul>
            </div>
          </nav>

          <div className="container-fluid inner-container">
            <div id="template-eight-navbar" className="row w-100">
            <div className="col-2"></div>
              <div id="navbar-col" className="col-8 ">
                <div id="navbar-main-row" className="d-flex flex-row justify-content-around">
                  <div
                    onClick={InvitationPressed_common}
                    id="first-element-navbar"
                    className="w-25"
                  >
                    <Link to={"/islamic-simplicity/"+weddingId}>
                      <h2 className="text-center">INVITATION</h2>
                    </Link>
                  </div>
                  {templateData.weddingWebsite.QAIsVisible &&
                    <div
                      onClick={QuestionsPressed_common}
                      className="w-25"
                      id="second-element-navbar"
                    >
                      <Link
                        to={"/islamic-simplicity/"+weddingId+"/questions"}
                      >
                        <h2 className="text-center">QUESTIONS</h2>
                      </Link>
                    </div>
                  }
                  {templateData.weddingWebsite.RSVPsIsVisible &&
                    <div
                      onClick={RSVPPressed_common}
                      className="w-25"
                      id="third-element-navbar"
                    >
                      <Link to={"/islamic-simplicity/"+weddingId+"/rsvp"}>
                        <h2 className="text-center">RSVP</h2>
                      </Link>
                    </div>
                  }

                  {templateData.weddingWebsite.GuestBoardIsVisible &&
                    <div
                      onClick={GuestBoardPressed_common}
                      className="w-25 "
                      id="fourth-element-navbar"
                    >
                      <Link
                        to={"/islamic-simplicity/"+weddingId+"/guest-board"}
                      >
                        <h2 className="text-center">GUEST BOARD</h2>
                      </Link>
                    </div>
                  }
                </div>
              </div>
            </div>

            <Routes>
              <Route
                index
                element={<TemplateEightInvitation templateData={templateData} weddingId={weddingId}/>}
              />
              {templateData.weddingWebsite.QAIsVisible &&
                <Route
                  path="/questions"
                  element={
                    <TemplateEightQuestions
                      id={templateData._id}
  
                    />
                  }
                />
              }
              {templateData.weddingWebsite.RSVPsIsVisible &&
                <Route
                  path="/rsvp"
                  element={<TemplateEightRSVP id={templateData._id} />}
                />
              }
              {templateData.weddingWebsite.GuestBoardIsVisible &&
                <Route
                  path="/guest-board"
                  element={<TemplateEightGuestBoard id={templateData._id} />}
                />
              }
            </Routes>
          </div>
          
        </div>
      )}
    </>
  );
};

export default TemplateEight;
