import React, { useState } from "react";
import "./TemplateEleven.css";
import { Link } from "react-router-dom";
import {
  //getDayName,
  getMonth,
  getDayNumber,
  getYear,
  //getTime,
  RSVPPressed_common
} from "../TemplatesCommonFunctions";
import { Box } from "@mui/material";

const TemplateElevenInvitation = ({ templateData, weddingId }) => {
  const [dummy, setDummy] = useState(0);

  const RSVPPressed = () => {
    setDummy(!dummy)
    RSVPPressed_common("eleven")
  }

  const getDayName = (date) => {
    const d2 = new Date(date)
    var weekdays = new Array(7);
    weekdays[0] = "Sun.";
    weekdays[1] = "Mon.";
    weekdays[2] = "Tues.";
    weekdays[3] = "Wed.";
    weekdays[4] = "Thurs.";
    weekdays[5] = "Fri.";
    weekdays[6] = "Sat.";
    return weekdays[d2.getDay()];
  }

  const getTime = (date) => {
    const d2 = new Date(date)
    var hour = d2.getHours();

    let timeSTR = ""
    if (hour == 0) {
      timeSTR = "12AM"
    }
    else if (hour > 12) {
      timeSTR = (hour - 12) + "PM"
    }
    else {
      timeSTR = hour + "AM"
    }

    return timeSTR;
  }

  return (
    <>
      <div id="template-eleven-body" className="row">
        <div className="col-1 col-md-2"></div>
        <div id="body-col" className="col-10 col-md-8">
          <div id="body-main-container" className="container-fluid">
            <div id="body-row-inv-message" className="row">
              <div className="col">
                <p
                  style={{
                    position:"relative",
                    zIndex:1,
                    //margin:0,
                  }}
                >
                    Together with their families
                  <Box
                    id="body-row-inv-message-background-box"
                    sx={{
                      background:"#EDF1F3", 
                      width:"auto",
                      position:"relative",
                      zIndex:-1
                    }}
                  >
                  </Box>
                 
                </p>
              </div>
            </div>

            <div id="body-row-couple">
              <div id="bride-col" className="row container-fluid">
                <div id="bride-first-name" className="row">
                  <p>{templateData.brideFirstName}</p>
                </div>
                <div id="bride-last-name" className="row">
                  <p>{templateData.brideLastName}</p>
                </div>
              </div>
              <div id="couple-and" className="row">
                <p>&</p>
              </div>
              <div id="groom-col" className="row container-fluid">
                <div id="groom-first-name" className="row">
                  <p>{templateData.groomFirstName}</p>
                </div>
                <div id="groom-last-name" className="row">
                  <p>{templateData.groomLastName}</p>
                </div>
              </div>
            </div>

            <div id="body-row-couple-message" className='row'>
              <p
               style={{
                position:"relative",
                zIndex:1,
                margin:0,
              }}
              >
                Invite you to celebrate <br/> their wedding day
              <Box
                    id="body-row-couple-message-background-box"
                    sx={{
                      background:"#EDF1F3", 
                      width:"auto",
                      position:"relative",
                      zIndex:-1
                    }}
                  >
                </Box>
              </p>
             
            </div>

            <div id="body-row-area" className="row">
              <p>{templateData.area}</p>
              {/*<p>{templateData.city}</p>*/}
            </div>

            <div id="body-row-day-month" className="row">
              <p>
                <span className="me-1">{getDayName(templateData.weddingDate)}</span> {getMonth(templateData.weddingDate)}
                </p>
            </div>
            
            <div id="body-row-day-number" className="row">
              <p>{getDayNumber(templateData.weddingDate)}</p>
            </div>

            <div id="body-row-time" className="row">
              <p>{"AT " + getTime(templateData.weddingDate)}</p>
            </div>

      
            {templateData.weddingWebsite.RSVPsIsVisible ?
              <div>
                <div id="body-row-rsvp-by" className="row">
                  <p>
                    KINDLY RSVP BY {getMonth(templateData.RSVPBy)}{" "}
                    {getDayNumber(templateData.RSVPBy)}
                  </p>
                </div>

                <div id="body-row-rsvp-button" className="text-center">
                  <div id="rsvp-col">
                    <Link to={"/golden-flora/"+weddingId+"/rsvp"}>

                      <button onClick={RSVPPressed} className=" shadow-none">
                        RSVP HERE
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              : <div id="rsvp-placeholder"></div>
            }

          </div>
        </div>
        <div className="col-1 col-md-2"></div>
      </div>

      <div id="created-on" className="w-100">
        <div className="w-50">

          <p>
            Created on Lovebirds
          </p>
          <p>
            Getting married? <Link id="created-on-link" style={{ textDecoration: 'underline' }}
            to={"/"}
            >Create your wedding website for free.</Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default TemplateElevenInvitation;
