import React, { createContext, useEffect, useContext, useState } from "react";
import Box from "@mui/material/Box";
import { WebAppTitle } from "../WebAppTitle";
import { ProfileContext } from "../../context/ProfileContext";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import { Button, OutlinedInput } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import GuestsTopbar from "./GuestsTopbar";
import { Route, Routes, useLocation } from "react-router-dom";
import GuestsGroups from "./GuestsGroups";
import GuestsFromGroup from "./GuestsFromGroup";

export const GuestsContext = createContext();

const GuestsList = () => {
  //const profile = useContext(ProfileContext);
 // const [searchContent, setSearchContent] = useState("")
    const url = useLocation()
  /*useEffect(()=>{
    //console.log(searchContent)
  }, [searchContent])*/

  const [currentPage, setCurrentPage] = useState("groups")
  useEffect(()=>{
    if(url.pathname === "/guests"){
        setCurrentPage("groups")
    }
    else{
        setCurrentPage("guests")
    }
  }, [url])


  const [groupsList, setGroupsList] = useState([])

  const [guestsList, setGuestsList] = useState([]);
  const [pageTitle, setPageTitle] = useState("Guests")

  const [guestsInfo, setGuestsInfo] = useState({
    acceptedGuests: "00",
    declinedGuests: "00",
    noResponseGuests: "00",
    totalGuests: "00",
  });

  const formatObject = (obj) => {
    let cpyObj = obj;
    for (const [key, value] of Object.entries(cpyObj)) {
      cpyObj[key] = value.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
    }
    return cpyObj;
  };

  const getGuestsInfo = (guestsCount) => {
    const guestsCountFormated = formatObject(guestsCount);
    setGuestsInfo(guestsCountFormated);
  };
  
  return (
    <Box sx={{ margin: {xs:"0 20px 0 20px", md:"0 58px 0 58px"} }}>
      <GuestsContext.Provider value={{ 
          mainList: [guestsList, setGuestsList], 
          currentGroupName:[pageTitle, setPageTitle],
          groupsListState:[groupsList, setGroupsList],
          guestsCountState: [guestsInfo, getGuestsInfo]
          }}>

        {
          currentPage === "groups" ?
          <GuestsGroups/>
          :
          <GuestsFromGroup/>
        }

      </GuestsContext.Provider>

    </Box>
  );
};
export default GuestsList;
