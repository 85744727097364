import { Route, Routes, useLocation } from "react-router-dom";
import "./WebAppCommonStyle.css";
import React, {
  useEffect
  // , useContext
} from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
//import { ProfileContext } from "../context/ProfileContext";
import AuthComponentsContextProvider from "../context/AuthComponentsContext";

import Home from "./home/Home";

import PropTypes from "prop-types";
import { Link as RouterLink, MemoryRouter } from "react-router-dom";
import { StaticRouter } from "react-router-dom/server";
import Navbar from "./navigation/Navbar";
import Footer from "./footer/Footer";
import AboutUs from "./about-us/AboutUs";
import Vendors from "./vendors/Vendors";
import CheckList from "./check-list/CheckList";
import Guests from "./guests/Guests";
import Invitations from "./invitations/Invitations";
import NotFound from "../routes/NotFound";
import MyAccount from "./my-account/MyAccount";
import FacebookDeletionInstructions from "./3rd-parties/FacebookDeletionInstructions";
import { WebAppTitle } from "./WebAppTitle";
import FAQs from "./faq/FAQS";
import ContactUs from "./contact-us/ContactUs";
import { PrivacyPolicy } from "./privacy/PrivacyPolicy";
import { TermsAndConditions } from "./privacy/TermsAndConditions";
import GuestWishDetails from "./invitations/GuestBoard/GuestWishDetails";


/*
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import appLogo from "../assets/Lovebirds.png";
import appleStore from "../assets/app_store.png"
import googleStore from "../assets/google_play.png"
import { GoogleID } from "./GoogleID";

const ComingSoon = () => {
  const goToGoogleStore =()=>{
    window.location.href = "https://play.google.com/store/apps/details?id=com.apessmartsolutions.loveBirds"
  }

  const goToAppleStore =()=>{
    window.location.href = "https://apps.apple.com/eg/app/lovebirds-the-wedding-guide/id1622993002"
  }

  return (
    <Box height={"100vh"} sx={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
    <Box  height={"50%"} sx={{display:"flex", flexDirection:"column", justifyContent:"space-around", textAlignment:"center"}}>

      <Box sx={{display:"flex", flexDirection:"row", justifyContent:"center"}}  paddingTop={"2rem"} marginTop={"3rem"}>
        <Box >
            <img src={appLogo} alt="" width={160} height={80} />
        </Box>
      </Box>

      <Typography
          color={"primary.main"}
          fontSize={{xs:"4.5vw", sm:"3vw", md:"2vw"}}
          fontWeight={"bold"}
          textAlign="center"
          paddingTop={"2rem"}
          paddingBottom={"1rem"}
        >
          Website Coming Soon...
        </Typography>
      <Typography
          color={"primary.main"}
          fontSize={{xs:"3.5vw", sm:"3vw", md:"2vw"}}
          width={"75%"}
          textAlign="center"
          sx={{display:"flex", alignSelf:"center"}}

        >
          Please download Lovebirds: The Wedding Guide App to access all features
        </Typography>
        <Box sx={{display:"flex", flexDirection:{xs:"column", sm:"row", md:"row"}, marginTop:"2rem", justifyContent:"center", alignItems:"center"}}>
            <a className="m-3" href="https://apps.apple.com/eg/app/lovebirds-the-wedding-guide/id1622993002" onClick={(e)=>{e.preventDefault()}}>
              <img src={appleStore} alt="" width={150} height={63} onClick={goToAppleStore}/>
            </a>
            <a className="m-3" href="https://play.google.com/store/apps/details?id=com.apessmartsolutions.loveBirds" onClick={(e)=>{e.preventDefault()}}>
              <img src={googleStore} alt="" width={150} height={63} onClick={goToGoogleStore} />
            </a>
        </Box>

      </Box>
      </Box>
  );
};*/


const LinkBehavior = React.forwardRef((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return (
    <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />
  );
});

LinkBehavior.propTypes = {
  href: PropTypes.oneOfType([
    PropTypes.shape({
      hash: PropTypes.string,
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    PropTypes.string,
  ]).isRequired,
};

function Router(props) {
  const { children } = props;
  if (typeof window === "undefined") {
    return <StaticRouter location="/">{children}</StaticRouter>;
  }
  // console.log("Router");
  return <MemoryRouter>{children}</MemoryRouter>;
}

Router.propTypes = {
  children: PropTypes.node,
};

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat",
    fontWeightBold: 600,
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
  palette: {
    primary: {
      main: "#AB9E72",
      light: "#ebf5ff",
    },
    text: {
      primary: "#475467",
      secondary: "#AB9E72",
      tertiary: "#F1A3A5",
      tertiaryHover: "#c96d6f",
      darkBlue: "#1A4075",
      dark: "#000",
      white: "#FFF",
      lightGray: "#A7B0C0",
      selected: "#AB9E72",
    },
  },
});







/* TODO: add document title
const documentTitles = {
  "/": "Home",
  "/about-us": "About Us",
  "/vendor-category": ""
}*/
const WebAppLayout = (props) => {

  //const profile = useContext(ProfileContext);
  const useCurrentRoute = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    //document.title = "Lovebirds | Privacy Policy";

  }, [useCurrentRoute]);

  return (
    <div id="webapp-container">
      <ThemeProvider theme={theme}>
        <AuthComponentsContextProvider>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/vendor-category/*" element={<Vendors />} />
            <Route path="/checklist" element={<CheckList />} />
            <Route path="/guests/*" element={<Guests />} />

            <Route path="/invitations/*" element={<Invitations />} />
            <Route path="/invitations/guest-wish-details/:id" element={<GuestWishDetails />} />

            <Route path="/my-account/*" element={<MyAccount />} />

            <Route path="/faqs" element={<FAQs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route
              path="/facebook-deletion-instructions"
              element={<FacebookDeletionInstructions />}
            />
            <Route path="/*" element={<NotFound />} />
          </Routes>

          <Footer />

          {/*<Home />*/}
        </AuthComponentsContextProvider>


        {/*<Routes>

          <Route path="/" element={<ComingSoon />} />
          
          <Route path="/googleb0ff57ad34be9e2e" element={<GoogleID />} />
          <Route path="/googleb0ff57ad34be9e2e.html" element={<GoogleID />} />

          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>*/}

      </ThemeProvider>
    </div>
  );
};

export default WebAppLayout;
