import {
    Box,
    Grid,
    Paper,
    Typography,
    Stack,
    Switch,
    Container,
    CircularProgress
} from '@mui/material';
import React, { useEffect, useContext, useState, useRef, useCallback } from "react";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { getGuestListApi } from '../InvitationsApiFunctions';
import { Link } from "react-router-dom";
import { ProfileContext } from '../../../context/ProfileContext';
import CustomizedSnackbars from '../../../ui-common-components/ui-components/SnackBar';
import { editWebsite } from '../InvitationsApiFunctions';
const GuestBoard = () => {
    const profile = useContext(ProfileContext);
    const [loading, setLoading] = useState(true);
    const [guestWishes, setGuestWishes] = useState([]);
    const openSnackBar = useRef(null);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [guestBoardVisibility, setGuestBoardVisibility] = useState(profile.wedding.weddingWebsite.GuestBoardIsVisible);


    // Pagination
    const observer = useRef();
    const pageToLoad = useRef(1);
    const hasMore = useRef(true);
    const toggle = async (event) => {
        try {
            const response = await editWebsite({
                GuestBoardIsVisible: !guestBoardVisibility,
            });
            if (response.status == 200) {

                setSeverity("success");
                setMessage("Guestboard visibility changed successfully");
                setGuestBoardVisibility(!guestBoardVisibility);
                openSnackBar.current();
            }
        } catch (error) {
            setSeverity("error");
            setMessage(error.response.data.message);
            openSnackBar.current();
        }

    }
    let lastGuestWishElementRef = useCallback((node) => {
        if (observer.current) {
            observer.current.disconnect();
        }

        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                if (hasMore.current) {
                    pageToLoad.current = pageToLoad.current + 1;
                    //console.log('here')
                    getGuestList(pageToLoad.current);
                }
            }
        });

        if (node) {
            observer.current.observe(node);
        }
    }, [guestWishes]);
    //////
    useEffect(() => {

        getGuestList(1);
    }, []);

    const getGuestList = async (page) => {
        try {
            const response = await getGuestListApi(page);
            hasMore.current = response.totalPages > pageToLoad.current;
            setGuestWishes(prev => [...prev, ...response.docs]);

        } catch (error) {
            //console.log(error);
            // TODO::Handle error

        } finally {
            setLoading(false);
        }
    }

    return (
        // <PdfComponent></PdfComponent>
        <>

            {
                loading === true ?
                    <Container
                        sx={{
                            textAlign: "center",
                            marginTop: "50px",
                            marginBottom: "50px",
                        }
                        }
                    >
                        <CircularProgress></CircularProgress>
                    </Container >
                    :

                    < Box id={"printabel2"} sx={{
                        width: { xs: "100%", md: '65%' },
                        paddingX: { md: "20px" },
                        marginBottom: "20px"
                    }
                    }>
                        <CustomizedSnackbars
                            openSnackBar={openSnackBar}
                            message={message}
                            severity={severity}
                        />

                        <Grid container direction={"row"}>
                            {guestWishes.length === 0 ? <Container
                                sx={{

                                    textAlign: "center",
                                    marginTop: "50px",
                                    marginBottom: "50px",
                                    minHeight: 500
                                }
                                }
                            >
                                <Typography>
                                    No Guest Wishes found!!
                                </Typography>
                            </Container > :
                                < Grid
                                    maxHeight={500} item xs={12}
                                    sx={{
                                        overflowX: "scroll",
                                        '&::-webkit-scrollbar': {
                                            display: "none"
                                        }
                                    }}

                                >

                                    {
                                        guestWishes.map((guestWish, index) => {

                                            return (
                                                <Link
                                                    key={index}
                                                    to={`../guest-wish-details/${guestWish._id}`} >

                                                    <Paper
                                                        elevation={0}
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "center",
                                                            alignItems: "flex-start",
                                                            padding: "16px 24px",
                                                            gap: "12px",
                                                            height: "81px",
                                                            background: "#FFFFFF",
                                                            boxShadow: "0px 0px 10px rgba(153, 153, 153, 0.15)",
                                                            borderRadius: "8px",
                                                            cursor: "pointer",
                                                            marginBottom: "10px"
                                                        }}
                                                    >
                                                        <Stack width={"100%"} direction={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"} >
                                                            <Stack spacing={1}>
                                                                <Typography
                                                                    sx={{
                                                                        fontStyle: "normal",
                                                                        fontWeight: 600,
                                                                        fontSize: "16px",
                                                                        lineHeight: "21px",
                                                                        color: "#090A0A",
                                                                    }}
                                                                >
                                                                    {guestWish.firstName} {guestWish.lastName}
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        fontStyle: "normal",
                                                                        fontWeight: 400,
                                                                        fontSize: "16px",
                                                                        lineHeight: "18px",
                                                                        color: "#878787",
                                                                    }}
                                                                >
                                                                    22-03-2022
                                                                </Typography>
                                                            </Stack>
                                                            <KeyboardArrowRightIcon fontSize="medium" />
                                                        </Stack>


                                                    </Paper>
                                                </Link>
                                            );
                                        })


                                    }
                                    <Box ref={lastGuestWishElementRef}>

                                    </Box>


                                </Grid>
                            }
                            <Grid item xs={12} marginY={1}>
                                <Paper
                                    elevation={0}
                                    sx={
                                        {
                                            height: "64px",
                                            backgroundColor: "#FFFFFF",
                                            boxShadow: "0px 0px 10px rgba(153, 153, 153, 0.15)",
                                            borderRadius: "8px",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: 1
                                        }
                                    }>
                                    <Typography
                                        sx={{
                                            width: "117px",
                                            height: "18px",

                                            fontFamily: 'Montserrat',
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            fontSize: "16px",
                                            lineHeight: "18px",
                                            color: "#090A0A"
                                        }}
                                    >
                                        Page visibility
                                    </Typography>
                                    {/* TODO:: chage color */}
                                    <Switch size='medium' onChange={toggle} checked={guestBoardVisibility} />
                                </Paper>
                            </Grid>
                        </Grid>
                        
                    </Box >
            }
        </>
    );

};
export default GuestBoard;



