import React, { createContext, useState, useRef, useEffect } from "react";
import axios from "axios";
import Login from "../webapp/auth/login/Login";
import Signup from "../webapp/auth/signup/Signup";
//import {responseHandler} from "../common/ResponseHandler"
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ForgetPassword from "../webapp/auth/forget-password/ForgetPassword";
import ChangePassword from "../webapp/auth/change-password/ChangePassword";
import RegisterFirst from "../webapp/auth/register-first/RegisterFirst";

import WeddingInfo from "../webapp/auth/wedding-info/WeddingInfo";
import WebsiteInfo from "../webapp/auth/website-info/WebsiteInfo";

const customTheme = createTheme({
  typography: {
    fontFamily: "Montserrat",
    fontWeightBold: 600,
  },
  palette: {
    primary: {
      main: "#A7B0C0",
      light: "#000",
    },
    text: {
      primary: "#A7B0C0",
      secondary: "#AB9E72",
      tertiary: "#F1A3A5",
      tertiaryHover: "#c96d6f",
      dark: "#000",
      white: "#FFF",
      lightGray: "#A7B0C0",
      darkGray: "#98A2B3",
      selected: "#AB9E72",
    },
  },
});

export const AuthComponentsContext = createContext();

const AuthComponentsContextProvider = (props) => {
  const openLoginDialog = useRef(null);
  const openSignupDialog = useRef(null);
  const openForgetPasswordDialog = useRef(null);
  const openChangePasswordDialog = useRef(null);
  const openWeddingInfoDialog = useRef(null);
  const openWebsiteInfoDialog = useRef(null);
  const openRegisterFirstDialog = useRef(null);

  const [state, setState] = useState();
  const [registerFirstText, setRegisterFirstText] = useState('');
  const hideAllExcept = (dialogName) => {
    const dialogIds = [
      "login-dialog-main",
      "signup-dialog-main",
      "forget-password-dialog-main",
      "change-password-dialog-main",
      "register-first-dialog-main",
      "wedding-info-dialog-main",
      
    ];

    dialogIds.forEach((dialogId) => {
      if (dialogId !== dialogName) {
        document.getElementById(dialogId).style.display = "none";
      }
    });
  };
  const openRegisterFirstFunction = (text) => {
    try {
      hideAllExcept("register-first-dialog-main");
    } catch (err) { }
    //console.log("openRegisterFirstFunction");
    setRegisterFirstText(text);
    openRegisterFirstDialog.current();

    try {
      document.getElementById("register-first-dialog-main").style.display =
        "block";
    } catch (err) { }
  };

  const openLoginFunction = () => {
    try {
      hideAllExcept("login-dialog-main");
    } catch (err) { }

    openLoginDialog.current();

    try {
      document.getElementById("login-dialog-main").style.display = "block";
    } catch (err) { }
  };

  const openSignupFunction = () => {
    try {
      hideAllExcept("signup-dialog-main");
    } catch (err) { }

    openSignupDialog.current();

    try {
      document.getElementById("signup-dialog-main").style.display = "block";
    } catch (err) { }
  };


  const openWeddingInfoFunction = () => {
    try {
      hideAllExcept("wedding-info-dialog-main");
    } catch (err) { }

    openWeddingInfoDialog.current();

    try {
      document.getElementById("wedding-info-dialog-main").style.display = "block";
    } catch (err) { }
  };

  const openWebsiteInfoFunction = () => {
    try {
      hideAllExcept("website-info-dialog-main");
    } catch (err) { }

    openWebsiteInfoDialog.current();

    try {
      document.getElementById("website-info-dialog-main").style.display = "block";
    } catch (err) { }
  };

  const openForgetPasswordFunction = () => {
    try {
      hideAllExcept("forget-password-dialog-main");
    } catch (err) { }

    openForgetPasswordDialog.current();

    try {
      //document.getElementById("forget-password-dialog-main").style.display= 'block'
    } catch (err) { }
  };
  const openChangePasswordFunction = () => {
    try {
      hideAllExcept("change-password-dialog-main");
    } catch (err) { }

    openChangePasswordDialog.current();

    try {
      //document.getElementById("forget-password-dialog-main").style.display= 'block'
    } catch (err) { }
  };

  return (
    <AuthComponentsContext.Provider
      value={{
        state: state,
        openLogin: openLoginFunction,
        openSignup: openSignupFunction,
        openForgetPassword: openForgetPasswordFunction,
        openChangePassword: openChangePasswordFunction,
        openRegisterFirst: openRegisterFirstFunction,
        openWeddingInfo: openWeddingInfoFunction,
        openWebsiteInfo: openWebsiteInfoFunction
      }}
    >
      <ThemeProvider theme={customTheme}>
        <RegisterFirst openDialog={openRegisterFirstDialog} text={registerFirstText} />
        <Login openDialog={openLoginDialog} />
        <Signup openDialog={openSignupDialog} />
        <ForgetPassword openDialog={openForgetPasswordDialog} />
        <ChangePassword openDialog={openChangePasswordDialog} />
        <WeddingInfo openDialog={openWeddingInfoDialog} />
        <WebsiteInfo openDialog={openWebsiteInfoDialog} />
        
      </ThemeProvider>
      {props.children}
    </AuthComponentsContext.Provider>
  );
};

export default AuthComponentsContextProvider;
