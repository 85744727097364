import React from "react";
import Box from "@mui/material/Box";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ImageListItem from "@mui/material/ImageListItem";


const ImagesCaroussel = (props) => {
  const vendorMedia = [];
  const getCarouselItems = (vendorMedias) => {
    const items = [];
    for (let i = 0; i < vendorMedias.length; i += 1) {
      const carouselItem = [];
      for (let j = i; j < i + 1 && j < vendorMedias.length; j++) {
        const vendorMedia = vendorMedias[j];
        carouselItem.push(
          (
            <ImageListItem
              key={j}
              sx={{
                height: {
                  xs: "150px !important",
                  md: "300px!important",
                  lg: "350px!important",
                },

                borderRadius: "13px",
                cursor: "pointer",
              }}
            >
              <img
                src={`${vendorMedia}`}
                // height="300px"
                // width="300"
                // cover="true"

                loading="lazy"
                style={{ borderRadius: "10px", objectFit: "cover" }}
              />
            </ImageListItem>
          )
          // </Grid>
        );
      }
      items.push(
        <div
          key={i}
          className={
            i === 0 ? "carousel-item d-none active" : "carousel-item d-none"
          }
        >
          {carouselItem}
        </div>
      );
    }
    return items;
  };

  return (
    <div
      id={"carouselExampleControls"}
      className="carousel slide"
      data-bs-interval="false"
      data-bs-ride="carousel"
      sx={{
        marginBottom: "20px",
      }}
    >
      <div
        className="carousel-indicators"
        style={{
          position: "absolute",
          bottom: "-30px",
        }}
      >
        {props.images.map((photo, index) => {
          return (
            <Box
              key={index}
              sx={{
                width: { xs: "5px", md: "10px" },
                height: { xs: "5px", md: "10px" },
                margin: "0 2px",
              }}
            >
              <button
                key={index}
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide-to={index}
                className={index === 0 ? "active" : "none"}
                aria-current="true"
                aria-label="Slide 1"
                style={{
                  borderRadius: "100%",
                  backgroundColor: "grey",
                  width: "100%",
                  height: "100%",
                  // opacity: "0.5",
                }}
              ></button>
            </Box>
          );
        })}
      </div>

      <div className="carousel-inner">{getCarouselItems(props.images)}</div>
      {props.images.length === 0 ? null : (
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target={"#carouselExampleControls"}
          data-bs-slide="prev"
        >
          <span
            style={{
              backgroundColor: "#F2F2F2",
              borderRadius: "10px",
              padding: "5px",
              ":hover": {
                backgroundColor: "#F1A3A5",
              },
            }}
          >
            <NavigateBeforeIcon
              style={{
                color: "#000000",
                ":hover": {
                  color: "white",
                },
              }}
              sx={{
                width: { xs: "1rem", md: "1.5rem" },
                height: { xs: "1rem", md: "1.5rem" },
              }}
            ></NavigateBeforeIcon>
          </span>
        </button>
      )}
      {props.images.length === 0 ? null : (
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target={"#carouselExampleControls"}
          data-bs-slide="next"
        >
          <span
            style={{
              backgroundColor: "#F2F2F2",
              borderRadius: "5px",
              padding: "1px",
              ":hover": {
                backgroundColor: "#F1A3A5",
              },
            }}
          >
            <NavigateNextIcon
              sx={{
                width: { xs: "1rem", md: "1.5rem" },
                height: { xs: "1rem", md: "1.5rem" },
              }}
              style={{ color: "#000000" }}
            ></NavigateNextIcon>
          </span>
        </button>
      )}
    </div>
  );
};

export default ImagesCaroussel;
