import React, { useContext } from "react";
import Box from '@mui/material/Box';
import { WebAppTitle } from "../WebAppTitle";
import { ProfileContext } from "../../context/ProfileContext";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import LoginOrRegisterFirst from '../auth/login-register-first/LoginOrRegisterFirst'
import GuestsList from "./GuestsList";
import SetupWeddingFirst from "../auth/setup-wedding-first/SetupWeddingFirst";

const Guests = () => {
  
  const profile = useContext(ProfileContext);
  
  return (
    <>
    
    {/*<WebAppTitle text="Guests"/>*/}
    <Box minHeight={200}> 

    {
      profile.isLoading ?
      <Box
        sx={{
        width:"100%",
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        height:"200px",
        }}
      >
        <Container
          sx={{
            textAlign: "center",
          }}
        >
          <CircularProgress></CircularProgress>
        </Container>
      </Box>
      :
          
        <>
          {
            profile.user ?
            <>
              {
                profile.wedding ?
                <>
                  {/*<Box sx={{
                    width:"100%",
                    display:"flex",
                    justifyContent:"center",
                    marginTop:"2rem",
                  }}>
                    <GuestsCount/>
                </Box>*/}


                  <GuestsList/>

                </>
                :
                <>
                  <WebAppTitle text="Guests"/>
                  <SetupWeddingFirst/>
                </>
              }
            </>
            :
            <>
              <WebAppTitle text="Guests"/>
              <LoginOrRegisterFirst pageName={"guests"}/>
            </>
          }
          
        </>
          
      }
      </Box>
    </>
  );
};
export default Guests;
