import React, { useState } from "react";
import "./TemplateOne.css";
import { Link } from "react-router-dom";
import {
  getDayName,
  getMonth,
  getDayNumber,
  getYear,
  getTime,
  RSVPPressed_common
} from "../TemplatesCommonFunctions";

const TemplateOneInvitation = ({ templateData, weddingId }) => {
  const [dummy, setDummy] = useState(0);

  const RSVPPressed = () => {
    setDummy(!dummy)
    RSVPPressed_common("one")
  }

  return (
    <>
      <div id="template-one-body" className="row">
        <div className="col-3"></div>
        <div id="body-col" className="col-6 ">
          <div id="body-main-container" className="container-fluid">
            <div id="body-row-inv-message" className="row">
              <div className="col">
                <p>Together with</p>
                <p>their families</p>
              </div>
            </div>

            <div id="body-row-couple">
              <div id="bride-col" className="row container-fluid">
                <div id="bride-first-name" className="row">
                  <p>{templateData.brideFirstName}</p>
                </div>
                <div id="bride-last-name" className="row">
                  <p>{templateData.brideLastName}</p>
                </div>
              </div>
              <div id="couple-and" className="row">
                <p>&</p>
              </div>
              <div id="groom-col" className="row container-fluid">
                <div id="groom-first-name" className="row">
                  <p>{templateData.groomFirstName}</p>
                </div>
                <div id="groom-last-name" className="row">
                  <p>{templateData.groomLastName}</p>
                </div>
              </div>
            </div>

            <div id="body-row-couple-message" className='row'>
              <p>
                Invite you to celebrate
              </p>
              <p>
                their wedding day
              </p>
            </div>

            <div id="body-row-day" className="row">
              <p>{getDayName(templateData.weddingDate)}</p>
            </div>

            <div id="body-row-date" className="row">
              <div id="body-date-month" className="col-3">
                <p>{getMonth(templateData.weddingDate)}</p>
              </div>
              <div id="body-date-day-num" className="col-6">
                <p>{getDayNumber(templateData.weddingDate)}</p>
              </div>
              <div id="body-date-year" className="col-3">
                <p>{getYear(templateData.weddingDate)}</p>
              </div>
            </div>

            <div id="body-row-time" className="row">
              <p>{getTime(templateData.weddingDate)}</p>
              <p>{templateData.area}</p>
              <p>{templateData.city}</p>
            </div>
            {templateData.weddingWebsite.RSVPsIsVisible ?
              <div>
                <div id="body-row-rsvp-by" className="row">
                  <p>
                    KINDLY RSVP BY {getMonth(templateData.RSVPBy)}{" "}
                    {getDayNumber(templateData.RSVPBy)}
                  </p>
                </div>

                <div id="body-row-rsvp-button" className="text-center">
                  <div id="rsvp-col">
                    <Link to={"/flowers-of-happiness/"+weddingId+"/rsvp"}>

                      <button onClick={RSVPPressed} className=" shadow-none">
                        RSVP HERE
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              : <div id="rsvp-placeholder"></div>
            }

          </div>
        </div>
        <div className="col-3"></div>
      </div>

      <div id="created-on" className="w-100">
        <div className="w-50">

          <p>
            Created on Lovebirds
          </p>
          <p>
            Getting married? <Link id="created-on-link" style={{ textDecoration: 'underline' }}
            to={"/"}
            >Create your wedding website for free.</Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default TemplateOneInvitation;
