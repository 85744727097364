import React, { useState } from "react";
import "./TemplateTwelve.css";
import { Link } from "react-router-dom";
import WeddingImage from '../../assets/template-twelve/TemplateTwelveWeddingImage.png' // relative path to image 

import {
  getDayNumber,
  RSVPPressed_common
} from "../TemplatesCommonFunctions";
import { Box } from "@mui/material";

const TemplateTwelveInvitation = ({ templateData, weddingId }) => {
  const [dummy, setDummy] = useState(0);

  const RSVPPressed = () => {
    setDummy(!dummy)
    RSVPPressed_common("twelve")
  }

  const getDayName = (date) => {
    const d2 = new Date(date)
    var weekdays = new Array(7);
    weekdays[0] = "Sunday";
    weekdays[1] = "Monday";
    weekdays[2] = "Tuesday";
    weekdays[3] = "Wednesday";
    weekdays[4] = "Thurssay";
    weekdays[5] = "Friday";
    weekdays[6] = "Saturday";
    return weekdays[d2.getDay()];
  }

  const getMonth = (date) => {
    const d2 = new Date(date)
    var Months = new Array(12);
    Months[0] = "JANUARY";
    Months[1] = "FEBRUARY";
    Months[2] = "MARCH";
    Months[3] = "APRIL";
    Months[4] = "MAY";
    Months[5] = "JUNE";
    Months[6] = "JULY";
    Months[7] = "AUGUST";
    Months[8] = "SEPTEMBER";
    Months[9] = "OCTOBER";
    Months[10] = "NOVEMBER";
    Months[11] = "DECEMBER";
    return Months[d2.getMonth()];
  }

  const getTime = (date) => {
    const d2 = new Date(date)
    var hour = d2.getHours();

    let timeSTR = ""
    if (hour == 0) {
      timeSTR = "12:00 AM"
    }
    else if (hour > 12) {
      timeSTR = (hour - 12) + ":00 PM"
    }
    else {
      timeSTR = hour + ":00 AM"
    }

    return timeSTR;
  }

  return (
    <>
      <div id="template-twelve-body" className="row">
        <div className="col-1 col-md-2"></div>
        <div id="body-col" className="col-10 col-md-8">
          <div id="body-main-container" className="container-fluid">
            <div id="body-row-inv-message" className="row">
              <div className="col">
                <p style={{position:"relative",zIndex:1}} >
                    Together with their families
                </p>
              </div>
            </div>

            <div id="body-row-couple">
                <div id="bride-first-name">
                  <p>{templateData.brideFirstName} {templateData.brideLastName} </p>
                </div>
              <div id="couple-and" className="row">
                <p>&</p>
              </div>
              <div id="groom-col" className="row">
                <div id="groom-first-name" className="row">
                  <p>{templateData.groomFirstName} {templateData.groomLastName}</p>
                </div>
              </div>
            </div>

            <div id="body-row-image" className="row">
              <img src={WeddingImage} alt="Wedding Image"/>
            </div>

            <div id="body-row-couple-message" className='row'>
              <p style={{ position:"relative", zIndex:1}}>
                 Request the honor of your presence <br/> at the celebration of their wedding
              </p>
            </div>

            <div id="body-row-area" className="row">
              <p>{templateData.area}</p>
            </div>

            <div id="weddingDate" className="row" style={{ alignItems:"center"}}>
              <div   id="day-dev" style={{width: "33%" ,float: "left"}} className="body-row-day-month"><p id="dayName"><span className="me-1">{getDayName(templateData.weddingDate)}</span></p></div>
              <div style={{width: "33%" ,float: "left"}}>
                  <div id="body-row-day-number" className="row">
                    <p>{getDayNumber(templateData.weddingDate)}</p>
                  </div>
                  <div id="body-row-time" className="row">
                    <p>{getTime(templateData.weddingDate)}</p>
                  </div>
              </div>
              <div id="month-dev" style={{width: "33%" ,float: "left"}} className="body-row-day-month">
                  <p  id="monthName">
                  <span className="me-1">{getMonth(templateData.weddingDate)}</span>
                  </p>
              </div>
            </div>
            
      
            {templateData.weddingWebsite.RSVPsIsVisible ?
              <div>
                <div id="body-row-rsvp-button" className="text-center">
                  <div id="rsvp-col">
                    <Link to={"/rose-garden/"+weddingId+"/rsvp"}>
                      <button onClick={RSVPPressed} className=" shadow-none">
                        RSVP HERE
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              : <div id="rsvp-placeholder"></div>
            }

          </div>
        </div>
        <div id="created-on" className="w-100">
        <div className="w-50">

          <p>
            Created on Lovebirds
          </p>
          <p>
            Getting married? <Link id="created-on-link" style={{ textDecoration: 'underline' }}
            to={"/"}
            >Create your wedding website for free.</Link>
          </p>
        </div>
      </div>
      </div>

      
    </>
  );
};

export default TemplateTwelveInvitation;
