import { AuthComponentsContext } from "../../../context/AuthComponentsContext";

import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, useContext } from "react";
// third party
import * as Yup from "yup";
import { Formik } from "formik";

// assets
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { COOKIES_SETTINGS, USER_SIGNUP } from "../../../endpoints/Constants";
import axios from "axios";
import GoogleBtn from "../common-btns/GoogleBtn";
import FacebookBtn from "../common-btns/FacebookBtn";
import AppleBtn from "../common-btns/AppleBtn";

const SignupForm = (props) => {
  const authComponents = useContext(AuthComponentsContext);

  //console.log(props);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          confirmPassword: "",
          submit: null,
        }}
        validationSchema={Yup.object().shape({
            firstName: Yup.string().max(255).required("First name is required"),
            lastName: Yup.string().max(255).required("Last name is required"),
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          password: Yup.string().max(255).required("Password is required"),
          confirmPassword: Yup.string().max(255).required("Confirm password is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            if(values.password !== values.confirmPassword){
                setErrors({confirmPassword:"Passwords don't match!"})
                return
            }

            setLoading(true);
            // console.log(values);
            let formData = {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              password: values.password,
            };
            
            axios
              .post(USER_SIGNUP, formData, COOKIES_SETTINGS)
              .then((res) => {
                setLoading(false);

                if (res.status === 200) {
                  setStatus({ success: true });
                  //window.location.reload()
                  authComponents.openWeddingInfo()
                } else {
                  setStatus({ success: false });
                  setErrors({ submit: res.data.message });
                }
              })
              .catch((err) => {
                setLoading(false);
                setStatus({ success: false });
                setErrors({ submit: err.response.data.message });
              });
          } catch (err) {
            //setLoading(false);
            setStatus({ success: false });
            setErrors({ submit: "Error" });
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container justifyContent="center" alignItems="center">
              {errors.submit && (
                <Alert severity="error" sx={{ width: "100%", margin: "1rem" }}>
                  {errors.submit}
                </Alert>
              )}
              <FormControl
                fullWidth
                error={Boolean(touched.firstName && errors.firstName)}
                sx={{ margin: "0.5rem 1rem" }}
              >
                <InputLabel
                  htmlFor="outlined-adornment-firstName-signup"
                  sx={{ color: "text.lightGray" }}
                >
                  First Name
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-firstName-signup"
                  type="text"
                  value={values.firstName}
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="First Name"
                  inputProps={{}}
                  style={{
                    color: "#000",
                    borderRadius: "10px"
                  }}
                />
                {touched.firstName && errors.firstName && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-firstName-signup"
                  >
                    {errors.firstName}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl
                fullWidth
                error={Boolean(touched.lastName && errors.lastName)}
                sx={{ margin: "0.5rem 1rem" }}
              >
                <InputLabel
                  htmlFor="outlined-adornment-lastName-signup"
                  sx={{ color: "text.lightGray" }}
                >
                  Last Name
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-lastName-signup"
                  type="text"
                  value={values.lastName}
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Last Name"
                  inputProps={{}}
                  style={{
                    color: "#000",
                    borderRadius: "10px"
                  }}
                />
                {touched.lastName && errors.lastName && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-lastName-signup"
                  >
                    {errors.lastName}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl
                fullWidth
                error={Boolean(touched.email && errors.email)}
                sx={{ margin: "0.5rem 1rem" }}
              >
                <InputLabel
                  htmlFor="outlined-adornment-email-signup"
                  sx={{ color: "text.lightGray" }}
                >
                  Email
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-email-signup"
                  type="email"
                  value={values.email}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Email"
                  inputProps={{}}
                  style={{
                    color: "#000",
                    borderRadius: "10px"
                  }}
                />
                {touched.email && errors.email && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-email-signup"
                  >
                    {errors.email}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl
                fullWidth
                error={Boolean(touched.password && errors.password)}
                sx={{ margin: "0.5rem 1rem" }}
              >
                <InputLabel
                  htmlFor="outlined-adornment-password-signup"
                  sx={{ color: "text.lightGray",
                    }}
                >
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password-signup"
                  type={showPassword ? "text" : "password"}
                  autoComplete="on"
                  value={values.password}
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? <VisibilityIcon /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                  inputProps={{}}
                  style={{
                    color: "#000",
                    borderRadius: "10px"
                  }}
                />
                {touched.password && errors.password && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-password-signup"
                  >
                    {errors.password}
                  </FormHelperText>
                )}
              </FormControl>
          
              <FormControl
                fullWidth
                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                sx={{ margin: "0.5rem 1rem" }}
              >
                <InputLabel
                  htmlFor="outlined-adornment-confirmPassword-signup"
                  sx={{ color: "text.lightGray",
                    }}
                >
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-confirmPassword-signup"
                  type={showPassword ? "text" : "password"}
                  autoComplete="on"
                  value={values.confirmPassword}
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle confirmPassword visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? <VisibilityIcon /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Confirm Password"
                  inputProps={{}}
                  style={{
                    color: "#000",
                    borderRadius: "10px"
                  }}
                />
                {touched.confirmPassword && errors.confirmPassword && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-confirmPassword-signup"
                  >
                    {errors.confirmPassword}
                  </FormHelperText>
                )}
              </FormControl>
          
              <Grid item xs={11}>
                <Button
                  fullWidth
                  disabled={loading}
                  size="medium"
                  type="submit"
                  variant="contained"
                  sx={{
                    padding:"0.7rem 0rem",
                    color:"text.white",
                    backgroundColor:"text.tertiary",
                      ":hover": {
                        backgroundColor: "text.tertiaryHover",
                      },
                      borderRadius:"48px",
                      textTransform:"none",
                      fontSize:"1.2rem",
                      marginBottom:"1.2rem"
                  }}
                >
                  {loading ? (
                    <CircularProgress sx={{ color: "#FFF" }} size={"1.8rem"} />
                  ) : (
                    "Register"
                  )}
                </Button>
                <Box sx={{display:"flex", justifyContent:"space-between", marginBottom:"2rem"}}>
                   {/* <Box sx={{border:"1px solid", borderColor:"text.darkGray", borderRadius:"0.5rem", padding:{xs:"0.4rem 1.5rem", md:"0.4rem 3.2rem"}, width:"fit-content"}}>
                        <GoogleIcon sx={{color: "text.darkGray", fontSize:"2rem"}}/>
                    </Box>*/}
                    <GoogleBtn/>

                    {/*<Box sx={{border:"1px solid", borderColor:"text.darkGray", borderRadius:"0.5rem", padding:{xs:"0.4rem 1.5rem", md:"0.4rem 3.2rem"}, width:"fit-content"}}>
                        <FacebookIcon sx={{color: "text.darkGray", fontSize:"2rem"}}/>
                  </Box>*/}
                  <FacebookBtn/>

                   {/* <Box sx={{border:"1px solid", borderColor:"text.darkGray", borderRadius:"0.5rem", padding:{xs:"0.4rem 1.5rem", md:"0.4rem 3.2rem"}, width:"fit-content"}}>
                        <AppleIcon sx={{color: "text.darkGray", fontSize:"2rem"}}/>
                </Box>*/}
                  <AppleBtn/>

                </Box>

                <Box>
                <Box
                   sx={{display:"flex", justifyContent:"center", fontWeight: "bold",marginBottom:"2rem"}}
                  >
                    <Typography
                     sx={{
                        fontWeight: "bold",
                        textAlign: "left",
                        fontSize:{xs:"0.9rem", md:"1rem"}
                      }}
                    >
                        {"Have an account?"}
                    </Typography>

                    <Typography
                    color="text.tertiary"

                     sx={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        textAlign: "left",
                        ":hover": {
                          color: "text.tertiaryHover",
                        },
                        paddingLeft:"4px",
                        fontSize:{xs:"0.9rem", md:"1rem"}
                      }}
                      onClick={()=>{
                        authComponents.openLogin()
                      }}
                    >
                        {"Sign in Now"} 

                    </Typography>
                  </Box>
                </Box>
              </Grid>


            </Grid>
          </form>
        )}
      </Formik>
  );
};

export default SignupForm;
