import React, { useEffect, useState } from "react";
import PhoneInput from 'react-phone-input-2'


import 'react-phone-input-2/lib/material.css'
// third party
import * as Yup from "yup";
import { Formik } from "formik";

// assets
import { Alert, Checkbox, FormControlLabel } from "@mui/material";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  Divider,
  Select,
  MenuItem
} from "@mui/material";
import {
  Box,
  Grid,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { GET_VENDORS, COOKIES_SETTINGS, VENDOR_CATEGORY } from "../../endpoints/Constants";
import axios from "axios";
const VendorForm = (props) => {

  const getVendorCategories = async () => {

    try {
      const response = await axios.get(
        VENDOR_CATEGORY + "?isDeleted=false",
        COOKIES_SETTINGS
      );
      // console.log("Vendor categories", response);
      setVendorCategories(response.data);
    } catch (error) {
      // console.log(error);
    }

  };

  const addVendor = async (values, setErrors, setStatus, setSubmitting) => {
    try {
      setLoadingBtn(true);
      let formData = {
        name: values.name,
        category: values.category,
        city: values.city,
        area: values.area,
        about: values.about,
        phone: values.phone,
        websiteUrl: values.websiteUrl,
        facebookLink: values.facebookLink,
        instagramUserName: values.instagramUserName,
        featured: featured,
      };
      //console.log("formDataaaaa", formData);


      axios
        .post(GET_VENDORS, formData, COOKIES_SETTINGS)
        .then((res) => {
          setLoadingBtn(false);
          // console.log(res);
          if (res.status === 201) {
            setStatus({ success: true });
            setSuccessMsg("Vendor Added Successfully");
            props.successaddvendordialog();
          }
        })
        .catch((err) => {
          setLoadingBtn(false);
          setStatus({ success: false });
          setErrors({ submit: err.response.data.message });
        });
    } catch (err) {
      setLoadingBtn(false);
      setStatus({ success: false });
      setErrors({ submit: "Error" });
    }

  };
  const editVendor = async (values, setErrors, setStatus, setSubmitting) => {
    try {
      setLoadingBtn(true);

      let formData = {
        id: profile._id,
        name: values.name,
        category: values.category,
        city: values.city == null ? "" : values.city,
        area: values.area == null ? "" : values.area,
        about: values.about == null ? "" : values.about,
        phone: values.phone,
        websiteUrl: values.websiteUrl == null ? "" : values.websiteUrl,
        facebookLink: values.facebookLink == null ? "" : values.facebookLink,
        instagramUserName: values.instagramUserName,
        featured: featured,
      };

      // console.log("formData:", formData);
      //console.log("Dataaaaaaa", formData);
      axios
        .put(GET_VENDORS, formData, COOKIES_SETTINGS)
        .then((res) => {
          setLoadingBtn(false);
          // console.log(res);
          if (res.status === 200) {
            setStatus({ success: true });
            setSuccessMsg("Vendor Updated Successfully");

          }
        })
        .catch((err) => {
          setLoadingBtn(false);
          setStatus({ success: false });
          setErrors({ submit: err.response.data.message });
        });
    } catch (err) {
      setLoadingBtn(false);
      setStatus({ success: false });
      setErrors({ submit: "Error" });
    }

  };
  const [featured, setFeatured] = useState(0);
  const [profile, setProfile] = useState({
    name: "",
    category: "",
    city: "",
    area: "",
    about: "",
    phone: "",
    websiteUrl: "",
    facebookLink: "",
    instagramUserName: "",
    instagramLink: "",
  });

  //const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [vendorCategories, setVendorCategories] = useState([]);
  const [categoryValue, setCategoryValue] = useState('');
  useEffect(() => {

    getVendorCategories();
    if (!props.add) {
      setProfile(props.data);
      setFeatured(props.data.featured);
      setCategoryValue(props.data.category._id);
    }
    setLoading(false);
  }, [props.data, props.add]);

  const phoneRegExp = /201[0125][0-9]{8}$/;
  // console.log("id", profile.category._id);

  const formFields = [{
    name: "name",
    label: "Name",
    type: "text",
    value: props.add ? "" : profile.name,
    validation: Yup.string().required("Name is required"),
  },
  {
    name: "category",
    label: "Category",
    type: "text",
    value: props.add ? "" : profile.category._id,
    validation: Yup.string().required("Category is required"),
  },
  {
    name: "city",
    label: "City",
    type: "text",
    value: props.add ? "" : profile.city,
    // validation: Yup.string().required("City is required"),
  },
  {
    name: "area",
    label: "Area",
    type: "text",
    value: props.add ? "" : profile.area,
    // validation: Yup.string().required("Area is required"),
  },
  {
    name: "about",
    label: "About",
    type: "text",
    value: props.add ? "" : profile.about,
    // validation: Yup.string().required("About is required"),
  },
  {
    name: "phone",
    label: "Phone",
    type: "phone",
    value: props.add ? "" : profile.phone,
    // validation: Yup.string()
    //   .matches(phoneRegExp, "Phone number is not valid")
    //   .required("Phone is required"),

    //validation: Yup.string(),
  },
  {
    name: "websiteUrl",
    label: "Website URL",
    type: "text",
    value: props.add ? "" : profile.websiteUrl,
    // validation: Yup.string().required("Website URL is required"),
  },
  {
    name: "facebookLink",
    label: "Facebook Link",
    type: "text",
    value: props.add ? "" : profile.facebookLink,
    // validation: Yup.string().required("Facebook Link is required"),
  },
  {
    name: "instagramUserName",
    label: "Instagram User Name",
    type: "text",
    value: props.add ? "" : profile.instagramLink,
    validation: Yup.string().required("Instagram User Name is required"),
  },
  ];

  var validationlist = {};
  formFields.forEach((field) => {
    validationlist[field.name] = field.validation;
  });

  const [successMsg, setSuccessMsg] = useState("");


  useEffect(() => {
    if (successMsg) {
      setTimeout(() => {
        setSuccessMsg("");
      }, 5000);
    }
  }, [successMsg]);

  return (
    <div>
      {loading ? (
        <Box
          sx={{
            width: "100%",
            minHeight: "70vh",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Formik
          initialValues={{
            name: profile.name,
            category: profile.category._id,
            city: profile.city,
            area: profile.area,
            about: profile.about,
            phone: profile.phone,
            websiteUrl: profile.websiteUrl,
            facebookLink: profile.facebookLink,
            instagramUserName: profile.instagramLink.replace("https://www.instagram.com/", "").replace("/", ""),
            submit: null,
          }}
          validationSchema={Yup.object().shape(validationlist)}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {

            if (props.add) {
              addVendor(values, setErrors, setStatus, setSubmitting);
            } else {
              editVendor(values, setErrors, setStatus, setSubmitting);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit} {...props}>
              <Grid container justifyContent="center" alignItems="center">
                {successMsg && (
                  <Alert
                    severity="success"
                    sx={{ width: "100%", margin: "1rem" }}
                  >
                    {successMsg}
                  </Alert>
                )}

                {errors.submit && (
                  <Alert
                    severity="error"
                    sx={{ width: "100%", margin: "1rem" }}
                  >
                    {errors.submit}
                  </Alert>
                )}

                {formFields.map((field, index) => {

                  return (
                    <FormControl
                      fullWidth
                      error={Boolean(touched[field.name] && errors[field.name])}
                      sx={{ margin: "1rem" }}
                      key={"field-" + index}
                    >
                      {field.name === "phone" ? null : <InputLabel htmlFor={"outlined-adornment-" + field.name}>
                        {field.label}
                      </InputLabel>}
                      {field.name === "phone" ?

                        <PhoneInput
                          inputStyle={{ width: "100%" }}
                          inputProps={{ name: "phone" }}

                          value={values[field.name]}
                          onBlur={handleBlur}
                          onChange={(e) => { values[field.name] = "+" + e;}}
                          country={'eg'}
                          placeholder="Phone Number"
                          // onlyCountries={['eg']}
                          countryCodeEditable={false}
                          isValid={touched[field.name] && !errors[field.name]}

                        /> : field.name === "category" ?
                          <Select
                            id={"outlined-adornment-" + field.name}
                            value={categoryValue}
                            label="outlined"
                            onChange={(e) => { setCategoryValue(e.target.value); values[field.name] = e.target.value; }}
                          >
                            {vendorCategories.map((category, index) => {
                              return <MenuItem key={"category-" + index} value={category._id}>{category.name}</MenuItem>
                            })}

                          </Select> :
                          <OutlinedInput
                            id={"outlined-adornment-" + field.name}
                            type={field.type}
                            value={values[field.name]}
                            name={field.name}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label={field.label}
                            inputProps={{}}
                          />}
                      {touched[field.name] && errors[field.name] && (
                        <FormHelperText
                          error
                          id={"standard-weight-helper-text-" + field.name}
                        >
                          {errors[field.name]}
                        </FormHelperText>
                      )}

                    </FormControl>
                  );
                })}
                <Box width={'100%'} marginLeft={'2rem'} marginBottom={'1rem'} >
                  <FormControlLabel
                    control={<Checkbox checked={featured} onChange={(event) => { setFeatured(!featured); }} />}
                    label="Featured"
                  />
                </Box>

                {!props.add ? <Grid item xs={12} md={5}>
                  <Button
                    fullWidth
                    disabled={loadingBtn}
                    size="medium"
                    type="submit"
                    variant="contained"
                  >
                    {loadingBtn ? (
                      <CircularProgress
                        sx={{ color: "#FFF" }}
                        size={"1.54rem"}
                      />
                    ) : profile ? (
                      "Save Changes"
                    ) : (
                      "Add Vendor"
                    )}
                  </Button>

                </Grid> :
                  <Box width={'100%'}>
                    <Divider></Divider>
                    <Stack padding={'1rem'} direction={'row'} display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                      <Button
                        onClick={() => { props.canceladdvendordialog(); }}
                        variant="text"
                        color="error">
                        Cancel
                      </Button>
                      {loadingBtn ? (
                        <Button
                          sx={{ marginLeft: '1rem', backgroundColor: "rgba(0,0,0,0)" }}
                          disabled={"true"}
                          variant="contained"
                          color="primary"
                        >
                          <CircularProgress size={"1.3rem"} />
                        </Button>
                      )
                        :
                        <Button
                          sx={{ marginLeft: '1rem' }}
                          disabled={loadingBtn}

                          type="submit"
                          variant="contained"
                          color="primary"
                        >Add
                        </Button>
                      }
                    </Stack>
                  </Box>
                }
              </Grid>
            </form>
          )}
        </Formik>
      )
      }
    </div >
  );
};

export default VendorForm;

