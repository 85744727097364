import { useEffect, useState, useRef } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  Stack,
  Box,
  styled,
  Alert,
  CircularProgress,
} from "@mui/material";
import image from "../../assets/placeholder-image.png";
import axios from "axios";
import {
  COOKIES_SETTINGS,
  UPLOAD_VENDOR_CATEGORY_IMAGE,
  VENDOR_CATEGORY,
} from "../../endpoints/Constants";
export default function FormDialog(props) {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [validForm, setValidForm] = useState(true);
  const [validationMessage, setValidationMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const nameTextFieldRef = useRef("");

  var imageUrl = useRef(null);
  const resetState = () => {
    setSelectedImage(null);
    setValidForm(true);
    setValidationMessage("");
    setLoading(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    resetState();
    setOpen(false);
  };
  useEffect(() => {
    props.openFormDialog.current = handleClickOpen;
  });
  const Input = styled("input")({
    display: "none",
  });
  const showImage = () => {
    //Case Add
    if (props.toEditCategory == null) {
      if (selectedImage == null) {
        return image;
      } else {
        return URL.createObjectURL(selectedImage);
      }
    } else {
      //Case Edit
      if (selectedImage == null) {
        return props.toEditCategory.icon;
      } else {
        return URL.createObjectURL(selectedImage);
      }
    }
  };
  const addCategory = async () => {
    if (selectedImage == null) {
      setValidForm(false);
      setValidationMessage("Please select an image");
      return;
    } else if (nameTextFieldRef.current.value === "") {
      setValidForm(false);
      setValidationMessage("Please enter a name");
      return;
    }
    setValidForm(true);
    try {
      setLoading(true);
      // console.log(imageUrl.current);
      if (imageUrl.current == null) {
        const response = await axios.post(
          UPLOAD_VENDOR_CATEGORY_IMAGE,
          {
            filename: selectedImage.name,
          },
          COOKIES_SETTINGS
        );

        const data = response.data;
        const uploadUrl = data.data.url;

        const uploadPhoto = new FormData();
        const image = data.data;
        Object.keys(image.fields).forEach((field) => {
          uploadPhoto.append(field, image.fields[field]);
        });
        uploadPhoto.append("file", selectedImage);

        await axios({
          method: "post",
          url: uploadUrl,
          data: uploadPhoto,
          headers: { "Content-Type": "multipart/form-data" },
        });

        imageUrl.current = data.imgUrl;
      }
      // console.log("Image url", imageUrl.current);
      await axios.post(
        VENDOR_CATEGORY,
        {
          name: nameTextFieldRef.current.value,
          icon: imageUrl.current,
        },
        COOKIES_SETTINGS
      );

      setLoading(false);

      // console.log(submitResponse);
      handleClose();
      props.successfullyChanged();
    } catch (error) {
      setLoading(false);
      // console.log(error.response.data.message);
      setValidForm(false);
      setValidationMessage(error.response.data.message);
    }
  };
  const editCategory = async () => {
    // console.log(props.toEditCategory.id);
    if (nameTextFieldRef.current.value === "") {
      setValidForm(false);
      setValidationMessage("Please enter a name");
      return;
    }
    setValidForm(true);
    try {
      setLoading(true);
      // console.log(imageUrl.current);
      if (selectedImage != null) {
        const response = await axios.post(
          UPLOAD_VENDOR_CATEGORY_IMAGE,
          {
            filename: selectedImage.name,
          },
          COOKIES_SETTINGS
        );

        const data = response.data;
        const uploadUrl = data.data.url;

        const uploadPhoto = new FormData();
        const image = data.data;
        Object.keys(image.fields).forEach((field) => {
          uploadPhoto.append(field, image.fields[field]);
        });
        uploadPhoto.append("file", selectedImage);
        await axios({
          method: "post",
          url: uploadUrl,
          data: uploadPhoto,
          headers: { "Content-Type": "multipart/form-data" },
        });

        imageUrl.current = data.imgUrl;
      } else {
        imageUrl.current = props.toEditCategory.icon;
      }
      // console.log("Image url", imageUrl.current);
      await axios.put(
        VENDOR_CATEGORY,
        {
          id: props.toEditCategory.id,
          name: nameTextFieldRef.current.value,
          icon: imageUrl.current,
        },
        COOKIES_SETTINGS
      );

      setLoading(false);

      // console.log(submitResponse);
      handleClose();
      props.successfullyChanged();
    } catch (error) {
      setLoading(false);
   //   console.log(error.response.data.message);
      setValidForm(false);
      setValidationMessage(error.response.data.message);
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { borderRadius: 15 },
        }}
      >
        <Box
          width={{ xs: 290, sm: 500, md: 600, lg: 600 }}
          sx={{ overflowX: "hidden" }}
        >
          <DialogContent sx={{ padding: "0", width: "100%" }}>
            <DialogTitle color="primary">Add Vendor Category</DialogTitle>
            <Divider />
            <Stack display={"flex"} alignItems={"center"}>
              {!validForm && (
                <Alert severity="error" sx={{ width: "80%", margin: "1rem" }}>
                  {validationMessage}
                </Alert>
              )}
              <Box padding={1}>
                <img
                  src={showImage()}
                  alt="icon"
                  width={"100%"}
                  height={200}
                  loading="lazy"
                />
              </Box>
              <Box
                width={"100%"}
                padding={"1rem"}
                display={"flex"}
                justifyContent={"center"}
              >
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    onChange={(e) => {
                      setSelectedImage(e.target.files[0]);
                      setValidForm(true);
                      imageUrl.current = null;
                    }}
                  />
                  <Button
                    disabled={loading}
                    variant="contained"
                    component="span"
                  >
                    Select image
                  </Button>
                </label>
              </Box>
              <TextField
                autoFocus
                id="name"
                label="Name"
                type="name"
                required
                variant="outlined"
                sx={{ width: "80%", margin: "1rem" }}
                inputRef={nameTextFieldRef}
                defaultValue={
                  props.toEditCategory === null ? "" : props.toEditCategory.name
                }
              />
            </Stack>

            <Divider></Divider>
          </DialogContent>
        </Box>
        <DialogActions sx={{ padding: "1rem" }}>
          <Button onClick={handleClose} variant="text" color="error">
            Cancel
          </Button>

          {loading ? (
            <Button
              disabled={"true"}

              variant="contained"
              color="primary"
              sx={{ backgroundColor: "rgba(0,0,0,0)" }}
            >

              <CircularProgress size={"1.3rem"} />
            </Button>
          )

            :
            <Button
              disabled={loading}
              onClick={props.toEditCategory === null ? addCategory : editCategory}
              type="submit"
              variant="contained"
              color="primary"
            >{
                props.toEditCategory === null ? (
                  "Add"
                ) : (
                  "Edit"
                )}
            </Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
}
