import Alert from "@mui/material/Alert";
import {
  Button,
  FormControl,
  FormHelperText,
  OutlinedInput,
  TextField,
  InputLabel,
  Grid
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, useContext } from "react";
// third party
import * as Yup from "yup";
import { Formik } from "formik";


import axios from "axios";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { ProfileContext } from "../../../context/ProfileContext";
import { createWebsite } from "../../invitations/InvitationsApiFunctions";
const WebsiteInfoForm = (props) => {
  const profile = useContext(ProfileContext);
  const [loading, setLoading] = useState(false);
  const outlinedInputStyle = {
    marginBottom: "10px",
    background: "#F8FAFD",
    border: "1px solid #E7ECF3",
    borderRadius: "10px",
    color: "#000",
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E7ECF3',
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: '#E7ECF3',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E7ECF3',

    },
    fieldSet: {
      legend: {
        width: "unset",
      }
    }
  };
  //console.log(profile);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Formik
        initialValues={{
          brideFirstName: profile.wedding.brideFirstName,
          brideLastName: profile.wedding.brideLastName,
          groomFirstName: profile.wedding.groomFirstName,
          groomLastName: profile.wedding.groomLastName,
          weddingDate: profile.wedding.weddingDate,
          city: "",
          area: "",
          rsvpby: "",
          submit: null,
        }}
        validationSchema={
          Yup.object().shape({
            brideFirstName: Yup.string().max(255).required("Bride's First name is required"),
            brideLastName: Yup.string().max(255).required("Bride's Last name is required"),
            groomFirstName: Yup.string().max(255).required("Groom's First name is required"),
            groomLastName: Yup.string().max(255).required("Groom's Last name is required"),
            weddingDate: Yup.date().required("Wedding Date is required"),
            city: Yup.string().max(255).required("City is required"),
            area: Yup.string().max(255).required("Area is required"),
            rsvpby: Yup.date().required("RSVP due date is required"),
          })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {

          setLoading(true);
          try {
            const response = await createWebsite({
              groomFirstName: values.groomFirstName,
              groomLastName: values.groomLastName,
              brideFirstName: values.brideFirstName,
              brideLastName: values.brideLastName,
              weddingDate: values.weddingDate.toString(),
              RSVPBy: values.rsvpby.toString(),
              RSVPsIsVisible: true,
              area: values.area,
              city: values.city
            });
            if (response.status == 200) {
              setStatus({ success: true });
              window.location.reload();
              document.getElementById("website-info-dialog-main").style.display = "none";

            }
          } catch (error) {
            setStatus({ success: false });
            setErrors({ submit: error.response.data.message });
          } finally {
            setLoading(false);
          }

        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container justifyContent="center" alignItems="center">
              {errors.submit && (
                <Alert severity="error" sx={{ width: "100%", margin: "1rem" }}>
                  {errors.submit}
                </Alert>
              )}

              {/* Bride first name */}
              <FormControl
                fullWidth
                error={Boolean(touched.brideFirstName && errors.brideFirstName)}
                sx={{ margin: "0.2rem 1rem" }}
              >
                <InputLabel
                  htmlFor="outlined-adornment-brideFirstName"
                  sx={{ color: "text.lightGray" }}
                >
                  Bride's First Name
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-brideFirstName"
                  type="text"
                  value={values.brideFirstName}
                  name="brideFirstName"
                  // placeholder={"Bride's First Name"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={outlinedInputStyle}
                />
                {touched.brideFirstName && errors.brideFirstName && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-brideFirstName"
                  >
                    {errors.brideFirstName}
                  </FormHelperText>
                )}
              </FormControl>

              {/* Bride last name */}
              <FormControl
                fullWidth
                error={Boolean(touched.brideLastName && errors.brideLastName)}
                sx={{ margin: "0.2rem 1rem" }}
              >
                <InputLabel
                  htmlFor="outlined-adornment-brideLastName"
                  sx={{ color: "text.lightGray" }}
                >
                  Bride's Last Name
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-brideLastName"
                  type="text"
                  value={values.brideLastName}
                  name="brideLastName"
                  placeholder={"Bride's Last Name"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={outlinedInputStyle}
                />
                {touched.brideLastName && errors.brideLastName && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-brideLastName"
                  >
                    {errors.brideLastName}
                  </FormHelperText>
                )}
              </FormControl>


              {/* Groom first name */}
              <FormControl
                fullWidth
                error={Boolean(touched.groomFirstName && errors.groomFirstName)}
                sx={{ margin: "0.2rem 1rem" }}
              >
                <InputLabel
                  htmlFor="outlined-adornment-groomFirstName"
                  sx={{ color: "text.lightGray" }}
                >
                  Groom's First Name
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-groomFirstName"
                  type="text"
                  value={values.groomFirstName}
                  name="groomFirstName"
                  placeholder={"Groom's First Name"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Groom's First Name"
                  sx={outlinedInputStyle}
                />
                {touched.groomFirstName && errors.groomFirstName && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-groomFirstName"
                  >
                    {errors.groomFirstName}
                  </FormHelperText>
                )}
              </FormControl>

              {/* Groom last name */}
              <FormControl
                fullWidth
                error={Boolean(touched.groomLastName && errors.groomLastName)}
                sx={{ margin: "0.2rem 1rem" }}
              >
                <InputLabel
                  htmlFor="outlined-adornment-groomLastName"
                  sx={{ color: "text.lightGray" }}
                >
                  Groom's Last Name
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-groomLastName"
                  type="text"
                  value={values.groomLastName}
                  name="groomLastName"
                  placeholder={"Groom's Last Name"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={outlinedInputStyle}
                />
                {touched.groomLastName && errors.groomLastName && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-groomLastName"
                  >
                    {errors.groomLastName}
                  </FormHelperText>
                )}
              </FormControl>

              <InputLabel
                sx={{ color: "#000", width: "100%", margin: "0.5rem 0.5rem 0rem 1.2rem" }}
              >
                Wedding Date
              </InputLabel>
              {/* Wedding date  */}
              <FormControl
                fullWidth
                error={Boolean(touched.weddingDate && errors.weddingDate)}
                variant="outlined"
                sx={{ margin: "0.2rem 1rem" }}>
                <DateTimePicker
                  value={values["weddingDate"]}
                  onChange={(value) => { setFieldValue("weddingDate", value); }}
                  minDate={new Date()}
                  renderInput={(params) =>
                    <TextField
                      id="outlined-adornment-weddingDate"
                      variant="outlined"
                      name="weddingDate"
                      sx={{
                        marginBottom: "10px",
                        background: "#F8FAFD",
                        borderRadius: "10px",
                        border: "1px solid #E7ECF3",
                        '& .MuiOutlinedInput-root': {
                          '& fieldset.MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                        },
                        fieldSet: {
                          legend: {
                            width: "unset",
                          }
                        }
                      }
                      } {...params} />}
                />
                {touched.weddingDate && errors.weddingDate && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-weddingDate"
                  >
                    {errors.weddingDate}
                  </FormHelperText>
                )}
              </FormControl>

              {/* Area */}
              <FormControl
                fullWidth
                error={Boolean(touched.area && errors.area)}
                sx={{ margin: "0.2rem 1rem" }}
              >
                <InputLabel
                  htmlFor="outlined-adornment-area"
                  sx={{ color: "text.lightGray" }}
                >
                  Area
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-area"
                  type="text"
                  value={values.area}
                  name="area"
                  placeholder={"Area"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={outlinedInputStyle}
                />
                {touched.area && errors.area && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-area"
                  >
                    {errors.area}
                  </FormHelperText>
                )}
              </FormControl>


              {/* City */}
              <FormControl
                fullWidth
                error={Boolean(touched.city && errors.city)}
                sx={{ margin: "0.2rem 1rem" }}
              >
                <InputLabel
                  htmlFor="outlined-adornment-city"
                  sx={{ color: "text.lightGray" }}
                >
                  City
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-city"
                  type="text"
                  value={values.city}
                  name="city"
                  placeholder={"City"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={outlinedInputStyle}
                />
                {touched.city && errors.city && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-city"
                  >
                    {errors.city}
                  </FormHelperText>
                )}
              </FormControl>


              <InputLabel
                sx={{ color: "#000", width: "100%", margin: "0.5rem 0.5rem 0rem 1.2rem" }}
              >
                RSVP by
              </InputLabel>
              {/* Rsvpby  */}
              <FormControl
                fullWidth
                error={Boolean(touched.rsvpby && errors.rsvpby)}
                variant="outlined"
                sx={{ margin: "0.2rem 1rem" }}>
                <DatePicker
                  value={values["rsvpby"]}
                  onChange={(value) => { setFieldValue("rsvpby", value); }}
                  minDate={new Date()}
                  renderInput={(params) =>
                    <TextField
                      id="outlined-adornment-rsvpby"
                      variant="outlined"
                      name="rsvpby"
                      sx={{
                        marginBottom: "10px",
                        background: "#F8FAFD",
                        borderRadius: "10px",
                        border: "1px solid #E7ECF3",
                        '& .MuiOutlinedInput-root': {
                          '& fieldset.MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                        },
                        fieldSet: {
                          legend: {
                            width: "unset",
                          }
                        }
                      }
                      } {...params} />}
                />
                {touched.rsvpby && errors.rsvpby && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-rsvpby"
                  >
                    {errors.rsvpby}
                  </FormHelperText>
                )}
              </FormControl>

              <Grid item xs={11}>
                <Button
                  fullWidth
                  disabled={loading}
                  size="medium"
                  type="submit"
                  variant="contained"
                  sx={{
                    padding: "0.7rem 0rem",
                    color: "text.white",
                    backgroundColor: "text.tertiary",
                    ":hover": {
                      backgroundColor: "text.tertiaryHover",
                    },
                    borderRadius: "48px",
                    textTransform: "none",
                    fontSize: "1.2rem",
                    marginBottom: "1.2rem"
                  }}
                >
                  {loading ? (
                    <CircularProgress sx={{ color: "#FFF" }} size={"1.8rem"} />
                  ) : (
                    "Submit"
                  )}
                </Button>

              </Grid>


            </Grid>
          </form>
        )}
      </Formik>
    </LocalizationProvider>

  );
};

export default WebsiteInfoForm;
