import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";

import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CustomizedSnackbars from "../../ui-common-components/ui-components/SnackBar";
import axios from "axios";

import FormDialog from "./FormDialog";
import {
  COOKIES_SETTINGS,
  VENDOR_CATEGORY,
  TOGGLE_HIDE_VENDOR_CATEGORY,
  TOGGLE_DELETE_VENDOR_CATEGORY,
} from "../../endpoints/Constants";
import { CircularProgress, Stack } from "@mui/material";
import AlertDialogSlide from "../../ui-common-components/ui-components/AlertDialogSlide";

const VendorCategories = () => {
  const navigate = useNavigate();

  const openAlertDialog = useRef(null);
  const openFormDialog = useRef(null);
  const openSnackBar = useRef(null);
  const [vendorCategories, setVendorCategories] = useState([]);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSevirity, setSnackBarSevirity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [actionText, setActionText] = useState("");
  const [toggleHideId, setToggleHideId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [toEditCategory, setToEditCategory] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    getVendorCategories();
  }, []);

  /*useEffect(() => {
        openFormDialog.current();

    }, [toEditCategory]);*/

  const successfullyChanged = () => {
    getVendorCategories();
    setSnackBarMessage("Success");
    setSnackBarSevirity("success");
    openSnackBar.current();
  };
  const confirmToggleHide = async () => {
    // console.log(toggleHideId);
    try {
      await axios.put(
        TOGGLE_HIDE_VENDOR_CATEGORY,
        {
          id: toggleHideId,
        },
        COOKIES_SETTINGS
      );
      getVendorCategories();
      setSnackBarMessage("Vendor category visibility is successfully toggled");
      setSnackBarSevirity("success");
      openSnackBar.current();
    } catch (error) {
    //  console.log(error);
      setSnackBarMessage("An error occcured");
      setSnackBarSevirity("error");
      openSnackBar.current();
    }
    setToggleHideId(null);
  };
  const confirmDelete = async () => {
    // console.log(deleteId);
    try {
      await axios.put(
        TOGGLE_DELETE_VENDOR_CATEGORY,
        {
          id: deleteId,
        },
        COOKIES_SETTINGS
      );
      getVendorCategories();
      setSnackBarMessage("Vendor category is deleted successfully");
      setSnackBarSevirity("success");
      openSnackBar.current();
    } catch (error) {
   //   console.log(error);
      setSnackBarMessage("An error occcured");
      setSnackBarSevirity("error");
      openSnackBar.current();
    }
    setDeleteId(null);
  };
  const getVendorCategories = async () => {
    setPageLoading(true);
    try {
      const response = await axios.get(
        VENDOR_CATEGORY + "?isDeleted=false",
        COOKIES_SETTINGS
      );
      // console.log("Vendor categories", response);
      setVendorCategories(response.data);
    } catch (error) {
    //  console.log(error);
    }
    setPageLoading(false);
  };
  function removeTime(date) {
    return date.split("T")[0];
  }
  return (
    <Grid container spacing={0} alignItems="start" justifyContent="center">
      <AlertDialogSlide
        confirmAlertRef={deleteId === null ? confirmToggleHide : confirmDelete}
        openAlertDialog={openAlertDialog}
        title={"Alert"}
        description={alertMessage}
        actionText={actionText}
        severity={snackBarSevirity}
      />
      <FormDialog
        successfullyChanged={successfullyChanged}
        openFormDialog={openFormDialog}
        toEditCategory={toEditCategory}
      />
      <CustomizedSnackbars
        openSnackBar={openSnackBar}
        message={snackBarMessage}
        severity={snackBarSevirity}
      />

      <Grid item xs={12}>
        <Stack
          direction={{ xs: "column", md: "row", sm: "row", lg: "row" }}
          height={{ xs: 180, sm: 60, md: 60, lg: 60 }}
          borderRadius={2}
          sx={{ backgroundColor: "#FFF" }}
          display={"flex"}
          alignContent={"center"}
          justifyContent={{
            md: "space-between",
            sm: "space-evenly",
            xs: "center",
          }}
          alignItems={"center"}
        >
          <Typography
            margin={1}
            marginLeft={{ sm: 1, md: 3, lg: 3 }}
            color={"primary.main"}
            fontSize={20}
            fontWeight={"bold"}
          >
            Vendor categories
          </Typography>
          <Stack direction={{ xs: "column", sm: "row", md: "row", lg: "row" }}>
            <Button
              sx={{ margin: "0.5rem" }}
              color="error"
              variant="contained"
              startIcon={<DeleteIcon />}
              onClick={() => navigate("./recycle-bin")}
            >
              Recycle bin
            </Button>
            <Button
              sx={{ margin: "0.5rem" }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                if (toEditCategory == null) {
                  openFormDialog.current();
                }
                setToEditCategory(null);
              }}
            >
              Add category
            </Button>
          </Stack>
        </Stack>
      </Grid>

      {pageLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        
          vendorCategories.length===0?<Typography>No Vendor categories</Typography>:
        vendorCategories.map((category, index) => {
          return (
            <Grid
              textAlign={"right"}
              key={index}
              item
              xs={12}
              sm={5}
              md={3}
              lg={2}
              margin={1}
            >
              <IconButton
                onClick={() => {
                  setToggleHideId(category._id);

                  if (category.isHidden) {
                    setActionText("Unhide");
                    setAlertMessage(
                      "Are you sure you want to Unhide this vendor catgory"
                    );
                  } else {
                    setActionText("Hide");
                    setAlertMessage(
                      "Are you sure you want to Hide this vendor catgory"
                    );
                  }

                  openAlertDialog.current();
                }}
                sx={{
                  poisiton: "relative",
                  top: 45,
                  right: "5px",
                  zIndex: 1,
                  backgroundColor: "rgba(0,0,0,0.4)",
                }}
                aria-label="edit"
              >
                {category.isHidden ? (
                  <VisibilityOffIcon sx={{ color: "#FFF" }} />
                ) : (
                  <VisibilityIcon sx={{ color: "#FFF" }} />
                )}
              </IconButton>
              <ImageListItem
                key={index}
                sx={{
                  height: "200px!important",
                  width: "100%",
                }}
              >
                <img
                  src={category.icon}
                  alt={category.title}
                  height="200px"
                  width="300"
                  loading="lazy"
                  style={{ borderRadius: "10px" }}
                />
                <ImageListItemBar
                  sx={{ textAlign: "left", borderRadius: "10px" }}
                  title={
                    <Typography
                      color={"#FFF"}
                      fontSize={"1.3rem"}
                      fontWeight={"bold"}
                      noWrap={true}
                    >
                      {category.name}
                    </Typography>
                  }
                  subtitle={
                    <Typography
                      variant="body2"
                      color="#FFF"
                      noWrap={true}
                      fontSize={"0.7rem"}
                      fontStyle={"italic"}
                    >
                      Created at: {removeTime(category.createdAt)}
                    </Typography>
                  }
                  actionIcon={
                    <Stack direction={"row"}>
                      <IconButton
                        aria-label="edit"
                        onClick={() => {
                          setToEditCategory({
                            name: category.name,
                            icon: category.icon,
                            id: category._id,
                          });
                          openFormDialog.current();
                        }}
                      >
                        <EditIcon sx={{ color: "#FFF" }} />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          setDeleteId(category._id);

                          setActionText("Delete");
                          setAlertMessage(
                            "Are you sure you want to delete this vendor catgory"
                          );

                          openAlertDialog.current();
                        }}
                      >
                        <DeleteIcon sx={{ color: "#F00" }} />
                      </IconButton>
                    </Stack>
                  }
                />
              </ImageListItem>
            </Grid>
          );
        })
      )}
    </Grid>
  );
};

export default VendorCategories;
