import { AuthComponentsContext } from "../../../context/AuthComponentsContext";

import { Box, Grid, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useState, useContext } from "react";
// third party
import * as Yup from "yup";
import { Formik } from "formik";

// assets
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { USER_LOGIN, COOKIES_SETTINGS } from "../../../endpoints/Constants";
import axios from "axios";
import GoogleBtn from "../common-btns/GoogleBtn";
import FacebookBtn from "../common-btns/FacebookBtn";
import AppleBtn from "../common-btns/AppleBtn";

const LoginForm = (props) => {
  const authComponents = useContext(AuthComponentsContext);

  //console.log(props);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          setLoading(true);
          // console.log(values);
          let formData = {
            email: values.email,
            password: values.password,
          };
          axios
            .post(USER_LOGIN, formData, COOKIES_SETTINGS)
            .then((res) => {
              setLoading(false);

              if (res.status === 200) {
                setStatus({ success: true });
                window.location.reload();
              } else {
                setStatus({ success: false });
                setErrors({ submit: res.data.message });
              }
            })
            .catch((err) => {
              setLoading(false);
              setStatus({ success: false });
              setErrors({ submit: err.response.data.message });
            });
        } catch (err) {
          //setLoading(false);
          setStatus({ success: false });
          setErrors({ submit: "Error" });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container justifyContent="center" alignItems="center">
            {errors.submit && (
              <Alert severity="error" sx={{ width: "100%", margin: "1rem" }}>
                {errors.submit}
              </Alert>
            )}
            <FormControl
              fullWidth
              error={Boolean(touched.email && errors.email)}
              sx={{ margin: "0.5rem 1rem" }}
            >
              <InputLabel
                htmlFor="outlined-adornment-email-login"
                sx={{ color: "text.lightGray" }}
              >
                Email
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-email-login"
                type="email"
                value={values.email}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                label="Email"
                inputProps={{}}
                style={{
                  color: "#000",
                  borderRadius: "10px",
                }}
              />
              {touched.email && errors.email && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-email-login"
                >
                  {errors.email}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              fullWidth
              error={Boolean(touched.password && errors.password)}
              sx={{ margin: "0.5rem 1rem" }}
            >
              <InputLabel
                htmlFor="outlined-adornment-password-login"
                sx={{ color: "text.lightGray" }}
              >
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password-login"
                type={showPassword ? "text" : "password"}
                value={values.password}
                name="password"
                autoComplete="on"
                onBlur={handleBlur}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                inputProps={{}}
                style={{
                  color: "#000",
                  borderRadius: "10px",
                }}
              />
              {touched.password && errors.password && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-password-login"
                >
                  {errors.password}
                </FormHelperText>
              )}
              <Box width="fit-content">
                <Typography
                  color="text.tertiary"
                  sx={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    textAlign: "left",
                    ":hover": {
                      color: "text.tertiaryHover",
                    },
                    width: "100%",
                    paddingTop: "1rem",
                  }}
                  onClick={() => {
                    authComponents.openForgetPassword();
                  }}
                >
                  Forgot Password?
                </Typography>
              </Box>
            </FormControl>

            <Grid item xs={11}>
              <Button
                fullWidth
                disabled={loading}
                size="medium"
                type="submit"
                variant="contained"
                sx={{
                  padding: "0.7rem 0rem",
                  color: "text.white",
                  backgroundColor: "text.tertiary",
                  ":hover": {
                    backgroundColor: "text.tertiaryHover",
                  },
                  borderRadius: "48px",
                  textTransform: "none",
                  fontSize: "1.2rem",
                  marginBottom: "1.2rem",
                }}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "#FFF" }} size={"1.8rem"} />
                ) : (
                  "Sign In"
                )
                }
              </Button>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "2rem",
                }}
              >
                <GoogleBtn />

                <FacebookBtn />

                <AppleBtn />
              </Box>

              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "bold",
                    marginBottom: "2rem",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      textAlign: "left",
                      fontSize: { xs: "0.9rem", md: "1rem" },
                    }}
                  >
                    {"Don't have an account?"}
                  </Typography>

                  <Typography
                    color="text.tertiary"
                    sx={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      textAlign: "left",
                      ":hover": {
                        color: "text.tertiaryHover",
                      },
                      paddingLeft: "4px",
                      fontSize: { xs: "0.9rem", md: "1rem" },
                    }}
                    onClick={() => {
                      authComponents.openSignup();
                    }}
                  >
                    {"Register Now"}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;
