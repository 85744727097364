
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, Route, Routes, Link } from "react-router-dom";
import './TemplateFour.css';
import { PhoneContext } from "../../context/PhoneContext";
import { Box, CircularProgress } from "@mui/material";
import { COOKIES_SETTINGS, GET_TEMPLATE_DETAILS, } from "../../endpoints/Constants";
import axios from "axios";
import {
  checkUrl,
  InvitationPressed_common,
  QuestionsPressed_common,
  RSVPPressed_common,
  GuestBoardPressed_common,
  InvitationPressedMob_common,
  QuestionsPressedMob_common,
  RSVPPressedMob_common,
  GuestBoardPressedMob_common,
  checkSelectedBtnInNavbar,
} from "../TemplatesCommonFunctions"
import TemplateFourQuestions from "./TemplateFourQuestions";
import TemplateFourRSVP from "./TemplateFourRSVP";
import TemplateFourGuestBoard from "./TemplateFourGuestBoard";
import TemplateFourInvitation from "./TemplateFourInvitation";




const TemplateFour = () => {
  const phone = useContext(PhoneContext);
  phone.state.templateMainColor = "#59755C";
  const [templateData, setTemplateData] = useState(null);
  const [tabLoading, setTabLoading] = useState(true);
  const navigate = useNavigate();
  const { weddingId } = useParams();
  const [templateID, setTemplateID] = useState(-1);

  useEffect(() => {
    if (weddingId) {
      getTemplateDetails(weddingId);
    } else {
      navigate("../");
    }
  }, []);

  useEffect(() => {
    if (!tabLoading) {
      checkSelectedBtnInNavbar();
    }
  }, [tabLoading])


  const getTemplateDetails = async (id) => {
    setTabLoading(true);
    try {
      axios.get(GET_TEMPLATE_DETAILS + `/${id}`, COOKIES_SETTINGS).then(async (res) => {
        await checkUrl("love-and-leaves", res.data.weddingWebsite.weddingTemplate.uniqueName, res.data.uniqueName);
        setTemplateData(res.data);
        setTemplateID(res.data._id);
        setTabLoading(false);
      })
        .catch((err) => {
          navigate("../not-found");
        });
    } catch (error) {
      setTabLoading(false);
      navigate("../not-found");
    }
  };

  return (

    <>
      {tabLoading && !templateData ? (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress
            style={{ 'color': phone.state.templateMainColor }}
          />
        </Box>
      ) : (
        <div id="template-four-dev" className="template-four-home-dev">

          <nav id="navbar-temp-four" className="navbar navbar-dark bg-white" >
            <div className="container-fluid">
              <button className="navbar-toggler shadow-none" style={{ borderColor: "rgb(0,0,0,0)", position: 'absolute', left: 0 }} type="button" data-bs-toggle="dropdown" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon white"></span>
              </button>
              <div className='d-flex justify-content-center w-100'>

                <h2 className='navbar-intials mb-0 pt-2'>
                  {templateData.brideFirstName[0]}&{templateData.groomFirstName[0]}
                </h2>
              </div>
              <ul className="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
                <Link to={"/love-and-leaves/" + weddingId}>
                  <li onClick={InvitationPressedMob_common} className="dropdown-item "><p className='' id='mob-nav-first-element'>INVITATION</p></li>
                </Link>
                {templateData.weddingWebsite.QAIsVisible &&
                  <Link to={"/love-and-leaves/" + weddingId+"/questions" }>
                    <li onClick={QuestionsPressedMob_common} className="dropdown-item"><p id='mob-nav-second-element'>QUESTIONS</p></li>
                  </Link>
                }
                {templateData.weddingWebsite.RSVPsIsVisible &&
                  <Link to={"/love-and-leaves/" + weddingId+"/rsvp"}>
                    <li onClick={RSVPPressedMob_common} className="dropdown-item"><p id='mob-nav-third-element'>RSVP</p></li>
                  </Link>
                }
                {templateData.weddingWebsite.GuestBoardIsVisible &&
                  <Link to={"/love-and-leaves/" + weddingId+"/guest-board"}>
                    <li onClick={GuestBoardPressedMob_common} className="dropdown-item"><p id='mob-nav-fourth-element'>GUEST BOARD</p></li>
                  </Link>
                }
              </ul>
            </div>
          </nav>

          <div className="container-fluid inner-container">
            <div id="template-four-navbar" className="row w-100">

              <div id="navbar-col" className="col-10 ">

                <div id="navbar-main-row" className="d-flex flex-row justify-content-around" >
                  <div onClick={InvitationPressed_common} id="first-element-navbar" className="w-25">
                    <Link to={"/love-and-leaves/" + weddingId+""}>

                      <h2 className="text-center">
                        INVITATION
                      </h2>
                    </Link>
                  </div>
                  {templateData.weddingWebsite.QAIsVisible &&
                    <div onClick={QuestionsPressed_common} className="w-25" id="second-element-navbar">
                      <Link to={"/love-and-leaves/" + weddingId+"/questions"}>

                        <h2 className="text-center">
                          QUESTIONS
                        </h2>
                      </Link>
                    </div>
                  }
                  {templateData.weddingWebsite.RSVPsIsVisible &&
                    <div onClick={RSVPPressed_common} className="w-25" id="third-element-navbar">
                      <Link to={"/love-and-leaves/" + weddingId+"/rsvp"}>

                        <h2 className="text-center">
                          RSVP
                        </h2>
                      </Link>
                    </div>
                  }
                  {templateData.weddingWebsite.GuestBoardIsVisible &&
                    <div onClick={GuestBoardPressed_common} className="w-25 " id="fourth-element-navbar">
                      <Link to={"/love-and-leaves/" + weddingId+"/guest-board"}>

                        <h2 className="text-center">
                          GUEST BOARD
                        </h2>
                      </Link>
                    </div>
                  }
                </div>

              </div>

            </div>

            <Routes>
              <Route index element={<TemplateFourInvitation templateData={templateData}  weddingId={weddingId}/>} />
              {templateData.weddingWebsite.QAIsVisible &&
                <Route
                  path="/questions"
                  element={
                    <TemplateFourQuestions
                      id={templateData._id}

                    />
                  }
                />
              }
              {templateData.weddingWebsite.RSVPsIsVisible &&
                <Route
                  path="/rsvp"
                  element={<TemplateFourRSVP id={templateData._id} />}
                />
              }
              {templateData.weddingWebsite.GuestBoardIsVisible &&
                <Route
                  path="/guest-board"
                  element={<TemplateFourGuestBoard id={templateData._id} />}
                />
              }
            </Routes>
          </div>
        </div>
      )
      }
    </>
  )
}

export default TemplateFour;
