import React, { useEffect, useContext, useState } from "react";
import Box from "@mui/material/Box";
import { Grid, Link, Typography } from "@mui/material";
import axios from "axios";
import {
  COOKIES_SETTINGS,
  GET_WEBSITE_VENDOR_CATEGORY,
} from "../../endpoints/Constants";
import DownloadAppSection from "./DownloadAppSection";

const DesktopFooter = () => {
  const [vendorCategoriesFirstHalf, setVendorCategoriesFirstHalf] = useState(
    []
  );
  const [vendorCategoriesSecondHalf, setVendorCategoriesSecondHalf] = useState(
    []
  );

  useEffect(() => {
    axios
      .get(GET_WEBSITE_VENDOR_CATEGORY)
      .then((response) => {
        // console.log(response.data);
        const data = response.data;

        const middleIndex = Math.ceil(data.length / 2);

        setVendorCategoriesFirstHalf(data.splice(0, middleIndex));
        setVendorCategoriesSecondHalf(data.splice(-middleIndex));
      })
      .catch((error) => {
        //console.log(error);
      });
  }, []);

  const planningTools = [
    {
      name: "Journey",
      url: "/",
    },
    {
      name: "Checklist",
      url: "/checklist",
    },
    {
      name: "Guestlist",
      url: "/guests",
    },
  ];

  const areYouAVendor = [
    {
      name: "Contact us",
      url: "/contact-us",
    },
  ];

  return (
    <Box
      sx={{       
        color: "text.light",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "95%",
          marginTop: "2rem",
        }}
      >
        <Grid container spacing={2}>
          <Grid container item sm={12} md={8}>
            <Grid container item sm={12} md={12} lg={6}>
              <Grid item xs={12}>
                <Typography
                  color={"text.white"}
                  fontSize={{
                    xs: "1rem",
                    sm: "1.1rem",
                    md: "1.7vw",
                    lg: "1.25vw",
                  }}
                  textAlign="left"
                  marginBottom={"1rem"}
                  marginTop="1rem"
                  textTransform="uppercase"
                  fontWeight="600"
                >
                  Vendor categories
                </Typography>
              </Grid>

              <Grid item xs={6}>
                {vendorCategoriesFirstHalf.map((category, index) => {
                  return (
                    <Link
                      key={"category-"+index}
                      href={"/vendor-category/"+category._id}
                      underline="none"
                      sx={{
                        color: "text.darkBlue",
                        width: "100%",
                        "&:hover": {
                          color: "#0c2950",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          margin: "0.5rem 0rem",
                        }}
                      >
                        {category.name}
                      </Typography>
                    </Link>
                  );
                })}
              </Grid>

              <Grid item xs={6}>
                {vendorCategoriesSecondHalf.map((category, index) => {
                  return (
                    <Link
                      key={"item-"+index}
                      href={"/vendor-category/"+category._id}
                      underline="none"
                      sx={{
                        color: "text.darkBlue",
                        width: "100%",
                        "&:hover": {
                          color: "#0c2950",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          margin: "0.5rem 0rem",
                        }}
                      >
                        {category.name}
                      </Typography>
                    </Link>
                  );
                })}
              </Grid>
            </Grid>

            <Grid container item xs={12} lg={6}>
              <Grid container item xs={6} sx={{ alignContent: "flex-start" }}>
                <Typography
                  color={"text.white"}
                  fontSize={{
                    xs: "1rem",
                    sm: "1.1rem",
                    md: "1.7vw",
                    lg: "1.25vw",
                  }}
                  textAlign="left"
                  marginBottom={"1rem"}
                  marginTop="1rem"
                  textTransform="uppercase"
                  fontWeight="600"
                >
                  Planning tools
                </Typography>
                <Grid item xs={12}>
                  {planningTools.map((tool, index) => {
                    return (
                      <Link
                        key = {"tool-"+index}
                        href={tool.url}
                        underline="none"
                        sx={{
                          color: "text.darkBlue",
                          width: "100%",
                          "&:hover": {
                            color: "#0c2950",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            margin: "0.5rem 0rem",
                          }}
                        >
                          {tool.name}
                        </Typography>
                      </Link>
                    );
                  })}
                </Grid>
              </Grid>

              <Grid container item xs={6} sx={{ alignContent: "flex-start" }}>
                <Typography
                  color={"text.white"}
                  fontSize={{
                    xs: "1rem",
                    sm: "1.1rem",
                    md: "1.7vw",
                    lg: "1.25vw",
                  }}
                  textAlign="left"
                  marginBottom={"1rem"}
                  marginTop="1rem"
                  textTransform="uppercase"
                  fontWeight="600"
                >
                  ARE YOU A VENDOR
                </Typography>
                <Grid item xs={12}>
                  {areYouAVendor.map((item, index) => {
                    return (
                      <Link
                        key={"vendor-"+index}
                        href={item.url}
                        underline="none"
                        sx={{
                          color: "text.darkBlue",
                          width: "100%",
                          "&:hover": {
                            color: "#0c2950",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            margin: "0.5rem 0rem",
                          }}
                        >
                          {item.name}
                        </Typography>
                      </Link>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>


          <DownloadAppSection/>


        </Grid>
      </Box>
    </Box>
  );
};
export default DesktopFooter;
