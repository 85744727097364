import {
  Grid
} from "@mui/material";
import Alert from "@mui/material/Alert";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
// third party
import * as Yup from "yup";
import { Formik } from "formik";

// assets
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import {
  USER_FORGETPW,
  USER_RESETPW,
  COOKIES_SETTINGS,
} from "../../../endpoints/Constants";
import axios from "axios";

import SwipeableViews from "react-swipeable-views";

const ForgetPasswordForm = ({ ...others }) => {
  const [emailState, setEmailState] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  //const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  /*const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showPassword);
  };*/

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  /*const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };*/

  const [activeStep, setActiveStep] = useState(0);
  const handleStepChange = (step) => {
    // console.log(step);
    setActiveStep(step);
  };

  return (
    <SwipeableViews
      axis={"x"}
      index={activeStep}
      onChangeIndex={handleStepChange}
      disabled={true}
    >
      <Formik
        initialValues={{
          email: "",
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setLoading(true);
            // console.log(values);
            let formData = {
              email: values.email,
            };
            axios
            .post(USER_FORGETPW, formData, COOKIES_SETTINGS)
            .then((res) => {
              setLoading(false);

              // console.log(res);

              if (res.status === 200) {
                setEmailState(values.email);
                setStatus({ success: true });
                handleStepChange(1);
              } else {
                setStatus({ success: false });
                setErrors({ submit: res.data.message });
              }
            })
            .catch((err) => {
              setLoading(false);
              setStatus({ success: false });
              setErrors({ submit: err.response.data.message });
            });
          } catch (err) {
            setLoading(false);
            setStatus({ success: false });
            setErrors({ submit: "Error" });
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            {...others}
            style={{ height: "100%" }}
          >
            <Grid container justifyContent="center" alignItems="center" height={"100%"}>
              {errors.submit && (
                <Alert severity="error" sx={{ width: "100%", margin: "1rem" }}>
                  {errors.submit}
                </Alert>
              )}

              <FormControl
                fullWidth
                error={Boolean(touched.email && errors.email)}
                sx={{ margin: "1rem" }}
              >
                <InputLabel
                  htmlFor="outlined-adornment-email-forget-password"
                  sx={{ color: "text.lightGray" }}
                >
                  Email
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-email-forget-password"
                  type="email"
                  value={values.email}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Email"
                  inputProps={{}}
                  style={{
                    color: "#000",
                    borderRadius: "10px",
                  }}
                />
                {touched.email && errors.email && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-email-forget-password"
                  >
                    {errors.email}
                  </FormHelperText>
                )}
              </FormControl>

              <Grid item xs={11}>
                <Button
                  fullWidth
                  disabled={loading}
                  size="medium"
                  type="submit"
                  variant="contained"
                  sx={{
                    padding: "0.7rem 0rem",
                    color: "text.white",
                    backgroundColor: "text.tertiary",
                    ":hover": {
                      backgroundColor: "text.tertiaryHover",
                    },
                    borderRadius: "48px",
                    textTransform: "none",
                    fontSize: "1.2rem",
                    marginBottom: "1.2rem",
                  }}
                >
                  {loading ? (
                    <CircularProgress sx={{ color: "#FFF" }} size={"1.8rem"} />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>

      <Formik
        initialValues={{
          code: "",
          password: "",
          confirmPassword: "",
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string().max(255).required("Code is required"),
          password: Yup.string().max(255).required("Password is required"),
          confirmPassword: Yup.string()
            .max(255)
            .required("Confirm Password is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          if (values.password !== values.confirmPassword) {
            setErrors({ confirmPassword: "Passwords don't match!" });
            return;
          }
          try {
            setLoading(true);
            // console.log(values);
            let formData = {
              email: emailState,
              resetCode: values.code,
              password: values.password,
            };
            axios
            .post(USER_RESETPW, formData, COOKIES_SETTINGS)
            .then((res) => {
              setLoading(false);

              // console.log(res);

              if (res.status === 200) {
                setStatus({ success: true });
                window.location.href = "/";
              }
            })
            .catch((err) => {
              setLoading(false);
              setStatus({ success: false });
              setErrors({ submit: err.response.data.message });
            });
          } catch (err) {
            setLoading(false);
            setStatus({ success: false });
            setErrors({ submit: "Error" });
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            {...others}
            style={{ height: "100%" }}
          >
            <Grid container justifyContent="center" alignItems="center" height={"100%"}>
              {errors.submit && (
                <Alert severity="error" sx={{ width: "100%", margin: "1rem" }}>
                  {errors.submit}
                </Alert>
              )}

              <FormControl
                fullWidth
                error={Boolean(touched.code && errors.code)}
                sx={{ margin: "1rem" }}
              >
                <InputLabel
                  htmlFor="outlined-adornment-code-forget-password"
                  sx={{ color: "text.lightGray" }}
                >
                  Code
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-code-forget-password"
                  type="text"
                  value={values.code}
                  name="code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Code"
                  inputProps={{}}
                  style={{
                    color: "#000",
                    borderRadius: "10px",
                  }}
                />
                {touched.code && errors.code && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-code-forget-password"
                  >
                    {errors.code}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl
                fullWidth
                error={Boolean(touched.password && errors.password)}
                sx={{ margin: "1rem" }}
              >
                <InputLabel
                  htmlFor="outlined-adornment-password-forget-password"
                  sx={{ color: "text.lightGray" }}
                >
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password-forget-password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="on"
                  value={values.password}
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Password"
                  inputProps={{}}
                  style={{
                    color: "#000",
                    borderRadius: "10px",
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? <VisibilityIcon /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {touched.password && errors.password && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-password-forget-password"
                  >
                    {errors.password}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl
                fullWidth
                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                sx={{ margin: "1rem" }}
              >
                <InputLabel
                  htmlFor="outlined-adornment-confirm-password-forget-password"
                  sx={{ color: "text.lightGray" }}
                >
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-confirm-password-forget-password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="on"
                  value={values.confirmPassword}
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  label="Confirm Password"
                  inputProps={{}}
                  style={{
                    color: "#000",
                    borderRadius: "10px",
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? <VisibilityIcon /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {touched.confirmPassword && errors.confirmPassword && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-confirm-password-forget-password"
                  >
                    {errors.confirmPassword}
                  </FormHelperText>
                )}
              </FormControl>


              <Grid item xs={11}>
                <Button
                  fullWidth
                  disabled={loading}
                  size="medium"
                  type="submit"
                  variant="contained"
                  sx={{
                    padding: "0.7rem 0rem",
                    color: "text.white",
                    backgroundColor: "text.tertiary",
                    ":hover": {
                      backgroundColor: "text.tertiaryHover",
                    },
                    borderRadius: "48px",
                    textTransform: "none",
                    fontSize: "1.2rem",
                    marginBottom: "1.2rem",
                  }}
                >
                  {loading ? (
                    <CircularProgress sx={{ color: "#FFF" }} size={"1.8rem"} />
                  ) : (
                    "Save new password"
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>

     

    </SwipeableViews>
  );
};

export default ForgetPasswordForm;

