
export const checkUrl = async (template,templateName,templateID)=>{
  
  if(templateName != template){
    console.log("template name: "+templateName);
    window.location.href = ('/'+templateName+"/"+templateID);
  }
}
  export const getDayName = (date)=> {
    const d2 = new Date(date)
    var weekdays = new Array(7);
    weekdays[0] = "Sunday";
    weekdays[1] = "Monday";
    weekdays[2] = "Tuesday";
    weekdays[3] = "Wednesday";
    weekdays[4] = "Thursday";
    weekdays[5] = "Friday";
    weekdays[6] = "Saturday";
    return weekdays[d2.getDay()];
  }
  export const getMonth = (date)=> {
    const d2 = new Date(date)
    var Months = new Array(12);
    Months[0] = "JAN";
    Months[1] = "FEB";
    Months[2] = "MAR";
    Months[3] = "APR";
    Months[4] = "MAY";
    Months[5] = "JUNE";
    Months[6] = "JULY";
    Months[7] = "AUG";
    Months[8] = "SEPT";
    Months[9] = "OCT";
    Months[10] = "NOV";
    Months[11] = "DEC";
    return Months[d2.getMonth()];
  }
  
  export const ordinalSuffixOf = (i)=> {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
  }
  export const getDayNumber = (date)=> {
    const d2 = new Date(date)
    return ordinalSuffixOf(d2.getDate());
  }
  
  
  export const getYear = (date)=> {
    const d2 = new Date(date)
    return d2.getFullYear();
  }
  
  export const getTime = (date)=> {
    const d2 = new Date(date)
    var hour    = d2.getHours();
  
    let timeSTR = ""
    if( hour == 0){
      timeSTR = "At 12"
    }
    else if (hour > 12) {
      timeSTR = "AT "+(hour - 12)
    }
    else{
      timeSTR = "AT "+hour 
    }
    if (hour > 18 & hour <= 6){
      timeSTR += " O’CLOCK IN THE EVENING";
    }
    else if(hour > 12 & hour <= 18){
      timeSTR += " O’CLOCK IN THE AFTERNOON";
    }
    else if(hour <= 12){
      timeSTR += " O’CLOCK IN THE MORNING";
    }
    return timeSTR;
  }

const navbarTranslation ={
  "invitation": "first-element-navbar",
  "questions": "second-element-navbar",
  "rsvp": "third-element-navbar",
  "guest-board": "fourth-element-navbar",
}
const navbarTranslationMobile ={
  "invitation": "mob-nav-first-element",
  "questions": "mob-nav-second-element",
  "rsvp": "mob-nav-third-element",
  "guest-board": "mob-nav-fourth-element",
}
export const checkSelectedBtnInNavbar =()=>{
  let selected = window.location.pathname.split("/")[3];
  if(selected){
    document.getElementById(navbarTranslation[selected]).classList.add("select-navbar");
    document.getElementById(navbarTranslationMobile[selected]).classList.add("select-navbar-mobile");
  }
  else{
    document.getElementById(navbarTranslation["invitation"]).classList.add("select-navbar");
    document.getElementById(navbarTranslationMobile["invitation"]).classList.add("select-navbar-mobile");
  }
}  
export const InvitationPressed_common=()=>{
    document.getElementById("first-element-navbar").classList.add("select-navbar")
    document.getElementById("second-element-navbar").classList.remove("select-navbar")
    document.getElementById("third-element-navbar").classList.remove("select-navbar")
    document.getElementById("fourth-element-navbar").classList.remove("select-navbar")
}

export const QuestionsPressed_common=()=>{
    document.getElementById("first-element-navbar").classList.remove("select-navbar")
    document.getElementById("second-element-navbar").classList.add("select-navbar")
    document.getElementById("third-element-navbar").classList.remove("select-navbar")
    document.getElementById("fourth-element-navbar").classList.remove("select-navbar")
  
  
  }

export const RSVPPressed_common=()=>{
    window.scrollTo(200,200)
    document.getElementById("mob-nav-first-element").classList.remove("select-navbar-mobile")
    document.getElementById("mob-nav-second-element").classList.remove("select-navbar-mobile")
    document.getElementById("mob-nav-third-element").classList.add("select-navbar-mobile")
    document.getElementById("mob-nav-fourth-element").classList.remove("select-navbar-mobile")
    
    document.getElementById("first-element-navbar").classList.remove("select-navbar")
    document.getElementById("second-element-navbar").classList.remove("select-navbar")
    document.getElementById("third-element-navbar").classList.add("select-navbar")
    document.getElementById("fourth-element-navbar").classList.remove("select-navbar")
    

  }

export const GuestBoardPressed_common=()=>{
    document.getElementById("first-element-navbar").classList.remove("select-navbar")
    document.getElementById("second-element-navbar").classList.remove("select-navbar")
    document.getElementById("third-element-navbar").classList.remove("select-navbar")
    document.getElementById("fourth-element-navbar").classList.add("select-navbar")
  }



export const InvitationPressedMob_common=()=>{
    document.getElementById("mob-nav-first-element").classList.add("select-navbar-mobile")
    document.getElementById("mob-nav-second-element").classList.remove("select-navbar-mobile")
    document.getElementById("mob-nav-third-element").classList.remove("select-navbar-mobile")
    document.getElementById("mob-nav-fourth-element").classList.remove("select-navbar-mobile")

  }
export const QuestionsPressedMob_common=()=>{
    document.getElementById("mob-nav-first-element").classList.remove("select-navbar-mobile")
    document.getElementById("mob-nav-second-element").classList.add("select-navbar-mobile")
    document.getElementById("mob-nav-third-element").classList.remove("select-navbar-mobile")
    document.getElementById("mob-nav-fourth-element").classList.remove("select-navbar-mobile")

  }
export const RSVPPressedMob_common=()=>{
    window.scrollTo(0, 0)
    document.getElementById("mob-nav-first-element").classList.remove("select-navbar-mobile")
    document.getElementById("mob-nav-second-element").classList.remove("select-navbar-mobile")
    document.getElementById("mob-nav-third-element").classList.add("select-navbar-mobile")
    document.getElementById("mob-nav-fourth-element").classList.remove("select-navbar-mobile")
}
export const GuestBoardPressedMob_common=()=>{
    document.getElementById("mob-nav-first-element").classList.remove("select-navbar-mobile")
    document.getElementById("mob-nav-second-element").classList.remove("select-navbar-mobile")
    document.getElementById("mob-nav-third-element").classList.remove("select-navbar-mobile")
    document.getElementById("mob-nav-fourth-element").classList.add("select-navbar-mobile")
  
  }


const templatesGuestBoardHeight = {
  templateOne:{
    web:"110vw",
    mobile:"155vw"
  },
  templateTwo:{
    web:"70vw",
    mobile:"120vw"
  },
  templateThree:{
    web:"60vw",
    mobile:"85vw"
  },
  templateFour:{
    web:"80vw",
    mobile:"125vw"
  },
  templateFive:{
    web:"70vw",
    mobile:"120vw"
  },
  templateSix:{
    web:"70vw",
    mobile:"120vw"
  },
  templateSeven:{
    web:"60vw",
    mobile:"110vw"
  },
  templateEight:{
    web:"90vw",
    mobile:"110vw"
  },
  templateNine:{
    web:"70vw",
    mobile:"100vw"
  },
  templateTen:{
    web:"58vw",
    mobile:"100vw"
  },
  templateEleven:{
    web:"90vw",
    ipad:"80vw",
    mobile:"120vw",
  },
  templateTwelve:{
    web:"90vw",
    ipad:"80vw",
    mobile:"120vw",
  },
}

export const updateHeight = (templateNumber, setHeight) =>{
  const templateSize = templatesGuestBoardHeight[templateNumber]
  const width = window.outerWidth
  // special case for template 11
  if(templateNumber == "templateEleven"){
    if(width > 1024){ // web
      setHeight("90vw")
    }
    else if( width <= 1024 && width > 821){

      setHeight("100vw")
    }
    else if( width <= 821 && width > 501){

      setHeight("120vw")
    }
    else if( width <= 501 && width > 320){

      setHeight("160vw")
    }
    else if(width <= 320){ // Mobile
      setHeight("140vw")
    }
  }
  else{

    if(width > 821){ // web and ipad
      setHeight(templateSize['web'])
    }
    else if(width <= 821){ // Mobile
      setHeight(templateSize['mobile'])
    }
  }

  
}  