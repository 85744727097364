import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Stack, Button, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AlertDialogSlide from "../../ui-common-components/ui-components/AlertDialogSlide";
import CustomizedSnackbars from "../../ui-common-components/ui-components/SnackBar";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import {
  TOGGLE_DELETE_ADMIN,
  COOKIES_SETTINGS,
  GET_ADMINS,
} from "../../endpoints/Constants";
//import AddVendorDialog from "./AddVendorDialog";
import PaginationCustom from "../../ui-common-components/ui-components/PaginationCustom";
import AdminFormDialog from "./AdminForm";
import AdminDialog from "./AdminDialog";

const Admins = () => {
  const navigate = useNavigate();
  /**************************Page variables********************************/
  const [admins, setAdmins] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);

  /**************************Form Dialog variables********************************/
  const openDialog = useRef(null);

  /**************************Snackbar variables********************************/
  const openSnackBar = useRef(null);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSevirity, setSnackBarSevirity] = useState("");

  /**************************Alert Dialog variables********************************/
  const openAlertDialog = useRef(null);
  const [toDeleteId, setToDeleteId] = useState(null);

  /********************************************************************************/
  const [searchText, setSearchText] = useState("");
  const [totalPages, setTotalPages] = useState(-1);

  const [changePage, setChangePage] = useState(1);
  const [paginationLoading, setPaginationLoading] = useState(true);

  useEffect(() => {
      localStorage.setItem("LBCurrentPage", 1);
  }, []);

  useEffect(() => {
    //("changePage")

    getAdmins(localStorage.getItem("LBCurrentPage"), "");
  }, [changePage]); // eslint-disable-line react-hooks/exhaustive-deps

  /**************************API CALL FUNCTIONS********************************/
  const getAdmins = async () => {
    var url = GET_ADMINS + `?page=` + localStorage.getItem("LBCurrentPage");

    if (searchText === "") {
      url += `&isDeleted=false`;
    } else {
      if(isNaN(searchText)){  
        url += `&name=${searchText}&isDeleted=false`;
    }
    else{
        url += `&phone=${searchText}&isDeleted=false`;
      }
    }

    setPageLoading(true);
    try {
      const response = await axios.get(url, COOKIES_SETTINGS);
      //console.log("Admins:", response);
      setAdmins(response.data.docs);

      setPaginationLoading(true);
      setTotalPages(response.data.totalPages);
      setPaginationLoading(false);
    } catch (error) {
      // console.log(error);
    }
    setPageLoading(false);
  };
  useEffect(() => {
    //console.log("Search")
    getAdmins();
  }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = async (e) => {
    setSearchText(e.target.value);
  };

  const toggleDelete = async () => {
    try {
      await axios.put(
        TOGGLE_DELETE_ADMIN,
        {
          adminId: toDeleteId,
        },
        COOKIES_SETTINGS
      );
      let flagForLessPage = false;
      if (admins.length === 1) {
        await localStorage.setItem(
          "LBCurrentPage",
          localStorage.getItem("LBCurrentPage") - 1
        );
        flagForLessPage = true;
      }
      //console.log("Delete")
      getAdmins("");
      setSnackBarMessage("Admin is deleted successfully");
      setSnackBarSevirity("success");
      openSnackBar.current();

      if (flagForLessPage) setChangePage(changePage + 1);
    } catch (error) {
      try {
        setSnackBarMessage(error.response.data.message);
      }
      catch(err){
          setSnackBarMessage("An error occcured");
      }
      setSnackBarSevirity("error");
      openSnackBar.current();
    }
    // console.log("toggleDelete");
  };

  const [toEditAdmin, setToEditAdmin] = useState(null);
  
  return (
    <div id="dashboard-admins" className="dashboard-vendors w-100">
     
     <AdminDialog
        openDialog={openDialog}
        refreshVendors={getAdmins}
        toEditAdmin={toEditAdmin}
      />
    
        <AlertDialogSlide
          confirmAlertRef={toggleDelete}
          openAlertDialog={openAlertDialog}
          title={"Alert"}
          description={"Are you sure you want to delete this admin?"}
          actionText={"Delete"}
          snackBarSevirity={"error"}
        />
        <CustomizedSnackbars
          openSnackBar={openSnackBar}
          message={snackBarMessage}
          severity={snackBarSevirity}
        />
      <Stack
        direction={{ xs: "column", sm: "row" }}
        height={{ xs: 180, sm: 60, md: 60, lg: 60 }}
        borderRadius={2}
        sx={{ backgroundColor: "#FFF" }}
        display={"flex"}
        alignContent={"center"}
        justifyContent={{
          sm: "space-between",
          xs: "center",
        }}
        alignItems={"center"}
      >
        <Typography
          margin={1}
          marginLeft={{ sm: 1, md: 3, lg: 3 }}
          color={"primary.main"}
          fontSize={20}
          fontWeight={"bold"}
        >
          Admins
        </Typography>
        <Box margin={3}>
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{ fontSize: { xs: "0.75rem", sm: "0.75rem", md: "1rem" } }}
          >
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              href="../"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Home
            </Link>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: { xs: "0.75rem", sm: "0.75rem", md: "1rem" },
              }}
              color={"primary.main"}
            >
              <AdminPanelSettingsIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Admins
            </Typography>
          </Breadcrumbs>
        </Box>
      </Stack>
      <Box>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            borderRadius: 2,
            border: "solid 1px",
            borderColor: "primary.border",
            marginTop: "1rem",
          }}
          elevation={0}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={12}>
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      display={"flex"}
                      justifyContent={{ xs: "center", sm: "space-between" }}
                      alignContent={"center"}
                    >
                      <TextField
                        id="standard-search"
                        label="Search"
                        type="search"
                        variant="outlined"
                        onChange={handleSearch}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderRadius: `55px`,
                            },
                          },
                        }}
                      />
                      <Stack direction={{ xs: "column", sm: "row" }}>
                        <Button
                          sx={{ margin: "0.5rem" }}
                          color="error"
                          variant="contained"
                          startIcon={<DeleteIcon />}
                          onClick={() => navigate("./recycle-bin")}
                        >
                          Recycle bin
                        </Button>
                        <Button
                          sx={{ margin: "0.5rem" }}
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={() => {
                            if (toEditAdmin == null) {
                              openDialog.current();
                            }
                            setToEditAdmin(null);
                          }}
                        >
                          Add admin
                        </Button>
                      </Stack>
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pageLoading ? (
                  <TableRow>
                    <TableCell
                      sx={{
                        width: "100%",
                        height: "70vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  admins.map((admin, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          display: "flex",
                          flexDirection: {
                            xs: "column",
                            lg: "row",
                            borderBottom: "0.5px solid #97cafc",
                          },
                        }}
                        
                      >
                        <TableCell
                          sx={{
                            border: 0,
                            width: { xs: "100%", lg: "30%" },
                          }}
                        >
                          <Stack
                            direction={{ xs: "column", lg: "row" }}
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <Stack
                              direction={"column"}
                              display={"flex"}
                              alignItems={{ xs: "center", lg: "start" }}
                            >
                              <Typography
                                marginLeft={{ xs: "0rem", md: "1rem" }}
                                fontSize={20}
                                color={"primary.main"}
                                fontWeight={"bold"}
                              >
                                {admin.name}
                              </Typography>
                              <Typography
                                marginLeft={{ xs: "0rem", md: "1rem" }}
                                fontSize={12}
                                color={"#808080"}
                              >
                                {admin.role}
                              </Typography>
                            </Stack>
                          </Stack>
                        </TableCell>

                        <TableCell
                          sx={{
                            border: 0,
                            width: { xs: "100%", lg: "45%" },
                          }}
                        >
                          <Stack direction={{ xs: "column", sm: "row" }} >
                            <Stack
                              width={{ xs: "100%", md: "65%" }}
                              direction={"column"}
                              paddingRight={{ xs: "0rem", md: "0rem" }}
                              display={"flex"}
                              alignItems={{ xs: "center", lg: "start" }}
                            >
                              <Typography fontSize={13} color={"#9E9E9E"}>
                                Email
                              </Typography>
                              <Typography
                                fontSize={15}
                                color={"#212121"}
                                fontWeight={"bold"}
                                marginBottom={"1rem"}
                              >
                                {admin.email}
                              </Typography>
                            </Stack>
                           
                              <Stack
                                width={{ xs: "100%", md: "35%" }}
                                direction={"column"}
                                paddingLeft={{ xs: "0rem", md: "0rem" }}
                                display={"flex"}
                                alignItems={{ xs: "center", md: "start" }}

                              >
                                <Typography fontSize={13} color={"#9E9E9E"}>
                                  Phone
                                </Typography>
                                <Typography
                                  fontSize={15}
                                  color={"#212121"}
                                  fontWeight={"bold"}
                                >
                                  {admin.phone}
                                </Typography>
                              </Stack>
                          </Stack>
                        </TableCell>

                        {/* Actions */}

                        <TableCell
                          sx={{
                            border: 0,
                            width: { xs: "100%", lg: "30%" },
                          }}
                        >
                          <Stack direction={"row"}>
                            <Button
                              sx={{
                                width: "50%",
                                margin: "1rem",
                              }}
                              variant="outlined"
                              onClick={() =>{
                                setToEditAdmin(admin);
                                openDialog.current();
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              color="error"
                              sx={{ width: "50%", margin: "1rem" }}
                              variant="outlined"
                              onClick={() => {
                                openAlertDialog.current();
                                setToDeleteId(admin._id);
                              }}
                            >
                              Delete
                            </Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {!paginationLoading &&
            (totalPages === 0 ||
            totalPages === -1 ||
            (totalPages === 1 && admins.length === 0) ? (
              <Typography textAlign={"center"}>No Admins found!</Typography>
            ) : (
              <Box
                onClick={(e) => {
                if(totalPages !== 1){
                  e.preventDefault();
                  setChangePage(changePage + 1);
                }
                }}
              >
                <PaginationCustom totalPages={totalPages} />
              </Box>
            ))}
        </Paper>
      </Box>
    </div>
  );
};

export default Admins;
