import React, { useState, useContext, useRef } from 'react';

import { Divider, Button, Paper, MenuList, MenuItem, ListItemText, ListItemIcon, Stack, Box } from '@mui/material';
import { Link } from "react-router-dom";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ProfileContext } from '../../context/ProfileContext';
import { domainName } from "../../endpoints/Constants";
import CustomizedSnackbars from '../../ui-common-components/ui-components/SnackBar';
const SideMenuBar = () => {
  const profile = useContext(ProfileContext);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const openSnackBar = useRef(null);
  const urlToIndex = {
    "website-theme": 0,
    "invitations": 0,
    "home-page": 1,
    "questions": 2,
    "rsvps": 3,
    "guest-board": 4,
  }
  const indexToUrl = {
    0: "./website-theme",
    1: "./home-page",
    2: "./questions",
    3: "./rsvps",
    4: "./guest-board",
  }
  const endPoints = window.location.href.split("/");
  const lastParam = endPoints[endPoints.length - 1];
  var urlIndex = 0;
  if (urlToIndex[lastParam]) {
    urlIndex = urlToIndex[lastParam];
  }
  const [selectedMenu, setSelectedMenu] = useState(urlIndex);
  const menuItems = ["Website Theme", "Home Page", "Q&A", "RSVPs", "Guest Board"];

  return (
    <Stack >
      <CustomizedSnackbars
        openSnackBar={openSnackBar}
        message={message}
        severity={severity}
      />
      <Paper elevation={0} sx={{ width: { xs: "100%", md: 259 }, maxWidth: '100%' }}>
        <MenuList sx={{ padding: 0 }}>

          {menuItems.map((menuItem, index) => {
            if (index !== 4 & (new Date() > new Date(profile.wedding.weddingDate))) {
              return;
            }
            return (
              <Box key={index}>
                <Link to={indexToUrl[index]} onClick={() => { setSelectedMenu(index) }}>
                  <MenuItem

                    selected={selectedMenu === index}
                    sx={{
                      paddingRight: 0,
                      width: '100%',
                      height: '56px',
                      backgroundColor: '#FBFBFB',
                      "&:hover": {
                        backgroundColor: "#F1A3A5; !important",
                        // color: "#FFFFFF !important"
                      },
                      '&.Mui-selected': {
                        xs: {
                          backgroundColor: "#FBFBFB; !important",

                        },
                        md: {
                          backgroundColor: "#F1A3A5; !important",
                          color: "#FFFFFF !important"
                        }
                      },
                      '&.Mui-selected:hover': {
                        backgroundColor: "#F1A3A5; !important"
                      }
                    }}>
                    <ListItemText >{menuItem}</ListItemText>
                    <ListItemIcon>
                      <KeyboardArrowRightIcon fontSize="small" sx=
                        {
                          {
                            color: { md: selectedMenu === index ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)", xs: "rgba(0,0,0,1)", }
                          }
                        } />
                    </ListItemIcon>
                  </MenuItem>
                </Link>
                {index === 4 ? null : <Divider sx={{ height: '1px', backgroundColor: "#FBFBFB" }} />}
              </Box>
            );
          }
          )
          }
        </MenuList>
      </Paper >
      <Link
        to={`../../${profile.wedding.weddingWebsite.weddingTemplate.uniqueName}/${profile.wedding.uniqueName}`}
        // underline="none"
        target="_blank"
      >
        <Button
          variant="contained"
          sx={{
            width: "100%",
            marginTop: "10px",
            borderRadius: "9px",
            backgroundColor: "#589BD5",
            color: "#FFFFFF",
            height: "46px",
            "&:hover": {
              backgroundColor: "#589BD5; !important"
            },
          }}
        >

          Preview Website

          <OpenInNewIcon sx={{
            marginLeft: "5px",
            width: "15px",
            height: "15px"
          }} />

        </Button>
      </Link>

      <Button
        variant="contained"
        sx={{
          marginTop: "10px",
          borderRadius: "9px",
          backgroundColor: "#589BD5",
          color: "#FFFFFF",
          height: "46px",
          "&:hover": {
            backgroundColor: "#589BD5; !important"
          },
        }}
        onClick={() => {
          try {
            navigator.clipboard.writeText(`${domainName}/${profile.wedding.weddingWebsite.weddingTemplate.uniqueName}?id=${profile.wedding.uniqueName}`);
            setSeverity("success");
            setMessage("Message copied successfully");
            openSnackBar.current();
          } catch (error) {
            setSeverity("error");
            setMessage("An error occured!!");
            openSnackBar.current();
          }

        }}
      >
        Copy Invitation Link
        <ContentCopyIcon sx={{
          marginLeft: "5px",
          width: "15px",
          height: "15px"
        }} />
      </Button>
    </Stack >
  );
};
export default SideMenuBar;