import React, { Suspense } from "react";
import { createRoot } from 'react-dom/client';
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import ProfileContextProvider from "./context/ProfileContext";
import PhoneContextProvider from "./context/PhoneContext";
import MainRoutes from "./routes/MainRoutes";

//import axios from "axios";

/*
axios.interceptors.response.use((response) => {
  if(response.status === 401) {
       alert("You are not authorized");
  }
  return response;
}, (error) => {
  if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
  }
  return Promise.reject(error.message);
});*/





const container = document.getElementById("root");
const root = createRoot(container);
{/*<React.StrictMode>*/}
root.render(
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <ProfileContextProvider>
          <PhoneContextProvider>
            <MainRoutes />
          </PhoneContextProvider>
        </ProfileContextProvider>
      </Suspense>
    </BrowserRouter>
);
{/*</React.StrictMode>*/}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
