import * as React from "react";
import Pagination from "@mui/material/Pagination";
import { Box } from "@mui/system";

export default function PaginationCustom({ totalPages }) {
  React.useEffect(() => {
    localStorage.setItem("LBCurrentPage", 1);
  }, []);

  const handleChange = (event, value) => {
    localStorage.setItem("LBCurrentPage", value);
  };
  return (
    <Box margin={1} width={"100%"} justifyContent={"center"}>
      <Pagination
        count={totalPages}
        color="primary"
        onChange={handleChange}
        sx={{
          marginTop: 2,
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
        size={"small"}
        disabled={totalPages === 1}
      />
    </Box>
  );
}
