import React from "react";

import Box from "@mui/material/Box";

const SectionDivider = () => {
  return (
    <Box
      sx={{
        width: { xs: "50%", sm: "50%", md: "25%" },
        borderBottom: "3px solid #AB9E72",
        justifySelf: "center",
        marginTop: "3rem",
        marginBottom: "3rem",
      }}
    ></Box>
  );
};

export default SectionDivider;
