import React, { useState , useEffect } from "react";
import "./TemplateSix.css";
import { Link } from "react-router-dom";
import {
  getMonth,
  getDayNumber,
  RSVPPressed_common,
  getDayName
} from "../TemplatesCommonFunctions";


const getTime = (date) => {
  const d2 = new Date(date);
  var hour = d2.getHours();

  let timeSTR = "";
  if (hour === 0) {
    timeSTR = "12 AM";
  } else if (hour > 12) {
    timeSTR = hour - 12 + "PM";
  } else {
    timeSTR = hour + "AM";
  }

  return timeSTR;
};

const getMonth_notcommon = (date) => {
  const d2 = new Date(date);
  var Months = new Array(12);
  Months[0] = " January";
  Months[1] = " February";
  Months[2] = " March";
  Months[3] = " April";
  Months[4] = " May";
  Months[5] = " June";
  Months[6] = " July";
  Months[7] = " August";
  Months[8] = " September";
  Months[9] = " October";
  Months[10] = " November";
  Months[11] = " December";
  return Months[d2.getMonth()];
};

const TemplateSixInvitation = ({ templateData , weddingId}) => {
  const [customStyle, setCustomStyle] = useState()
  const [dummy, setDummy] = useState(0);
  useEffect(() => {
    if(((templateData.groomFirstName.length + templateData.groomLastName.length) < 18) && ((templateData.brideFirstName.length + templateData.brideLastName.length) < 18) ){
      if(window.innerWidth > 1025){
        setCustomStyle({fontSize:"2.5vw",height: "2.5vw"})
      }
      else if(window.innerWidth > 821){
        setCustomStyle({fontSize:"3vw",height: "3vw"})
      }
      else if(window.innerWidth > 501){
        setCustomStyle({fontSize:"3.5vw",height: "3.5vw"})
      }
      else if(window.innerWidth > 320){
        setCustomStyle({fontSize:"4vw",height: "4vw"})
      }
      else{
        setCustomStyle({fontSize:"3.5vw",height: "3.5vw"})
      }
    }
    else{
      if(window.innerWidth > 1025){
        setCustomStyle({fontSize:"2vw",height: "2vw"})
      }
      else if(window.innerWidth > 821){
        setCustomStyle({fontSize:"2.5vw",height: "2.5vw"})
      }
      else if(window.innerWidth > 501){
        setCustomStyle({fontSize:"2vw",height: "2vw"})
      }
      else if(window.innerWidth > 320){
        setCustomStyle({fontSize:"2.3vw",height: "2.3vw"})
      }
      else{
        setCustomStyle({fontSize:"3vw",height: "3vw"})
      }
    }
  }, [templateData]);

  const RSVPPressed = () => {
    setDummy(!dummy)
    RSVPPressed_common("one")
  }

  return (
    <>
      <div id="template-six-body" className="row">
        <div className="col-1">
        </div>
        <div id="body-col" className="col-10 ">

          <div id="body-main-container" className="container-fluid" >
            <div id="body-row-inv-message" className='row'>
              <div className='col'>
                <p>Together with Their families</p>
              </div>
            </div>

            <div id="body-row-couple">
              
              <div id="bride-col" className='row container-fluid'>
                <div id="bride-first-name" className='row'>
                  <p style={customStyle}>
                    {templateData.brideFirstName} {templateData.brideLastName}
                  </p>
                </div>
              </div>
              <div id="couple-and" className='row'>
                <p style={customStyle}>
                  &
                </p>
              </div>
              <div id="groom-col" className='row container-fluid'>
                <div id="groom-first-name" className='row'>
                  <p style={customStyle}>
                    {templateData.groomFirstName} {templateData.groomLastName}
                  </p>
                </div>
              </div>

            </div>

            <div id="body-row-couple-message" className='row'>
              <p>
                INVITE YOU TO
              </p>
              <p>
                CELEBRATE THEIR WEDDING
              </p>
            </div>


            <div id="body-row-date" className='row'>
              <div id="body-date-month" >
                <p>
                  {getDayName(templateData.weddingDate)} {getMonth_notcommon(templateData.weddingDate)} {getDayNumber(templateData.weddingDate)}
                </p>
              </div>
              <div id="body-date-time">
                <p>
                  {getTime(templateData.weddingDate)}
                </p>
              </div>
            </div>
            
            <div id="body-row-time" className='row'>
              {/* <p id="body-row-time-area">
                {templateData.area}
              </p> */}
              <p id="body-row-time-city">
                {templateData.city}
              </p>
            </div>


            {templateData.weddingWebsite.RSVPsIsVisible ?

              <div>


                <div id="body-row-rsvp-by" className='row'>
                  <p>
                    KINDLY RSVP BY {getMonth(templateData.RSVPBy)} {getDayNumber(templateData.RSVPBy)}
                  </p>
                </div>

                <div id="body-row-rsvp-button" className='row'>
                  <div id="rsvp-col" className='col-4'></div>
                  <div id="rsvp-col" className='col-4'>
                    <Link to={"/winter-weddingLand/"+weddingId+"/rsvp"}>


                      <button onClick={RSVPPressed} className=" shadow-none" >
                        RSVP HERE
                      </button>
                    </Link>
                  </div>
                  <div id="rsvp-col" className='col-4'></div>
                </div>
              </div>
              : <div id="rsvp-placeholder"></div>
            }

          </div>

        </div>
        <div className="col-1">
        </div>
      </div>

      <div id="created-on" className="w-100">
            <div className="w-60">

              <p>
                Created on Lovebirds
              </p>
              <p>
                Getting married? <Link id="created-on-link" style={{ textDecoration: 'underline' }} 
                to={"/"}
                >Create your wedding website for free.</Link>
              </p>
            </div>
          </div>
    </>
  );
};

export default TemplateSixInvitation;
