import React, { useEffect, useState, useRef } from "react";
import { Grid, Button, CircularProgress, Box } from "@mui/material";
import { COOKIES_SETTINGS, GET_TEMPLATES } from "../../../endpoints/Constants";
import axios from "axios";
import InvitationComponent from "./InvitationComponent";
import CustomizedSnackbars from "../../../ui-common-components/ui-components/SnackBar";
import { editWebsite } from "../InvitationsApiFunctions";
const WebsiteTheme = () => {
    const [templates, setTemplates] = useState([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const openSnackBar = useRef(null);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");

    useEffect(() => {
        getTemplates();
    }, []);

    const getTemplates = async () => {
        setPageLoading(true);
        try {
            const response = await axios.get(GET_TEMPLATES, COOKIES_SETTINGS);
            setTemplates(response.data);
        } catch (error) {
            //console.log(error);
        } finally {
            setPageLoading(false);
        }

    };

    const edit = async () => {
        setLoading(true);
        try {
            const response = await editWebsite({
                weddingTemplate: selectedTemplateId,
            });
            if (response.status == 200) {
                setSeverity("success");
                setMessage("Wedding website changed successfully");
                openSnackBar.current();
                window.location.reload();
            }
        } catch (error) {
            setSeverity("error");
            setMessage(error.response.data.message);
            openSnackBar.current();
        } finally {
            setLoading(false);
        }
    }

    return (
        <Box sx={{
            width: "100%",
            paddingX: { md: "20px" },
            marginBottom: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <CustomizedSnackbars
                openSnackBar={openSnackBar}
                message={message}
                severity={severity}
            />
            {
                pageLoading === true ? <CircularProgress sx={{ color: "#F1A3A5", textAlign: "center" }} size={"1.8rem"} /> :
                    <Grid container spacing={0} justifyContent="center">
                        {templates.map((template, index) => {
                            return (
                                <InvitationComponent
                                    key={index}
                                    data={template}
                                    index={index}
                                    selectedTemplateId={selectedTemplateId}
                                    setSelectedTemplateId={setSelectedTemplateId}
                                >
                                </InvitationComponent>
                            );
                        })}
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            paddingX={1}
                            sx={{
                                display: "flex",
                                justifyContent: "center",

                            }}
                        >
                            <Button
                                size="medium"
                                type="submit"
                                variant="contained"
                                disabled={selectedTemplateId === -1}
                                onClick={edit}
                                sx={{
                                    width: { xs: "70%", md: "50%" },
                                    height: "48px",
                                    marginTop: "10px",
                                    background: "#F1A3A5",
                                    borderRadius: "48px",
                                    fontWeight: "600",
                                    fontSize: "18px",
                                    lineHeight: "16px",
                                    color: "#FFF",
                                    '&:hover': {
                                        backgroundColor: '#F1A3A5',
                                        color: '#FFF',
                                    },

                                }}
                            >
                                {loading ? (
                                    <CircularProgress sx={{ color: "#FFF" }} size={"1.8rem"} />
                                ) : (
                                    "Change Theme"
                                )}

                            </Button>
                        </Grid>
                    </Grid>
            }
        </Box>
    );
};
export default WebsiteTheme;