import React from "react";
import "./TemplateNine.css";
import RSVPPhone from "../RSVP/RSVPPhone";

const TemplateNineRSVP = ({ id }) => {
  return (
    <>
      <div id="template-nine-RSVP" className='container-fluid'>
              <div className='row'> 
                <div className="col-3">
                </div>
              
                <div  className="col-6"id="RSVP-nine-inner-div">
                  
                  <div id="RSVP-header">
                      <h2>
                        RSVP
                      </h2>
                  </div>
                  <RSVPPhone 
                    templateID = {id}
                  />
                </div>
                
                <div className="col-3">
                </div>
              </div>
            </div>

    </>
  );
};

export default TemplateNineRSVP;
