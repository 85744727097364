import React from "react";
import "./TemplateEleven.css";
import RSVPPhone from "../RSVP/RSVPPhone";

const TemplateElevenRSVP = ({ id }) => {
  return (
    <>
      <div id="template-eleven-RSVP" className="container-fluid">
        <div className="row">
          <div className="col-3"></div>

          <div className="col-6" id="RSVP-eleven-inner-div">
            <div id="RSVP-header">
              <h2>RSVP</h2>
            </div>
            <RSVPPhone templateID={id} />
          </div>

          <div className="col-3"></div>
        </div>
      </div>
    </>
  );
};

export default TemplateElevenRSVP;
