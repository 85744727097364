import { Box, Grid, Stack, Typography } from '@mui/material';

const FacebookDeletionInstructions = () => {
    const steps = ["Go to your Facebook Account’s Setting & Privacy. Click “Settings”",
        " Look for “Apps and Websites” and you will see all of the apps and websites you linked with your Facebook.",
        "Search and Click “Lovebirds” in the search bar.",
        "Scroll and click “Remove”.",
        "Congratulations, you have succesfully removed your app activities."
    ]
    return (

        <Grid container height={2000} display={'flex'} alignItems={'start'} justifyContent={'center'}>
            <Grid margin={7} item xs={12} md={6}>
                <Stack direction={'column'} >
                    <Typography  marginBottom={3} textAlign={'center'} fontSize={25} fontWeight={'bold'}>
                        Facebook Data Deletion Instructions URL
                    </Typography>
                    <Typography margin={1} fontSize={18}>
                        Lovebirds Login is a facebook login app and we do not save your personal data in our server. According to Facebook policy, we have to provide User Data Deletion Callback URL or Data Deletion Instructions URL.
                        If you want to delete your activities for Lovebirds Login App, you can remove your information by following these steps:
                    </Typography>
                    <ol>
                        {steps.map((step, index) => {
                            return (
                                <li margin={0.5} key={index}>
                                    {step}
                                </li>
                            );

                        })}
                    </ol>

                </Stack>
            </Grid>
        </Grid>

    );
};
export default FacebookDeletionInstructions;
