import React from "react";
import "./TemplateSix.css";

import Questions from "../Questions/Questions";

const TemplateSixQuestions = ({ id, data }) => {
  return (
    <>
      <div id="template-six-questions" className='container-fluid'>
              <div className='row'> 
                <div className="col-3">
                </div>
              
                <div  className="col-6">
                  
                  <div id="questions-header">
                      <h2>
                          Questions
                      </h2>
                  </div>
                  <Questions 
                    templateID = {id}
                    questionsDataInput = {data}
                  />
                </div>
                
                <div className="col-3">
                </div>
              </div>
            </div>
    </>
  );
};

export default TemplateSixQuestions;
