import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Button, Typography } from "@mui/material";
import ImageListItem from "@mui/material/ImageListItem";

/*import venue1Image from "../../assets/webapp/home/venue-1.png";
import venue2Image from "../../assets/webapp/home/venue-2.png";
import venue3Image from "../../assets/webapp/home/venue-3.png";*/

import hotel1Image from "../../assets/webapp/home/hotels/Fairmont Hotel.png";
import hotel2Image from "../../assets/webapp/home/hotels/Four Seasons Hotel.png";
import hotel3Image from "../../assets/webapp/home/hotels/JW Marriott Hotel.png";
import hotel4Image from "../../assets/webapp/home/hotels/Marriott Mena House Hotel.png";
import hotel5Image from "../../assets/webapp/home/hotels/Nile Ritz Carlton Hotel.png";
import hotel6Image from "../../assets/webapp/home/hotels/Royal Maxim Palace Kempinski Hotel.png";

const VenueCarousel = () => {
  const venueIcons = [
    {
      imgSrc: hotel1Image,
      txt: "Fairmont",
    },
    {
      imgSrc: hotel2Image,
      txt: "Four Seasons",
    },
    {
      imgSrc: hotel3Image,
      txt: "JW Marriott",
    },
    {
      imgSrc: hotel4Image,
      txt: "Marriott Mena House",
    },
    {
      imgSrc: hotel5Image,
      txt: "Nile Ritz Carlton",
    },
    {
      imgSrc: hotel6Image,
      txt: "Royal Maxim Palace Kempinski",
    }
  ];

  const [selectedItem, setSelectedItem] = useState(0);// eslint-disable-line
  const [numberOfItems, setNumberOfItems] = useState(3);

  // optional: save the width of the window using state
  const handleWindowSizeChange = () => {
    if (window.innerWidth < 600) {
      setNumberOfItems(3);
    } else if (window.innerWidth < 900) {
      setNumberOfItems(3);
    } else {
      setNumberOfItems(3);
    }
  };

  // call your useEffect
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const getCarouselItems = () => {
    const items = [];
    for (let i = 0; i < venueIcons.length / numberOfItems; i += 1) {
      const carouselItem = [];
      for (
        let j = i * numberOfItems;
        j < i * numberOfItems + numberOfItems && j < venueIcons.length;
        j++
      ) {
        const item = venueIcons[j];
        carouselItem.push(
          <Grid
            item
            //xs={12/numberOfItems}
            xs={4}
            md={3}
            key={"venue-" + j}
            container
            justifyContent="center"
            marginTop={{ xs: "2rem", sm: "2rem", md: "3rem" }}
            sx={{
              transition: "all .2s ease-in-out",
              "&:hover": {
                transform: "scale(1.01)",
              },
            }}
          >
            <ImageListItem
              key={j}
              sx={{
                //height: {xs:"120px!important", sm:"180px!important", md:"200px!important"},
                width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              <img
                src={item.imgSrc}
                alt={item.txt}
                loading="lazy"
                style={{ borderRadius: "8px" }}
              />
            </ImageListItem>
            <Typography
              color={"text.darkBlue"}
              fontSize={{ xs: "2vw", sm: "2vw", md: "1.2rem" }}
              textAlign="center"
              marginTop={{xs:"0.5rem", md:"1rem"}}
              marginBottom={"1rem"}
              fontWeight={"bold"}
              noWrap={true}
              textTransform="uppercase"
            >
              {item.txt}
            </Typography>
          </Grid>
        );
      }
      items.push(
        <div
          key={"venue-item-" + i}
          className={
            i === Math.floor(selectedItem / numberOfItems)
              ? "carousel-item active w-100"
              : "carousel-item w-100"
          }
        >
          <Grid container spacing={1} justifyContent="center">
            {carouselItem}
          </Grid>
        </div>
      );
    }
    return items;
  };

  return (
    <Grid
      id={"venueSectionCarouselControl"}
      className="carousel slide"
      data-bs-interval="false"
      data-ride="carousel"
      //sx={{width:{xs:"90%",sm:"100%", md:"80%", lg:"60%"}}}
      sx={{ width: "75%" }}
    >
      <div className="carousel-inner carousel-container w-100">
        {getCarouselItems()}
      </div>
      {venueIcons.length === 0 ? null : (
        <Button
          className="carousel-control-prev"
          type="button"
          data-bs-target={"#venueSectionCarouselControl"}
          data-bs-slide="prev"
          sx={{
            width: "fit-content",
            position:"absolute",
            left:{xs:"-3rem", sm:"-4rem", md:"0"},
          }}
        >
          <NavigateBeforeIcon
            style={{ color: "#AB9E72" }}
            sx={{
              width: { xs: "2rem", sm: "2rem", md: "3rem" },
              height: { xs: "2rem", sm: "2rem", md: "3rem" },
              marginRight: 0,
            }}
          ></NavigateBeforeIcon>
        </Button>
      )}
      {venueIcons.length === 0 ? null : (
        <Button
          className="carousel-control-next"
          type="button"
          data-bs-target={"#venueSectionCarouselControl"}
          data-bs-slide="next"
          sx={{
            width: "fit-content",
            position:"absolute",
            right:{xs:"-3rem", sm:"-4rem", md:"0"},
          }}
        >
          
          <NavigateNextIcon
            sx={{
              width: { xs: "2rem", sm: "2rem", md: "3rem" },
              height: { xs: "2rem", sm: "2rem", md: "3rem" },
              marginLeft: 0,
            }}
            style={{ color: "#AB9E72" }}
          ></NavigateNextIcon>
        </Button>
      )}
    </Grid>
  );
};

export default VenueCarousel;
