import {
  Box,
  Grid,
  OutlinedInput,
  TextField,
  FormControl,
  FormHelperText,
  Button,
  CircularProgress,
  InputLabel,
} from "@mui/material";
import { Formik } from "formik";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as Yup from "yup";
import { useContext, useState, useRef } from "react";
import { ProfileContext } from "../../context/ProfileContext";
import CustomizedSnackbars from "../../ui-common-components/ui-components/SnackBar";
import axios from "axios";
import {
  USER_UPDATE_WEDDING_DETAILS,
  COOKIES_SETTINGS,
} from "../../endpoints/Constants";
const WeddingDetails = () => {
  const profile = useContext(ProfileContext);
  const [loading, setLoading] = useState(false);
  const openErrorSnackBar = useRef(null);
  const [error, setError] = useState("");
  const openSuccessSnackBar = useRef(null);
  const [success, setSuccess] = useState("");

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  return (
    <Box
      sx={{
        width: { xs: "100%", md: "65%" },
        paddingX: { md: "20px" },
        marginBottom: "20px",
      }}
    >
      <CustomizedSnackbars
        openSnackBar={openErrorSnackBar}
        severity="error"
        message={error}
      ></CustomizedSnackbars>
      <CustomizedSnackbars
        openSnackBar={openSuccessSnackBar}
        severity="success"
        message={success}
      ></CustomizedSnackbars>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Formik
          initialValues={{
            brideFirstName: profile.wedding.brideFirstName,
            brideLastName: profile.wedding.brideLastName,
            groomFirstName: profile.wedding.groomFirstName,
            groomLastName: profile.wedding.groomLastName,
            weddingDate: profile.wedding.weddingDate,
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            brideFirstName: Yup.string().required(
              "Bride first Name is required"
            ),
            brideLastName: Yup.string().required("Bride last Name is required"),
            groomFirstName: Yup.string().required(
              "Groom first Name is required"
            ),
            groomLastName: Yup.string().required("Groom last Name is required"),
            weddingDate: Yup.date().required("Wedding date is required"),
          })}
          // validationSchema={Yup.object().shape({
          //     title: Yup.string().
          //         required("Title is required").test('title', 'Please enter a valid title', function (value) { if (value === " ") return false; else return true; }),
          // })}
          onSubmit={async (values, { setErrors, setSubmitting }) => {
            setLoading(true);
            const {
              brideFirstName,
              brideLastName,
              groomFirstName,
              groomLastName,
              weddingDate,
            } = values;
            const wedding = {
              brideFirstName,
              brideLastName,
              groomFirstName,
              groomLastName,
              weddingDate,
            };

            try {
              const response = await axios.put(
                USER_UPDATE_WEDDING_DETAILS,
                wedding,
                COOKIES_SETTINGS
              );
              if (response.status === 200) {
                setLoading(false);
                setSuccess("Wedding details updated successfully");
                openSuccessSnackBar.current();
              }
            } catch (e) {
              setLoading(false);
              setError(e.response.data.message);
              openErrorSnackBar.current();
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12} md={6}>
                  <FormControl
                    fullWidth
                    error={Boolean(
                      touched.brideFirstName && errors.brideFirstName
                    )}
                    variant="outlined"
                    sx={{
                      marginBottom: "10px",
                    }}
                  >
                    <InputLabel
                      htmlFor="outlined-adornment-brideFirstName"
                      sx={{ color: "text.lightGray" }}
                    >
                      Bride First Name
                    </InputLabel>
                    <OutlinedInput
                      fullWidth
                      sx={{
                        background: "#F8FAFD",
                        border: "1px solid #E7ECF3",
                        borderRadius: "10px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E7ECF3",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E7ECF3",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E7ECF3",
                        },
                        fieldSet: {
                          legend: {
                            width: "unset",
                          },
                        },
                      }}
                      id={"outlined-adornment-brideFirstName"}
                      type={"text"}
                      value={values["brideFirstName"]}
                      name={"brideFirstName"}
                      placeholder={"Bride First Name"}
                      label="Bride First Name"
                      onBlur={handleBlur}
                      onChange={(e) =>
                        setFieldValue(
                          "brideFirstName",
                          capitalize(e.target.value)
                        )
                      }
                    />
                    {touched.brideFirstName && errors.brideFirstName && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                      >
                        {errors.brideFirstName}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl
                    fullWidth
                    error={Boolean(
                      touched.brideLastName && errors.brideLastName
                    )}
                    variant="outlined"
                    sx={{
                      marginBottom: "10px",
                    }}
                  >
                    <InputLabel
                      htmlFor="outlined-adornment-brideLastName"
                      sx={{ color: "text.lightGray" }}
                    >
                      Bride Last Name
                    </InputLabel>
                    <OutlinedInput
                      sx={{
                        background: "#F8FAFD",
                        border: "1px solid #E7ECF3",
                        borderRadius: "10px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E7ECF3",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E7ECF3",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E7ECF3",
                        },
                        fieldSet: {
                          legend: {
                            width: "unset",
                          },
                        },
                      }}
                      id={"outlined-adornment-brideLastName"}
                      type={"text"}
                      value={values["brideLastName"]}
                      name={"brideLastName"}
                      placeholder={"Bride Last Name"}
                      label={"Bride Last Name"}
                      onBlur={handleBlur}
                      onChange={(e) =>
                        setFieldValue(
                          "brideLastName",
                          capitalize(e.target.value)
                        )
                      }
                    />
                    {touched.brideLastName && errors.brideLastName && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                      >
                        {errors.brideLastName}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl
                    fullWidth
                    error={Boolean(
                      touched.groomFirstName && errors.groomFirstName
                    )}
                    variant="outlined"
                    sx={{
                      marginBottom: "10px",
                    }}
                  >
                    <InputLabel
                      htmlFor="outlined-adornment-groomFirstName"
                      sx={{ color: "text.lightGray" }}
                    >
                      Groom First Name
                    </InputLabel>
                    <OutlinedInput
                      sx={{
                        background: "#F8FAFD",
                        border: "1px solid #E7ECF3",
                        borderRadius: "10px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E7ECF3",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E7ECF3",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E7ECF3",
                        },
                        fieldSet: {
                          legend: {
                            width: "unset",
                          },
                        },
                      }}
                      id={"outlined-adornment-groomFirstName"}
                      type={"text"}
                      value={values["groomFirstName"]}
                      name={"groomFirstName"}
                      placeholder={"Groom First Name"}
                      label="Groom First Name"
                      onBlur={handleBlur}
                      onChange={(e) =>
                        setFieldValue(
                          "groomFirstName",
                          capitalize(e.target.value)
                        )
                      }
                    />
                    {touched.groomFirstName && errors.groomFirstName && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                      >
                        {errors.groomFirstName}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl
                    fullWidth
                    error={Boolean(
                      touched.groomLastName && errors.groomLastName
                    )}
                    variant="outlined"
                    sx={{
                      marginBottom: "10px",
                    }}
                  >
                    <InputLabel
                      htmlFor="outlined-adornment-groomLastName"
                      sx={{ color: "text.lightGray" }}
                    >
                      Groom Last Name
                    </InputLabel>
                    <OutlinedInput
                      sx={{
                        background: "#F8FAFD",
                        border: "1px solid #E7ECF3",
                        borderRadius: "10px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E7ECF3",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E7ECF3",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#E7ECF3",
                        },
                        fieldSet: {
                          legend: {
                            width: "unset",
                          },
                        },
                      }}
                      id={"outlined-adornment-groomLastName"}
                      type={"text"}
                      value={values["groomLastName"]}
                      name={"groomLastName"}
                      placeholder={"Groom Last Name"}
                      label={"Groom Last Name"}
                      onBlur={handleBlur}
                      onChange={(e) =>
                        setFieldValue(
                          "groomLastName",
                          capitalize(e.target.value)
                        )
                      }
                    />
                    {touched.groomLastName && errors.groomLastName && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                      >
                        {errors.groomLastName}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <InputLabel
                    htmlFor="outlined-adornment-weddingDate"
                    sx={{ color: "text.lightGray" }}
                  >
                    Wedding Date
                  </InputLabel>
                  <FormControl fullWidth variant="outlined">
                    <DatePicker
                      value={values["weddingDate"]}
                      onChange={(value) => {
                        setFieldValue("weddingDate", value);
                      }}
                      minDate={new Date()}
                      renderInput={(params) => (
                        <TextField
                          id="outlined-adornment-weddingDate"
                          variant="outlined"
                          name="weddingDate"
                          sx={{
                            marginBottom: "10px",
                            background: "#F8FAFD",
                            borderRadius: "10px",
                            border: "1px solid #E7ECF3",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset.MuiOutlinedInput-notchedOutline": {
                                borderColor: "transparent",
                              },
                            },
                            fieldSet: {
                              legend: {
                                width: "unset",
                              },
                            },
                          }}
                          {...params}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}></Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sx={{
                    justifyContent: { md: "end", xs: "center" },
                  }}
                >
                  <Grid item sm={10} md={6}>
                    <Button
                      fullWidth
                      size="medium"
                      type="submit"
                      variant="contained"
                      disabled={loading}
                      sx={{
                        height: "48px",
                        textTransform: "none",
                        marginTop: "10px",
                        background: "#F1A3A5",
                        borderRadius: "48px",
                        fontWeight: "600",
                        fontSize: { md: "18px", xs: "14px" },
                        lineHeight: "16px",
                        color: "#FFF",
                        "&:hover": {
                          backgroundColor: "#F1A3A5",
                          color: "#FFF",
                        },
                      }}
                    >
                      {loading === true ? (
                        <CircularProgress
                          sx={{ color: "#FFF" }}
                          size={"1.8rem"}
                        />
                      ) : (
                        "Save Changes"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </LocalizationProvider>
    </Box>
  );
};
export default WeddingDetails;
