import React from "react";
import "./TemplateFour.css";
import RSVPPhone from "../RSVP/RSVPPhone";

const TemplateFourRSVP = ({ id }) => {
  return (
    <>
      <div id="template-four-RSVP" className='container-fluid'>
        <div className='row'>
          <div className="col-3">
          </div>

          <div className="col-6" id="RSVP-four-inner-div">

            <div id="RSVP-header">
              <h2>
                RSVP
              </h2>
            </div>
            <RSVPPhone
              templateID={id}
            />
          </div>

          <div className="col-3">
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateFourRSVP;
