import React, { useState } from "react";
import "./TemplateFour.css";
import { Link } from "react-router-dom";
import {
  getMonth,
  getDayNumber,
  getYear,
  RSVPPressed_common
} from "../TemplatesCommonFunctions";

const TemplateFourInvitation = ({ templateData , weddingId}) => {
  const [dummy, setDummy] = useState(0);

  const RSVPPressed = () => {
    setDummy(!dummy)
    RSVPPressed_common("one")
  }
  const getMonth_notcommon = (date) => {
    const d2 = new Date(date)
    var Months = new Array(12);
    Months[0] = " January";
    Months[1] = " February";
    Months[2] = " March";
    Months[3] = " April";
    Months[4] = " May";
    Months[5] = " June";
    Months[6] = " July";
    Months[7] = " August";
    Months[8] = " September";
    Months[9] = " October";
    Months[10] = " November";
    Months[11] = " December";
    return Months[d2.getMonth()];
  }
  const getDayName = (date) => {
    const d2 = new Date(date)
    var weekdays = new Array(7);
    weekdays[0] = "Sun.";
    weekdays[1] = "Mon.";
    weekdays[2] = "Tues.";
    weekdays[3] = "Wed.";
    weekdays[4] = "Thurs.";
    weekdays[5] = "Fri.";
    weekdays[6] = "Sat.";
    return weekdays[d2.getDay()];
  }
  const getTime = (date) => {
    const d2 = new Date(date)
    var hour = d2.getHours();

    let timeSTR = ""
    if (hour == 0) {
      timeSTR = "12AM"
    }
    else if (hour > 12) {
      timeSTR = (hour - 12) + "PM"
    }
    else {
      timeSTR = hour + "AM"
    }

    return timeSTR;
  }
  return (
    <>
      <div id="template-four-body" className="row">
        <div className="col-3">
        </div>
        <div id="body-col" className="col-6 ">

          <div id="body-main-container" className="container-fluid" >
            <div id="body-row-inv-message" className='row'>
              <div className='col'>
                <p>Together with Their families</p>
              </div>
            </div>

            <div id="body-row-couple">
              <div id="bride-col" className='row container-fluid'>
                <div id="bride-first-name" className='row'>
                  <p>
                    {templateData.brideFirstName}
                  </p>
                </div>
                <div id="bride-last-name" className='row'>
                  <p>
                    {templateData.brideLastName}
                  </p>
                </div>
              </div>
              <div id="couple-and" className='row'>
                <p>
                  &
                </p>
              </div>
              <div id="groom-col" className='row container-fluid'>
                <div id="groom-first-name" className='row'>
                  <p>
                    {templateData.groomFirstName}
                  </p>
                </div>
                <div id="groom-last-name" className='row'>
                  <p>
                    {templateData.groomLastName}
                  </p>
                </div>
              </div>
            </div>

            <div id="body-row-couple-message" className='row'>
              <p>
                INVITE YOU TO
              </p>
              <p>
                CELEBRATE THEIR WEDDING
              </p>
            </div>

            {/* <div id="body-row-day" className='row'>
                      <p>
                        {data.day}
                      </p>
                    </div> */}
            <div className="d-flex justify-content-center">
              <div id="message-time-divider"></div>
            </div>
            <div id="body-row-time" className='row'>
              {/* <p>
                    {getTime(templateData.weddingDate)}
                  </p> */}
              <p id="body-row-time-area">
                {templateData.city}
              </p>
              <p id="body-row-time-city">
                {templateData.area}
              </p>
            </div>

            <div id="body-row-date" className='row'>
              <div id="body-date-date" >
                <p>
                  {getDayName(templateData.weddingDate) + " " + getMonth_notcommon(templateData.weddingDate) + " " + getDayNumber(templateData.weddingDate) + " " + getYear(templateData.weddingDate)}
                </p>
              </div>
              <div id="body-date-time">
                <p>
                  {getTime(templateData.weddingDate)}
                </p>
              </div>
            </div>

            {templateData.weddingWebsite.RSVPsIsVisible ?
              <div>


                <div id="body-row-rsvp-by" className='row'>
                  <p>
                    KINDLY RSVP
                  </p>
                  <p>
                    BY {getMonth(templateData.RSVPBy)} {getDayNumber(templateData.RSVPBy)}
                  </p>
                </div>

                <div id="body-row-rsvp-button" className='d-flex justify-content-center'>
                  <div id="rsvp-col" className=''>
                    <Link to={"/love-and-leaves/" + weddingId+"/rsvp"}>
                      <button onClick={RSVPPressed} className=" shadow-none" >
                        RSVP HERE
                      </button>
                    </Link>
                  </div>
                </div>

              </div>
              : <div id="rsvp-placeholder"></div>
            }
          </div>

        </div>
        <div className="col-3">
        </div>
      </div>

      <div id="created-on" className="w-100">
            <div className="w-50">

              <p>
                Created on Lovebirds
              </p>
              <p>
                Getting married? <Link id="created-on-link" style={{ textDecoration: 'underline' }} 
                to={"/"}
                >Create your wedding website for free.</Link>
              </p>
            </div>
          </div>
          
    </>
  );
};

export default TemplateFourInvitation;
