import React, { useEffect, useState, useRef } from "react";

import Typography from "@mui/material/Typography";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import RestoreIcon from "@mui/icons-material/Restore";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import { CircularProgress, Stack } from '@mui/material';
import CategoryIcon from "@mui/icons-material/Category";
import DeleteIcon from "@mui/icons-material/Delete";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import CustomizedSnackbars from "../../ui-common-components/ui-components/SnackBar";
import axios from "axios";

import {
  COOKIES_SETTINGS,
  VENDOR_CATEGORY,
  TOGGLE_DELETE_VENDOR_CATEGORY,
} from "../../endpoints/Constants";
import AlertDialogSlide from "../../ui-common-components/ui-components/AlertDialogSlide";

const VendorCategoriesRecycleBin = () => {

  const openAlertDialog = useRef(null);
  const openSnackBar = useRef(null);
  const [vendorCategories, setVendorCategories] = useState([]);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSevirity, setSnackBarSevirity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [actionText, setActionText] = useState("");
  const [restoreId, setRestoreId] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    getVendorCategoriesDeleted();
  }, []);


  const confirmRestore = async () => {
    // console.log(restoreId);
    try {
      await axios.put(
        TOGGLE_DELETE_VENDOR_CATEGORY,
        {
          id: restoreId,
        },
        COOKIES_SETTINGS
      );
      getVendorCategoriesDeleted();
      setSnackBarMessage("Vendor category is restored successfully");
      setSnackBarSevirity("success");
      openSnackBar.current();
    } catch (error) {
     // console.log(error);
      setSnackBarMessage("An error occcured");
      setSnackBarSevirity("error");
      openSnackBar.current();
    }
    setRestoreId(null);
  };
  const getVendorCategoriesDeleted = async () => {
    setPageLoading(true);
    
    try {
        const response = await axios.get(
            VENDOR_CATEGORY + "?isDeleted=true",
            COOKIES_SETTINGS
      );
      // console.log("Vendor categories", response);
      setVendorCategories(response.data);
    } catch (error) {
       // console.log(error);
    }
    setPageLoading(false);
  };
  function removeTime(date) {
    return date.split("T")[0];
  }

  return (
    <Grid container spacing={0} alignItems="start" justifyContent="center">
      <AlertDialogSlide
        confirmAlertRef={confirmRestore}
        openAlertDialog={openAlertDialog}
        title={"Alert"}
        description={alertMessage}
        actionText={actionText}
        severity={snackBarSevirity}
      />
      <CustomizedSnackbars
        openSnackBar={openSnackBar}
        message={snackBarMessage}
        severity={snackBarSevirity}
      />

      <Grid item xs={12} marginBottom={5}>
        <Stack
          direction={{ xs: "column", md: "row", sm: "row", lg: "row" }}
          height={{ xs: 180, sm: 60, md: 60, lg: 60 }}
          borderRadius={2}
          sx={{ backgroundColor: "#FFF" }}
          display={"flex"}
          alignContent={"center"}
          justifyContent={{
            md: "space-between",
            sm: "space-evenly",
            xs: "center",
          }}
          alignItems={"center"}
        >
          <Typography
            margin={1}
            marginLeft={{ sm: 1, md: 3, lg: 3 }}
            color={"primary.main"}
            fontSize={{xs:15, sm:20}}
            fontWeight={"bold"}
          >
            Vendor categories Recycle Bin
          </Typography>

         

          <Box margin={3}>
            <Breadcrumbs
              aria-label="breadcrumb"
              sx={{ fontSize: { xs: "0.75rem", sm: "0.75rem", md: "1rem" } }}
            >
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/dashboard"
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Home
              </Link>
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/dashboard/vendor-categories"
              >
                <CategoryIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Vendor Categories
              </Link>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: { xs: "0.75rem", sm: "0.75rem", md: "1rem" },
                }}
                color={"primary.main"}
              >
                <DeleteIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Recycle Bin
              </Typography>
            </Breadcrumbs>
          </Box>
        </Stack>
      </Grid>
      {pageLoading ?
            (
                <Box
                sx={{
                  width: "100%",
                  height: "70vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
    )
            :(
              vendorCategories.length===0?<Typography>No Vendor categories deleted</Typography>:
      vendorCategories.map((category, index) => {
        return (
          <Grid
            textAlign={"right"}
            key={index}
            item
            xs={12}
            sm={5}
            md={3}
            lg={2}
            margin={1}
          >
            <ImageListItem
              key={index}
              sx={{
                height: "200px!important",
                width: "100%",
              }}
            >
              <img
                src={category.icon}
                alt={category.title}
                height="200px"
                width="300"
                loading="lazy"
                style={{ borderRadius: "10px" }}
              />
              <ImageListItemBar
                sx={{ textAlign: "left", borderRadius: "10px" }}
                title={
                  <Typography
                    color={"#FFF"}
                    fontSize={"1.3rem"}
                    fontWeight={"bold"}
                    noWrap={true}
                  >
                    {category.name}
                  </Typography>
                }
                subtitle={
                  <Typography
                    variant="body2"
                    color="#FFF"
                    noWrap={true}
                    fontSize={"0.7rem"}
                    fontStyle={"italic"}
                  >
                    Created at: {removeTime(category.createdAt)}
                  </Typography>
                }
                actionIcon={
                  <Stack direction={"row"}>
                    <IconButton
                        sx={{ margin: "1rem" }}
                      aria-label="delete"
                      onClick={() => {
                        setRestoreId(category._id);
                        setActionText("Restore");
                        setAlertMessage(
                          "Are you sure you want to Restore this vendor catgory"
                        );
                        openAlertDialog.current();
                      }}
                    >
                      <RestoreIcon sx={{ color: "#F00" }} />
                    </IconButton>
                  </Stack>
                }
              />
            </ImageListItem>
          </Grid>
        );
            }))}
    </Grid>
  );
};

export default VendorCategoriesRecycleBin;
