
import React, { useState, useContext, useEffect } from 'react';
import './RSVP.css';
import axios from "axios";
import Box from "@mui/material/Box";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
    Alert,
    CircularProgress,
    Icon,
} from "@mui/material";
import {
    COOKIES_SETTINGS,
    POST_WEDDING_RSVP_PHONE,
    PUT_WEDDING_RSVP_STATUS
} from "../../endpoints/Constants";

import { PhoneContext } from "../../context/PhoneContext";
import { MobileCodeNumbers } from '../MobileCodeNumbers';




const RSVPPhone = ({ templateID }) => {
    const phone = useContext(PhoneContext);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [countryNumber, setCountryNumber] = useState("+20");
    const [errorMessage, setErrorMessage] = useState("Please provide a valid phone number.")
    const [RSVPData, setRSVPData] = useState()
    const [tabLoading, setTabLoading] = useState(false)
    const [subTabLoading, setSubTabLoading] = useState(true)
    const [errPhoneNumber, setErrPhoneNumber] = useState(null)
    const [submitStatusSuccess, setSubmitStatusSuccess] = useState(null)
    const [RSVPErrorMessage, setRSVPErrorMessage] = useState("Error")

    useEffect(() => {
        if (phone.state.isPhoneLogged === true) {
            setRSVPData(phone.state.RSVPData)
            setSubTabLoading(false)
        }
    }, [])

    useEffect(() => {
        setRSVPData(phone.state.RSVPData)

    }, [phone.state]);

    useEffect(() => {
        if (errPhoneNumber === true) {
            setTimeout(() => setErrPhoneNumber(null), 10000)
        }
    }, [errPhoneNumber]);

    useEffect(() => {
        if (submitStatusSuccess === true) {
            setTimeout(() => setSubmitStatusSuccess(null), 10000)
        }
    }, [submitStatusSuccess]);


    const onSubmitHandler = (event) => {
        event.preventDefault();

        // if (countryNumber === "+20") {
        //     var pattern = /^1[0125][0-9]{8}$/;
        //     if (!pattern.test(phoneNumber)) {
        //         setErrorMessage("Please enter a valid phone number.");
        //         setErrPhoneNumber(true);
        //         return;
        //     }

        // } else if (countryNumber === "+1") {
        //     var pattern = /^[0-9]{10}$/;
        //     if (!pattern.test(phoneNumber)) {
        //         setErrorMessage("Please enter a valid phone number.");
        //         setErrPhoneNumber(true);
        //         return;
        //     }
        // }
        if (phoneNumber == "") {
            setErrorMessage("Please enter a phone number.");
            setErrPhoneNumber(true);
            return;
        }
        let formData = {}

        formData = {
            phone: countryNumber + phoneNumber
        };
        handlePhoneRequest(formData)

    }

    const handlePhoneRequest = async (formData) => {

        setTabLoading(true);
        try {

            axios
                .post(POST_WEDDING_RSVP_PHONE + `/${templateID}/guest`, formData, COOKIES_SETTINGS)
                .then((res) => {
                    if (res.status === 200) {
                        //console.log("phone response: ",res.data)
                        //phone.state.RSVPData = res.data
                        //phone.state.isPhoneLogged = true
                        phone.logPhone(formData.phone, res.data)
                        setRSVPData(res.data);
                        setTabLoading(false);
                        setSubTabLoading(false)
                        setErrPhoneNumber(false)
                    }
                    else {
                        setTabLoading(false);
                        setSubTabLoading(false)
                        setErrorMessage("Kindly check your phone number.")
                        setErrPhoneNumber(true)
                    }
                })
                .catch((err) => {
                    setTabLoading(false);
                    setSubTabLoading(false)
                    setErrorMessage("Your phone number is not in the guest list.")
                    setErrPhoneNumber(true)
                    // console.log("error in phone number:"+err)

                });
        } catch (err) {
            setTabLoading(false);
            setSubTabLoading(false)
            setErrorMessage("Your phone number is not in the guest list.")
            setErrPhoneNumber(true)
        }
    }

    const toggleAcceptGuest = () => {
        const temp = RSVPData;
        if (RSVPData.RSVPStatus == "Attending") {
            temp.RSVPStatus = "No Response"
        }
        else {
            temp.RSVPStatus = "Attending"
        }
        setRSVPData({ ...RSVPData, [RSVPData.RSVPStatus]: temp.RSVPStatus })
    }
    const toggleDeclineGuest = () => {
        const temp = RSVPData;
        if (RSVPData.RSVPStatus == "Declined") {
            temp.RSVPStatus = "No Response"
        }
        else {
            temp.RSVPStatus = "Declined"
        }
        setRSVPData({ ...RSVPData, [RSVPData.RSVPStatus]: temp.RSVPStatus })
    }

    const toggleAcceptAccompaningGuest = (id) => {
        const temp = RSVPData;
        for (let i = 0; i < RSVPData.accompanyingGuests.length; i++) {

            if (RSVPData.accompanyingGuests[i]._id == id) {

                if (RSVPData.accompanyingGuests[i].RSVPStatus == "Attending") {
                    temp.accompanyingGuests[i].RSVPStatus = "No Response"
                }
                else {
                    temp.accompanyingGuests[i].RSVPStatus = "Attending"
                }
            }
        }
        setRSVPData({ ...RSVPData, [RSVPData.accompanyingGuests]: temp.accompanyingGuests })
    }

    const toggleDeclineAccompaningGuest = (id) => {

        const temp = RSVPData;
        for (let i = 0; i < RSVPData.accompanyingGuests.length; i++) {

            if (RSVPData.accompanyingGuests[i]._id == id) {

                if (RSVPData.accompanyingGuests[i].RSVPStatus == "Declined") {
                    temp.accompanyingGuests[i].RSVPStatus = "No Response"
                }
                else {
                    temp.accompanyingGuests[i].RSVPStatus = "Declined"
                }
            }
        }
        setRSVPData({ ...RSVPData, [RSVPData.accompanyingGuests]: temp.accompanyingGuests })
    }

    const submitRSVPRequest = () => {
        window.scrollTo(100, 200)
        setSubTabLoading(true)
        // if(RSVPData.RSVPBy > today){
        //if(RSVPData.RSVPBy > today){
        try {
            axios
                .put(PUT_WEDDING_RSVP_STATUS + `/${templateID}/guest/${RSVPData._id}`, RSVPData, COOKIES_SETTINGS)
                .then((res) => {
                    if (res.status === 200) {
                        setSubTabLoading(false)
                        setSubmitStatusSuccess(true)
                        // console.log("RSVP Request status: "+res.status)
                    }
                    else {
                        setSubTabLoading(false)
                        setSubmitStatusSuccess(false)
                        // console.log("RSVP Request status: "+res)
                    }
                })
                .catch((err) => {
                    setSubTabLoading(false)
                    setSubmitStatusSuccess(false)
                    setRSVPErrorMessage(err.response.data.message)

                });
        } catch (err) {
            setSubTabLoading(false)
            setSubmitStatusSuccess(false)
            setRSVPErrorMessage(err.response.data.message)
        }
        /* }
         else{
             setSubTabLoading(false)
             setSubmitStatusSuccess(false)
             setRSVPErrorMessage("RSVP date has passed.")
         }*/
    }

    return (
        <>
            {
                phone.state.isPhoneLogged === true ?
                    <>
                        {
                            subTabLoading ?
                                (
                                    <Box
                                        sx={{
                                            width: "100%",
                                            height: "50vh",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <CircularProgress
                                            style={{ 'color': phone.state.templateMainColor }}
                                        />
                                    </Box>
                                ) :
                                (
                                    <div id="RSVP-inv-col" className='card'>

                                        <form id='RSVP-inv-form' className='card-body RSVP-inv-form' onSubmit={submitRSVPRequest}>
                                            <div className="mb-3" id="RSVP-from-inv-col">
                                                <label htmlFor="inlineFormCustomSelectPref" className="form-label" id="RSVP-inv-header"><strong>Hello {RSVPData.firstName} {RSVPData.lastName}</strong></label>
                                                <div className="input-group p-2" id="RSVP-inv-div" >
                                                    Please mark your response so that arrangements can be made for you.
                                                </div>
                                                <div className="input-group mb-2" id="RSVP-guest" >
                                                    <span className='RSVP-names'>
                                                        {RSVPData.firstName} {RSVPData.lastName}
                                                    </span>
                                                    {
                                                        RSVPData.RSVPStatus === "Attending" ?
                                                            <button type="button" onClick={toggleAcceptGuest} className="btn btn-outline-secondary RSVP-button-selected RSVP-buttons">
                                                                <CheckIcon
                                                                    sx={{
                                                                        fontSize: { xs: "180%", sm: "180%", md: "180%", lg: "180%" },
                                                                        alignItems: "center",
                                                                        padding: "0",
                                                                        margin: "0",
                                                                    }}
                                                                /> Accepted
                                                            </button>
                                                            :
                                                            <button type="button" onClick={toggleAcceptGuest} className="btn btn-outline-secondary RSVP-button-unselected RSVP-buttons">
                                                                <CheckIcon
                                                                    sx={{
                                                                        fontSize: { xs: "180%", sm: "180%", md: "180%", lg: "180%" },
                                                                        alignItems: "center",
                                                                        padding: "0",
                                                                        margin: "0",
                                                                    }}
                                                                /> Accept
                                                            </button>
                                                    }
                                                    {
                                                        RSVPData.RSVPStatus == "Declined" ?
                                                            <button type="button" onClick={toggleDeclineGuest} className="btn btn-outline-secondary RSVP-button-selected RSVP-buttons">
                                                                <CloseIcon
                                                                    sx={{
                                                                        fontSize: { xs: "180%", sm: "180%", md: "180%", lg: "180%" },
                                                                        alignItems: "center",
                                                                        padding: "0",
                                                                        margin: "0",
                                                                    }}
                                                                /> Declined
                                                            </button>
                                                            :
                                                            <button type="button" onClick={toggleDeclineGuest} className="btn btn-outline-secondary RSVP-button-unselected RSVP-buttons">
                                                                <CloseIcon
                                                                    sx={{
                                                                        fontSize: { xs: "180%", sm: "180%", md: "180%", lg: "180%" },
                                                                        alignItems: "center",
                                                                        padding: "0",
                                                                        margin: "0",
                                                                    }}
                                                                /> Decline
                                                            </button>
                                                    }
                                                </div>
                                                {
                                                    RSVPData.accompanyingGuests !== null ?
                                                        <>
                                                            <hr className='hr '></hr>
                                                            <div className="input-group mb-2" id="RSVP-accompaning-guest" >
                                                                <div className="input-group mb-2 " id="RSVP-inv-accompanying-header" >
                                                                    {Array.isArray(RSVPData.accompanyingGuests) ?
                                                                        RSVPData.accompanyingGuests.length != 0 ?
                                                                            <strong>
                                                                                <p>
                                                                                    Accompanying Guests
                                                                                </p>
                                                                            </strong>
                                                                            : ''
                                                                        : ""
                                                                    }
                                                                </div>

                                                                {
                                                                    Array.isArray(RSVPData.accompanyingGuests) ?
                                                                        RSVPData.accompanyingGuests.length != 0 ?
                                                                            RSVPData.accompanyingGuests.map((accompaningguest) =>
                                                                                <>
                                                                                    <span className='RSVP-names pt-4'>
                                                                                        {accompaningguest.firstName} {accompaningguest.lastName}
                                                                                    </span>
                                                                                    <span className='pt-4 RSVP-accompaning-guest-buttons'>
                                                                                        {
                                                                                            accompaningguest.RSVPStatus === "Attending" ?
                                                                                                <button type="button" onClick={() => toggleAcceptAccompaningGuest(accompaningguest._id)} className="btn btn-outline-secondary RSVP-button-selected RSVP-buttons shadow-none">
                                                                                                    <CheckIcon
                                                                                                        sx={{
                                                                                                            fontSize: { xs: "180%", sm: "180%", md: "180%", lg: "180%" },
                                                                                                            alignItems: "center",
                                                                                                            padding: "0",
                                                                                                            margin: "0",
                                                                                                        }}
                                                                                                    /> Accepted
                                                                                                </button>
                                                                                                :
                                                                                                <button type="button" onClick={() => toggleAcceptAccompaningGuest(accompaningguest._id)} className="btn btn-outline-secondary RSVP-button-unselected RSVP-buttons shadow-none">
                                                                                                    <CheckIcon
                                                                                                        sx={{
                                                                                                            fontSize: { xs: "180%", sm: "180%", md: "180%", lg: "180%" },
                                                                                                            alignItems: "center",
                                                                                                            padding: "0",
                                                                                                            margin: "0",
                                                                                                        }}
                                                                                                    /> Accept
                                                                                                </button>
                                                                                        }
                                                                                        {
                                                                                            accompaningguest.RSVPStatus == "Declined" ?
                                                                                                <button type="button" onClick={() => toggleDeclineAccompaningGuest(accompaningguest._id)} className="btn btn-outline-secondary RSVP-button-selected RSVP-buttons shadow-none">
                                                                                                    <CloseIcon
                                                                                                        sx={{
                                                                                                            fontSize: { xs: "180%", sm: "180%", md: "180%", lg: "180%" },
                                                                                                            alignItems: "center",
                                                                                                            padding: "0",
                                                                                                            margin: "0",
                                                                                                        }}
                                                                                                    /> Declined
                                                                                                </button>
                                                                                                :
                                                                                                <button type="button" onClick={() => toggleDeclineAccompaningGuest(accompaningguest._id)} className="btn btn-outline-secondary RSVP-button-unselected RSVP-buttons shadow-none">
                                                                                                    <CloseIcon
                                                                                                        sx={{
                                                                                                            fontSize: { xs: "180%", sm: "180%", md: "180%", lg: "180%" },
                                                                                                            alignItems: "center",
                                                                                                            padding: "0",
                                                                                                            margin: "0",
                                                                                                        }}
                                                                                                    /> Decline
                                                                                                </button>
                                                                                        }
                                                                                    </span>
                                                                                </>
                                                                            )
                                                                            : ""
                                                                        : ''
                                                                }
                                                            </div>
                                                        </>
                                                        :
                                                        <br></br>
                                                }
                                            </div>
                                            <button type="button" onClick={submitRSVPRequest} className="btn btn-primary shadow-none" id="RSVP-inv-submit">Submit</button>
                                        </form>
                                        {
                                            submitStatusSuccess !== null ?
                                                (
                                                    submitStatusSuccess === true ?
                                                        <Alert
                                                            sx={{
                                                                fontSize: { xs: "50%", sm: "60%", md: "70%", lg: "80%" },
                                                                alignItems: "center",
                                                                padding: { xs: "0% 4% 0% 4%", sm: "0% 4% 0% 4%", md: "0% 4% 0% 4%", lg: "2% 4% 2% 4%" },
                                                                margin: "0",
                                                                "& .MuiAlert-icon": {
                                                                    fontSize: { xs: 12, sm: 15, md: 20, lg: 25 }
                                                                },
                                                                borderRadius: "inherit"
                                                            }}
                                                        >
                                                            Successful
                                                        </Alert>
                                                        :
                                                        <Alert
                                                            sx={{
                                                                fontSize: { xs: "50%", sm: "60%", md: "70%", lg: "80%" },
                                                                alignItems: "center",
                                                                padding: { xs: "0% 4% 0% 4%", sm: "0% 4% 0% 4%", md: "0% 4% 0% 4%", lg: "2% 4% 2% 4%" },
                                                                margin: "0",
                                                                "& .MuiAlert-icon": {
                                                                    fontSize: { xs: 12, sm: 15, md: 20, lg: 25 }
                                                                },
                                                                borderRadius: "inherit"
                                                            }}
                                                            severity="error"
                                                        >
                                                            {RSVPErrorMessage}
                                                        </Alert>
                                                )
                                                : ""
                                        }
                                    </div>
                                )}
                    </>
                    :
                    <>
                        {
                            tabLoading ? (
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "50vh",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <CircularProgress
                                        style={{ 'color': phone.state.templateMainColor }}
                                    />
                                </Box>
                            ) : (
                                <div id="RSVP-phone-col" className='card'>
                                    <form id='RSVP-phone-form' className='card-body' onSubmit={onSubmitHandler}>
                                        {errPhoneNumber !== null ?
                                            (errPhoneNumber == true ?
                                                (
                                                    <Alert
                                                        sx={{
                                                            fontSize: { xs: "50%", sm: "60%", md: "70%", lg: "80%" },
                                                            alignItems: "center",
                                                            padding: { xs: "0% 4% 0% 4%", sm: "0% 4% 0% 4%", md: "0% 4% 0% 4%", lg: "2% 4% 2% 4%" },
                                                            margin: "0",
                                                            "& .MuiAlert-icon": {
                                                                fontSize: { xs: 12, sm: 15, md: 20, lg: 25 }
                                                            },
                                                            borderRadius: "inherit"
                                                        }}
                                                        severity="error"
                                                    >
                                                        {errorMessage}
                                                    </Alert>
                                                )
                                                : ""
                                            ) : ''
                                        }
                                        <div className="mb-3" >
                                            <label htmlFor="inlineFormCustomSelectPref" className="form-label" id="RSVP-phone-header"><strong>Please enter your phone number</strong></label>
                                            <div className="input-group mb-2" id="RSVP-phone-div" >
                                                <div id="country-start-num">
                                                    <select
                                                        className="form-select  shadow-none"
                                                        id="form-select"
                                                        aria-label="Default select"
                                                        onChange={(e) => setCountryNumber(e.target.value)}
                                                    >
                                                        {
                                                            MobileCodeNumbers.map((obj)=>{
                                                            return <option value={obj.code} selected={obj.code === countryNumber ? true : false}><p>{obj.code}</p></option>
                                                            })
                                                        }

                                                    </select>
                                                </div>

                                                <>
                                                    <input
                                                        type="text"
                                                        className="form-control form-phone-number  shadow-none"
                                                        id="form-phone-number"
                                                        placeholder="Phone"
                                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                                    ></input>
                                                </>

                                            </div>

                                        </div>
                                        <button type="button" onClick={onSubmitHandler} placeholder='Upload Photos' className="btn btn-primary shadow-none" id="RSVP-phone-submit-RSVP">Submit</button>
                                    </form>
                                </div>
                            )
                        }
                    </>
            }
        </>
    )
}

export default RSVPPhone;