import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Typography,
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from "@mui/material";

const Question = ({ question, answer, answerComponent }) => {
  return ( <Box paddingBottom={2}>
    <Accordion
      elevation={0}
      sx={{
        background: "#FFFFFF",
        boxShadow: "0px 0px 10px rgba(153, 153, 153, 0.15)",
        borderRadius: "8px",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
      >
        <Typography
          sx={{
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "21px",
            color: "#090A0A",
          }}
        >
          {question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ marginY: "10px" }}>
        <Typography
          sx={{
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "22px",
            color: "#000000",
          }}
        >
          {answerComponent? answerComponent:answer}
        </Typography>
      </AccordionDetails>
    </Accordion>
  </Box>);
};

export default Question;
