import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { Box, Stack } from '@mui/material';
import LoginDetails from "./LoginDetails";
import WeddingDetails from "./WeddingDetails";
import SideMenuBar from "./SideMenuBar";
import { WebAppTitle } from "../WebAppTitle";
import { ProfileContext } from "../../context/ProfileContext";
import LoginOrRegisterFirst from "../auth/login-register-first/LoginOrRegisterFirst";
const MyAccount = () => {
  const profile = useContext(ProfileContext);
  //console.log(profile);
  return (
    <>
      <WebAppTitle text='My Account' />
      <Box minHeight={500} style={{ margin: "40px 58px 0 58px" }}>
        {profile.isUser === false ?
          <LoginOrRegisterFirst pageName={"Account"}>

          </LoginOrRegisterFirst> :
          <>

            {/* Mobile */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <Routes>
                <Route path="/" element={< SideMenuBar />} />
                <Route path="/login-details" element={< LoginDetails />} />
                <Route path="/wedding-details" element={< WeddingDetails />} />
              </Routes>
            </Box>
            {/* Desktop */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <Stack direction={"row"}>

                <SideMenuBar></SideMenuBar>
                <Routes>
                  <Route path="/" element={< LoginDetails />} />
                  <Route path="/login-details" element={< LoginDetails />} />
                  <Route path="/wedding-details" element={< WeddingDetails />} />
                </Routes>

              </Stack>

            </Box>
          </>
        }
      </Box>
    </>
  );
};
export default MyAccount;
