import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import { Link, Typography, Radio, Stack, IconButton, Box } from "@mui/material";
import ImageListItem from "@mui/material/ImageListItem";
import { ProfileContext } from "../../../context/ProfileContext";
const InvitationComponent = (props) => {
  const profile = useContext(ProfileContext);
  return (
    <>
      <Grid
        item
        xs={10}
        sm={6}
        md={5}
        lg={3}
        key={"template-" + props.index}
        marginTop={"1rem"}
        sx={{
          backgroundColor: "#FFF",
          padding: "0",
          margin: {
            xs: "1rem 0.75rem",
            sm: "1rem 1rem",
            md: "1rem 1rem",
            lg: "1rem 1rem",
          },
          borderRadius: "8px",
          transition: "all .2s ease-in-out",
          "&:hover": {
            transform: "scale(1.01)",
          },
        }}
      >
        {
          profile.wedding.weddingWebsite.weddingTemplate._id === props.data._id ?
            <Box
              sx=
              {{
                position: "absolute",
                margin: "15px",
                padding: "5px",
                zIndex: 1,
                width: 110,
                height: 35,
                backgroundColor: "rgba(241, 163, 165, 0.8);",
                borderRadius: "7px",
              }}>


              <Typography
                sx={{
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#FFFFFF"
                }}
              >

                Your Theme
              </Typography>
            </Box> :
            <IconButton
              sx={{
                position: "absolute",
                margin: "10px",
                zIndex: 1,
                width: 24,
                height: 24,
                backgroundColor: "rgba(0,0,0,0.2)",
              }}
              onClick={() => {
                props.setSelectedTemplateId(props.data._id);
                //console.log(props.data._id);
              }}
            >
              <Radio
                checked={props.selectedTemplateId === props.data._id}
                sx={{
                  color: "white",
                  "&.Mui-checked": {
                    color: "#F1A3A5",
                  }
                }}
                name="radio-buttons"
              />

            </IconButton>
        }
        <ImageListItem
          key={props.index}
          sx={{
            borderRadius: "8px",
            cursor: "pointer",
            height: "200px!important",
            width: "100%!important",
          }}
        >
          <img
            src={props.data.icon}
            alt={props.data.name}
            loading="lazy"
          //style={{ borderRadius: "8px" }}
          //height="200px!important"
          //width="300px!important"
          />
        </ImageListItem>
        <Stack width={"100%"} direction={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <Typography
            color={"text.dark"}
            fontSize={{ xs: "3vw", sm: "1.1rem", md: "1.1rem" }}
            textAlign="left"
            marginLeft={"1rem"}
            marginTop={"1rem"}
            marginBottom={"1rem"}
            fontWeight={"bold"}
          //textTransform="uppercase"
          >
            {props.data.name}
          </Typography>
          <Link
            href={`/${props.data.uniqueName}/${props.data.uniqueName}`}
            underline="none"
            target="_blank"
          >
            <Typography
              fontStyle={"normal"}
              fontWeight={700}
              fontSize={"16px"}
              color={"#F1A3A5"}
              padding={1}
            >
              {"View"}
            </Typography>
          </Link>
        </Stack>

      </Grid>
    </>
  );
};

export default InvitationComponent;
