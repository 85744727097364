import React, { useContext, useRef } from "react";
import Box from "@mui/material/Box";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ImageListItem from "@mui/material/ImageListItem";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { AuthComponentsContext } from "../../../context/AuthComponentsContext";
import { ProfileContext } from "../../../context/ProfileContext";
import axios from "axios";
import {
  COOKIES_SETTINGS,
  FAVORITE_VENDOR,
  UNFAVORITE_VENDOR,
} from "../../../endpoints/Constants";

const VendorMediaCarousel = ({
  vendor,
  setVendor,
  setSuccessfulSnackBarText,
  successfulSnackBar,
  errorSnackBar,
  setErrorSnackBarText,
}) => {
  const authComponents = useContext(AuthComponentsContext);
  const profile = useContext(ProfileContext);

  const isFavouring = useRef(false);
  const isUnFavouring = useRef(false);
  const favoriteVendor = () => {
    if (vendor.isFavorite) {
      // Unfavorite
      if (isUnFavouring.current) return;
      isUnFavouring.current = true;
      axios
        .post(
          UNFAVORITE_VENDOR,
          {
            id: vendor._id,
          },
          COOKIES_SETTINGS
        )
        .then(() => {
          //console.log("Unfavorited vendor");
          setSuccessfulSnackBarText(
            "Vendor removed from favorites successfully"
          );
          successfulSnackBar.current();
          isUnFavouring.current = false;
        })
        .catch((error) => {
          const { response } = error;
          setErrorSnackBarText(
            "Error occurred while unfavoriting vendor: " + response.data.message
          );
          errorSnackBar.current();
          setVendor((prev) => {
            prev.isFavorite = true;
            return prev;
          });
        });
      setVendor((prev) => {
        prev.isFavorite = false;
        return prev;
      });
    } else {
      // Favorite
      if (isFavouring.current) return;
      isFavouring.current = true;
      axios
        .post(
          FAVORITE_VENDOR,
          {
            id: vendor._id,
          },
          COOKIES_SETTINGS
        )
        .then(() => {
         // console.log("favored vendor");
          setSuccessfulSnackBarText("Vendor added to favorites successfully");
          successfulSnackBar.current();
          isFavouring.current = false;
        })
        .catch((error) => {
          const { response } = error;
          setErrorSnackBarText(
            "Error occurred while favouring vendor: " + response.data.message
          );
          errorSnackBar.current();
          setVendor((prev) => {
            prev.isFavorite = false;
            return prev;
          });
        });
      setVendor((prev) => {
        prev.isFavorite = true;
        return prev;
      });
    }
  };
  const vendorMedia = [];
  vendor.images.forEach((photo) => {
    vendorMedia.push({
      isVideo: false,
      mediaUrl: photo,
    });
  });
  vendor.videos.forEach((video) => {
    vendorMedia.splice(video.index, 0, {
      isVideo: true,
      mediaUrl: video.url,
    });
  });

  const getCarouselItems = (vendorMedias) => {
    const items = [];
    for (let i = 0; i < vendorMedias.length; i += 1) {
      const carouselItem = [];
      for (let j = i; j < i + 1 && j < vendorMedias.length; j++) {
        const vendorMedia = vendorMedias[j];
        carouselItem.push(
          vendorMedia.isVideo ? (
            <Box
              sx={{
                height: {
                  xs: "150px !important",
                  md: "300px!important",
                  lg: "350px!important",
                },
                // width: "80%",
              }}
            >
              <video width="100%" height="100%" autoplay controls>
                <source src={vendorMedia.mediaUrl}></source>
              </video>
            </Box>
          ) : (
            <ImageListItem
              key={j}
              sx={{
                height: {
                  xs: "150px !important",
                  md: "300px!important",
                  lg: "350px!important",
                },

                borderRadius: "13px",
                cursor: "pointer",
              }}
            >
              <img
                src={`https://lucky-mode-6c5e.testproxyimage.workers.dev/${vendorMedia.mediaUrl}`}
                // height="300px"
                // width="300"
                // cover="true"

                loading="lazy"
                style={{ borderRadius: "10px", objectFit: "cover" }}
              />
            </ImageListItem>
          )
          // </Grid>
        );
      }
      items.push(
        <div
          key={i}
          className={
            i === 0 ? "carousel-item d-none active" : "carousel-item d-none"
          }
        >
          {carouselItem}
        </div>
      );
    }
    return items;
  };

  return (
    <div
      id={"carouselExampleControls"}
      className="carousel slide"
      data-bs-interval="false"
      data-bs-ride="carousel"
      sx={{
        marginBottom: "20px",
      }}
    >
      <div
        class="carousel-indicators"
        style={{
          position: "absolute",
          bottom: "-30px",
        }}
      >
        {vendorMedia.map((photo, index) => {
          return (
            <Box
              sx={{
                width: { xs: "5px", md: "10px" },
                height: { xs: "5px", md: "10px" },
                margin: "0 2px",
              }}
            >
              <button
                key={index}
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide-to={index}
                class={index === 0 ? "active" : "none"}
                aria-current="true"
                aria-label="Slide 1"
                style={{
                  borderRadius: "100%",
                  backgroundColor: "grey",
                  width: "100%",
                  height: "100%",
                  // opacity: "0.5",
                }}
              ></button>
            </Box>
          );
        })}
      </div>
      <div className="position-relative">
        <Box
          className="position-absolute"
          sx={{
            top: "0px",
            right: { xs: "20px", md: "160px" },
            zIndex: "2",
          }}
        >
          <span
            onClick={(e) => {
              e.stopPropagation();
              // If not logged In open Register First Dialog
              if (!profile.isUser)
                return authComponents.openRegisterFirst("favorite a vendor");
              favoriteVendor();
            }}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              padding: "1px",
              // paddingTop: "-1px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "4px",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              cursor: "pointer",
            }}
          >
            {vendor.isFavorite ? (
              <StarIcon
                sx={{
                  fontSize: { xs: "1rem", md: "1.2rem" },
                  color: "#ffb400",
                }}
              />
            ) : (
              <StarBorderIcon
                sx={{
                  fontSize: { xs: "1rem", md: "1.2rem" },
                }}
              />
            )}
          </span>
        </Box>
      </div>
      <div className="carousel-inner">{getCarouselItems(vendorMedia)}</div>
      {vendorMedia.length === 0 ? null : (
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target={"#carouselExampleControls"}
          data-bs-slide="prev"
        >
          <span
            style={{
              backgroundColor: "#F2F2F2",
              borderRadius: "10px",
              padding: "5px",
              ":hover": {
                backgroundColor: "#F1A3A5",
              },
            }}
          >
            <NavigateBeforeIcon
              style={{
                color: "#000000",
                ":hover": {
                  color: "white",
                },
              }}
              sx={{
                width: { xs: "1rem", md: "1.5rem" },
                height: { xs: "1rem", md: "1.5rem" },
              }}
            ></NavigateBeforeIcon>
          </span>
        </button>
      )}
      {vendorMedia.length === 0 ? null : (
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target={"#carouselExampleControls"}
          data-bs-slide="next"
        >
          <span
            style={{
              backgroundColor: "#F2F2F2",
              borderRadius: "5px",
              padding: "1px",
              ":hover": {
                backgroundColor: "#F1A3A5",
              },
            }}
          >
            <NavigateNextIcon
              sx={{
                width: { xs: "1rem", md: "1.5rem" },
                height: { xs: "1rem", md: "1.5rem" },
              }}
              style={{ color: "#000000" }}
            ></NavigateNextIcon>
          </span>
        </button>
      )}
    </div>
  );
};

export default VendorMediaCarousel;
