import { useEffect, useState, useRef, useContext } from "react";
import { ProfileContext } from "../../context/ProfileContext";

import { Button, Typography } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, Box } from "@mui/material";
import CustomizedSnackbars from "../../ui-common-components/ui-components/SnackBar";
import SwipeableViews from "react-swipeable-views";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { domainName } from "../../endpoints/Constants";

export default function SendMessageForm(props) {
  const profile = useContext(ProfileContext);

  /*********************Form variables********************/
  const [open, setOpen] = useState(false);

  /*********************Snackbar variables********************/
  const openSnackBar = useRef(null);
  const [message, setMessage] = useState("");
  const [sevirity, setSeverity] = useState("");
  const selectedGuest = useRef(null)

  const handleClickOpen = () => {
    setActiveStep(0)
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    props.openDialog.current = handleClickOpen;
    if(props.selectedGuest){
      selectedGuest.current = props.selectedGuest.current
    }
  });

  const [activeStep, setActiveStep] = useState(0);
  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const templateName = profile.wedding.weddingWebsite.weddingTemplate.uniqueName
  const weddingId = profile.wedding.weddingWebsite.wedding
  let invitationURL = domainName + "/" + templateName + "/" + weddingId
  
  const messagesOption = [
    {
      name: "Share Your Website",
      content:
        "You are invited to our big day! Check out our website below for more details. We can’t wait to celebrate with you! "
        + "\n" + invitationURL + "\n",
      instructions:
       "1. Choose your desired message template through the filters at the top\n"+
       "2. Once it populates on your screen, please feel free to edit the message as you see fit\n"+
       "3. Copy your message\n"+
       "4. Within WhatsApp (or your preferred method of communication), paste and send your message to your guest\n\n",
    },
    {
      name: "Remind Guests to RSVP",
      content:
        "Please RSVP for our big day through the following link. We hope you can make it!"
        + "\n" + invitationURL + "\n",
      instructions:
       "1. Choose your desired message template through the filters at the top\n"+
       "2. Once it populates on your screen, please feel free to edit the message as you see fit\n"+
       "3. Copy your message\n"+
       "4. Within WhatsApp (or your preferred method of communication), paste and send your message to your guest\n\n",
    },
    {
      name: "Ask Guests to Upload Their Photos",
      content:
        "Our big day wouldn’t have been the same without you! Please share your favorite photos on our guest board. "
        + "\n" + invitationURL + "\n",
      instructions:
       "1. Choose your desired message template through the filters at the top\n"+
       "2. Once it populates on your screen, please feel free to edit the message as you see fit\n"+
       "3. Copy your message\n"+
       "4. Within WhatsApp (or your preferred method of communication), paste and send your message to your guest\n\n",
    },
  ];

  const [selectedMessageOption, setSelectedMessageOption] = useState({});
 
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <CustomizedSnackbars
        openSnackBar={openSnackBar}
        message={message}
        severity={sevirity}
      />
      
      <Dialog
        id="login-dialog-main"
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { borderRadius: 15 },
        }}
        sx={{ background: "rgba(0,0,0,0.5)", backdropFilter: "blur(16px)" }}
      >
        <SwipeableViews
          axis={"x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          disabled={true}
        >
          <Box
            //width={{ xs: "80vw", sm: 500}}
            sx={{ overflowX: "hidden" }}
          >
            <DialogContent sx={{ padding: "0", width: "100%" }}>
              <DialogTitle
                color="text.dark"
                sx={{
                  fontSize: { xs: "5.5vw", sm: "2.2rem", md: "2rem" },
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Choose Message Option
              </DialogTitle>
              <Box
                sx={{
                  margin: "2rem",
                }}
              >
                {messagesOption.map((option, index) => {
                  return (
                    <Box
                      key={"option-"+index}
                      sx={{
                        border: "1px solid #DFDFDF",
                        borderRadius: "8px",
                        marginBottom: "2rem",
                        ":hover": {
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => {
                        setSelectedMessageOption(option);
                        if(props.whatsapp){
                          handleClose()
                          window.open(
                            "https://api.whatsapp.com/send/?phone="+ selectedGuest.current.phone +"&text="+
                            encodeURIComponent(option.content)
                            +"&type=phone_number&app_absent=0",
                            '_blank'
                          );
                        }
                        else{
                          handleStepChange(1);
                        }
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="#090A0A"
                        sx={{
                          fontWeight: "600",
                          fontSize: { xs: "0.75rem", sm: "1.25rem" },
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: { xs: "1rem", sm: "1.5rem" },
                        }}
                      >
                        {option.name}{" "}
                        <ArrowForwardIosIcon fontSize="small" sx={{}} />
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </DialogContent>
          </Box>

          <Box
            //width={{ xs: "80vw", sm: 500}}
            sx={{ overflowX: "hidden" }}
          >
            <DialogContent sx={{ padding: "0", width: "100%" }}>
              <DialogTitle
                color="text.dark"
                sx={{
                  fontSize: { xs: "5.5vw", sm: "2.2rem", md: "2rem" },
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Message Details
              </DialogTitle>
              <Typography
                variant="body2"
                color="#000"
                sx={{
                  textAlign: "left",
                  margin: { xs: "0.5rem 1rem", md: "1rem 2.5rem" },
                  backgroundColor: "#F8FAFD",
                  padding: {xs:"1rem", md:"2rem"},
                  border: "1px solid #E7ECF3",
                  borderRadius: "10px",
                  fontSize: { xs: "0.6rem", md: "1rem" },
                  fontWeight: 500,
                }}
              >
                {selectedMessageOption.content}
              </Typography>
               <Box
                sx={{
                    display:"flex",
                    justifyContent:"center"
                }}
               >

              
              <Button
                variant="text"
                fontWeight="bold"
                sx={{
                  /*marginRight: "35px",*/
                  fontSize: { xs: "0.8rem", sm: "1rem", md: "1rem" },
                  color: "text.white",
                  backgroundColor: "text.tertiary",
                  padding: { xs: "0rem 2rem", md: "0.3rem 2.3rem" },
                  height: { xs: "2.5rem" },
                  borderRadius: "2rem",
                  ":hover": {
                    backgroundColor: "text.tertiaryHover",
                  },
                  textAlign: "center",
                  textTransform: "none",
                  marginRight: "10px",
                  marginTop: "1rem",
                  width:"60%"
                }}
                onClick={() => {
                    try { 
                        navigator.clipboard.writeText(selectedMessageOption.content);
                        setSeverity("success");
                        setMessage("Link Copied Successfully");
                      } catch (error) {
                        setSeverity("error");
                        setMessage("Something went wrong with your invitation");
                      }
                      openSnackBar.current();
                }}
              >
                Copy Message
              </Button>
              </Box>

              <Box
                sx={{
                  margin: "1rem 1.5rem",
                }}
              >
                <Typography
                  color="#000"
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  Instructions:
                </Typography>
                <Typography
                  variant="body2"
                  color="#000"
                  sx={{
                    textAlign: "left",
                    fontSize: { xs: "0.6rem", md: "1rem" },
                    fontWeight: 500,
                    marginTop: "0.5rem",
                  }}
                >
                  {"1. Choose your desired message template through the filters at the top"}
                    <br/>
                  {"2. Once it populates on your screen, please feel free to edit the message as you see fit"}
                    <br/>
                  {"3. Copy your message"}
                    <br/>
                  {"4. Within WhatsApp (or your preferred method of communication), paste and send your message to your guest"}

                </Typography>

                <Typography
                  variant="body2"
                  color="#000"
                  sx={{
                    textAlign: "left",
                    fontSize: { xs: "0.6rem", md: "1rem" },
                    fontWeight: 500,
                    marginTop: "0.5rem",
                  }}
                >
                  {"*Please be aware that in order enable the RSVP and Guest Board features, you must first register your guests' numbers under your guest list."}
                </Typography>
              </Box>
            </DialogContent>
          </Box>
        </SwipeableViews>
      </Dialog>
    </Box>
  );
}
