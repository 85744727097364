import {
    Box,
    Grid,
    Paper,
    Typography,
    Switch,
    Button,
    CircularProgress,
    Container

} from '@mui/material';
import { editWebsite } from '../InvitationsApiFunctions';
import { useEffect, useState, createContext, useRef, useContext } from 'react';
import { getQuestions } from '../InvitationsApiFunctions';
import Question from './QuestionsComponent';
import QuestionDialog from './Question-dialog';
import CustomizedSnackbars from '../../../ui-common-components/ui-components/SnackBar';
import { ProfileContext } from '../../../context/ProfileContext';
export const QuestionsContext = createContext();
const QuestionAndAnswers = () => {
    const profile = useContext(ProfileContext);
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [questionsPageVisibility, setQuestionsPageVisibility] = useState(profile.wedding.weddingWebsite.QAIsVisible);
    const opendialog = useRef(null);
    const openSnackBar = useRef(null);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    useEffect(() => {
        initData();
    }, []);
    const toggle = async (event) => {
        try {
            const response = await editWebsite({
                QAIsVisible: !questionsPageVisibility,
            });
            if (response.status == 200) {

                setSeverity("success");
                setMessage("Q&A visibility changed successfully");
                setQuestionsPageVisibility(!questionsPageVisibility);
                openSnackBar.current();
            }
        } catch (error) {
            setSeverity("error");
            setMessage(error.response.data.message);
            openSnackBar.current();
        }

    }
    const initData = async () => {
        setLoading(true);
        try {
            const data = await getQuestions();
            setQuestions(data);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }


    return (
        <QuestionsContext.Provider value={{ mainList: [questions, setQuestions] }}>
            <CustomizedSnackbars
                openSnackBar={openSnackBar}
                message={message}
                severity={severity}
            />
            <QuestionDialog
                opendialog={opendialog}
                data={null}
                toedit={false}
            />
            <Box sx={{
                width: { xs: "100%", md: '65%' },
                paddingX: { md: "20px" },
                marginBottom: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                {loading === true ?
                    <CircularProgress sx={{ color: "#F1A3A5", textAlign: "center" }} size={"1.8rem"} /> :
                    <Grid container direction={"row"}>
                        {/* Bride First Name */}
                        <Grid item
                            maxHeight={500}
                            minHeight={500}
                            sx={{
                                overflowX: "scroll",
                                '&::-webkit-scrollbar': {
                                    display: "none"
                                }
                            }} xs={12} paddingX={1}>
                            {questions.length === 0 ?
                                <Container
                                    sx={{
                                        textAlign: "center",
                                        marginTop: "50px",
                                        marginBottom: "50px",
                                    }
                                    }
                                >
                                    <Typography>
                                        No Questions found!!
                                    </Typography>
                                </Container >
                                :
                                <div>
                                    {
                                        questions.map((question, index) => {
                                            return (
                                                <Question
                                                    key={index}
                                                    index={index}
                                                    data={question}
                                                >
                                                </Question>
                                            );
                                        })
                                    }

                                </div>
                            }
                        </Grid>
                        <Grid item xs={12} paddingX={1}>
                            <Paper
                                elevation={0}
                                sx={
                                    {
                                        height: "64px",
                                        backgroundColor: "#FFFFFF",
                                        boxShadow: "0px 0px 10px rgba(153, 153, 153, 0.15)",
                                        borderRadius: "8px",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        padding: 1
                                    }
                                }>
                                <Typography
                                    sx={{
                                        width: "117px",
                                        height: "18px",

                                        fontFamily: 'Montserrat',
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        fontSize: "16px",
                                        lineHeight: "18px",
                                        color: "#090A0A"
                                    }}
                                >
                                    Page Visibility
                                </Typography>
                                {/* TODO:: chage color */}
                                <Switch size='medium' onChange={toggle} checked={questionsPageVisibility} />
                            </Paper>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            paddingX={1}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Button

                                size="medium"
                                type="submit"
                                variant="contained"
                                // disabled={loading}
                                sx={{
                                    width: { xs: "100%", md: "307px" },
                                    height: "48px",
                                    marginTop: "10px",
                                    background: "#F1A3A5",
                                    borderRadius: "48px",
                                    fontWeight: "600",
                                    fontSize: "18px",
                                    lineHeight: "16px",
                                    color: "#FFF",
                                    '&:hover': {
                                        backgroundColor: '#F1A3A5',
                                        color: '#FFF',
                                    },
                                }}
                                onClick={() => { opendialog.current(); }}
                            >
                                Add question
                            </Button>
                        </Grid>
                    </Grid>
                }

            </Box>
        </QuestionsContext.Provider>
    );
};
export default QuestionAndAnswers;