import React, { useEffect, useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import ImageListItem from "@mui/material/ImageListItem";
import { ProfileContext } from "../../context/ProfileContext";
import { Box, Link } from "@mui/material";

const MyInvitation = () => {
  const profile = useContext(ProfileContext);

  const [myTemplate, setMyTemplate] = useState(null);

  // call your useEffect
  useEffect(() => {
    //console.log(profile);
    setMyTemplate(profile.wedding.weddingWebsite);
  }, [profile]);

  return (
    <>
      {myTemplate ? (
        <Grid container spacing={0} justifyContent="center">
          <Grid
            item
            xs={9}
            sm={6}
            md={5}
            lg={4}
            container
            justifyContent="start"
            marginTop={{ xs: "2rem", sm: "2rem", md: "3rem", lg: "3rem" }}
            sx={{
              backgroundColor: "#FFF",
              padding: "0",
              margin: {
                xs: "3rem 0.5rem",
                sm: "3rem 1rem",
                md: "3rem 1rem",
                lg: "3rem 1rem",
              },
              borderRadius: "8px",
              transition: "all .2s ease-in-out",
              "&:hover": {
                transform: "scale(1.01)",
              },
            }}
          >
            <Link
              href={`/${myTemplate.weddingTemplate.uniqueName}/${profile.wedding.uniqueName}`}
              underline="none"
              target="_blank"
              className="w-100"
            >
              <ImageListItem
                sx={{
                  //height: {xs:"120px!important", sm:"180px!important", md:"200px!important"},
                  borderRadius: "8px",
                  cursor: "pointer",
                  height: "200px!important",
                  width: "100%!important",
                }}
              >
                <img
                  src={myTemplate.weddingTemplate.icon}
                  alt={myTemplate.weddingTemplate.name}
                  loading="lazy"
                  style={{ borderRadius: "8px" }}
                  height="200px!important"
                  width="300px!important"
                />
              </ImageListItem>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "10px",
                }}
              >
                <Typography
                  color={"text.dark"}
                  fontSize={{ xs: "3vw", sm: "1.1rem", md: "1.2rem" }}
                  textAlign="left"
                  marginTop={"1rem"}
                  marginBottom={"1rem"}
                  fontWeight={"bold"}
                  textTransform="uppercase"
                >
                  {myTemplate.weddingTemplate.name}
                </Typography>
                <Link
                  href="#edit"
                  underline="none"
                  sx={{
                    color: "text.tertiary",
                    "&:hover": {
                      color: "text.darkBlue",
                    },
                  }}
                >
                  Edit
                </Link>
              </Box>
            </Link>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default MyInvitation;
