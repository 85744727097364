import React from "react";
import "./TemplateEleven.css";

import Questions from "../Questions/Questions";

const TemplateElevenQuestions = ({ id, data }) => {
  return (
    <>
      <div id="template-eleven-questions" className="container-fluid">
        <div className="row">
          <div className="col-3"></div>

          <div className="col-6">
            <div id="questions-header">
              <h2>Questions</h2>
            </div>
            <Questions templateID={id} questionsDataInput={data} />
          </div>

          <div className="col-3"></div>
        </div>
      </div>
    </>
  );
};

export default TemplateElevenQuestions;
