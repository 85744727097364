import { Box } from "@mui/material";

// assets
import AppleIcon from "@mui/icons-material/Apple";

//import { COOKIES_SETTINGS, FACEBOOK_USER } from "../../../endpoints/Constants";
//import axios from "axios";
import { useRef } from "react";
import CustomizedSnackbars from "../../../ui-common-components/ui-components/SnackBar";

import AppleLogin from "react-apple-login";

const AppleBtn = () => {
  const openSnackBar = useRef(null);

  return (
    <>
      <CustomizedSnackbars
        openSnackBar={openSnackBar}
        message="Error in Sign in"
        severity="error"
      />

      <AppleLogin
        clientId="com.react.apple.login"
        redirectURI={window.location.href}
        usePopup={true}
        callback={(res)=>{
            console.log(res)
        }}
        render={(renderProps) => (
          <Box
            sx={{
              border: "1px solid",
              borderColor: "text.darkGray",
              borderRadius: "0.5rem",
              padding: { xs: "0.4rem 1.5rem", md: "0.4rem 3.2rem" },
              width: "fit-content",
              ":hover": {
                cursor: "pointer",
              },
            }}
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            <AppleIcon sx={{ color: "text.darkGray", fontSize: "2rem" }} />
          </Box>
        )}
      />
    </>
  );
};

export default AppleBtn;
