import React, { useContext } from "react";
import { ProfileContext } from "../../context/ProfileContext";

import Box from "@mui/material/Box";
import { Link, Typography } from "@mui/material";
/*import appLogo from "../../assets/Lovebirds.png";
import appleStore from "../../assets/app_store.png"
import googleStore from "../../assets/google_play.png"*/
/*import cateringImage from "../../assets/webapp/home/banquet.png";
import photographyImage from "../../assets/webapp/home/photo-camera.png";
import heelsImage from "../../assets/webapp/home/high-heels.png";
import brideImage from "../../assets/webapp/home/bride.png";
import flowersImage from "../../assets/webapp/home/flower-bouquet.png";*/

import dressImage from "../../assets/webapp/home/vendor-categories/Dress.png";
import makeupImage from "../../assets/webapp/home/vendor-categories/Makeup.png";
import hairImage from "../../assets/webapp/home/vendor-categories/Hair.png";
import WeddingPlannerImage from "../../assets/webapp/home/vendor-categories/DJ.png";
import cameraImage from "../../assets/webapp/home/vendor-categories/photo-camera.png";


import weddingDayImage from "../../assets/webapp/home/wedding-day.png";
import rsvpImage from "../../assets/webapp/home/rsvp.png";
import guestsBookImage from "../../assets/webapp/home/guests-book.png";
import VenueCarousel from "./VenueCarousel";
import GetStartedCarousel from "./GetStartedCarousel";
import WeddingSection from "./WeddingSection";
import InvitationSectionController from "./InvitationSectionController";
import SectionDivider from "./SectionDivider";

const Home = () => {
  const profile = useContext(ProfileContext);
  /*const goToGoogleStore =()=>{
    window.location.href = "https://play.google.com/store/apps/details?id=com.apessmartsolutions.loveBirds"
  }

  const goToAppleStore =()=>{
    window.location.href = "https://apps.apple.com/eg/app/lovebirds-the-wedding-guide/id1622993002"
  }*/

  // Create wedding team section
  const weddingTeamIcons = [
    {
      imgSrc: dressImage,
      txt: "DRESS",
      id: "628a64b03308c308c63e441d"
    },
    {
      imgSrc: makeupImage,
      txt: "MAKEUP",
      id: "628a64f63308c3943e3e443d"
    },
    {
      imgSrc: hairImage,
      txt: "HAIR",
      id: "628a64d43308c3330f3e442d"
    },
    {
      imgSrc: WeddingPlannerImage,
      txt: "WEDDING PLANNER",
      id: "628a65183308c31c073e444d"
    },
    {
      imgSrc: cameraImage,
      txt: "PHOTOGRAPHY",
      id: "628a6aab3308c36ec63e4471"
    },
  ];

  // Wedding planning tools
  const weddingToolsIcons = [
    {
      imgSrc: weddingDayImage,
      txt: "Journey",
      bgColor: "#C3E0EF",
      href:""
    },
    {
      imgSrc: rsvpImage,
      txt: "Checklist",
      bgColor: "#589BD5",
      href:"checklist"
    },
    {
      imgSrc: guestsBookImage,
      txt: "Invitations",
      bgColor: "#AB9E72",
      href:"invitations"
    },
  ];

  //console.log(profile);
  return (
    <div id="home-page-container">
      <Box>
        <Box
          sx={{
            paddingTop: {xs:"0.75rem", lg:"1rem"},
            height: { xs: "50vw", sm: "40vw", md: "38vw", lg: "38vw" },
          }}
        >
          <GetStartedCarousel />
        </Box>
        {profile.isUser ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "2rem",
            }}
          >
            <WeddingSection />
            <SectionDivider />
          </Box>
        ) : null} {/* TODO: See if we need to open wedding setup modal */}
        {/********************************************************************************************************/}
        {/********************************************************************************************************/}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "0rem",
          }}
        >
          <Typography
            color={"primary.main"}
            fontSize={{ xs: "5vw", sm: "2rem", md: "2.3rem" }}
            fontWeight={"bold"}
            textAlign="center"
            paddingTop={"1rem"}
            paddingBottom={"0rem"}
          >
            FIND YOUR PERFECT VENUE
          </Typography>
          <Typography
            color={"text.darkBlue"}
            fontSize={{ xs: "3vw", sm: "1.1rem", md: "1.2rem" }}
            textAlign="center"
            marginTop="1rem"
            marginLeft={"1rem"}
            marginRight={"1rem"}
          >
            Find the best wedding venues based on your style, budget &
            destination
          </Typography>

          <Box
            sx={{ marginBottom: "0rem", width: "100%", display: "contents" }}
          >
            <VenueCarousel />
          </Box>

          <SectionDivider />
        </Box>

        {/********************************************************************************************************/}
        {/********************************************************************************************************/}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "0rem",
          }}
        >
          <Typography
            color={"primary.main"}
            fontSize={{ xs: "5vw", sm: "2rem", md: "2.3rem" }}
            fontWeight={"bold"}
            textAlign="center"
            paddingTop={"2rem"}
            paddingBottom={"0rem"}
          >
            CREATE YOUR WEDDING TEAM
          </Typography>
          <Typography
            color={"text.darkBlue"}
            fontSize={{ xs: "3vw", sm: "1.1rem", md: "1.2rem" }}
            textAlign="center"
            marginBottom={"1rem"}
            marginLeft={"1rem"}
            marginRight={"1rem"}
          >
            Find vendors you can trust to achieve your vision
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              marginBottom: "0rem",
            }}
          >
            {
              /*, width:"12%" */
              weddingTeamIcons.map((icon, index) => {
                return (
                  <Link
                  key={"wedding-team-"+index}
                  href={"/vendor-category/"+icon.id}
                  underline="none"
                  >
                  
                  <Box
                    key={"team-" + index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: { xs: "150px", sm: "200px", md: "150px" },
                      alignItems: "center",
                      transition: "all .2s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.01)",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "rgba(179, 160, 112, 0.5)",
                        borderRadius: "7px",
                        margin: "1rem 3rem",
                      }}
                    >
                      <img src={icon.imgSrc} width="87px" height="86px" alt="..." className="p-2" />
                    </Box>
                    <Typography
                      color={"text.darkBlue"}
                      fontSize={{ xs: "3vw", sm: "1.1rem", md: "0.9rem" }}
                      fontWeight="600"
                      textAlign="center"
                      marginBottom={"1rem"}
                      width="85%"
                      textTransform={"uppercase"}
                    >
                      {icon.txt}
                    </Typography>
                  </Box>
                  </Link>
                );
              })
            }
            <Link
              href={"/vendor-category"}
              underline="none"
            >
           
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { xs: "150px", sm: "200px", md: "150px" },
                alignItems: "center",
                transition: "all .2s ease-in-out",
                "&:hover": {
                  transform: "scale(1.01)",
                },
              }}
            >
              <Box
                sx={{
                  backgroundColor: "rgba(179, 160, 112, 0.5)",
                  borderRadius: "7px",
                  margin: "1rem 3rem",
                  color: "primary.main",
                  width: "87px",
                  height: "86px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <i
                  className="fa fa-ellipsis-h p-0"
                  style={{ fontSize: "60px" }}
                ></i>
              </Box>
              <Typography
                color={"text.darkBlue"}
                fontSize={{ xs: "3vw", sm: "1.1rem", md: "0.9rem" }}
                fontWeight="600"
                textAlign="center"
                marginBottom={"1rem"}
                width="85%"
                textTransform={"uppercase"}
              >
                View more
              </Typography>
            </Box>
          </Link>
          </Box>
          <SectionDivider />
        </Box>

        {/********************************************************************************************************/}
        {/********************************************************************************************************/}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "0rem",
          }}
        >
          <Typography
            color={"primary.main"}
            fontSize={{ xs: "5vw", sm: "2rem", md: "2.3rem" }}
            fontWeight={"bold"}
            textAlign="center"
            paddingTop={"2rem"}
            paddingBottom={"0rem"}
          >
            WEDDING PLANNING TOOLS
          </Typography>
          <Typography
            color={"text.darkBlue"}
            fontSize={{ xs: "3vw", sm: "1.1rem", md: "1.2rem" }}
            textAlign="center"
            marginBottom={"1rem"}
          >
            Use our planning tools to guide you throughout your wedding journey
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
              marginBottom: "0rem",
            }}
          >
            {weddingToolsIcons.map((icon, index) => {
              return (
                <Link
                  key={"wedding-tool-"+index}
                  href={"/"+icon.href}
                  underline="none"
                >
               
                <Box
                  key={"tool-" + index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: { xs: "180px", sm: "250px", md: "300px" },
                    alignItems: "center",
                    transition: "all .2s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.01)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: icon.bgColor,
                      borderRadius: "7px",
                      margin: "1rem 3rem",
                    }}
                  >
                    <img
                      src={icon.imgSrc}
                      width="147px"
                      alt="..."
                      className="ps-4 pe-4 pt-2 pb-2"
                    />
                  </Box>
                  <Typography
                    color={"text.darkBlue"}
                    fontSize={{ xs: "3vw", sm: "1.1rem", md: "0.9rem" }}
                    fontWeight="600"
                    textAlign="center"
                    marginBottom={"1rem"}
                    width="85%"
                    textTransform={"uppercase"}
                  >
                    {icon.txt}
                  </Typography>
                </Box>
                </Link>
              );
            })}
          </Box>
        </Box>

        {profile.isUser ? <InvitationSectionController /> : null}
      </Box>
    </div>
  );
};
export default Home;

/*


  <Box height={"100vh"} sx={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
  <Box  height={"50%"} sx={{display:"flex", flexDirection:"column", justifyContent:"space-around", textAlignment:"center"}}>

    <Box sx={{display:"flex", flexDirection:"row", justifyContent:"center"}}  paddingTop={"2rem"} marginTop={"3rem"}>
      <Box >
          <img src={appLogo} alt="" width={160} height={80} />
      </Box>
    </Box>

    <Typography
        color={"primary.main"}
        fontSize={{xs:"4.5vw", sm:"3vw", md:"2vw"}}
        fontWeight={"bold"}
        textAlign="center"
        paddingTop={"2rem"}
        paddingBottom={"1rem"}
      >
        Website Coming Soon...
      </Typography>
    <Typography
        color={"primary.main"}
        fontSize={{xs:"3.5vw", sm:"3vw", md:"2vw"}}
        width={"75%"}
        textAlign="center"
        sx={{display:"flex", alignSelf:"center"}}

      >
        Please download Lovebirds: The Wedding Guide App to access all features
      </Typography>
      <Box sx={{display:"flex", flexDirection:{xs:"column", sm:"row", md:"row"}, marginTop:"2rem", justifyContent:"center", alignItems:"center"}}>
          <a href="https://apps.apple.com/eg/app/lovebirds-the-wedding-guide/id1622993002" onClick={(e)=>{e.preventDefault()}}>
            <img src={appleStore} alt="" width={160} height={80} onClick={goToAppleStore}/>
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.apessmartsolutions.loveBirds" onClick={(e)=>{e.preventDefault()}}>
            <img src={googleStore} alt="" width={155} height={63} onClick={goToGoogleStore} />
          </a>
      </Box>

    </Box>
    </Box>
  */
