import React, {
  useEffect, useState,
} from "react";
import "./TemplateFive.css";

import GuestBoard from "../GuestBoard/GuestBoard"
import { updateHeight } from "../TemplatesCommonFunctions";

const TemplateFiveGuestBoard = ({ id }) => {
  const [height, setHeight] = useState(null)
  useEffect(() => {
    window.addEventListener("resize", ()=>{updateHeight("templateFive", setHeight)});
    updateHeight("templateFive", setHeight)
  }, []);

  return (
    <>
     {
        height === null ? null:

      <div id="template-five-guest-board" className='container-fluid'>
        <div className='d-flex justify-content-center'>
  
          <div className="w-50" id="guest-board-five-inner-div">

            <div id="guest-board-header">
              <h2>
                Guest Board
              </h2>
            </div>
            <div style={{height: height}}>
              <GuestBoard
                templateID={id}
                />
            </div>
          </div>
        </div>
      </div>
  }
    </>
  );
};

export default TemplateFiveGuestBoard;
