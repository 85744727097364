import React, { useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import DesktopFooter from "./DesktopFooter";
import { Link, Typography } from "@mui/material";

const PrivacyLinksSection = () => {
  return (
    <Box sx={{width:"95%",        justifySelf: "center", marginBottom:"2rem"
}}>
      <Box sx={{
        display:"flex",
        justifyContent:"flex-start",
        flexDirection:{xs:"column", md:"row"}
      }}>
        <Link
          href="/about-us"
          underline="none"
          sx={{
            color: "text.white",
            marginRight:"2rem",
            "&:hover": {
              color: "#0c2950",
            },
          }}
        >
          <Typography
            sx={{
              margin: "0.5rem 0rem",
            }}
          >
            About us
          </Typography>
        </Link>


        <Link
          href="/contact-us"
          underline="none"
          sx={{
            color: "text.white",
            marginRight:"2rem",
            "&:hover": {
              color: "#0c2950",
            },
          }}
        >
          <Typography
            sx={{
              margin: "0.5rem 0rem",
            }}
          >
            Contact us
          </Typography>
        </Link>

        <Link
          href="/faqs"
          underline="none"
          sx={{
            color: "text.white",
            marginRight:"2rem",
            "&:hover": {
              color: "#0c2950",
            },
          }}
        >
          <Typography
            sx={{
              margin: "0.5rem 0rem",
            }}
          >
            FAQs
          </Typography>
        </Link>

        <Link
          href="/privacy-policy"
          underline="none"
          sx={{
            color: "text.white",
            marginRight:"2rem",
            "&:hover": {
              color: "#0c2950",
            },
          }}
        >
          <Typography
            sx={{
              margin: "0.5rem 0rem",
            }}
          >
            Privacy Policy
          </Typography>
        </Link>

        <Link
          href="/terms-and-conditions"
          underline="none"
          sx={{
            color: "text.white",
            marginRight:"2rem",
            "&:hover": {
              color: "#0c2950",
            },
          }}
        >
          <Typography
            sx={{
              margin: "0.5rem 0rem",
            }}
          >
            Terms and conditions
          </Typography>
        </Link>
        <Typography
        sx={{
            margin: "0.5rem 0rem",
            color: "text.white",
            fontSize:{xs:"4vw", sm:"2vw", md:"1rem"}
          }}
        >© 2022 LOVEBIRDS. All Rights Reserved.</Typography>
      </Box>
    </Box>
  );
};
export default PrivacyLinksSection;
