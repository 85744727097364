import React, { useRef, useContext } from "react";
import { Box, Stack, Card, Radio, Typography } from '@mui/material';
import CheckListDialog from "./check-list-dialog";
import { CheckListContext } from "./CheckList";
import { editCheckListItem } from "./check-list-api-functions";

const CheckListItem = (props) => {
    const { mainList } = useContext(CheckListContext);
    const [checkList, setCheckList] = mainList;
    const opendialog = useRef(null);
    const deadline = new Date(props.data.deadline);
    return (
        <>
            <CheckListDialog
                opendialog={opendialog}
                data={props.data}
                toedit={true}
            />
            <Card
                onClick={() => {
                    opendialog.current();
                }}
                sx={{
                    padding: "16px 12px",
                    height: "118px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    boxShadow: "0px 0px 10px rgba(153, 153, 153, 0.15)",
                    borderRadius: "8px",
                    transition: "all .2s ease-in-out",  
                    ':hover':{
                        transform: "scale(1.01)",
                        cursor:"pointer"
                    }

                }}
                elevation={1}
            >

                <Stack display={"flex"} alignItems={"center"} direction={"row"} width={"100%"}>
                    <Box width={"10%"} paddingRight={1}>
                        <Radio
                            sx={{
                                color: "#CDCFD0",

                                width: "100%",
                                '&.Mui-checked': {
                                    color: "#F1A3A5",
                                },
                            }}
                            checked={props.data.status === "completed"}
                            onClick={async (e) => {
                                e.stopPropagation();
                                const response = await editCheckListItem(
                                    {
                                        id: props.data._id,
                                        status: props.data.status === "incompleted" ? "completed" : "incompleted",
                                    },
                                );

                                let newCheckList = [...checkList];
                                const index = newCheckList.findIndex(item => item._id === props.data._id);
                                newCheckList[index] = response;
                                setCheckList(newCheckList);


                            }}

                        />
                    </Box>
                    <Stack direction={"column"} spacing={1} width={"90%"} paddingLeft={1}>
                        <Typography
                            className={props.data.status === "completed" ? "text-muted" : ""}
                            width={"100%"}
                            height={"18px"}
                            color={"#878787"}
                            fontWeight={400}
                            fontSize={"14px"}
                            lineHeight={"18px"}
                        >
                            {props.data.deadline === "" ? "" : `${deadline.getDate()}-${deadline.getMonth() + 1}-${deadline.getFullYear()}`}
                        </Typography>
                        <Typography
                            className={props.data.status === "completed" ? "text-muted" : ""}
                            width={"100%"}
                            height={"18px"}
                            color={"#090A0A"}
                            fontWeight={600}
                            fontSize={"16px"}
                            lineHeight={"18px"}
                            sx={{
                                textDecoration: props.data.status === "completed" ? "line-through" : "none",
                                wordWrap: "break-word",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: "1",
                                WebkitBoxOrient: "vertical",
                            }}
                        >
                            {props.data.title}
                        </Typography>
                        <Typography
                            className={props.data.status === "completed" ? "text-muted" : ""}
                            sx={{
                            
                                wordWrap: "break-word",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: "2",
                                WebkitBoxOrient: "vertical",
                            }}
                            width={"100%"}
                            height={"40px"}
                            color={"#000000"}
                            fontWeight={400}
                            fontSize={"14px"}
                            lineHeight={"20px"}

                        >
                            {props.data.description}
                        </Typography>

                    </Stack>
                </Stack>
            </Card>
        </>
    );
};
export default CheckListItem;