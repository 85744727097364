import React, { useEffect, useState, useContext } from "react";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import { WebAppTitle } from "../../WebAppTitle";
import axios from "axios";
import { GET_WEBSITE_VENDOR_CATEGORY, GET_FAVORITE_VENDORS, COOKIES_SETTINGS } from "../../../endpoints/Constants";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import VendorCategoryCard from "./vendor-category-card";
import { Chip, Stack } from "@mui/material";
import { AuthComponentsContext } from "../../../context/AuthComponentsContext";
import { ProfileContext } from "../../../context/ProfileContext";
import { VendorsContext } from "../../../context/VendorsContext";

const VendorCategories = () => {

  const authComponents = useContext(AuthComponentsContext);
  const profile = useContext(ProfileContext);
  const vendorsContext = useContext(VendorsContext);

  // States
  const [fetchFavorites, setFetchFavorites] = useState(false || vendorsContext.navigationFromFavorites);
  const [vendorCategory, setVendorCategory] = useState([]);
  const [isLoadingVendorCategory, setIsLoadingVendorCategory] = useState(true);

  //Use Effect to get categories
  useEffect(() => {
    setIsLoadingVendorCategory(true);
    if(!fetchFavorites)
      axios
        .get(GET_WEBSITE_VENDOR_CATEGORY, COOKIES_SETTINGS )
        .then((response) => {
          //console.log(response.data);
          setVendorCategory(response.data);
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => setIsLoadingVendorCategory(false));
    if(fetchFavorites)
      axios
        .get(GET_FAVORITE_VENDORS, COOKIES_SETTINGS )
        .then((response) => {
          //console.log(response.data);
          setVendorCategory(response.data);
        })
        .catch((error) => {
          //console.log(error);
        })
        .finally(() => setIsLoadingVendorCategory(false));
  }, [fetchFavorites]);

  return (
    <>
      <WebAppTitle text="Vendors" />
      <Box minHeight="500px" sx={{ margin: {md:"0 58px 58px 58px", xs:'0 30px 30px 30px'} }}>
        <Grid container marginTop={5} justifyContent="center">
          <Grid item xs={12}>
            <Stack
              direction={{ xs: "row" }}
              spacing={1}
              justifyContent={"center"}
            >
              <Chip
                label={
                  <Typography
                    sx={{
                      fontSize: { xs: "0.8rem", md: "1rem" },
                    }}
                  >
                    Find a Vendor
                  </Typography>
                }
                sx={{
                  color: !fetchFavorites ? "#FFF" : "#475467",
                  backgroundColor: !fetchFavorites ? "#F1A3A5" : "transparent",
                  borderRadius: "7px",
                  width: "150px",
                  height: "44px",
                  // pointerEvents: !fetchFavorites ? "none" : null,
                  "&:hover": {
                    backgroundColor: "#F1A3A5",
                    color: "#FFF",
                  },
                }}
                onClick={() => {
                  setFetchFavorites(false)
                  vendorsContext.setNavigationFromFavorites(false)
                }}
              />
              <Chip
                label={
                  <Typography
                    sx={{
                      fontSize: { xs: "0.8rem", md: "1rem" },
                    }}
                  >
                    My Vendors
                  </Typography>
                }
                sx={{
                  color: fetchFavorites ? "#FFF" : "#475467",
                  backgroundColor: fetchFavorites ? "#F1A3A5" : "transparent",
                  borderRadius: "7px",
                  width: "150px",
                  height: "44px",
                  "&:hover": {
                    backgroundColor: "#F1A3A5",
                    color: "#FFF",
                  },
                  // pointerEvents: fetchFavorites ? "none" : null,
                }}
                onClick={() => {
                  
                  if (!profile.isUser) {
                    return authComponents.openRegisterFirst('view your favorite vendors');
                  }
                  setFetchFavorites(true)
                  vendorsContext.setNavigationFromFavorites(true)
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {isLoadingVendorCategory ? (
              <Container
                sx={{
                  textAlign: "center",
                  marginTop: "50px",
                }}
              >
                <CircularProgress></CircularProgress>
              </Container>
            ) : (
              <Grid container marginTop={2} spacing={1}>
                {vendorCategory.length === 0 ? (
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      No vendors found
                    </Typography>
                  </Grid>
                ) : (
                  vendorCategory.map((vendorCategory, index) => {
                    return (
                      <VendorCategoryCard
                        vendorCategory={vendorCategory}
                        key={index}
                        isFromFavorites={fetchFavorites}
                        setCurrentVendorCategory={vendorsContext.setCurrentVendorCategory}
                      />
                    );
                  })
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default VendorCategories;
