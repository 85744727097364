import React, { useContext } from "react";
import { ProfileContext } from "../../context/ProfileContext";

import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

import "./Home.css";
import InvitationSectionGrid from "./InvitationSectionGrid";
import MyInvitation from "./MyInvitation";
import SectionDivider from "./SectionDivider";

const InvitationSectionController = () => {
  const profile = useContext(ProfileContext);

  return (
    <>
      {!profile.wedding || !profile.wedding.weddingWebsite || !profile.wedding.weddingWebsite.weddingTemplate ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "0rem",
            marginBottom: "5rem",
          }}
        >
          <SectionDivider />
          <Typography
            color={"primary.main"}
            fontSize={{ xs: "5vw", sm: "2rem", md: "2.3rem" }}
            fontWeight={"bold"}
            textAlign="center"
            paddingTop={"1rem"}
            paddingBottom={"0rem"}
          >
            FIND YOUR INVITATION TEMPLATE
          </Typography>
          <Typography
            color={"text.darkBlue"}
            fontSize={{ xs: "3vw", sm: "1.1rem", md: "1.2rem" }}
            textAlign="center"
            marginTop="1rem"
            marginLeft={"1rem"}
            marginRight={"1rem"}
          >
            Find website invitation templates that you can sent to your guests
          </Typography>

          <Box
            sx={{ marginBottom: "0rem", width: "100%", display: "contents" }}
          >
            <InvitationSectionGrid />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "0rem",
          }}
        >
          <SectionDivider />
          <Typography
            color={"primary.main"}
            fontSize={{ xs: "5vw", sm: "2rem", md: "2.3rem" }}
            fontWeight={"bold"}
            textAlign="center"
            paddingTop={"1rem"}
            paddingBottom={"0rem"}
          >
            YOUR INVITATION TEMPLATE
          </Typography>

          <Box
            sx={{ marginBottom: "0rem", width: "100%", display: "contents" }}
          >
            <MyInvitation />
          </Box>
        </Box>
      )}
    </>
  );
};

export default InvitationSectionController;
