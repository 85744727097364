import React, { useEffect, useState, useRef } from "react";
import { Typography, Stack, Button, Box, Avatar, Rating } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import PublicIcon from "@mui/icons-material/Public";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import AlertDialogSlide from "../../ui-common-components/ui-components/AlertDialogSlide";
import CustomizedSnackbars from "../../ui-common-components/ui-components/SnackBar";
import { Facebook, Instagram } from "@mui/icons-material";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import {
  TOGGLE_DELETE_VENDOR,
  COOKIES_SETTINGS,
  GET_VENDORS,
} from "../../endpoints/Constants";
import AddVendorDialog from "./AddVendorDialog";
import PaginationCustom from "../../ui-common-components/ui-components/PaginationCustom";

const VendorsRecycleBin = () => {
  /**************************Page variables********************************/
  const [vendors, setVendors] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);

  /**************************Form Dialog variables********************************/
  const openDialog = useRef(null);

  /**************************Snackbar variables********************************/
  const openSnackBar = useRef(null);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSevirity, setSnackBarSevirity] = useState("");

  /**************************Alert Dialog variables********************************/
  const openAlertDialog = useRef(null);
  const [toDeleteId, setToDeleteId] = useState(null);

  /********************************************************************************/
  const [totalPages, setTotalPages] = useState(-1);

  const [changePage, setChangePage] = useState(1);

  const [paginationLoading, setPaginationLoading] = useState(true);

  useEffect(() => {
    if (changePage === 1) {
      localStorage.setItem("LBCurrentPage", 1);
      setChangePage(changePage + 1);
    }
  }, [changePage]);

  useEffect(() => {
    getVendors("");
  }, [changePage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getVendors("");
  }, []);
  /**************************API CALL FUNCTIONS********************************/
  const getVendors = async (name) => {
    var url = GET_VENDORS + `?page=` + localStorage.getItem("LBCurrentPage");
    if (name === "") {
      url += `&isDeleted=true`;
    } else {
      url += `&name=${name}&isDeleted=true`;
    }

    setPageLoading(true);
    try {
      const response = await axios.get(url, COOKIES_SETTINGS);
      //console.log(response.data);
      // console.log("Vendors", response.data.docs);
      setVendors(response.data.docs);
      setPaginationLoading(true);
      setTotalPages(response.data.totalPages);
      setPaginationLoading(false);
    } catch (error) {
      //console.log(error);
    }
    setPageLoading(false);
  };

  const toggleDelete = async () => {
    try {
      await axios.put(
        TOGGLE_DELETE_VENDOR,
        {
          id: toDeleteId,
        },
        COOKIES_SETTINGS
      );
      let flagForLessPage = false;

      if (vendors.length === 1) {
        await localStorage.setItem(
          "LBCurrentPage",
          localStorage.getItem("LBCurrentPage") - 1
        );
        flagForLessPage = true;
      }
      getVendors("");
      setSnackBarMessage("Vendor is restored successfully");
      setSnackBarSevirity("success");
      openSnackBar.current();

      if (flagForLessPage) setChangePage(changePage + 1);
    } catch (error) {
      //console.log(error);
      setSnackBarMessage("An error occcured");
      setSnackBarSevirity("error");
      openSnackBar.current();
    }
    // console.log("toggleDelete");
  };
  /***************************************************************************/

  return (
    <div id="dashboard-vendors" className="dashboard-vendors w-100">
      <AddVendorDialog openDialog={openDialog} />
      <AlertDialogSlide
        confirmAlertRef={toggleDelete}
        openAlertDialog={openAlertDialog}
        title={"Alert"}
        description={"Are you sure you want to restore this vendor?"}
        actionText={"Restore"}
        snackBarSevirity={"error"}
      />
      <CustomizedSnackbars
        openSnackBar={openSnackBar}
        message={snackBarMessage}
        severity={snackBarSevirity}
      />
      <Stack
        direction={{ xs: "column", sm: "row" }}
        height={{ xs: 180, sm: 60, md: 60, lg: 60 }}
        borderRadius={2}
        sx={{ backgroundColor: "#FFF" }}
        display={"flex"}
        alignContent={"center"}
        justifyContent={{
          sm: "space-between",
          xs: "center",
        }}
        alignItems={"center"}
      >
        <Typography
          margin={1}
          marginLeft={{ sm: 1, md: 3, lg: 3 }}
          color={"primary.main"}
          fontSize={20}
          fontWeight={"bold"}
        >
          Vendors
        </Typography>
        <Box margin={3}>
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{ fontSize: { xs: "0.75rem", sm: "0.75rem", md: "1rem" } }}
          >
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              href="../"
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Home
            </Link>
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              href="/dashboard/vendors"
            >
              <ShoppingBagIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Vendors
            </Link>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: { xs: "0.75rem", sm: "0.75rem", md: "1rem" },
              }}
              color={"primary.main"}
            >
              <DeleteIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Recycle Bin
            </Typography>
          </Breadcrumbs>
        </Box>
      </Stack>
      <Box>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            borderRadius: 2,
            border: "solid 1px",
            borderColor: "primary.border",
            marginTop: "1rem",
          }}
          elevation={0}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={12}>
                    <Stack
                      direction={"row"}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <TextField
                        id="standard-search"
                        label="Search"
                        type="search"
                        variant="outlined"
                        onChange={(e) => getVendors(e.target.value)}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderRadius: `55px`,
                            },
                          },
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pageLoading ? (
                  <TableRow>
                    <TableCell
                      sx={{
                        width: "100%",
                        height: "70vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  vendors.map((vendor, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", md: "row" },
                          borderBottom: "0.5px solid #97cafc",
                        }}
                      >
                        <TableCell
                          sx={{
                            border: 0,
                            width: { xs: "100%", md: "20%" },
                          }}
                        >
                          <Stack
                            direction={{ xs: "column", md: "row" }}
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <Avatar
                              alt="Remy Sharp"
                              src={vendor.icon}
                              sx={{ width: 60, height: 60 }}
                            />
                            <Stack
                              direction={"column"}
                              display={"flex"}
                              alignItems={{ xs: "center", md: "start" }}
                            >
                              <Typography
                                marginLeft={{ xs: "0rem", md: "1rem" }}
                                fontSize={20}
                                color={"primary.main"}
                                fontWeight={"bold"}
                              >
                                {vendor.name}
                              </Typography>
                              <Typography
                                marginLeft={{ xs: "0rem", md: "1rem" }}
                                fontSize={12}
                                color={"#808080"}
                              >
                                {vendor.category.name}
                              </Typography>
                            </Stack>
                          </Stack>
                        </TableCell>

                        <TableCell
                          sx={{
                            border: 0,
                            width: { xs: "100%", md: "15%" },
                          }}
                        >
                          <Stack direction={{ xs: "row", md: "column" }}>
                            <Stack
                              width={"50%"}
                              direction={"column"}
                              paddingRight={{ xs: "1rem", md: "0rem" }}
                              display={"flex"}
                              alignItems={{ xs: "center", md: "start" }}
                            >
                              <Typography fontSize={13} color={"#9E9E9E"}>
                                Area
                              </Typography>
                              <Typography
                                fontSize={15}
                                color={"#212121"}
                                fontWeight={"bold"}
                                marginBottom={"1rem"}
                              >
                                {vendor.area}
                              </Typography>
                            </Stack>
                            <Stack
                              width={"50%"}
                              direction={"column"}
                              paddingLeft={{ xs: "1rem", md: "0rem" }}
                              display={"flex"}
                              alignItems={{ xs: "center", md: "start" }}
                            >
                              <Typography fontSize={13} color={"#9E9E9E"}>
                                Phone
                              </Typography>
                              <Typography
                                fontSize={15}
                                color={"#212121"}
                                fontWeight={"bold"}
                              >
                                {vendor.phone}
                              </Typography>
                            </Stack>
                          </Stack>
                        </TableCell>

                        <TableCell
                          sx={{
                            border: 0,
                            width: { xs: "100%", md: "10%" },
                          }}
                        >
                          <Stack
                            direction={"column"}
                            spacing={4}
                            display={"flex"}
                            alignItems={{ xs: "center", md: "start" }}
                          >
                            <Typography
                              fontSize={13}
                              color={"#9E9E9E"}
                              paddingLeft={"1px"}
                            >
                              Links
                            </Typography>
                            <Stack direction={"row"}>
                              <a
                                href={vendor.websiteUrl}
                                rel="noreferrer"
                                target="_blank"
                              >
                                <PublicIcon
                                  sx={{
                                    mr: 1,
                                    color: "primary.main",
                                    fontSize: "1.5rem",
                                  }}
                                />
                              </a>

                              <a
                                href={vendor.instagramLink}
                                rel="noreferrer"
                                target="_blank"
                              >
                                <Instagram
                                  sx={{
                                    mr: 1,
                                    color: "rgb(216, 67, 21)",
                                    fontSize: "1.5rem",
                                  }}
                                />
                              </a>

                              <a
                                href={vendor.facebookLink}
                                rel="noreferrer"
                                target="_blank"
                              >
                                <Facebook
                                  sx={{
                                    mr: 1,
                                    color: "rgb(33, 150, 243)",
                                    fontSize: "1.5rem",
                                  }}
                                />
                              </a>
                            </Stack>
                          </Stack>
                        </TableCell>
                        <TableCell
                          sx={{
                            border: 0,
                            width: { xs: "100%", md: "20%" },
                          }}
                        >
                          <Stack
                            direction={{ xs: "column", md: "row" }}
                            alignItems={"center"}
                          >
                            <Typography
                              fontSize={13}
                              color={"#9E9E9E"}
                              margin={"1rem"}
                            >
                              Rating
                            </Typography>
                            <Rating
                              name="half-rating-read"
                              value={vendor.rating}
                              defaultValue={2.5}
                              precision={0.1}
                              readOnly
                            />
                          </Stack>
                        </TableCell>

                        <TableCell
                          sx={{
                            border: 0,
                            width: { xs: "100%", md: "35%" },
                          }}
                        >
                          <Stack
                            direction={"row"}
                            display={"flex"}
                            justifyContent={"center"}
                          >
                            <Button
                              color="error"
                              sx={{ width: "50%", margin: "1rem" }}
                              variant="outlined"
                              onClick={() => {
                                openAlertDialog.current();
                                setToDeleteId(vendor._id);
                              }}
                            >
                              Restore
                            </Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {!paginationLoading &&
            (totalPages === 0 ||
            totalPages === -1 ||
            (totalPages === 1 && vendors.length === 0) ? (
              <Typography textAlign={"center"}>No Vendors found!</Typography>
            ) : (
              <Box
                onClick={(e) => {
                  if (totalPages !== 1) {
                    e.preventDefault();
                    setChangePage(changePage + 1);
                  }
                }}
              >
                <PaginationCustom totalPages={totalPages} />
              </Box>
            ))}
        </Paper>
      </Box>
    </div>
  );
};

export default VendorsRecycleBin;
