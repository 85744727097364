import React, { useEffect, useContext, useState } from "react";
import Box from "@mui/material/Box";
import { WebAppTitle } from "../WebAppTitle";
//import { ProfileContext } from "../../context/ProfileContext";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import { Grid, Link, Typography } from "@mui/material";
import GuestsTopbar from "./GuestsTopbar";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import axios from "axios";
import { COOKIES_SETTINGS, GET_GUESTS_GROUPS } from "../../endpoints/Constants";
import GuestsCount from "./GuestsCount";
import { GuestsContext } from "./GuestsList";

const GuestsGroups = () => {
  //const profile = useContext(ProfileContext);
    const [loading, setLoading] = useState(true)

  const {groupsListState } = useContext(GuestsContext);
  const [groupsList, setGroupsList] = groupsListState;

  useEffect(()=>{
    setLoading(true)
    axios
      .get(GET_GUESTS_GROUPS, COOKIES_SETTINGS)
      .then((res) => {
       //console.log(res)
       let tempArr = res.data
       const sum = tempArr.reduce((accumulator, object) => {
        return accumulator + object.numberOfGuests;
        }, 0);
       tempArr.unshift({numberOfGuests:sum, name:"All Guests"})
       setGroupsList(tempArr)
      })
      .finally(() => setLoading(false));
  },[]) //eslint-disable-line
 
  return (
    <>
      <WebAppTitle text="Guests"/>
      <Box sx={{
        width:"100%",
        display:"flex",
        justifyContent:"center",
        marginTop:"2rem",
      }}>
        <GuestsCount/>
      </Box>
      
      
      <GuestsTopbar />
      <Box
        sx={{
          marginTop:"1rem",
          marginBottom:"4rem",
        }}
      >
      {loading ?
        <Box
          sx={{
          width:"100%",
          display:"flex",
          flexDirection:"column",
          justifyContent:"center",
          height:"200px",
          }}
        >
          <Container
            sx={{
              textAlign: "center",
            }}
          >
            <CircularProgress></CircularProgress>
          </Container>
        </Box>
        :
        <Box>

              <Grid container spacing={2}>
                  {
                      groupsList.map((groupItem, index)=>{
                          return(
                              <Grid item 
                                  key={"group-"+index}
                                  xs={12} 
                                  sm={12}
                                  md={6}
                                  lg={4}
                                  sx={{
                                    transition: "all .2s ease-in-out",
                                    "&:hover": {
                                      transform: "scale(1.01)",
                                    },
                                  }}
                                >
                                  <Link
                                    href={"./"+ (index===0 ? "all" : groupItem._id)}
                                    underline="none"
                                    sx={{
                                      color:"#090A0A"
                                    }}
                                  >
                                    <Box sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      padding: "1.5rem 1rem",
                                      boxShadow:"0px 0px 10px rgba(153, 153, 153, 0.15)",
                                      borderRadius:"8px",
                                      background:"#FFF",
                                    }}>
                                      <Box sx={{
                                        display:"flex",
                                        alignItems:"flex-end",
                                      }}>

                                        <Typography
                                          sx={{
                                            fontWeight:600,
                                            fontSize:{xs:"1rem",sm:"1.1rem"},
                                          }}
                                          >
                                          {groupItem.name}
                                        </Typography>

                                        <Typography
                                          sx={{
                                            fontWeight:400,
                                            color:"#878787",
                                            marginLeft:"10px",
                                          }}
                                          >
                                          {groupItem.numberOfGuests} Guest
                                        </Typography>
                                      </Box>

                                      <ArrowForwardIosIcon fontSize="small" sx={{}} />
                                    </Box>
                                  </Link>
                              </Grid>
                          )
                      })
                  }
              </Grid>
          </Box>
      }
      </Box>
    </>
  );
};
export default GuestsGroups;
