import { useContext } from "react";
import { Paper, Box, Grid, Button, Typography } from "@mui/material";

import { AuthComponentsContext } from "../../../context/AuthComponentsContext";

export default function LoginOrRegisterFirst({ pageName }) {
  const authComponents = useContext(AuthComponentsContext);

  const currentPageName = pageName;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "3rem 0rem",
      }}
    >
      <Paper
        elevation={4}
        sx={{
          padding: {xs:"1rem 1rem", md:"1rem 2rem"},
          borderRadius: "8px",
          textAlign: "center",
          width:{xs:"80%", md:"auto"}
        }}
      >
        <Typography
          sx={{
            fontSize: {xs:"1rem", md:"1.3rem"},
            fontWeight: 600,
            color: "#090A0A",
          }}
        >
          You will find your {currentPageName} here
        </Typography>

        <Typography
          sx={{
            fontSize: {xs:"0.8rem", md:"0.9rem"},
            fontWeight: 400,
            color: "#090A0A",
            marginTop: "0.9rem",
          }}
        >
          You have to login or register an account to be able to add your{" "}
          {currentPageName}
        </Typography>
        <Grid container spacing={1} 
        sx={{
            marginTop:{xs:"1rem", md:"2rem"},
             justifyContent:"center",
             
            }}
        >
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="text"
              fontWeight="bold"
              sx={{
                /*marginRight: "35px",*/
                fontSize: { xs: "0.8rem", sm: "1rem", md: "1rem" },
                color: "black",
                backgroundColor: "white",
                padding: { xs: "0rem 3rem", md: "0.3rem 2.3rem" },
                height: { xs: "2.5rem" },
                borderRadius: "2rem",
                border: "1px solid #D0D5DD",
                ":hover": {
                  backgroundColor: "#e7e7e7",
                },
                textAlign: "center",
                textTransform:"none"
              }}
              onClick={() => {
                authComponents.openLogin();
              }}
            >
              {" "}
              Login
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="text"
              fontWeight="bold"
              sx={{
                /*marginRight: "35px",*/
                fontSize: { xs: "0.8rem", sm: "1rem", md: "1rem" },
                color: "text.white",
                backgroundColor: "text.tertiary",
                padding: { xs: "0rem 3rem", md: "0.3rem 2.3rem" },
                height: { xs: "2.5rem" },
                borderRadius: "2rem",
                ":hover": {
                  backgroundColor: "text.tertiaryHover",
                },
                textAlign: "center",
                textTransform:"none"
              }}
              onClick={() => {
                authComponents.openSignup();
              }}
            >
              {" "}
              Register Now
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
