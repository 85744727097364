import React, { createContext, useState, useContext } from "react";

import { WebAppTitle } from "../WebAppTitle";
import { CheckListComponent } from "./CheckListComponent";
import { ProfileContext } from "../../context/ProfileContext";
import LoginOrRegisterFirst from '../auth/login-register-first/LoginOrRegisterFirst'

export const CheckListContext = createContext();
const CheckList = () => {
  const [checkList, setCheckList] = useState([]);
  const profile = useContext(ProfileContext);
  return (
    <>

      <WebAppTitle text='Checklist' />
      {
        profile.user ?
          <CheckListContext.Provider value={{ mainList: [checkList, setCheckList] }}>
            <CheckListComponent></CheckListComponent>
          </CheckListContext.Provider> : <LoginOrRegisterFirst pageName={"checklist"} />
      }
    </>
  );
};
export default CheckList;