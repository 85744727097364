import React, { useEffect, useContext, useState, useRef } from "react";
import Box from "@mui/material/Box";
import { WebAppTitle } from "../WebAppTitle";
import { ProfileContext } from "../../context/ProfileContext";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import { Button, OutlinedInput } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import SendMessageForm from "./SendMessageForm";
import CustomizedSnackbars from "../../ui-common-components/ui-components/SnackBar";
import GuestForm from "./GuestForm";
const GuestsTopbar = ({searchText, controlFunction}) => {
  const profile = useContext(ProfileContext);

  const [viewSearch, setViewSearch] = useState(false)
    
    const onSearchTextChange = (e)=>{
        controlFunction(e.target.value)
    }

    const url = useLocation()

    useEffect(()=>{
        if(url.pathname === "/guests"){
            setViewSearch(false)
        }
        else{
            setViewSearch(true)
        }
    }, [url])


  const openSendMessageForm = useRef(null)
  const openAddGuestForm = useRef(null)

  const openSnackBar = useRef(null);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarSevirity, setSnackBarSevirity] = useState("");

  return (
    <Box sx={{ }}>
      <CustomizedSnackbars
        openSnackBar={openSnackBar}
        message={snackBarMessage}
        severity={snackBarSevirity}
      />

      <Box sx={{
        display:"flex",
        flexDirection:{xs:"column-reverse", md:"row"},
        alignItems:"center",
        justifyContent:{xs:"center", md: viewSearch ? "space-between": "flex-end"},
        marginTop:"1rem",
      }}>
        {
            viewSearch ?
            <Container
            sx={{
              width: { xs: "100%", md:"40%"},
              marginLeft: "0px",
              paddingLeft: "0px !important",
              paddingRight: "0px !important",
              marginTop:{xs:"1rem", md:"0"}
            }}
          >
            <OutlinedInput
              fullWidth
              placeholder="Search for a guest"
               value={searchText}
               onChange={onSearchTextChange}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              sx={{
                marginBottom: "10px",
                background: "#F8FAFD",
                border: "1px solid #E7ECF3",
                borderRadius: "10px",
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#E7ECF3",
                },
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#E7ECF3",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#E7ECF3",
                },
                fieldSet: {
                  legend: {
                    width: "unset",
                  },
                },
              }}
            ></OutlinedInput>
          </Container>
          :null
        }
       
          <Box>

        <Button
          variant="text"
          fontWeight="bold"
          sx={{
            /*marginRight: "35px",*/
            fontSize: { xs: "0.8rem", sm: "1rem", md: "1rem" },
            color: "text.white",
            backgroundColor: "text.tertiary",
            padding: { xs: "0rem 2rem", md: "0.3rem 2.3rem" },
            height: { xs: "2.5rem" },
            borderRadius: "2rem",
            ":hover": {
              backgroundColor: "text.tertiaryHover",
            },
            textAlign: "center",
            textTransform:"none", 
            marginRight:"10px",
            marginBottom:"10px"

          }}
          onClick={() => {
            if(profile.wedding.weddingWebsite){
              openSendMessageForm.current();
            }
            else{
              setSnackBarMessage("You haven't setup your invitation yet!")
              setSnackBarSevirity("warning")
              openSnackBar.current()
            }
          }}
        >
          Send Message
        </Button>
        <Button
              variant="text"
              fontWeight="bold"
              sx={{
                /*marginRight: "35px",*/
                fontSize: { xs: "0.8rem", sm: "1rem", md: "1rem" },
                color: "text.white",
                backgroundColor: "text.tertiary",
                padding: { xs: "0rem 1rem", md: "0.3rem 0.5rem" },
                height: { xs: "2.5rem" },
                borderRadius: "2rem",
                ":hover": {
                  backgroundColor: "text.tertiaryHover",
                },
                textAlign: "center",
                textTransform:"none",
                marginBottom:"10px"
                
              }}
              onClick={() => {
                openAddGuestForm.current()
              }}
            >
                <AddIcon />

            </Button>
          </Box>
      </Box>
      {
        profile.wedding.weddingWebsite ?
        <SendMessageForm
          openDialog = {openSendMessageForm}
        />

        :null
      }
      <GuestForm
        opendialog = {openAddGuestForm}
        data={null}
        toedit={"false"}
      />
    </Box>
  );
};
export default GuestsTopbar;
