
import { Box} from "@mui/material";

import { gapi } from 'gapi-script';


// assets
import GoogleIcon from "@mui/icons-material/Google";

import { COOKIES_SETTINGS, GOOGLE_USER } from "../../../endpoints/Constants";
import axios from "axios";
import { GoogleLogin } from "react-google-login";
import { useEffect, useRef } from "react";
import CustomizedSnackbars from "../../../ui-common-components/ui-components/SnackBar";

const clientId =
  "125306738194-asdkc2pqnsbmslk9h77i0bp5k6namgj5.apps.googleusercontent.com";

const GoogleBtn = () => {
const openSnackBar = useRef(null);

  useEffect(()=>{
    function start() {
        gapi.auth2.init({
          clientId: clientId,
          scope:""
        });
      }
      gapi.load('client:auth2', start);
  },[])  

  const onSuccess = response => {
    console.log('SUCCESS', response.accessToken);

     axios
        .post(
          GOOGLE_USER,
          {
            token: response.accessToken,
          },
          COOKIES_SETTINGS
        )
        .then((res) => {
            //console.log(res)
          if (res.status === 200) {
            window.location.reload()
          }
        })
        .catch((err) => {});
  };

  const onFailure = response => {
    openSnackBar.current()
};

  
  return (
    <>
    <CustomizedSnackbars
        openSnackBar={openSnackBar}
        message="Error in Sign in"
        severity="error"
    />

    <GoogleLogin
      clientId={clientId}
      render={(renderProps) => (
          <Box
          sx={{
              border: "1px solid",
              borderColor: "text.darkGray",
              borderRadius: "0.5rem",
              padding: { xs: "0.4rem 1.5rem", md: "0.4rem 3.2rem" },
              width: "fit-content",
              ":hover": {
                  cursor: "pointer",
                },
          }}
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          >
          <GoogleIcon sx={{ color: "text.darkGray", fontSize: "2rem" }} />
        </Box>
      )}
      onSuccess={onSuccess}
      onFailure={onFailure}
      />
      </>
  );
};

export default GoogleBtn;
