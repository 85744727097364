import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import StarIcon from "@mui/icons-material/Star";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { AuthComponentsContext } from "../../../context/AuthComponentsContext";
import { ProfileContext } from "../../../context/ProfileContext";
import { useContext, useRef, useState } from "react";
import axios from "axios";
import CustomizedSnackbars from "../../../ui-common-components/ui-components/SnackBar";
import {
  COOKIES_SETTINGS,
  FAVORITE_VENDOR,
  UNFAVORITE_VENDOR,
} from "../../../endpoints/Constants";
const VendorCard = ({ vendor, setVendorList, index }) => {

  const authComponents = useContext(AuthComponentsContext);
  const profile = useContext(ProfileContext);
  const successfulSnackBar = useRef(null);
  const [successfulSnackBarText, setSuccessfulSnackBarText] = useState('');
  const errorSnackBar = useRef(null);
  const [errorSnackBarText, setErrorSnackBarText] = useState('');

  const isFavouring = useRef(false);
  const isUnFavouring = useRef(false);
  const favoriteVendor = () => {
    
    if(vendor.isFavorite)
    {
      // Unfavorite
      if(isUnFavouring.current) return;
      isUnFavouring.current = true;
      axios.post(UNFAVORITE_VENDOR, {
        'id': vendor._id
      },
      COOKIES_SETTINGS)
      .then(() => {
        //console.log('Unfavorited vendor');
        setSuccessfulSnackBarText('Vendor removed from favorites successfully');
        successfulSnackBar.current();
        isUnFavouring.current = false;
      }).catch((error) => {
        const {response} = error;
        setErrorSnackBarText('Error occurred while unfavoriting vendor: ' + response.data.message);
        errorSnackBar.current();
        setVendorList((prev) => {
          let newList = [...prev];
          newList[index].isFavorite = true;
          return newList;
        })
      })
      setVendorList((prev) => {
        let newList = [...prev];
        newList[index].isFavorite = false;
        return newList;
      })
    }
    else 
    {
      // Favorite
      if(isFavouring.current) return;
      isFavouring.current = true;
      axios.post(FAVORITE_VENDOR, {
        'id': vendor._id
      },
      COOKIES_SETTINGS)
      .then(() => {
        //console.log('favored vendor');
        setSuccessfulSnackBarText('Vendor added to favorites successfully');
        successfulSnackBar.current();
        isFavouring.current = false;
      }).catch((error) => {
        const {response} = error;
        setErrorSnackBarText('Error occurred while favouring vendor: ' + response.data.message);
        errorSnackBar.current();
        setVendorList((prev) => {
          let newList = [...prev];
          newList[index].isFavorite = false;
          return newList;
        })
      })
      setVendorList((prev) => {
        let newList = [...prev];
        newList[index].isFavorite = true;
        return newList;
      })
    }
  }

  return (
    <Grid item xs={6} sm={6} md={4} lg={3}>
      <CustomizedSnackbars
                openSnackBar={successfulSnackBar}
                message={successfulSnackBarText}
                severity="success"
      />
      <CustomizedSnackbars
                openSnackBar={errorSnackBar}
                message={errorSnackBarText}
                severity="error"
      />
      <Card
        sx={{
          // maxWidth: 645,
          width: "100%",
          margin: "auto",
          cursor: "pointer",
          height: "100%",
          transition: "all .2s ease-in-out",
          "&:hover": {
            transform: "scale(1.01)",
          },
          position: "relative",
        }}
        elevation={0}
        onClick={() => {
          window.location.href = `/vendor-category/vendor/${vendor._id}`;
        }}
      >
        <span
        onClick={(e) => { 
          e.stopPropagation();
          // If not logged In open Register First Dialog
          if(!profile.isUser)
           return authComponents.openRegisterFirst('favorite a vendor');
          favoriteVendor();
        }}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          padding: "1px",
          // paddingTop: "-1px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "4px",
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          cursor: "pointer",
        }}
        >
          {vendor.isFavorite?
          <StarIcon
          sx={{
            fontSize: { xs: "1rem", md: "1.2rem" },
            color: "#ffb400"
          }}
          />:<StarBorderIcon sx={{
            fontSize: { xs: "1rem", md: "1.2rem" },
          }}/>}
        </span>
        <CardMedia
          component="img"
          sx={{ height: { xs: "100px", md: "220px" } }}
          image={`https://lucky-mode-6c5e.testproxyimage.workers.dev/${vendor.icon}`}
        />
        <CardContent>
          <Typography
          noWrap
            sx={{
              color: "#090A0A",
              fontWeight: "700",
              fontSize: {xs:"0.9rem" ,md:"1.2rem"},
              marginBottom: "0.5rem",
            }}
          >
            {vendor.name}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: "0.5rem 0",
              alignItems: "flex-end",
            }}
          >
            <Typography
              noWrap
              sx={{
                color: "#090A0A",
                fontWeight: "400",
                fontSize: {xs:"0.7rem",md:"0.9rem"},
              }}
            >
              {vendor.city || "No city"}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <StarIcon
                fontSize="small"
                sx={{
                  color: "#ffb400",
                  marginRight: "0.2rem",
                }}
              />
              <span
                sx={{
                  fontWeight: "700",
                  fontSize: "0.8rem",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    color: "#090A0A",
                    fontSize: "12px",
                  }}
                >
                  {vendor.rating.toFixed(2)}
                </Typography>
              </span>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default VendorCard;
