import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/material.css";
// third party
import * as Yup from "yup";
import { Formik } from "formik";

// assets
import Alert from "@mui/material/Alert";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  Divider,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { Box, Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import {
  ADD_ADMIN,
  COOKIES_SETTINGS,
  EDIT_ADMIN,
} from "../../endpoints/Constants";
import axios from "axios";
import { margin } from "@mui/system";
const AdminFormDialog = (props) => {
  const [admin, setAdmin] = useState({
    name: "",
    email: "",
    phone: "",
  });

  //const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [vendorCategories, setVendorCategories] = useState([]);
  const [categoryValue, setCategoryValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (!props.add) {
      //console.log(props.data);
      setAdmin(props.data);
    }
    setLoading(false);
  }, [props.data, props.add]);

  const addAdmin = async (values, setErrors, setStatus, setSubmitting) => {
    if (values.password !== values.confirmPassword) {
      setErrors({
        submit: "Password and Confirm Password must be same",
      });
    } else {
      try {
        setLoadingBtn(true);
        let formData = {
          name: values.name,
          email: values.email,
          password: values.password,
          phone: values.phone,
        };

        axios
          .post(ADD_ADMIN, formData, COOKIES_SETTINGS)
          .then((res) => {
            setLoadingBtn(false);
            // console.log(res);
            if (res.status === 200) {
              setStatus({ success: true });
              setSuccessMsg("Admin Added Successfully");
              props.successadmindialog();
            }
          })
          .catch((err) => {
            setLoadingBtn(false);
            setStatus({ success: false });
            setErrors({ submit: err.response.data.message });
          });
      } catch (err) {
        setLoadingBtn(false);
        setStatus({ success: false });
        setErrors({ submit: "Error" });
      }
    }
  };
  const editAdmin = async (values, setErrors, setStatus, setSubmitting) => {
    //console.log("In edit");
    try {
      setLoadingBtn(true);

      let formData = {
        adminId: props.data._id,
        name: values.name,
        email: values.email,
        phone: values.phone,
      };

      axios
        .put(EDIT_ADMIN, formData, COOKIES_SETTINGS)
        .then((res) => {
          setLoadingBtn(false);
          if (res.status === 200) {
            setStatus({ success: true });
            setSuccessMsg("Admin Updated Successfully");
            props.successadmindialog();
          }
        })
        .catch((err) => {
          setLoadingBtn(false);
          setStatus({ success: false });
          setErrors({ submit: err.response.data.message });
        });
    } catch (err) {
      setLoadingBtn(false);
      setStatus({ success: false });
      setErrors({ submit: "Error" });
    }
  };

  const phoneRegExp = /201[0125][0-9]{8}$/;

  const formFields = [
    {
      name: "name",
      label: "Name",
      type: "text",
      value: props.add ? "" : admin.name,
      validation: Yup.string().required("Name is required"),
    },
    {
      name: "email",
      label: "Email",
      type: "email",
      value: props.add ? "" : admin.email,
      validation: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
    },
    {
      name: "password",
      label: "Password",
      type: "password",
      value: "",
      validation: Yup.string().max(255).required("Password is required"),
    },
    {
      name: "confirmPassword",
      label: "Confirm Password",
      type: "password",
      value: "",
      validation: Yup.string()
        .max(255)
        .required("Confirm Password is required"),
    },
    {
      name: "phone",
      label: "Phone",
      type: "phone",
      value: props.add ? "" : admin.phone,
      validation: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Phone is required"),
    },
  ];

  var validationlist = {};
  formFields.forEach((field) => {
    if (
      !(field.name === "password" || field.name === "confirmPassword") &&
      props.add === 0
    ) {
      validationlist[field.name] = field.validation;
    }
  });

  const [successMsg, setSuccessMsg] = useState("");

  useEffect(() => {
    if (successMsg) {
      setTimeout(() => {
        setSuccessMsg("");
      }, 5000);
    }
  }, [successMsg]);

  return (
    <div>
      {loading ? (
        <Box
          sx={{
            width: "100%",
            minHeight: "70vh",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Formik
          initialValues={{
            name: admin.name,
            email: admin.email,
            password: "",
            confirmPassword: "",
            phone: admin.phone,
            submit: null,
          }}
          validationSchema={Yup.object().shape(validationlist)}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            //console.log("AAAAAAAAAAA");
            if (props.add) {
              addAdmin(values, setErrors, setStatus, setSubmitting);
            } else {
             // console.log("Edit");
              editAdmin(values, setErrors, setStatus, setSubmitting);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit} {...props}>
              <Grid container justifyContent="center" alignItems="center">
                {successMsg && (
                  <Alert
                    severity="success"
                    sx={{ width: "100%", margin: "1rem" }}
                  >
                    {successMsg}
                  </Alert>
                )}

                {errors.submit && (
                  <Alert
                    severity="error"
                    sx={{ width: "100%", margin: "1rem" }}
                  >
                    {errors.submit}
                  </Alert>
                )}

                {formFields.map((field, index) => {
                  let havePassword = false;
                  let displayNonePassword = false
                  if (
                    (field.name === "password" ||
                      field.name === "confirmPassword")
                  ) {
                    if( props.add === 1){
                      havePassword = true;

                    }
                    else{
                      displayNonePassword = true;
                    }
                  }
                  
                  let displayCustom =displayNonePassword? "none":"flex";

                  return (
                  
                    
                      
                        <FormControl
                          fullWidth
                          error={Boolean(
                            touched[field.name] && errors[field.name]
                          )}
                          sx={{ margin: "1rem", display: displayCustom }}
                          key={"field-" + index}
                        >
                          {(field.name === "password" ||
                        field.name === "confirmPassword") &&
                      props.add === 0 ? null : (
                          <>
                          {field.name === "phone" ? null : (
                            <InputLabel
                              htmlFor={"outlined-adornment-" + field.name}
                            >
                              {field.label}
                            </InputLabel>
                          )} 
                          
                          {field.name === "phone" ? (
                            <PhoneInput
                              inputStyle={{ width: "100%" }}
                              inputProps={{ name: "phone" }}
                              value={values[field.name]}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                //console.log(e);
                                values[field.name] = "+" + e;
                              }}
                              country={"eg"}
                              placeholder="Phone Number"
                              onlyCountries={["eg"]}
                              countryCodeEditable={false}
                              isValid={
                                touched[field.name] && !errors[field.name]
                              }
                            />
                          ) : havePassword ? (
                            <OutlinedInput
                              id={"outlined-adornment-password-login" + index}
                              type={showPassword ? "text" : "password"}
                              autoComplete="new-password"
                              value={values[field.name]}
                              name={field.name}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              endAdornment={
                                <InputAdornment position="end" >
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    size="large"
                                  >
                                    {showPassword ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label={field.label}
                              inputProps={{}}
                            />
                          ) : (
                            <OutlinedInput
                              id={"outlined-adornment-" + field.name}
                              type={field.type}
                              autoComplete="username"
                              value={values[field.name]}
                              name={field.name}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              label={field.label}
                              inputProps={{}}
                            />
                          )}

                          {touched[field.name] && errors[field.name] && (
                            <FormHelperText
                              error
                              id={"standard-weight-helper-text-" + field.name}
                            >
                              {errors[field.name]}
                            </FormHelperText>
                          )}
                </>)}
                        </FormControl>
                      
                    
                  );
                })}

                <Box width={"100%"}>
                  <Divider></Divider>
                  <Stack
                    padding={"1rem"}
                    direction={"row"}
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"flex-end"}
                  >
                    <Button
                      onClick={() => {
                        props.canceladdvendordialog();
                      }}
                      variant="text"
                      color="error"
                    >
                      Cancel
                    </Button>
                    {loadingBtn ? (
                      <Button
                        sx={{
                          marginLeft: "1rem",
                          backgroundColor: "rgba(0,0,0,0)",
                        }}
                        disabled={"true"}
                        variant="contained"
                        color="primary"
                      >
                        <CircularProgress size={"1.3rem"} />
                      </Button>
                    ) : (
                      <Button
                        sx={{ marginLeft: "1rem" }}
                        disabled={loadingBtn}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        {props.add === 1 ? "Add" : "Save"}
                      </Button>
                    )}
                  </Stack>
                </Box>
              </Grid>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default AdminFormDialog;
