import React, { useEffect, useContext } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Box, Stack } from '@mui/material';
import WebsiteTheme from "./WebsiteTheme/WebsiteTheme";
import HomePage from "./HomePage";
import QuestionAndAnswers from "./Q&A/QuestionsAndAnswers";
import Rsvps from "./Rsvps";
import GuestBoard from "./GuestBoard/GuestBoard";
import SideMenuBar from "./SideMenuBar";
import { WebAppTitle } from "../WebAppTitle";
import InvitationSectionGrid from "../home/InvitationSectionGrid";
import { ProfileContext } from "../../context/ProfileContext";
import LoginOrRegisterFirst from "../auth/login-register-first/LoginOrRegisterFirst";
import IncompleteDataCard from "./IncompleteDataCard";
import GuestWishDetails from "./GuestBoard/GuestWishDetails";

const Invitations = () => {
  const profile = useContext(ProfileContext);
  // console.log(profile);
  return (
    <>
      <WebAppTitle text='Invitation' />
      {
        profile.isUser === false ?
          <Box
            sx={{ marginBottom: "0rem", width: "100%", display: "contents" }}
          >
            <Stack>
              {/* TODO::Recheck the auth */}
              <InvitationSectionGrid />
              {/* TODO::Change to send title and subtitle */}
              <LoginOrRegisterFirst pageName={"Invitations"} />
            </Stack>
          </Box> :
          profile.wedding === null ? <IncompleteDataCard title={"You haven't set up your wedding yet"} mode={"wedding"} /> :
            profile.wedding.weddingWebsite === null ? <IncompleteDataCard title={"You haven't set up your website invitation yet"} mode={"website"} /> :
              <>
                <Box minHeight={500} style={{ margin: "40px 58px 0 58px" }}>
                  {/* Mobile */}
                  <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                    <Routes>
                      <Route path="/" element={< SideMenuBar />} />

                      {new Date() < new Date(profile.wedding.weddingDate) && <Route path="/website-theme" element={< WebsiteTheme />} />}
                      {new Date() < new Date(profile.wedding.weddingDate) && <Route path="/home-page" element={< HomePage />} />}
                      {new Date() < new Date(profile.wedding.weddingDate) && <Route path="/questions" element={< QuestionAndAnswers />} />}
                      {new Date() < new Date(profile.wedding.weddingDate) && <Route path="/rsvps" element={< Rsvps />} />}

                      <Route path="/guest-board" element={< GuestBoard />} />

                    </Routes>
                  </Box>
                  {/* Desktop */}
                  <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Stack direction={"row"}>

                      <SideMenuBar></SideMenuBar>
                      <Routes>
                        {(new Date() < new Date(profile.wedding.weddingDate)) === true ? <Route path="/" element={< WebsiteTheme />} /> : <Route path="/" element={< GuestBoard />} />}

                        {new Date() < new Date(profile.wedding.weddingDate) && <Route path="/website-theme" element={< WebsiteTheme />} />}
                        {new Date() < new Date(profile.wedding.weddingDate) && <Route path="/home-page" element={< HomePage />} />}
                        {new Date() < new Date(profile.wedding.weddingDate) && <Route path="/questions" element={< QuestionAndAnswers />} />}
                        {new Date() < new Date(profile.wedding.weddingDate) && <Route path="/rsvps" element={< Rsvps />} />}

                        <Route path="/guest-board" element={< GuestBoard />} />


                      </Routes>

                    </Stack>

                  </Box>
                </Box>
              </>



      }
    </>
  );
};
export default Invitations;
