import React, {
  useEffect, useState,
} from "react";
import "./TemplateTwo.css";

import GuestBoard from "../GuestBoard/GuestBoard"
import { updateHeight } from "../TemplatesCommonFunctions";

const TemplateTwoGuestBoard = ({ id }) => {
  const [height, setHeight] = useState(null)
  useEffect(() => {
    window.addEventListener("resize", ()=>{updateHeight("templateTwo", setHeight)});
    updateHeight("templateTwo", setHeight)
  }, []);

  return (
    <>
      {
        height === null ? null:

      <div id="template-two-guest-board" className='container-fluid'>
        <div className='d-flex justify-content-center'>
        
          <div className="w-50" id="guest-board-two-inner-div">

            <div id="guest-board-header">
              <h2>
                Guest Board
              </h2>
            </div>
            <div style={{height: height}}>

            <GuestBoard
              templateID={id}
              />
            </div>
          </div>
        </div>
      </div>
    }
    </>
  );
};

export default TemplateTwoGuestBoard;
