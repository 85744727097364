import React from "react";
import { Route, Routes } from "react-router-dom";

import VendorCategories from "./vendor-category/VendorCategories";
import VendorCategory from "./vendor-category/VendorCategory";
import VendorsContextProvider from "../../context/VendorsContext";
import Vendor from "./vendor/Vendor";
const Vendors = () => {
  // States

  return (
    <>
      <VendorsContextProvider>
        <Routes>
          <Route path="/" element={<VendorCategories />} />
          <Route path="/:vendorCategoryId" element={<VendorCategory />} />
          <Route path="/vendor/:vendorId" element={<Vendor />} />
          <Route
            path="/favorites"
            element={<VendorCategory isFromFavorites={true} />}
          />
        </Routes>
      </VendorsContextProvider>
    </>
  );
};
export default Vendors;
