import React, {
  useEffect, useState,
} from "react";
import "./TemplateThree.css";

import GuestBoard from "../GuestBoard/GuestBoard"
import { updateHeight } from "../TemplatesCommonFunctions";

const TemplateThreeGuestBoard = ({ id }) => {
  const [height, setHeight] = useState(null)
  useEffect(() => {
    window.addEventListener("resize", ()=>{updateHeight("templateThree", setHeight)});
    updateHeight("templateThree", setHeight)
  }, []);
  
  return (
    <>
{
        height === null ? null:
      <div id="template-three-guest-board" className='container-fluid'>
        <div className='d-flex justify-content-center'>
          <div className="w-50" id="guest-board-three-inner-div">

            <div id="guest-board-header">
              <h2>
                Guest Board
              </h2>
            </div>
            <div style={{height: height}}>
            <GuestBoard
              templateID={id}
            />
          </div>
          </div>
        </div>
      </div>
}
    </>
  );
};

export default TemplateThreeGuestBoard;
