import React, { createContext, Component } from "react";
import axios from "axios";
import { GET_Phone, COOKIES_SETTINGS } from "../endpoints/Constants";
//import {responseHandler} from "../common/ResponseHandler"


export const PhoneContext = createContext();

class PhoneContextProvider extends Component {
  
  state = {
    isPhoneLogged: false,
    phoneNumber: "",
    RSVPData:{},
    templateMainColor:"#fff",
    guestBoardInlineStyle:""
  };

  logPhone =(phoneNumber, RSVPData)=>{
    var temp = this.state
    temp.isPhoneLogged = true
    temp.phoneNumber = phoneNumber
    temp.RSVPData = RSVPData
    this.setState(temp)
    //console.log("updateddd")
  }

  render() {
    return (
      <PhoneContext.Provider value={{ state:this.state, logPhone:this.logPhone}} >
        {this.props.children}
      </PhoneContext.Provider>
    );
  }
}

export default PhoneContextProvider;
