import React, { useState , useEffect} from "react";
import "./TemplateThree.css";
import { Link } from "react-router-dom";
import {
  getDayName,
  getMonth,
  getDayNumber,
  RSVPPressed_common
} from "../TemplatesCommonFunctions";

const TemplateThreeInvitation = ({ templateData, weddingId }) => {
  const [dummy, setDummy] = useState(0);

  const RSVPPressed = () => {
    setDummy(!dummy)
    RSVPPressed_common("three")
  }
  const getMonth_notcommon = (date) => {
    const d2 = new Date(date)
    var Months = new Array(12);
    Months[0] = "January";
    Months[1] = "February";
    Months[2] = "March";
    Months[3] = "April";
    Months[4] = "May";
    Months[5] = "June";
    Months[6] = "July";
    Months[7] = "August";
    Months[8] = "September";
    Months[9] = "October";
    Months[10] = "November";
    Months[11] = "December";
    return Months[d2.getMonth()];
  }
  const getTime = (date) => {
    const d2 = new Date(date)
    var hour = d2.getHours();

    let timeSTR = ""
    if (hour == 0) {
      timeSTR = "12AM"
    }
    else if (hour > 12) {
      timeSTR = (hour - 12) + "PM"
    }
    else {
      timeSTR = hour + "AM"
    }

    return timeSTR;
  }
  return (
    <>
      <div id="template-three-body" className="row">
        <div className="col-2">
        </div>
        <div id="body-col" className="col-8 ">

          <div id="body-main-container" className="container-fluid" >
            <div id="body-row-inv-message" className='row'>
              <div className='col'>
                <p>Together with Their families</p>
              </div>
            </div>

            <div id="body-row-couple-intials" className='row'>
              <div className='col'>
                <p>
                  {templateData.brideFirstName[0]}&{templateData.groomFirstName[0]}
                </p>
              </div>
            </div>

            <div id="body-row-couple">
              <div id="bride-col" className='row'>
                <div id="bride-first-name" className='col-12'>
                  <p style={{fontSize:(templateData.brideFirstName.length + templateData.brideLastName.length + templateData.groomFirstName.length + templateData.groomLastName.length)>32?"2.5vw":"3.5vw"}}>
                    {templateData.brideFirstName} {templateData.brideLastName} & {templateData.groomFirstName} {templateData.groomLastName}
                  </p>
                </div>
              </div>
            </div>

            <div id="body-row-couple-message" className='row'>
              <p>
                INVITE YOU TO CELEBRATE
              </p>
              <p>
                THEIR WEDDING DAY
              </p>
            </div>

            {/* <div id="body-row-day" className='row'>
                    <p>
                      {data.day}
                    </p>
                  </div> */}

            <div id="body-row-date" className='d-flex justify-content-center'>
              <div id="body-date-month" className=''>
                <p>
                  {getDayName(templateData.weddingDate)}
                </p>
              </div>
              <div id="body-date-day-num" className='ps-2 pe-2'>
                <p>
                  {getDayNumber(templateData.weddingDate)}
                </p>
              </div>
              <div id="body-date-year" className=''>
                <p>
                  {getMonth_notcommon(templateData.weddingDate)}
                </p>
              </div>
            </div>

            <div id="body-row-time" className='row'>
              <p>
                {getTime(templateData.weddingDate)}
              </p>
              <p className="">
                {templateData.area}
              </p>
              {/* <p>
                      {templateData.city}
                    </p> */}
            </div>

            {templateData.weddingWebsite.RSVPsIsVisible ?
              <div>


                <div id="body-row-rsvp-by" className='row'>
                  <p>
                    KINDLY RSVP BY {getMonth(templateData.RSVPBy)} {getDayNumber(templateData.RSVPBy)}
                  </p>
                </div>

                <div id="body-row-rsvp-button" className='d-flex justify-content-center'>
                  <div id="rsvp-col" className=''>
                    <Link to={"/orange-blossom/"+weddingId+"/rsvp"}>
                      <button onClick={RSVPPressed} className=" shadow-none" >
                        RSVP HERE
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              : <div id="rsvp-placeholder"></div>
            }
          </div>

        </div>
        <div className="col-2">
        </div>
      </div>

      <div id="created-on" className="w-100">
            <div className="w-50">

              <p>
                Created on Lovebirds
              </p>
              <p>
                Getting married? <Link id="created-on-link" style={{ textDecoration: 'underline' }} 
                to={"/"}
                >Create your wedding website for free.</Link>
              </p>
            </div>
          </div>
    </>
  );
};

export default TemplateThreeInvitation;
