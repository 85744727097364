import { Box, Paper, Typography, Grid, Button } from "@mui/material";
import { useContext } from "react";
import { AuthComponentsContext } from "../../context/AuthComponentsContext";
const IncompleteDataCard = (props) => {
    const authComponents = useContext(AuthComponentsContext);
    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                padding: "3rem 0rem",
            }}
        >
            <Paper
                elevation={4}
                sx={{
                    padding: { xs: "1rem 1rem", md: "1rem 2rem" },
                    borderRadius: "8px",
                    textAlign: "center",
                    width: { xs: "80%", md: "auto" }
                }}
            >
                <Typography
                    sx={{
                        fontSize: { xs: "1rem", md: "1.3rem" },
                        fontWeight: 600,
                        color: "#090A0A",
                    }}
                >
                    {props.title}
                </Typography>

                <Grid container spacing={1}
                    sx={{
                        marginTop: { xs: "1rem", md: "2rem" },
                        justifyContent: "center",

                    }}
                >
                    <Grid item xs={12} sm={6}>
                        <Button
                            fullWidth
                            variant="text"
                            fontWeight="bold"
                            sx={{
                                /*marginRight: "35px",*/
                                fontSize: { xs: "0.8rem", sm: "1rem", md: "1rem" },
                                color: "text.white",
                                backgroundColor: "text.tertiary",
                                padding: { xs: "0rem 3rem", md: "0.3rem 2.3rem" },
                                height: { xs: "2.5rem" },
                                borderRadius: "2rem",
                                ":hover": {
                                    backgroundColor: "text.tertiaryHover",
                                },
                                textAlign: "center",
                                textTransform: "none"
                            }}
                            onClick={() => {
                                if (props.mode == "wedding") {
                                    authComponents.openWeddingInfo();
                                } else {
                                    authComponents.openWebsiteInfo();
                                }

                            }}
                        >
                            Setup now
                        </Button>
                    </Grid>

                </Grid>
            </Paper>
        </Box>
    );
};
export default IncompleteDataCard;
