import React, { useState } from 'react';

import { Divider, Button, Paper, MenuList, MenuItem, ListItemText, ListItemIcon, Stack } from '@mui/material';
import { Link } from "react-router-dom";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import axios from 'axios';
import { USER_LOGOUT, COOKIES_SETTINGS } from '../../endpoints/Constants'
import { useContext } from "react";
import { AuthComponentsContext } from "../../context/AuthComponentsContext";
import { ProfileContext } from "../../context/ProfileContext";

const SideMenuBar = () => {
  const urlToIndex = {
    "login-details": 0,
    "wedding-details": 1,
  }
  const indexToUrl = {
    0: "./login-details",
    1: "./wedding-details",
  }
  const endPoints = window.location.href.split("/");
  const lastParam = endPoints[endPoints.length - 1];
  var urlIndex = 0;
  if (urlToIndex[lastParam]) {
    urlIndex = urlToIndex[lastParam];
  }
  const [selectedMenu, setSelectedMenu] = useState(urlIndex);
  const menuItems = ["Login Details", "Wedding Details"];

  const profile = useContext(ProfileContext);
  const authComponents = useContext(AuthComponentsContext);

  const logout = async () => {
    try{
      await axios.post(USER_LOGOUT, {}, COOKIES_SETTINGS);
    }
    finally
    {
      window.location.href = "/";
    }

  }
  return (
    <Stack >
      <Paper elevation={0} sx={{ width: { xs: "100%", md: 259 }, maxWidth: '100%' }}>
        <MenuList sx={{ padding: 0 }}>
          {menuItems.map((menuItem, index) => {
            return (
              <div
              key={index}>
                <Link to={indexToUrl[index]} onClick={(e) => { 
                  setSelectedMenu(index) 
                  
                  if(index === 1 && profile.wedding === null)
                  {
                    authComponents.openWeddingInfo();
                    e.preventDefault();
                  }
                  }}>
                  <MenuItem
                    selected={selectedMenu === index}
                    sx={{
                      paddingRight: 0,
                      width: '100%',
                      height: '56px',
                      backgroundColor: '#FBFBFB',
                      "&:hover": {
                        backgroundColor: "#F1A3A5; !important",
                      },
                      '&.Mui-selected': {
                        xs: {
                          backgroundColor: "#FBFBFB; !important",

                        },
                        md: {
                          backgroundColor: "#F1A3A5; !important",
                          color: "#FFFFFF !important"
                        }
                      },
                      '&.Mui-selected:hover': {
                        backgroundColor: "#F1A3A5; !important"
                      }
                    }}>
                    <ListItemText >{menuItem}</ListItemText>
                    <ListItemIcon>
                      <KeyboardArrowRightIcon fontSize="small"
                      sx=
                      {
                        {
                          color: { md: selectedMenu === index ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)", xs: "rgba(0,0,0,1)", }
                        }
                      }  
                      />
                    </ListItemIcon>
                  </MenuItem>
                </Link>
                {index === 4 ? null : <Divider sx={{ height: '1px', backgroundColor: "#FBFBFB" }} />}
              </div>
            );
          }
          )
          }
        </MenuList>
      </Paper >

      <Button
        variant="contained"

        sx={{
          marginTop: "10px",
          borderRadius: "9px",
          backgroundColor: "#589BD5",
          color: "#FFFFFF",
          height: "46px",
          "&:hover": {
            backgroundColor: "#589BD5; !important"
          },
          textTransform: "none"
        }}
        href="/contact-us"
      >
        Customer Support 
        
      </Button>
      <Button
        variant="contained"
        sx={{
          marginTop: "10px",
          borderRadius: "9px",
          backgroundColor: "#FFFFFF; !important",
          color: "#000000",
          height: "46px",
          ":hover": {
            backgroundColor: "#e7e7e7",
          },
          textTransform: "none"
        }}
        onClick={logout}
      >
        Logout
      </Button>
    </Stack >
  );
};
export default SideMenuBar;