import React, { useEffect, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { ProfileContext } from "../context/ProfileContext";
import DashboardProtectedRoute from "./DashboardProtectedRoutes";
import Box from "@mui/material/Box";

import NotFound from "./NotFound";
/****************************** Templates *****************************************/
import TemplateOne from "../templates/TemplateOne/TemplateOne";
import TemplateTwo from "../templates/TemplateTwo/TemplateTwo";
import TemplateThree from "../templates/TemplateThree/TemplateThree";
import TemplateFour from "../templates/TemplateFour/TemplateFour";
import TemplateFive from "../templates/TemplateFive/TemplateFive";
import TemplateSix from "../templates/TemplateSix/TemplateSix";
import TemplateSeven from "../templates/TemplateSeven/TemplateSeven";
import TemplateEight from "../templates/TemplateEight/TemplateEight";
import TemplateNine from "../templates/TemplateNine/TemplateNine";
import TemplateTen from "../templates/TemplateTen/TemplateTen";
import TemplateTwelve from "../templates/TemplateTwelve/TemplateTwelve";


/****************************** Dashboard *****************************************/
import DashboardLayout from "../dashboard/DashboardLayout";
//import Login from "../dashboard/Authentication/Login";
import ForgetPassword from "../dashboard/Authentication/ForgetPassword";
import DashboardLogin from "../dashboard/Authentication/DashboardLogin";
import WebAppLayout from "../webapp/WebAppLayout";
import FacebookDeletionInstructions from "../webapp/3rd-parties/FacebookDeletionInstructions";
import TemplateEleven from "../templates/TemplateEleven/TemplateEleven";

const MainRoutes = () => {
  const profile = useContext(ProfileContext);
  useEffect(() => {}, [profile]);
  return (
    <>
      {profile.isLoading === true ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Routes>
          {/* Templates Routes */}
            <Route path="/flowers-of-happiness/:weddingId/*" element={<TemplateOne />} />
            <Route path="/vintage-blush/:weddingId/*" element={<TemplateTwo />} />
            <Route path="/orange-blossom/:weddingId/*" element={<TemplateThree />} />
            <Route path="/love-and-leaves/:weddingId/*" element={<TemplateFour />} />
            <Route path="/forest-walk/:weddingId/*" element={<TemplateFive />} />
            <Route path="/winter-weddingLand/:weddingId/*" element={<TemplateSix />} />
            <Route path="/tropical-love/:weddingId/*" element={<TemplateSeven />} />
            <Route path="/islamic-simplicity/:weddingId/*" element={<TemplateEight />} />
            <Route path="/frosty-elegance/:weddingId/*" element={<TemplateNine />} />
            <Route path="/oriental-royalty/:weddingId/*" element={<TemplateTen />} />
            <Route path="/golden-flora/:weddingId/*" element={<TemplateEleven/>} />
            <Route path="/rose-garden/:weddingId/*" element={<TemplateTwelve/>} />


          {/* Dashboard Routes */}
          <Route path="/dashboard-login" element={<DashboardLogin />} />
          <Route path="/dashboard-forget-password" element={<ForgetPassword />} />
          <Route  element={<DashboardProtectedRoute />}>
            <Route path="/dashboard/*" element={<DashboardLayout />} />
          </Route>

          {/* Website Routes */}
          <Route path="/*" element={<WebAppLayout />} />

          <Route path="/not-found" element={<NotFound />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      )}
    </>
  );
};

export default MainRoutes;
