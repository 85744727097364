import React, { useEffect, useContext } from "react";
import Paper from "@mui/material/Paper";
import { WebAppTitle } from "../WebAppTitle";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import AboutUsImg from "../../assets/about_us.png";
const AboutUs = () => {
  return (
    <>
      <WebAppTitle text="About Us" />
      <Container>
        {/* <Paper
          sx={{
            margin: "20px 0px",
            padding: "25px",
          }}
          elevation={0}
        >
          <Typography
            sx={{
              color: "#AB9E72",
              fontWeight: "600",
              fontSize: { xs: "1.2rem", md: "2rem" },
              textAlign: "center",
              lineHeight: "51px",
              fontFamily: "Montserrat",
            }}
          >
            GET IN TOUCH WITH US
          </Typography>
          <Container sx={{ paddingTop: "15px" }}>
            <Typography
              sx={{
                padding: "auto",
                textAlign: "center",
                fontFamily: "Montserrat",
                color: "#090A0A",
                fontWeight: "400",
                fontSize: { xs: "0.8rem", md: "0.95rem" },
              }}
            >
              In the eighteenth century the German philosopher Immanuel Kant
              developed a theory of knowledge in which knowledge about space can
              be both a priori and synthetic. According to Kant, knowledge about
              space is synthetic, in that statements about space are not simply
              true by virtue of the meaning of the words in the statement. In
              his work, Kant rejected the view that space must be either a
              substance or relation. Instead he came to the conclusion that
              space and time are not discovered by humans to be objective
              features of the world, but imposed by us as part of a framework
              for organizing experience.
            </Typography>
          </Container>
        </Paper> */}

        <Container
          sx={{
            textAlign: "center",
            marginTop: { xs: "20px", md: "81px" },
            width: { xs: "80%" },
          }}
        >
          <img src={AboutUsImg} width="100%" alt="about us" />
        </Container>
        <Box
          sx={{
            margin: "20px 0px",
            padding: "25px",
          }}
          elevation={0}
        >
          <Typography
            sx={{
              color: "#AB9E72",
              fontWeight: "600",
              fontSize: { xs: "1.2rem", md: "2rem" },
              textAlign: "center",
              // lineHeight: "51px",
              fontFamily: "Montserrat",
            }}
          >
            GET IN TOUCH WITH US
          </Typography>
          <Typography
            sx={{
              padding: "auto",
              textAlign: "center",
              fontFamily: "Montserrat",
              color: "#090A0A",
              fontWeight: "400",
              fontSize: { xs: "0.8rem", md: "1.1rem" },
              marginTop: "10px",
            }}
          >
            After seeing the distress brought on by wedding planning, we decided it was time to make a change. Founded in 2022, Lovebirds is the ultimate Egyptian wedding guide. Our mission is to help couples achieve the wedding of their dreams as seamlessly as possible. This way, they can spend less time worrying about the wedding and more time enjoying the journey of getting married.
            <br></br>
            <br></br>
            Lovebirds fills a hole in the Egyptian wedding industry through two main components: 1. connecting couples to vendors, 2. connecting couples to their guests. Through Lovebirds, a couple is able to browse through hundreds of local vendors in the same place, request appointments from vendors, stay organized through their wedding checklist, create a FREE wedding invitation in minutes, and keep track of their guests’ RSVPs. 
            <br></br>
            <br></br>
            And we’ve only just begun! Over the next few years, we plan to keep growing Lovebirds by adding various features to ensure a couple’s wedding journey is a joyful chapter in their lives rather than one full of stress. 
				
          </Typography>
          <Box
          sx={{
            display:'flex',
            justifyContent: 'center',
            marginTop: '34px'
          }}
          >
            <Button
              // fullWidth
              href="/contact-us"
              size="medium"
              type="submit"
              variant="contained"
              sx={{
                height: "48px",
                marginTop: "10px",
                margin: "auto",
                padding: {xs:"8px 60px",md:"16px 132px"},
                background: "#F1A3A5",
                borderRadius: "48px",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "16px",
                color: "#FFF",
                "&:hover": {
                  backgroundColor: "#F1A3A5",
                  color: "#FFF",
                },
              }}
            >
              Contact Us
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
};
export default AboutUs;
