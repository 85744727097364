import { useEffect, useState, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  FormControl,
  Grid,
  OutlinedInput,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomizedSnackbars from "../../../ui-common-components/ui-components/SnackBar";
import {
  REQUEST_QUOTE_VENDOR,
  COOKIES_SETTINGS,
} from "../../../endpoints/Constants";
import axios from "axios";
export default function RequestQuoteDialog({
  vendorId,
  openDialog,
  setSuccessMessage,
}) {
  const formStyle = {
    marginBottom: "10px",
    background: "#F8FAFD",
    border: "1px solid #E7ECF3",
    borderRadius: "10px",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E7ECF3",
    },
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "#E7ECF3",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E7ECF3",
    },
    fieldSet: {
      legend: {
        width: "unset",
      },
    },
  };
  const initialValues = {
    firstName: "",
    lastName: "",
    phone: "",
    estimatedWeddingDateStart: null,
    estimatedWeddingDateEnd: null,
    budgetFrom: "",
    budgetTo: "",
    requestDetails: "",
    weddingDetails: "",
    vendor: vendorId,
  };
  const [item, setItem] = useState(initialValues);

  const [open, setOpen] = useState(false);
  const [requestingQuote, setRequestQuote] = useState(false);
  const errorSnackBar = useRef(null);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const setFieldValue = (field, value) => {
    setItem({ ...item, [field]: value });
  };
  const handleChange = (event) => {
    setItem({ ...item, [event.target.name]: event.target.value });
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    openDialog.current = handleClickOpen;
  });
  const handleSubmit = async (event) => {
    event.preventDefault();
    setRequestQuote(true);
    try {
      await axios.post(REQUEST_QUOTE_VENDOR, item, COOKIES_SETTINGS);
    } catch (e) {
      setErrorMessage(e.response.data.message);
      errorSnackBar.current();
      return;
    }
    setRequestQuote(false);
    setItem(initialValues);
    setSuccessMessage("Your request has been sent successfully");
    handleClose();
  };
  return (
    <div>
      <CustomizedSnackbars
        openSnackBar={errorSnackBar}
        message={errorMessage}
        severity="error"
      ></CustomizedSnackbars>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: 15,
            "& .MuiPickersDay-root": {
              "&.Mui-selected": {
                backgroundColor: "black",
              },
            },
          },
        }}
        sx={{}}
      >
        <Box width={{ xs: 300, md: 500 }} sx={{ overflowX: "hidden" }}>
          <DialogContent sx={{ padding: "0", width: "100%" }}>
            <DialogTitle color="#101828">
              <Typography
                fontStyle={"normal"}
                fontWeight={"700"}
                fontSize={"30px"}
                lineHeight={"38px"}
                textAlign={"center"}
                color={"#101828"}
                marginBottom={"12px"}
              >
                {"Request a Quote"}
              </Typography>
              <Typography variant="body2" color="#667085">
                {`Tell us about your wedding to be able to estimate a price for you.`}
              </Typography>
            </DialogTitle>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <form onSubmit={handleSubmit}>
                <Box paddingX={2} paddingY={3}>
                  <FormControl fullWidth variant="outlined">
                    <OutlinedInput
                      required
                      sx={formStyle}
                      id={"outlined-adornment-" + "firstName"}
                      type={"text"}
                      value={item["firstName"]}
                      name={"firstName"}
                      placeholder={"First Name"}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl fullWidth variant="outlined">
                    <OutlinedInput
                      sx={formStyle}
                      id={"outlined-adornment-" + "lastName"}
                      type={"text"}
                      value={item["lastName"]}
                      name={"lastName"}
                      placeholder={"Last Name"}
                      required
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl fullWidth variant="outlined">
                    <OutlinedInput
                      sx={formStyle}
                      id={"outlined-adornment-" + "phone"}
                      type={"text"}
                      value={item["phone"]}
                      name={"phone"}
                      placeholder={"Phone Number"}
                      required
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl fullWidth variant="outlined">
                    <DatePicker
                      value={item["estimatedWeddingDateStart"]}
                      onChange={(value) => {
                        setFieldValue("estimatedWeddingDateStart", value);
                      }}
                      minDate={new Date()}
                      renderInput={(params) => {
                        params.inputProps["placeholder"] =
                          "Estimated Wedding Start Date";
                        return (
                          <TextField
                            {...params}
                            id="outlined-adornment-estimatedWeddingDateStart"
                            variant="outlined"
                            name="estimatedWeddingDateStart"
                            sx={{
                              marginBottom: "10px",
                              background: "#F8FAFD",
                              borderRadius: "10px",
                              border: "1px solid #E7ECF3",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset.MuiOutlinedInput-notchedOutline": {
                                  borderColor: "transparent",
                                },
                              },
                              fieldSet: {
                                legend: {
                                  width: "unset",
                                },
                              },
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth variant="outlined">
                    <DatePicker
                      value={item["estimatedWeddingDateEnd"]}
                      onChange={(value) => {
                        setFieldValue("estimatedWeddingDateEnd", value);
                      }}
                      minDate={new Date()}
                      renderInput={(params) => {
                        params.inputProps["placeholder"] =
                          "Estimated Wedding End Date";
                        return (
                          <TextField
                            {...params}
                            id="outlined-adornment-estimatedWeddingDateEnd"
                            variant="outlined"
                            name="estimatedWeddingDateEnd"
                            sx={{
                              marginBottom: "10px",
                              background: "#F8FAFD",
                              borderRadius: "10px",
                              border: "1px solid #E7ECF3",
                              "& .MuiOutlinedInput-root": {
                                "& fieldset.MuiOutlinedInput-notchedOutline": {
                                  borderColor: "transparent",
                                },
                              },
                              fieldSet: {
                                legend: {
                                  width: "unset",
                                },
                              },
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth variant="outlined">
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <OutlinedInput
                          sx={formStyle}
                          id={"outlined-adornment-" + "budgetFrom"}
                          type={"number"}
                          value={item["budgetFrom"]}
                          name={"budgetFrom"}
                          placeholder={"Budget From"}
                          required
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <OutlinedInput
                          sx={formStyle}
                          id={"outlined-adornment-" + "budgetTo"}
                          type={"number"}
                          value={item["budgetTo"]}
                          name={"budgetTo"}
                          placeholder={"Budget To"}
                          required
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                  <FormControl fullWidth variant="outlined">
                    <OutlinedInput
                      sx={formStyle}
                      id={"outlined-adornment-" + "requestDetails"}
                      type={"text"}
                      value={item["requestDetails"]}
                      name={"requestDetails"}
                      multiline
                      rows={4}
                      placeholder={"Tell us more about your request"}
                      required
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl fullWidth variant="outlined">
                    <OutlinedInput
                      sx={formStyle}
                      id={"outlined-adornment-" + "weddingDetails"}
                      type={"text"}
                      value={item["weddingDetails"]}
                      name={"weddingDetails"}
                      multiline
                      rows={4}
                      placeholder={"Tell us more about your wedding"}
                      required
                      onChange={handleChange}
                    />
                  </FormControl>
                  <Button
                    fullWidth
                    size="medium"
                    type="submit"
                    variant="contained"
                    sx={{
                      height: "48px",
                      marginTop: "10px",

                      background: "#F1A3A5",
                      borderRadius: "48px",
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: "16px",
                      color: "#FFF",
                      "&:hover": {
                        backgroundColor: "#F1A3A5",
                        color: "#FFF",
                      },
                    }}
                  >
                    {requestingQuote ? (
                      <CircularProgress
                        sx={{
                          color: "#FFF",
                          width: "30px",
                        }}
                      ></CircularProgress>
                    ) : (
                      `Submit a Request`
                    )}
                  </Button>
                </Box>
              </form>
            </LocalizationProvider>
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
}
