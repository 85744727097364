import { useEffect, useState, useRef, useContext } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
    Typography,
    Grid,
    FormControl,
    OutlinedInput,
    Button,
    FormHelperText,
    Alert,
    CircularProgress,

} from "@mui/material";
import { Formik } from 'formik';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as Yup from "yup";
import { QuestionsContext } from "./QuestionsAndAnswers";
import { addQuestion, deleteQuestion, editQuestion } from "../InvitationsApiFunctions";
export default function QuestionDialog(props) {
    const [loading, setLoading] = useState(false);
    const { mainList } = useContext(QuestionsContext);
    const [questions, setQuestions] = mainList;
    const [item, setItem] = useState({
        _id: "",
        question: "",
        answer: ""
    });

    useEffect(() => {
        if (props.data !== null) {
            setItem(props.data);
        }
    }, [props.data]);

    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        props.opendialog.current = handleClickOpen;
    });
    const add = async (values, setErrors) => {
        try {
            setLoading(true);
            const response = await addQuestion(values);
            setQuestions(questions => [...questions, response]);
            handleClose();
            setErrors({ submit: null });
        } catch (error) {
            setErrors({ submit: "An error occured" });
        } finally {
            setLoading(false);
        }
    };

    const edit = async (values, setErrors) => {
        try {
            setLoading(true);
            const response = await editQuestion({
                questionId: values._id,
                question: values.question,
                answer: values.answer
            });
            let newQuestions = [...questions];
            const index = newQuestions.findIndex(item => item._id === props.data._id);
            newQuestions[index] = response;
            setQuestions(newQuestions);
            handleClose();
            setErrors({ submit: null });
        } catch (error) {
            setErrors({ submit: "An error occured" });
        } finally {
            setLoading(false);
        }

    };

    const deleteItem = async (values, setErrors) => {
        try {
            setLoading(true);
            await deleteQuestion(values);
            let newQuestions = questions.filter(function (value) {
                return value._id !== props.data._id;
            });
            setQuestions(newQuestions);
            handleClose();
            setErrors({ submit: null });
        } catch (error) {
            setErrors({ submit: "An error occured" });
        } finally {
            setLoading(false);
        }
    };
    return (
        <div>

            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: { borderRadius: 15 },
                }}

                sx={{ background: "rgba(0,0,0,0.5)", backdropFilter: "blur(16px)" }}
            >
                <Box
                    width={{ xs: "80vw", sm: 500 }}
                    sx={{ overflowX: "hidden" }}
                >
                    <DialogContent sx={{ padding: "0", width: "100%" }}>
                        <DialogTitle color="#101828">
                            <Typography
                                fontStyle={"normal"}
                                fontWeight={"700"}
                                fontSize={"30px"}
                                lineHeight={"38px"}
                                textAlign={"center"}
                                color={"#101828"}
                            >
                                {props.toedit === true ? "Edit Question" : "Add New Question"}
                            </Typography>
                        </DialogTitle>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Formik
                                initialValues={
                                    {
                                        _id: item._id,
                                        question: item.question,
                                        answer: item.answer
                                    }
                                }
                                validationSchema={Yup.object().shape({
                                    question: Yup.string()
                                        .required("Question is required"),
                                    answer: Yup.string()
                                        .required("Answer is required"),
                                })}
                                // validationSchema={Yup.object().shape({
                                //     title: Yup.string().
                                //         required("Title is required").test('title', 'Please enter a valid title', function (value) { if (value === " ") return false; else return true; }),
                                // })}
                                onSubmit={async (values, { setErrors, setSubmitting }) => {
                                    if (!props.toedit) {
                                        add(values, setErrors, setSubmitting);
                                    } else {
                                        // console.log(values);
                                        edit(values, setErrors, setSubmitting);
                                    }
                                }}
                            >
                                {({
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    setFieldValue,
                                    touched,
                                    values,
                                }) => (

                                    <form noValidate onSubmit={handleSubmit} {...props}>
                                        <Grid container justifyContent="center" alignItems="center">
                                            <Box paddingX={2} paddingY={3}>
                                                {errors.submit && (
                                                    <Alert
                                                        sx={{
                                                            marginBottom: "10px",
                                                        }}
                                                        severity="error"
                                                    >
                                                        {errors.submit}
                                                    </Alert>
                                                )}
                                                <FormControl
                                                    fullWidth
                                                    error={Boolean(touched.question && errors.question)}
                                                    variant="outlined"
                                                    sx={{
                                                        marginBottom: "10px",
                                                    }}
                                                >
                                                    <OutlinedInput
                                                        sx={{
                                                            background: "#F8FAFD",
                                                            border: "1px solid #E7ECF3",
                                                            borderRadius: "10px",
                                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#E7ECF3',
                                                            },
                                                            '.MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#E7ECF3',
                                                            },
                                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#E7ECF3',

                                                            },
                                                            fieldSet: {
                                                                legend: {
                                                                    width: "unset",
                                                                }
                                                            }
                                                        }}
                                                        id={"outlined-adornment-" + "question"}
                                                        type={"text"}
                                                        value={values["question"]}
                                                        name={"question"}
                                                        placeholder={"Question"}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}


                                                    />
                                                    {touched.question && errors.question && (
                                                        <FormHelperText
                                                            error
                                                            id="standard-weight-question"
                                                        >
                                                            {errors.question}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>

                                                <FormControl fullWidth variant="outlined">
                                                    <OutlinedInput
                                                        sx={{
                                                            marginBottom: "10px",
                                                            background: "#F8FAFD",
                                                            border: "1px solid #E7ECF3",
                                                            borderRadius: "10px",

                                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#E7ECF3',
                                                            },
                                                            '.MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#E7ECF3',
                                                            },
                                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#E7ECF3',

                                                            },
                                                            fieldSet: {
                                                                legend: {
                                                                    width: "unset",
                                                                }
                                                            }
                                                        }}
                                                        multiline={true}
                                                        id={"outlined-adornment-" + "answer"}
                                                        type={"text"}
                                                        value={values["answer"]}
                                                        name={"answer"}
                                                        placeholder={"Answer"}
                                                        minRows={3}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}

                                                    />
                                                    {touched.answer && errors.answer && (
                                                        <FormHelperText
                                                            error
                                                            id="standard-weight-answer"
                                                        >
                                                            {errors.answer}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>

                                                <Button
                                                    fullWidth
                                                    size="medium"
                                                    type="submit"
                                                    variant="contained"
                                                    disabled={loading}
                                                    sx={{
                                                        height: "48px",
                                                        marginTop: "10px",

                                                        background: "#F1A3A5",
                                                        borderRadius: "48px",
                                                        fontWeight: "600",
                                                        fontSize: "18px",
                                                        lineHeight: "16px",
                                                        color: "#FFF",
                                                        '&:hover': {
                                                            backgroundColor: '#F1A3A5',
                                                            color: '#FFF',
                                                        },
                                                    }}
                                                >

                                                    {loading === true ? <CircularProgress sx={{ color: "#FFF" }} size={"1.8rem"} /> : "Save changes"}
                                                </Button>

                                                {props.toedit === true ? <Button
                                                    fullWidth
                                                    size="medium"
                                                    variant="outlined"
                                                    disabled={loading}
                                                    onClick={() => { deleteItem(values); }}
                                                    sx={{
                                                        height: "48px",
                                                        marginTop: "10px",
                                                        background: "#FFFFFF",
                                                        border: "1px solid #D3180C",
                                                        borderRadius: "48px",
                                                        fontWeight: "600",
                                                        fontSize: "16px",
                                                        lineHeight: "16px",
                                                        color: "#D3180C",
                                                        '&:hover': {
                                                            backgroundColor: 'text.tertiaryHover',
                                                            color: '#FFFFFF',
                                                        },
                                                    }}
                                                >
                                                    Delete item
                                                </Button> : null}
                                            </Box>
                                        </Grid>
                                    </form>


                                )}
                            </Formik>
                        </LocalizationProvider>
                    </DialogContent>
                </Box>

            </Dialog>

        </div >
    );
}