import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
// import StarIcon from "@mui/icons-material/Star";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link } from "@mui/material";
// import Box from "@mui/material/Box";
const VendorCategoryCard = ({ vendorCategory, isFromFavorites = false, setCurrentVendorCategory }) => {
  return (
    <Grid item xs={6} md={4} lg={3}>
      <Link
      href={isFromFavorites ? `/vendor-category/favorites` : `/vendor-category/${vendorCategory._id}`}
      onClick={() => setCurrentVendorCategory(vendorCategory)}
      underline="none"
      >
        <Card
          sx={{
            // maxWidth: 545,
            width: "100%",
            margin: "auto",
            cursor: "pointer",
            height: "100%",
            transition: "all .2s ease-in-out",
            "&:hover": {
              transform: "scale(1.01)",
            },
          }}
          elevation={0}
        >
          <CardMedia
            component="img"
            sx={{ height: { xs: "100px", md: "220px" } }}
            image={`${vendorCategory.icon}`}
          />
          <CardContent
            sx={{
              paddingLeft: "5px",
            }}
          >
            <Typography
              sx={{
                color: "#090A0A",
                fontWeight: "700",
                fontSize: { xs: "0.8rem", md: "1.2rem" },
                marginBottom: "0.5rem",
                // textAlign: "start"
              }}
            >
              {vendorCategory.name}
            </Typography>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
};

export default VendorCategoryCard;
