import React, { useEffect, useContext } from "react";
import Box from '@mui/material/Box';
import DesktopFooter from "./DesktopFooter";
import PrivacyLinksSection from "./PrivacyLinksSection";

const Footer = () => {
  

  return (
    <Box
    sx={{
      //padding:"1rem 1rem",
      backgroundColor: "primary.main",
      minHeight: 465,
      color: "text.light",
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
      alignItems:"center"
    }}
  >
    <DesktopFooter/>


    <Box
      sx={{
        width: "95%",
        borderBottom: "2px solid #fff",
        opacity:0.15,
        justifySelf: "center",
        marginTop: "3rem",
        marginBottom: "3rem",
      }}
    ></Box>

      <PrivacyLinksSection/>
    
    </Box>

  );
};
export default Footer;
